import cn from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import ReactPlayer from 'react-player';
import {render} from 'storyblok-rich-text-react-renderer';

import DynamicComponent from '@/src/dynamicComponents/components/DynamicComponent';
import VideoFile from '@/src/howTo/components/fileWithPreview/VideoFile';
import css from '@/src/landing/styles/ForWhom.module.css';
import {Text} from '@/src/ui';

const VIMEO = 'https://vimeo.com/';
const VIDEO_FORMATS = ['.mp4', '.webm', '.ogg'];

const ForWhomMedia = ({src}) => {
  if (!src) {
    return null;
  }

  if (src.includes(VIMEO)) {
    return (
      <div className={css.vimeoVideo}>
        <ReactPlayer url={src} width={'100%'} height={'auto'} controls />
      </div>
    );
  }

  if (VIDEO_FORMATS.some((format) => src.includes(format))) {
    return <VideoFile src={src} withDownloadButton={false} />;
  }

  return <img src={src} loading="lazy" alt="" />;
};
const ForWhomLayout = ({
  title,
  description,
  image,
  subTitle,
  isReverse,
  ...rest
}) => {
  return (
    <div
      {...rest}
      className={cn(css.main, {
        [css.reverse]: isReverse,
      })}
    >
      <div className={css.info}>
        <div className={css.title}>
          <Text
            fontSize={Text.FONT_SIZE.SIZE_32}
            fontWeight={Text.FONT_WEIGHT.BOLD}
            lineHeight={Text.LINE_HEIGHT.LINE_HEIGHT_1_4}
            tagComponent={Text.TAG.H2}
          >
            {title}
          </Text>
        </div>
        <div className={'subtitle'}>
          <Text
            color={Text.COLOR.GREY}
            fontSize={Text.FONT_SIZE.SIZE_18}
            fontWeight={Text.FONT_WEIGHT.BOLD}
            lineHeight={Text.LINE_HEIGHT.LINE_HEIGHT_1_6}
          >
            {subTitle}
          </Text>
        </div>
        <div className={'description'}>
          <Text
            color={Text.COLOR.GREY}
            fontSize={Text.FONT_SIZE.SIZE_18}
            lineHeight={Text.LINE_HEIGHT.LINE_HEIGHT_1_6}
          >
            {typeof description === 'string'
              ? description
              : render(description, {
                  // eslint-disable-next-line react/display-name
                  defaultBlokResolver: (name, props) => (
                    <DynamicComponent
                      blok={{
                        component: name,
                        ...props,
                      }}
                    />
                  ),
                })}
          </Text>
        </div>
      </div>
      <div className={css.image}>
        <ForWhomMedia src={image} />
      </div>
    </div>
  );
};

ForWhomLayout.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  isReverse: PropTypes.bool,
};

ForWhomMedia.propTypes = {
  src: PropTypes.string.isRequired,
};

export default ForWhomLayout;
