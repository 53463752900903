import cn from 'clsx';
import {MouseEventHandler} from 'react';

import css from '../styles/Dropdown.module.css';

interface DropdownProps {
  children: Array<JSX.Element>;
  toggleNode: JSX.Element;
  isLeftSide?: boolean;
  isAside?: boolean;
}

interface DropdownItemProps {
  onClink: MouseEventHandler<HTMLLIElement>;
  isActive?: Boolean;
  children: JSX.Element | string;
  withBorder?: boolean;
}

export const DropdownItem = ({
  onClink,
  isActive,
  children,
  withBorder,
}: DropdownItemProps) => {
  return (
    <li
      onClick={onClink}
      className={cn(css.dropdownListItem, {
        [css.dropdownListItemActive]: isActive,
        [css.withBorder]: withBorder,
      })}
    >
      {children}
    </li>
  );
};

const Dropdown = ({
  children,
  toggleNode,
  isLeftSide,
  isAside,
}: DropdownProps) => {
  return (
    <div
      className={cn(css.main, {
        [css.asideCurret]: isAside,
      })}
    >
      <div>{toggleNode}</div>
      <div
        className={cn(css.dropdownWrapper, {
          [css.leftSide]: isLeftSide,
          [css.asideDropDown]: isAside,
        })}
      >
        <ul className={css.dropdownList}>
          {children}
          <span className={css.dropdownListIcon} />
        </ul>
      </div>
    </div>
  );
};

export default Dropdown;
