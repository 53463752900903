import {ChangeEvent} from 'react';

import css from '../styles/Toggle.module.css';

interface ToggleProps {
  value: boolean;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

const Toggle = ({value, onChange}: ToggleProps) => {
  return (
    <div className={css.container}>
      <input
        type="checkbox"
        className={css.checkbox}
        checked={value}
        onChange={onChange}
      />
      <div className={css.knobs} />
      <div className={css.layer} />
    </div>
  );
};

export default Toggle;
