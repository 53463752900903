import PropTypes from 'prop-types';

import ForWhomLayout from '@/src/landing/components/ForWhomLayout';

const ForWhom = ({title, subTitle, description, image, isReverse}) => {
  return (
    <ForWhomLayout
      title={title}
      subTitle={subTitle}
      description={description}
      image={image}
      isReverse={isReverse}
    />
  );
};

ForWhom.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  isReverse: PropTypes.bool,
};

ForWhom.defaultProps = {
  isReverse: false,
};

export default ForWhom;
