import Head from 'next/head';
import {useRouter} from 'next/router';
import {useTranslation} from 'next-i18next';
import {serverSideTranslations} from 'next-i18next/serverSideTranslations';
import PropTypes from 'prop-types';

import Storyblok, {useStoryblok} from '@/src/app/utils/storyblok';
import LandingLayout from '@/src/landing/components/LandingLayout';

import {PREVIEW_IMAGE_PATH} from './job-portal/position/[slug]';
const LOCALE_KEY = 'LOCALE_KEY';

export const LANDING_PAGE_SLUG = 'landing-v2';

export const getLocaleFromStorage = () => {
  return localStorage.getItem(LOCALE_KEY) || null;
};

export const setLocaleToStorage = (locale) => {
  localStorage.setItem(LOCALE_KEY, locale);
};

const Homepage = ({story, locale, preview, host}) => {
  const {t} = useTranslation('metaData');
  const {asPath} = useRouter();
  const fullPath = `https://${host}${asPath}`;
  const imagesPath = `https://${host}/${PREVIEW_IMAGE_PATH}`;

  const metaTitle = t('landing.title');
  const metaDescription = t('landing.description');

  story = useStoryblok(story, preview, locale);

  return (
    <>
      <Head>
        <title>{metaTitle}</title>
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="title" content={metaTitle} />
        <meta name="description" content={metaDescription} />

        {/*Open Graph / Facebook*/}
        <meta property="og:type" content="website" />
        <meta property="og:url" content={fullPath} />
        <meta property="og:title" content={metaTitle} />
        <meta property="og:description" content={metaDescription} />
        <meta property="og:image" content={imagesPath} />

        {/*Twitter*/}
        <meta name="twitter:site" content={host} />
        <meta name="twitter:title" content={metaTitle} />
        <meta name="twitter:description" content={metaDescription} />
        <meta name="twitter:image" content={imagesPath} />
      </Head>
      <LandingLayout storyBlock={story.content} />;
    </>
  );
};

export async function getServerSideProps({locale, ...context}) {
  const slug = LANDING_PAGE_SLUG;
  const insideStoryblok = context.query._storyblok;
  const shouldLoadDraft = context.preview || insideStoryblok;

  // load the published content outside of the preview mode
  let storyblokParams: IStorblokParams = {
    version: 'publish', // or 'published'
    language: locale,
  };

  if (shouldLoadDraft) {
    // load the draft version inside of the preview mode
    storyblokParams.version = 'draft';
    storyblokParams.cv = Date.now();
  }

  let {data} = await Storyblok.get(`cdn/stories/${slug}`, storyblokParams);

  return {
    props: {
      host: context.req.headers.host,
      locale,
      story: data ? data.story : false,
      preview: shouldLoadDraft || false,
      ...(await serverSideTranslations(locale, [
        'common',
        'service',
        'doctors',
        'metaData',
      ])),
    },
  };
}

Homepage.propTypes = {
  story: PropTypes.object.isRequired,
  locale: PropTypes.string.isRequired,
  preview: PropTypes.bool.isRequired,
};

export default Homepage;
