import Image from 'next/image';

import {Text} from '@/src/ui';

import css from '../../styles/HeroBenefits.module.css';

const BenefitItem = ({icon, title}) => {
  return (
    <div className={css.benefitItem}>
      <div className={css.image}>
        <Image
          className={css.image}
          src={icon}
          loading="lazy"
          alt={''}
          width={30}
          height={30}
        />
      </div>

      <Text
        fontSize={Text.FONT_SIZE.SIZE_18}
        color={Text.COLOR.WHITE}
        fontWeight={Text.FONT_WEIGHT.THIN}
      >
        {title}
      </Text>
    </div>
  );
};

const HeroBenefits = ({items}) => {
  return (
    <div className={css.benefits}>
      {items.map((item) => (
        <BenefitItem key={item.title} {...item} />
      ))}
    </div>
  );
};

export default HeroBenefits;
