import cn from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

import css from '../styles/Card.module.css';

const SIZE = {
  SMALL: 'small',
  regular: 'regular',
};

const BORDER_RADIUS = {
  SMALL: 'borderRadiusSmall',
  REGULAR: 'borderRadiusRegular',
};

const INNER_OFFSET = {
  NONE: 'padding0',
  REGULAR: 'paddingRegular',
  MEDIUM_EQUAL: 'paddingMediumEqual',
};

const Card = ({size, innerOffset, children, borderRadius}) => {
  return (
    <div
      className={cn(css.card, css[size], css[borderRadius], css[innerOffset])}
    >
      {children}
    </div>
  );
};

Card.BORDER_RADIUS = BORDER_RADIUS;
Card.SIZE = SIZE;
Card.INNER_OFFSET = INNER_OFFSET;

Card.propTypes = {
  size: PropTypes.oneOf(Object.values(SIZE)),
  innerOffset: PropTypes.oneOf(Object.values(INNER_OFFSET)),
  borderRadius: PropTypes.oneOf(Object.values(INNER_OFFSET)),
  children: PropTypes.node,
};

Card.defaultProp = {
  size: SIZE.SMALL,
  innerOffset: INNER_OFFSET.REGULAR,
  borderRadius: BORDER_RADIUS.REGULAR,
};

export default Card;
