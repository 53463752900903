import {useRouter} from 'next/router';
import {useCallback, useContext, useEffect} from 'react';

import {RESPONSE_STATUSES} from '@/src/app/constants/api';
import {
  PDF_TYPE,
  SUPPORTED_IMAGES,
  VIDEO_SUPPORTED_FORMAT,
} from '@/src/app/constants/course';
import {ROLES_NAMES} from '@/src/app/constants/roles';
import useUserActions from '@/src/app/store/user/useUserActions';
import {getAuthorizationToken} from '@/src/app/utils/authorizationToken';
import useEmploymentTypes from '@/src/hooks/useEmploymentTypes';
import useLoading from '@/src/hooks/useLoading';
import useApplicationPopup from '@/src/jobPortal/applicationPopup/utils/useApplicationPopup';
import ClinicPositionPreviewLayout from '@/src/jobPortal/clinicPositionPreview/components/ClinicPositionPreviewLayout';
import SuccessfulPublishPopup from '@/src/jobPortal/clinicPositionPreview/containers/SuccessfulPublishPopup';
import publishClinicPosition from '@/src/jobPortal/clinicPositionPreview/utils/publishClinicPosition';
import useClinicPositions from '@/src/jobPortal/clinicPositions/hooks/useClinicPositions';
import JopPortalManagePermission from '@/src/jobPortal/common/containers/JopPortalManagePermission';
import SinglePositionBootstrap from '@/src/jobPortal/common/containers/SinglePositionBootstrap';
import useSingleJobPosition from '@/src/jobPortal/common/hooks/useSingleJobPosition';
import MainContainer from '@/src/main/container/MainContainer';
import PopupContext from '@/src/popup/utils/PopupContext';
import useTargetClinic from '@/src/profile/clinicTarget/hooks/useTargetClinic';
import RouteContext from '@/src/routes/utils/RouteContext';
import {LoadingWrapper} from '@/src/ui';

interface ClinicPositionPreviewProps {
  isTargetPage?: boolean;
}

const getPdfAttachments = (mediaAttachments = []) => {
  return (
    mediaAttachments.filter((item) => {
      return PDF_TYPE.includes(item?.content_type);
    }) || []
  );
};

const getImageAttachments = (mediaAttachments = []) => {
  return (
    mediaAttachments.filter((item) => {
      return SUPPORTED_IMAGES.includes(item?.content_type);
    }) || []
  );
};

const getVideoAttachments = (mediaAttachments = []) => {
  return (
    mediaAttachments.filter((item) => {
      return VIDEO_SUPPORTED_FORMAT.includes(item?.content_type);
    }) || []
  );
};

const ClinicPositionPreview = ({isTargetPage}: ClinicPositionPreviewProps) => {
  const {push, query} = useRouter();
  const {isLoading, setLoading} = useLoading();
  const {user: userData} = useUserActions();
  const {data, isFetching, getJobPosition} = useSingleJobPosition(
    userData?.clinic?.id
  );
  const {employmentTypes} = useEmploymentTypes();
  const {requestTargetClinicById, resetTargetClinic, clinic} =
    useTargetClinic();
  // @ts-ignore
  const {
    // @ts-ignore
    JOB_PORTAL_MY_POSITIONS,
    // @ts-ignore
    JOB_PORTAL_MY_POSITION_EDIT,
    // @ts-ignore
    JOB_PORTAL_APPLICANTS_PAGE,
  } = useContext(RouteContext);
  const {openPopup} = useContext(PopupContext);
  const {archivePositionWithConfirm} = useClinicPositions();
  const {openApplicationPopup} = useApplicationPopup();
  const isAuthorized = Boolean(getAuthorizationToken());
  const isCurrentUserClinic = userData?.user?.type === ROLES_NAMES.CLINIC;

  useEffect(() => {
    if (!data.clinic_id) {
      return null;
    }

    requestTargetClinicById(data.clinic_id);

    return () => {
      resetTargetClinic();
    };
  }, [data]);

  const handleDashboardClick = useCallback(() => {
    push(JOB_PORTAL_MY_POSITIONS.getLink());
  }, []);

  const handleEditClick = useCallback(() => {
    push(JOB_PORTAL_MY_POSITION_EDIT.getLink(data.id));
  }, [data]);

  const handlePublishClick = useCallback(async () => {
    setLoading(true);

    const {status, responseBody} = await publishClinicPosition(data.id);

    setLoading(false);

    if (status === RESPONSE_STATUSES.SUCCESS) {
      // @ts-ignore
      openPopup({
        popup: (
          <SuccessfulPublishPopup
            vacancyTitle={responseBody.title}
            vacancyExpireDate={responseBody.expire_date}
          />
        ),
        options: {
          withCloseButton: true,
          onClose: () => {
            push(JOB_PORTAL_MY_POSITIONS.getLink());
          },
        },
      });
    }
  }, [data, query, getJobPosition]);

  const handleArchiveClick = useCallback(() => {
    archivePositionWithConfirm(data.id, data.title, true);
  }, [data]);

  const onSuccessfulApplicationCallback = useCallback(() => {
    if (isAuthorized) {
      getJobPosition(query.slug);
    }
  }, [isAuthorized, query]);

  const handleApplyClick = useCallback(() => {
    openApplicationPopup(null, {
      positionId: data.id,
      positionTitle: data.title,
      clinicName: data.clinic.organization_name,
      onSuccessfulApplicationCallback: onSuccessfulApplicationCallback,
    });
  }, [data, onSuccessfulApplicationCallback]);

  const handleViewApplicantsClick = useCallback((vacancyId: number) => {
    push(JOB_PORTAL_APPLICANTS_PAGE.getLink(vacancyId));
  }, []);

  return (
    <MainContainer topOffset={MainContainer.TOP_OFFSET.OFFSET_0}>
      <JopPortalManagePermission shouldSkipCheck={isTargetPage}>
        <SinglePositionBootstrap>
          <LoadingWrapper
            isLoading={isFetching || !employmentTypes.length || !clinic?.data}
            hideContentBeforeLoad
          >
            <ClinicPositionPreviewLayout
              isApplied={data?.is_applied}
              isTargetPage={isTargetPage}
              onApplyClick={handleApplyClick}
              onDashboardClick={handleDashboardClick}
              onEditClick={handleEditClick}
              onPublishClick={handlePublishClick}
              positionData={data}
              employmentTypes={employmentTypes}
              clinicData={clinic.data}
              isLoading={isLoading}
              pdfAttachments={getPdfAttachments(data.media_attachments)}
              videoAttachments={getVideoAttachments(data.media_attachments)}
              imageAttachments={getImageAttachments(data.media_attachments)}
              onArchiveClick={handleArchiveClick}
              positionStatus={data.status}
              isCurrentUserClinic={isCurrentUserClinic}
              onViewApplicantsClick={handleViewApplicantsClick}
              applicantsCount={data?.vacancy_applications_count}
            />
          </LoadingWrapper>
        </SinglePositionBootstrap>
      </JopPortalManagePermission>
    </MainContainer>
  );
};

export default ClinicPositionPreview;
