import NavLink from 'next/link';

import useRoutePaths from '@/src/hooks/useRoutePaths';

import css from '../../styles/TryMedyoucate.module.css';

const TryMedyoucate = ({title, description, buttonTitle}) => {
  const {SIGN_UP_ROUTE} = useRoutePaths();
  return (
    <div className={css.main}>
      <h2 className={css.title}>{title}</h2>
      <div
        className={css.description}
        dangerouslySetInnerHTML={{
          __html: description,
        }}
      />
      <NavLink href={SIGN_UP_ROUTE.getLink()}>
        <a aria-label={'link to sing-up'}>
          <button className={css.button}>
            <span>{buttonTitle}</span>
          </button>
        </a>
      </NavLink>
    </div>
  );
};

export default TryMedyoucate;
