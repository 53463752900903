import NavLink from 'next/link';
import {useMemo} from 'react';

import PriceFeaturesTable from '@/src/promoSections/components/PricingSectionLayout/PriceFeaturesTable';
import css from '@/src/promoSections/styles/PricingSection.module.css';
import {Button, Text} from '@/src/ui';

const getArrayOfMaxElements = (
  doctorsList,
  studentList,
  contentCreatorList,
  clinicList
) => {
  const maxArray = Math.max(
    doctorsList.length,
    studentList.length,
    contentCreatorList.length,
    clinicList.length
  );

  return new Array(maxArray).fill(undefined).map((value, index) => index);
};

interface PricingDesktopProps {
  pricingList: Array<any>;
  doctorsList: Array<any>;
  studentList: Array<any>;
  contentCreatorList: Array<any>;
  clinicList: Array<any>;
  currencySymbol: string;
  isYearly: boolean;
}

const PricingDesktop = ({
  pricingList,
  doctorsList,
  studentList,
  contentCreatorList,
  clinicList,
  currencySymbol,
  isYearly,
}: PricingDesktopProps) => {
  const maxArray = getArrayOfMaxElements(
    doctorsList,
    studentList,
    contentCreatorList,
    clinicList
  );

  const preparedArray = maxArray.reduce(
    (previousValue, currentValue) => [
      ...previousValue,
      [
        doctorsList[currentValue],
        studentList[currentValue],
        contentCreatorList[currentValue],
        clinicList[currentValue],
      ],
    ],
    []
  );

  const pricingCardNodes = useMemo(
    () =>
      pricingList.map(
        ({
          icon,
          id,
          title,
          price,
          yearlyPrice,
          discount,
          description,
          slug,
          buttonText,
          oldPrice,
          oldPriceYearly,
          descriptionMonthly,
        }) => {
          return (
            <div key={id}>
              <div className={css.priceCard}>
                <div className={css.priceIcon}>
                  <img src={icon} loading="lazy" alt={title} />
                </div>

                <div className={css.priceInfoText}>
                  <Text
                    fontSize={Text.FONT_SIZE.SIZE_26}
                    fontFamily={Text.FONT_FAMILY.RUBIK}
                    fontWeight={Text.FONT_WEIGHT.MEDIUM}
                  >
                    {title}
                  </Text>
                </div>

                <div className={css.priceInfo}>
                  {discount && (
                    <div className={css.oldPrice}>
                      {currencySymbol}
                      {isYearly ? oldPriceYearly : oldPrice}
                    </div>
                  )}

                  <div className={css.priceWrapper}>
                    <div className={css.priceCurrent}>
                      <Text
                        fontSize={Text.FONT_SIZE.SIZE_32}
                        fontWeight={Text.FONT_WEIGHT.MEDIUM}
                        fontFamily={Text.FONT_FAMILY.RUBIK}
                        lineHeight={Text.LINE_HEIGHT.LINE_HEIGHT_1}
                      >
                        {currencySymbol}
                        {isYearly ? yearlyPrice : price}
                      </Text>
                    </div>{' '}
                    {discount && (
                      <div className={css.discount}>-{discount}%</div>
                    )}
                  </div>

                  <div className={css.priceDescription}>
                    <Text
                      fontSize={Text.FONT_SIZE.SIZE_12}
                      color={Text.COLOR.GREY}
                    >
                      {isYearly ? description : descriptionMonthly}
                    </Text>
                  </div>
                </div>

                <div className={css.priceButton}>
                  <NavLink href={slug}>
                    <Button height={Button.HEIGHT.SMALL}>{buttonText}</Button>
                  </NavLink>
                </div>
              </div>
            </div>
          );
        }
      ),
    [pricingList]
  );

  return (
    <div>
      <div className={css.pricingWrapper}>{pricingCardNodes}</div>
      <div className={css.priceFeaturesWrapper}>
        {preparedArray.map((arrayOfFeatures) => (
          <div className={css.priceFeatures} key={arrayOfFeatures}>
            <PriceFeaturesTable features={arrayOfFeatures} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default PricingDesktop;
