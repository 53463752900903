import {useRouter} from 'next/router';
import {useTranslation} from 'next-i18next';
import {useCallback, useContext, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {RESPONSE_STATUSES} from '@/src/app/constants/api';
import {ORDER_FILTER, SEARCH_FILTERS} from '@/src/app/constants/search';
import {
  selectMyPositions,
  setFilterCountAction,
  setIsLoadingAction,
  setPositionsListAction,
} from '@/src/app/store/jobPortal/myPositionsSlice';
import requestArchivePosition from '@/src/jobPortal/clinicPositions/utils/requestArchivePosition';
import requestDeletePosition from '@/src/jobPortal/clinicPositions/utils/requestDeletePosition';
import requestGetFilterCount from '@/src/jobPortal/clinicPositions/utils/requestGetFilterCount';
import requestGetMyJobPositions from '@/src/jobPortal/clinicPositions/utils/requestGetMyJobPositions';
import ConfirmPopup from '@/src/popup/components/ConfirmPopup';
import PopupContext from '@/src/popup/utils/PopupContext';
import RouteContext from '@/src/routes/utils/RouteContext';

export interface jopPositionsFilterProps {
  order: string;
  'filters[status]': string;
}

export const STATUSES_JOBS = {
  ACTIVE: '["active"]',
  DRAFT: '["draft"]',
  ARCHIVED: '["archived", "expired"]',
};

export const STATUS_JOBS_LIST = [
  {
    value: STATUSES_JOBS.ACTIVE,
    label: 'job-portal.active',
    orderBy: '',
  },
  {
    value: STATUSES_JOBS.DRAFT,
    label: 'job-portal.drafts',
    orderBy: '',
  },
  {
    value: STATUSES_JOBS.ARCHIVED,
    label: 'job-portal.archived',
    orderBy: '',
  },
];

export const ORDER_JOBS_LIST = [
  {
    value: ORDER_FILTER.DESC,
    label: 'tables.newest-to-oldest',
  },
  {
    value: ORDER_FILTER.ASC,
    label: 'tables.oldest-to-newest',
  },
];

const useClinicPositions = () => {
  const dispatch = useDispatch();
  const router = useRouter();
  const {closePopup, openPopup} = useContext(PopupContext);
  const {t} = useTranslation('common');
  const {isLoading, filterCount, ...data} = useSelector(selectMyPositions);
  // @ts-ignore
  const {JOB_PORTAL_MY_POSITIONS, JOB_PORTAL_APPLICANTS_PAGE} =
    useContext(RouteContext);

  const filter = useMemo(
    () => ({
      [SEARCH_FILTERS.FILTERS_STATUS]:
        router.query[SEARCH_FILTERS.FILTERS_STATUS] ||
        STATUS_JOBS_LIST[0].value,
      [SEARCH_FILTERS.ORDER_BY]: router.query[SEARCH_FILTERS.ORDER_BY] || '',
      [SEARCH_FILTERS.ORDER]:
        router.query[SEARCH_FILTERS.ORDER] || ORDER_JOBS_LIST[0].value,
    }),
    [router.query]
  );

  const currentType = useMemo(
    () => JSON.parse(filter[SEARCH_FILTERS.FILTERS_STATUS] as string)[0],
    [filter]
  );

  const onClearClick = useCallback(() => {
    const pathname = router.asPath.split('?')[0];

    router.push({
      pathname: pathname,
      query: {
        [SEARCH_FILTERS.FILTERS_STATUS]: STATUS_JOBS_LIST[0].value,
        [SEARCH_FILTERS.ORDER]: ORDER_JOBS_LIST[0].value,
      },
    });
  }, [router]);

  const getFilterCount = useCallback(async () => {
    const {status, responseBody} = await requestGetFilterCount();

    if (status === RESPONSE_STATUSES.SUCCESS) {
      const newValues = {
        active: responseBody?.statuses?.active || 0,
        draft: responseBody?.statuses?.draft || 0,
        archived: responseBody?.statuses?.archived_and_expired || 0,
      };

      dispatch(setFilterCountAction(newValues));
    }
  }, [data]);

  const onFilterChange = useCallback(
    (newFilter) => {
      const pathName = router.asPath.split('?')[0];

      router.push({
        pathname: pathName,
        query: {
          ...filter,
          ...newFilter,
        },
      });
    },
    [filter, router.query]
  );

  const getPositionsList = useCallback(
    async (filter: any) => {
      dispatch(setIsLoadingAction(true));
      getFilterCount();

      const {status, responseBody} = await requestGetMyJobPositions(filter);

      if (status === RESPONSE_STATUSES.SUCCESS) {
        dispatch(
          setPositionsListAction({
            type: currentType,
            data: responseBody.data,
            totalAmount: responseBody.total_amount,
          })
        );
      }

      dispatch(setIsLoadingAction(false));
    },
    [currentType]
  );

  /*
   * This function is used to set correct page on items change
   */
  const setCorrectPageOnItemsChange = (data) => {
    if (
      Number(router.query[SEARCH_FILTERS.OFFSET]) > 0 &&
      Number(data.length) === 0
    ) {
      const pathname = router.asPath.split('?')[0];
      const newOffset =
        Number(router.query[SEARCH_FILTERS.OFFSET]) -
        Number(router.query[SEARCH_FILTERS.PER_PAGE]);

      router.push({
        pathname: pathname,
        query: {
          ...router.query,
          offset: newOffset,
        },
      });
    } else {
      getPositionsList(router.query);
    }
  };

  const deletePosition = useCallback(
    async (id: number): Promise<void> => {
      dispatch(setIsLoadingAction(true));
      closePopup();

      const {status} = await requestDeletePosition(id);

      if (status === RESPONSE_STATUSES.SUCCESS) {
        const preparedData = data[currentType].data.filter(
          (position) => position.id !== id
        );
        dispatch(
          setPositionsListAction({
            type: currentType,
            data: preparedData,
            totalAmount: data[currentType].totalAmount - 1,
          })
        );

        setCorrectPageOnItemsChange(preparedData);
        getFilterCount();
      }

      dispatch(setIsLoadingAction(false));
    },
    [data, currentType]
  );

  const deletePositionWithConfirm = useCallback(
    (id: number, title: string): void => {
      // @ts-ignore
      openPopup({
        popup: (
          <ConfirmPopup
            reverseConfirm
            onConfirmClick={() => deletePosition(id)}
            onCancelClick={closePopup}
            cancelButtonText={t('employees.no-dont-delete')}
            confirmButtonText={t('employees.yes-delete')}
            mainText={t('job-portal.confirmDeletePosition', {position: title})}
            secondaryText={t('job-portal.all-deleted-data-permanetly deleted')}
          />
        ),
        options: {withCloseButton: true, withTransparentBackground: true},
      });
    },
    [deletePosition, data]
  );

  const archivePosition = useCallback(
    async (id: number, withRedirectToMyPositions = false): Promise<void> => {
      dispatch(setIsLoadingAction(true));

      const {status} = await requestArchivePosition(id);

      if (status === RESPONSE_STATUSES.SUCCESS) {
        const preparedData = data[currentType].data.filter(
          (position) => position.id !== id
        );
        dispatch(
          setPositionsListAction({
            type: currentType,
            data: preparedData,
            totalAmount: data[currentType].totalAmount - 1,
          })
        );

        setCorrectPageOnItemsChange(preparedData);
        getFilterCount();

        closePopup();
      }

      dispatch(setIsLoadingAction(false));

      if (withRedirectToMyPositions) {
        router.push(JOB_PORTAL_MY_POSITIONS.getLink());
      }
    },
    [data, currentType]
  );

  const archivePositionWithConfirm = useCallback(
    (id, title, withRedirectToMyPositions = false) => {
      // @ts-ignore
      openPopup({
        popup: (
          <ConfirmPopup
            reverseConfirm
            onConfirmClick={() =>
              archivePosition(id, withRedirectToMyPositions)
            }
            onCancelClick={closePopup}
            cancelButtonText={t('job-portal.not-archive')}
            confirmButtonText={t('job-portal.yes-archive')}
            mainText={t('job-portal.confirmArchivePosition', {position: title})}
            secondaryText={t('job-portal.allInfoArchived')}
          />
        ),
        options: {withCloseButton: true, withTransparentBackground: true},
      });
    },
    [archivePosition, data, currentType, filterCount]
  );

  const openVacancyApplicants = useCallback(
    (vacancyId: number) => {
      router.push(JOB_PORTAL_APPLICANTS_PAGE.getLink(vacancyId));
    },
    [router]
  );

  return {
    onFilterChange,
    deletePosition,
    getPositionsList,
    archivePosition,
    deletePositionWithConfirm,
    archivePositionWithConfirm,
    isLoading,
    filterCount,
    filter,
    getFilterCount,
    onClearClick,
    openVacancyApplicants,
    ...data,
  };
};

export default useClinicPositions;
