import {serialize} from 'object-to-formdata';

import {REST_METHODS} from '@/src/app/constants/api';
import authorizedFetch from '@/src/app/utils/authorizedFetch';
import unauthorizedFetch from '@/src/app/utils/unauthorizedFetch';

const AUTHORIZED_FETCH_PATH = '/api/v1/job_portal/vacancy_applications';
const UNAUTHORIZED_FETCH_PATH =
  '/api/v1/job_portal/vacancy_applications/incognito';

const postVacancyApplication = (
  {
    vacancyId,
    message,
    cv_file,
    first_name = null,
    last_name = null,
    email = null,
  },
  isAuthorized,
  locale
) => {
  const fetchMethod = isAuthorized ? authorizedFetch : unauthorizedFetch;
  const fetchPath = isAuthorized
    ? AUTHORIZED_FETCH_PATH
    : UNAUTHORIZED_FETCH_PATH;

  const formData = serialize({
    vacancy_application: {
      vacancy_id: vacancyId,
      message,
      cv_file,
    },
    user: isAuthorized
      ? null
      : {
          first_name,
          last_name,
          email,
          language: locale,
        },
  });

  // @ts-ignore
  return fetchMethod({
    method: REST_METHODS.POST,
    path: fetchPath,
    formData,
  });
};

export default postVacancyApplication;
