import {serialize} from 'object-to-formdata';

import {REST_METHODS} from '@/src/app/constants/api';
import authorizedFetch from '@/src/app/utils/authorizedFetch';

const postClinicImages = (profileFields, clinicId) => {
  const formData = serialize({
    clinic: profileFields,
  });

  // @ts-ignore
  return authorizedFetch({
    method: REST_METHODS.PUT,
    path: `/api/v1/clinics/clinics/${clinicId}`,
    formData,
  });
};

export default postClinicImages;
