import {useRouter} from 'next/router';
import {useTranslation} from 'next-i18next';
import PropTypes from 'prop-types';
import {useCallback, useContext, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';

import {ROLES_NAMES} from '@/src/app/constants/roles';
import {SEARCH_FILTERS} from '@/src/app/constants/search';
import {selectClinicData} from '@/src/app/store/clinic/clinicDataSlice';
import getProfilePicture from '@/src/header/utils/getProfilePicture';
import useRoutePaths from '@/src/hooks/useRoutePaths';

import PopupContext from '../../popup/utils/PopupContext';
import {
  getUserFullName,
  getUserInitials,
  getUserSubheader,
} from '../../profile/common/containers/MyProfileWrapper';
import MobileMenuLayout from '../components/MobileMenuLayout';
import useSignOut from '../utils/useSignOut';
import SupportPopup from './SupportPopup';

interface MobileMenuProps {
  user: any;
  activeMenu?: string;
}
const MobileMenu = ({user, activeMenu}: MobileMenuProps) => {
  const {t} = useTranslation('common');
  const {asPath: currentPath, locale} = useRouter();
  const {
    profile: {picture},
  } = user;
  const [searchQuery, setSearchQuery] = useState('');
  const [isOpened, setIsOpened] = useState(false);
  const {handleSignOutClick} = useSignOut();
  const {openPopup} = useContext(PopupContext);
  const {SEARCH_UNITED_ROUTE} = useRoutePaths();
  const clinicData = useSelector(selectClinicData);

  const handleMenuIconClick = useCallback(() => {
    setIsOpened(!isOpened);
  }, [isOpened]);

  useEffect(() => {
    setIsOpened(false);
  }, [currentPath]);

  const handleSearchQueryChange = useCallback(
    (event) => {
      setSearchQuery(event.target.value);
    },
    [setSearchQuery]
  );

  const handleSupportClick = useCallback(() => {
    // @ts-ignore
    openPopup({
      popup: <SupportPopup />,
      options: {withCloseButton: true},
    });
  }, []);

  const handleSubmitSearchClick = useCallback(
    (event) => {
      event.preventDefault();
      window.location.replace(
        `/${locale}/${SEARCH_UNITED_ROUTE.getLink()}?${
          SEARCH_FILTERS.SEARCH
        }=${searchQuery}`
      );
      setIsOpened(false);
    },
    [searchQuery, locale]
  );

  if (user.user.type === ROLES_NAMES.CLINIC && !clinicData.isFetched) {
    return null;
  }

  return (
    <MobileMenuLayout
      activeMenu={activeMenu}
      onMenuIconClick={handleMenuIconClick}
      isOpened={isOpened}
      onSearchQueryChange={handleSearchQueryChange}
      searchQuery={searchQuery}
      userInitials={getUserInitials(
        user,
        user.user.type === ROLES_NAMES.CLINIC,
        clinicData
      )}
      picture={getProfilePicture(picture, clinicData, user)}
      fullName={getUserFullName(
        user,
        user.user.type === ROLES_NAMES.CLINIC,
        clinicData,
        user?.profile?.prefixed_academic_title
      )}
      userSubheader={getUserSubheader(
        t,
        user,
        user.user.type === ROLES_NAMES.CLINIC,
        clinicData,
        user?.profile?.suffixed_academic_title
      )}
      currentPath={currentPath}
      onSignOutClick={() => handleSignOutClick()}
      onSupportClick={handleSupportClick}
      onSubmitSearchClick={handleSubmitSearchClick}
    />
  );
};

MobileMenu.propTypes = {
  user: PropTypes.object.isRequired,
};

export default MobileMenu;
