import {useField} from 'formik';
import {useRouter} from 'next/router';
import PropTypes from 'prop-types';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';

import {LOCALES} from '@/src/app/constants/locales';
import useOutsideClick from '@/src/hooks/useOutsideClick';
import {
  PLACE_SELECTS_WIDTH,
  SIZE,
} from '@/src/signUp/emailStep/constants/selectsWidthConstants';

import CountriesSelectLayout from '../components/CountriesSelectLayout';

const CountriesSelect = ({
  countriesDictionary,
  isRequired,
  defaultCountryId,
  width,
  size,
  ...props
}) => {
  // @ts-ignore
  const [field, meta, helpers] = useField(props);
  const error = meta.touched && meta.error;
  const [inputValue, setInputValue] = useState('');
  const [selectedIsoCode, setSelectedIsoCode] = useState('');
  const {setValue} = helpers;
  const [isSelectOpened, setIsSelectOpened] = useState(false);
  const selectRef = useRef(null);
  const isDefaultValueSet = useRef(false);
  const {locale} = useRouter();

  const countryFieldName = useMemo(() => {
    return locale === LOCALES.EN ? 'name_en' : 'name_de';
  }, [locale]);

  useEffect(() => {
    if (defaultCountryId && !isDefaultValueSet.current) {
      const defaultCountryItem = countriesDictionary.find(
        (countryItem) => countryItem.id === defaultCountryId
      );

      isDefaultValueSet.current = true;
      setSelectedIsoCode(defaultCountryItem.iso2_code);
      setInputValue(defaultCountryItem[countryFieldName]);
      setValue(defaultCountryItem.id);
    }
  }, [countriesDictionary, defaultCountryId, countryFieldName]);

  useOutsideClick(selectRef, () => {
    setIsSelectOpened(false);
  });

  const filteredCountries = useMemo(() => {
    return countriesDictionary.filter((countryItem) => {
      return countryItem[countryFieldName]
        .toLowerCase()
        .includes(inputValue.toLowerCase());
    });
  }, [countriesDictionary, inputValue, countryFieldName]);

  const handleInputChange = useCallback(
    (event) => {
      const newInputValue = event.target.value;

      setInputValue(newInputValue);

      const matchedCountry = countriesDictionary.find((countryItem) => {
        return (
          countryItem[countryFieldName].toLowerCase() ===
          newInputValue.toLowerCase()
        );
      });

      if (matchedCountry) {
        setSelectedIsoCode(matchedCountry.iso2_code);
        setInputValue(matchedCountry[countryFieldName]);
        setValue(matchedCountry.id);
      } else {
        setValue(null);
        setSelectedIsoCode('');
      }
    },
    [countriesDictionary, countryFieldName]
  );

  const handleSelectItemClick = useCallback(
    (countryItem) => {
      setInputValue(countryItem[countryFieldName]);
      setValue(countryItem.id);
      setSelectedIsoCode(countryItem.iso2_code);
      setIsSelectOpened(false);
    },
    [countryFieldName]
  );

  return (
    <CountriesSelectLayout
      size={size}
      countryFieldName={countryFieldName}
      field={field}
      error={error}
      inputValue={inputValue}
      onInputChange={handleInputChange}
      isSelectOpened={isSelectOpened}
      setIsSelectOpened={setIsSelectOpened}
      selectRef={selectRef}
      onSelectItemClick={handleSelectItemClick}
      countriesDictionary={filteredCountries}
      isRequired={isRequired}
      selectedIsoCode={selectedIsoCode}
      width={width}
    />
  );
};

CountriesSelect.propTypes = {
  size: PropTypes.oneOf(Object.values(SIZE)),
  width: PropTypes.oneOf(Object.values(PLACE_SELECTS_WIDTH)),
  countriesDictionary: PropTypes.array,
  isRequired: PropTypes.bool,
  defaultCountryId: PropTypes.number,
};

export default CountriesSelect;
