import AWS from 'aws-sdk';
import {pathOr} from 'ramda';
import {useState} from 'react';
import {v4 as uuidv4} from 'uuid';

import {RESPONSE_STATUSES, REST_METHODS} from '@/src/app/constants/api';
import authorizedFetch from '@/src/app/utils/authorizedFetch';
import {getSessionAWSToken} from '@/src/app/utils/s3FilesUpload';

import useLoading from './useLoading';

const OLD_KEY_MAX_LENGTH = 37;

const updateKey = (oldKey) => {
  const name = oldKey.slice(OLD_KEY_MAX_LENGTH);
  return `${uuidv4()}_${name}`;
};

const useS3Upload = () => {
  const [progressPercent, setProgressPercent] = useState(0);
  const {isLoading, setLoading} = useLoading();

  const getDuplicateFiles = async (keys) => {
    setLoading(true);

    const {access_key_id, secret_access_key, session_token} =
      await getSessionAWSToken();

    AWS.config.update({
      region: process.env.NEXT_PUBLIC_REGION,
      accessKeyId: access_key_id,
      secretAccessKey: secret_access_key,
      sessionToken: session_token,
      // @ts-ignore
      AWS_SDK_LOAD_CONFIG: 1,
    });

    const s3 = new AWS.S3({httpOptions: {timeout: 10 * 60 * 1000}});

    const listOfPromises = keys.map((key) => {
      const params = {
        Bucket: process.env.NEXT_PUBLIC_S3_BUCKET,
        CopySource: `/${process.env.NEXT_PUBLIC_S3_BUCKET}/${key}`,
        Key: updateKey(key),
      };

      return new Promise((resolve) => {
        s3.copyObject(params, async function () {
          const location = await getFileLocation(params.Key);

          resolve({
            location: location,
            key: params.Key,
            fileName: params.Key,
          });
        });
      });
    });

    setLoading(false);

    return await Promise.allSettled(listOfPromises);
  };

  const getDuplicatedFile = async (key) => {
    setLoading(true);

    const s3 = new AWS.S3({httpOptions: {timeout: 10 * 60 * 1000}});
    const params = {
      Bucket: process.env.NEXT_PUBLIC_S3_BUCKET,
      CopySource: `/${process.env.NEXT_PUBLIC_S3_BUCKET}/${key}`,
      Key: updateKey(key),
    };

    const {access_key_id, secret_access_key, session_token} =
      await getSessionAWSToken();

    AWS.config.update({
      region: process.env.NEXT_PUBLIC_REGION,
      accessKeyId: access_key_id,
      secretAccessKey: secret_access_key,
      sessionToken: session_token,
    });

    setLoading(false);

    return new Promise((resolve) => {
      s3.copyObject(params, async function () {
        const location = await getFileLocation(params.Key);

        resolve({
          location: location,
          key: params.Key,
          fileName: params.Key,
        });
      });
    });
  };

  const getFileLocation = async (key) => {
    // @ts-ignore
    const {status, responseBody} = await authorizedFetch({
      method: REST_METHODS.GET,
      path: '/api/v1/aws_s3/file_url',
      queryParams: [
        {
          name: 'key',
          value: key,
        },
      ],
    });

    if (status === RESPONSE_STATUSES.SUCCESS) {
      return responseBody.url;
    } else {
      return '';
    }
  };

  const s3FilesUpload = async (file, fileName) => {
    const Key = `${uuidv4()}_${fileName}`;

    const params = {
      Body: file,
      Bucket: process.env.NEXT_PUBLIC_S3_BUCKET,
      Key,
    };

    setLoading(true);

    const {access_key_id, secret_access_key, session_token} =
      await getSessionAWSToken();

    AWS.config.update({
      region: process.env.NEXT_PUBLIC_REGION,
      accessKeyId: access_key_id,
      secretAccessKey: secret_access_key,
      sessionToken: session_token,
    });

    // @ts-ignore
    const {location, key} = await s3Uploader(params);
    setLoading(false);

    return {location, key};
  };

  const s3MultiFilesUpload = async (files) => {
    setLoading(true);

    const {access_key_id, secret_access_key, session_token} =
      await getSessionAWSToken();

    AWS.config.update({
      region: process.env.NEXT_PUBLIC_REGION,
      accessKeyId: access_key_id,
      secretAccessKey: secret_access_key,
      sessionToken: session_token,
    });

    const listOfPromises = files.map((file) => {
      const Key = `${uuidv4()}_${file.name}`;

      const params = {
        Body: file,
        Bucket: process.env.NEXT_PUBLIC_S3_BUCKET,
        Key,
      };
      return multiples3Uploader(params, Number(files.length));
    });

    const result = await Promise.allSettled(listOfPromises);

    setLoading(false);
    setProgressPercent(0);

    return result;
  };

  const multiples3Uploader = async (params, countOfFiles) => {
    const s3 = new AWS.S3({httpOptions: {timeout: 10 * 60 * 1000}});
    const options = {partSize: 5 * 1024 * 1024, queueSize: 10};

    let previousUploaded = 0;

    const percentPreFile = 100 / countOfFiles;

    return new Promise((resolve) => {
      s3.upload(params, options)
        .on('httpUploadProgress', function (evt) {
          const filePercent = ((evt.loaded * 100) / evt.total).toFixed();

          // @ts-ignore
          const currentFileProgress = (filePercent / 100) * percentPreFile;

          setProgressPercent((prevState) => {
            return prevState + currentFileProgress - previousUploaded;
          });

          previousUploaded = currentFileProgress;
        })
        .send(async (err, data) => {
          const location = await getFileLocation(data.Key);

          resolve({
            location: location,
            key: pathOr('', ['Key'], data),
            fileName: pathOr('', ['Key'], data),
          });
        });
    });
  };

  const s3Uploader = async (params) => {
    const s3 = new AWS.S3({httpOptions: {timeout: 10 * 60 * 1000}});
    const options = {partSize: 5 * 1024 * 1024, queueSize: 10};

    return new Promise((resolve) => {
      s3.upload(params, options)
        .on('httpUploadProgress', function (evt) {
          // @ts-ignore
          setProgressPercent(((evt.loaded * 100) / evt.total).toFixed());
        })
        .send(function (err, data) {
          resolve({
            location: pathOr('', ['Location'], data),
            key: pathOr('', ['Key'], data),
          });
        });
    });
  };

  const s3DeleteFile = async (key) => {
    const params = {
      Bucket: process.env.NEXT_PUBLIC_S3_BUCKET,
      Key: key,
    };

    const {access_key_id, secret_access_key, session_token} =
      await getSessionAWSToken();

    AWS.config.update({
      region: process.env.NEXT_PUBLIC_REGION,
      accessKeyId: access_key_id,
      secretAccessKey: secret_access_key,
      sessionToken: session_token,
    });

    const s3 = new AWS.S3({httpOptions: {timeout: 10 * 60 * 1000}});

    return new Promise((resolve) => {
      s3.deleteObject(params, function (error, data) {
        resolve(data);
      });
    });
  };

  return {
    s3FilesUpload,
    progressPercent,
    isLoading,
    s3MultiFilesUpload,
    s3DeleteFile,
    getDuplicatedFile,
    getDuplicateFiles,
  };
};

export default useS3Upload;
