import {useCallback, useContext} from 'react';

import {RESPONSE_STATUSES} from '@/src/app/constants/api';

import PopupContext from '../../popup/utils/PopupContext';
import SupportPopupLayout from '../components/SupportPopupLayout';
import postSupportMessage from '../utils/postSupportMessage';

const SupportPopup = () => {
  const {closePopup} = useContext(PopupContext);

  const handleCancelClick = useCallback(() => {
    closePopup();
  }, [closePopup]);

  const handleSubmitClick = useCallback(async ({comment}) => {
    const {status} = await postSupportMessage(comment);

    if (status === RESPONSE_STATUSES.SUCCESS) {
      closePopup();
    }
  }, []);

  return (
    <SupportPopupLayout
      onCancelClick={handleCancelClick}
      onSubmitClick={handleSubmitClick}
    />
  );
};

export default SupportPopup;
