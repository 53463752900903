import cn from 'clsx';
import {useTranslation} from 'next-i18next';
import PropTypes from 'prop-types';
import {useMemo} from 'react';

import Text from '../../text/components/Text';
import {
  COLOR,
  FONT_SIZE,
  FONT_WEIGHT,
} from '../../text/constants/textConstants';
import css from '../styles/Rating.module.css';
import Star from './Star';

const MAX_RATING = 5;

const Rating = ({
  rating,
  withMaxRating,
  color,
  fontSize,
  fontWeight,
  ratingsCount,
  withRating,
}) => {
  const {t} = useTranslation('common');
  const normalizedRating = rating * 2;

  const stars = useMemo(() => {
    const stars = [];

    for (let i = 0; i < 10; i++) {
      let fill = false;

      if (normalizedRating > i && normalizedRating !== null) {
        fill = true;
      }

      stars.push(
        <li
          className={cn(css.item, i % 2 === 0 ? css.full : css.empty)}
          key={i}
        >
          <Star fill={fill} />
        </li>
      );
    }
    return stars;
  }, [rating]);

  return (
    <div className={css.row}>
      <ul className={css.wrapper}>{stars}</ul>
      <Text
        fontSize={fontSize}
        fontFamily={Text.FONT_FAMILY.POPPINS}
        lineHeight={Text.LINE_HEIGHT.LINE_HEIGHT_1_2}
        fontWeight={fontWeight}
        color={color}
      >
        <span className={css.rating}>
          {withRating && rating}
          {withMaxRating && `/${MAX_RATING}`}
          {ratingsCount !== null &&
            t('general.ratings-count', {ratingsCount: ratingsCount})}
        </span>
      </Text>
    </div>
  );
};

Rating.COLOR = COLOR;
Rating.FONT_WEIGHT = FONT_WEIGHT;
Rating.FONT_SIZE = FONT_SIZE;

Rating.propTypes = {
  rating: PropTypes.number,
  withMaxRating: PropTypes.bool,
  withRating: PropTypes.bool,
  color: PropTypes.oneOf(Object.values(Text.COLOR)),
  fontWeight: PropTypes.oneOf(Object.values(Text.FONT_WEIGHT)),
  fontSize: PropTypes.oneOf(Object.values(Text.FONT_SIZE)),
  ratingsCount: PropTypes.number,
};

Rating.defaultProps = {
  rating: 0,
  withMaxRating: false,
  color: Text.COLOR.PRIMARY,
  fontSize: Text.FONT_SIZE.SIZE_14,
  fontWeight: Text.FONT_WEIGHT.BOLD,
  ratingsCount: null,
  withRating: true,
};

export default Rating;
