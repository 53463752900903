import {useCallback, useMemo, useRef, useState} from 'react';

import useOutsideClick from '@/src/hooks/useOutsideClick';
import MultiSelectLayout from '@/src/ui/select/components/MultiSelectLayout';

interface MultiSelectProps {
  selectedOptions: Array<OptionProps>;
  options: Array<OptionProps>;
  onRemoveOption: Function;
  onAddOption: Function;
  placeholder?: string;
  isRequired?: boolean;
  errorMessage?: string;
}

export interface OptionProps {
  value: string;
  label: string;
}

const MultiSelect = ({
  selectedOptions,
  options,
  onRemoveOption,
  onAddOption,
  placeholder,
  isRequired,
  errorMessage,
}: MultiSelectProps) => {
  const [isSelectOpened, setIsSelectOpened] = useState<Boolean>(false);
  const [search, setSearch] = useState('');
  const selectRef = useRef(null);

  const isDropDownOpen = useMemo(
    () => isSelectOpened && Boolean(options.length),
    [isSelectOpened, options]
  );

  const handleOnChange = useCallback((event) => {
    const value = event.target.value;

    setSearch(value);
  }, []);

  useOutsideClick(selectRef, (): void => {
    setIsSelectOpened(false);
    setSearch('');
  });

  const handleSelect = useCallback(
    (value: OptionProps, isSelected: boolean): void => {
      if (isSelected) {
        onRemoveOption(value);
      } else {
        onAddOption(value);
      }

      setSearch('');
    },
    [onAddOption, onRemoveOption]
  );

  const preparedOptions = useMemo(
    () =>
      !search?.length
        ? options
        : options.filter((option) =>
            option.label.toLowerCase().includes(search.toLowerCase())
          ),
    [search, options]
  );

  return (
    <MultiSelectLayout
      selectedOptions={selectedOptions}
      onRemoveOption={onRemoveOption}
      onAddOption={onAddOption}
      selectRef={selectRef}
      search={search}
      handleOnChange={handleOnChange}
      setIsSelectOpened={setIsSelectOpened}
      isDropDownOpen={isDropDownOpen}
      preparedOptions={preparedOptions}
      handleSelect={handleSelect}
      placeholder={placeholder}
      isRequired={isRequired}
      errorMessage={errorMessage}
    />
  );
};

export default MultiSelect;
