import lgVideo from 'lightgallery/plugins/video';
import lgZoom from 'lightgallery/plugins/zoom';
import dynamic from 'next/dynamic';
import {useCallback, useState} from 'react';
import {Swiper, SwiperSlide} from 'swiper/react';

import getRootServerUrl from '@/src/app/utils/getRootServerUrl';
import ClinicImage from '@/src/profile/clinicTarget/components/ClinicImage';
import css from '@/src/profile/clinicTarget/styles/ClinicImagesAndVideo.module.css';
import TextLocal from '@/src/textLocal/containers/TextLocal';
import Text from '@/src/ui/text/components/Text';

const LightGallery = dynamic(() => import('lightgallery/react'), {
  ssr: false,
});

interface ClinicImagesProps {
  images: {
    data: Array<ClinicImageProps>;
  };
}

interface ClinicImageProps {
  id: string | number;
  url: string;
}

const SHORT_PREVIEW_LENGTH = 4;

const ClinicImages = ({images}: ClinicImagesProps): JSX.Element => {
  const [isShort, setIsShort] = useState(true);

  const handleOpenGallery = (link) => {
    return () => {
      const image = document.querySelectorAll(`a[href='${link}']`)[0];

      if (!image) return;

      // @ts-ignore
      image.click();
    };
  };

  const toggleShortImagesList = useCallback(() => {
    setIsShort(!isShort);
  }, [isShort]);

  const prepareImages = isShort
    ? images.data.slice(0, SHORT_PREVIEW_LENGTH)
    : images.data;

  const isShowToggleImages =
    Number(images.data.length) - SHORT_PREVIEW_LENGTH > 0;

  return (
    <div>
      <LightGallery
        licenseKey={process.env.NEXT_PUBLIC_LIGHTGALLERY_LICENSE_KEY}
        speed={400}
        plugins={[lgZoom, lgVideo]}
        mobileSettings={{
          closable: true,
        }}
        download={false}
        zoom={false}
        counter={false}
      >
        {images.data.map(({url, id}) => {
          const link = `${getRootServerUrl()}/${url}`;

          return <a href={link} key={id} aria-label={`zoom image`} />;
        })}
      </LightGallery>
      <div className={css.imagesView}>
        {prepareImages.map(({url, id}) => {
          return (
            <ClinicImage url={url} onImageClink={handleOpenGallery} key={id} />
          );
        })}
        {isShowToggleImages && (
          <button className={css.showMore} onClick={toggleShortImagesList}>
            <Text
              color={Text.COLOR.GRADIENT}
              fontSize={Text.FONT_SIZE.SIZE_16}
              fontWeight={Text.FONT_WEIGHT.BOLD}
              lineHeight={Text.LINE_HEIGHT.LINE_HEIGHT_1_6}
            >
              {isShort ? (
                <>
                  + {Number(images.data.length) - SHORT_PREVIEW_LENGTH} <br />{' '}
                  <TextLocal id={'profile.photos-more'} />
                </>
              ) : (
                <>
                  <TextLocal id={'profile.show-less-photos'} />
                </>
              )}
            </Text>
          </button>
        )}
      </div>
      <div>
        <Swiper
          spaceBetween={16}
          className={css.imagesSlider}
          slidesPerView={'auto'}
        >
          {images.data.map(({url, id}) => {
            return (
              <SwiperSlide key={id} className={css.sliderWidth}>
                <ClinicImage url={url} onImageClink={handleOpenGallery} />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </div>
  );
};

export default ClinicImages;
