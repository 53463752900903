import CalendarIcon from '@/public/icons/calendarLight.svg';
import SpecialityIcon from '@/public/icons/documentIcon.svg';
import PointIcon from '@/public/icons/locationPoint.svg';
import TextLocal from '@/src/textLocal/containers/TextLocal';

import css from '../styles/PositionDetailsList.module.css';

interface PositionDetailsListProps {
  countryName: string;
  stateName: string;
  cityName: string;
  entryDate: string;
  speciality: string;
}

const PositionDetailsList = ({
  countryName,
  stateName,
  cityName,
  entryDate,
  speciality,
}: PositionDetailsListProps) => {
  return (
    <div>
      <div className={css.detailsItem}>
        <SpecialityIcon />
        <span className={css.detailsItemText}>{speciality}</span>
      </div>
      <div className={css.detailsItem}>
        <CalendarIcon width="16" height="18" />
        <span className={css.detailsItemText}>
          <TextLocal id={'job-portal.entry-date'} />:{' '}
          {entryDate || <TextLocal id={'job-portal.immediately'} />}
        </span>
      </div>
      <div className={css.detailsItem}>
        <PointIcon width="15" height="20" />
        <span
          className={css.detailsItemText}
        >{`${cityName}, ${stateName}, ${countryName}`}</span>
      </div>
    </div>
  );
};

export default PositionDetailsList;
