import getRootServerUrl from '@/src/app/utils/getRootServerUrl';
import VideoFile from '@/src/howTo/components/fileWithPreview/VideoFile';
import DocumentAttachment from '@/src/jobPortal/clinicPositionPreview/components/DocumentAttachment';
import ClinicImages from '@/src/profile/clinicTarget/components/ClinicImages';
import TextLocal from '@/src/textLocal/containers/TextLocal';
import {Text} from '@/src/ui';

import css from '../styles/ClinicPositionAttachments.module.css';

interface ClinicPositionAttachmentsProps {
  pdfAttachments: Array<any>;
  imageAttachments: Array<any>;
  videoAttachments: Array<any>;
}

const ClinicPositionAttachments = ({
  pdfAttachments,
  imageAttachments,
  videoAttachments,
}: ClinicPositionAttachmentsProps) => {
  if (
    !pdfAttachments?.length &&
    !imageAttachments?.length &&
    !videoAttachments?.length
  ) {
    return null;
  }

  return (
    <div className={css.widget}>
      <div className={css.widgetTitle}>
        <Text
          fontSize={Text.FONT_SIZE.SIZE_24}
          fontWeight={Text.FONT_WEIGHT.BOLD}
        >
          <TextLocal id={'create-course.attachments'} />
        </Text>
      </div>
      {Boolean(pdfAttachments?.length) && (
        <div className={css.pdfsBlock}>
          {pdfAttachments.map((item) => {
            return (
              <DocumentAttachment
                key={item.id}
                url={item.url}
                fileName={item.filename}
                id={item.id}
              />
            );
          })}
        </div>
      )}
      {Boolean(videoAttachments?.length) && (
        <div className={css.videosBlock}>
          {videoAttachments.map((item) => {
            return (
              <div className={css.videosBlockItem} key={item.id}>
                <VideoFile src={`${getRootServerUrl()}${item.url}`} />
              </div>
            );
          })}
        </div>
      )}
      {Boolean(imageAttachments?.length) && (
        // @ts-ignore
        <ClinicImages images={{data: imageAttachments}} />
      )}
    </div>
  );
};

export default ClinicPositionAttachments;
