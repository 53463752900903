import {REST_METHODS} from '@/src/app/constants/api';
import authorizedFetch from '@/src/app/utils/authorizedFetch';

const requestUserOpenForOpportunities = (
  userId: number | string
): Promise<any> => {
  // @ts-ignore
  return authorizedFetch({
    method: REST_METHODS.GET,
    path: `/api/v1/profiles/open_for_new_job_opportunities/${userId}`,
    shouldHandleNotFound: false,
  });
};

export default requestUserOpenForOpportunities;
