import {REST_METHODS} from '@/src/app/constants/api';
import {getAuthorizationToken} from '@/src/app/utils/authorizationToken';
import authorizedFetch from '@/src/app/utils/authorizedFetch';
import unauthorizedFetch from '@/src/app/utils/unauthorizedFetch';

const requestGetSingleJobPosition = (positionId) => {
  const isBySlug = !Number(positionId);
  const requestMethod = getAuthorizationToken()
    ? authorizedFetch
    : unauthorizedFetch;

  // @ts-ignore
  return requestMethod({
    method: REST_METHODS.GET,
    path: isBySlug
      ? `/api/v1/job_portal/vacancies_search/${positionId}`
      : `/api/v1/clinics/vacancies/${positionId}`,
  });
};

export default requestGetSingleJobPosition;
