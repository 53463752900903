import Link from 'next/link';
import PropTypes from 'prop-types';
import {memo} from 'react';

import TextLocal from '@/src/textLocal/containers/TextLocal';

import {Text} from '../../ui';
import css from '../styles/footer.module.css';

const FooterLinks = ({title, list}) => {
  const linksList = (
    <ul className={css.list}>
      {list.map((item) => (
        <li key={item.name}>
          <Link href={item.href}>
            <a>
              <Text
                color={Text.COLOR.WHITE}
                fontSize={Text.FONT_SIZE.SIZE_14}
                fontFamily={Text.FONT_FAMILY.POPPINS}
              >
                <TextLocal id={item.name} />
              </Text>
            </a>
          </Link>
        </li>
      ))}
    </ul>
  );

  return (
    <div className={css.mainlinks}>
      <div className={css.listTitle}>
        <Text
          color={Text.COLOR.WHITE}
          fontSize={Text.FONT_SIZE.SIZE_18}
          fontWeight={Text.FONT_WEIGHT.BOLD}
        >
          <TextLocal id={title} />
        </Text>
      </div>
      {linksList}
    </div>
  );
};

export default memo(FooterLinks);

FooterLinks.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  list: PropTypes.arrayOf(
    PropTypes.shape({
      href: PropTypes.string,
      name: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    })
  ),
};

FooterLinks.defaultProps = {
  title: '',
  list: [],
};
