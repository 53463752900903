import {Form, Formik} from 'formik';
import dynamic from 'next/dynamic';
import {useRouter} from 'next/router';
import {useTranslation} from 'next-i18next';
import {useCallback, useContext, useState} from 'react';
import * as Yup from 'yup';

import css from '@/src/jobPortal/applicationPopup/styles/VacancyApplicationPopupLayout.module.css';
import RouteContext from '@/src/routes/utils/RouteContext';
import TextLocal from '@/src/textLocal/containers/TextLocal';
import {Button, FileInput, Input, Separator, Text} from '@/src/ui';
import CheckboxCommon from '@/src/ui/checkbox/components/CheckboxCommon';

const MESSAGE_MAX_LENGTH = 1500;
const MAX_FILE_SIZE = 10000000;
const MAX_TEXT_INPUT_LENGTH = 60;

const FormikRichTextEditor = dynamic(
  () => import('@/src/ui/Textarea/containers/FormikRichTextEditor'),
  {
    ssr: false,
  }
);

interface VacancyUnauthorizedApplicationProps {
  positionTitle: string;
  clinicName: string;
  onSubmitClick: (...args: any) => any;
  onCancelClick: (...args: any) => any;
  isLoading: boolean;
}

const getValidationSchema = (t) => {
  return Yup.object().shape({
    first_name: Yup.string()
      .min(2, t('validation.min-length-error', {minLength: 2}))
      .max(50, t('validation.max-length-error', {maxLength: 50}))
      .matches(
        // eslint-disable-next-line
        /^[a-zA-Z',.-]*$/i,
        t('emailStep.first_name_is_invalid')
      )
      .required(t('validation.field-req')),
    last_name: Yup.string()
      .min(2, t('validation.min-length-error', {minLength: 2}))
      .max(50, t('validation.max-length-error', {maxLength: 50}))
      .matches(
        // eslint-disable-next-line
        /^[a-zA-Z',.-]*$/i,
        t('emailStep.last_name_is_invalid')
      )
      .required(t('validation.field-req')),
    email: Yup.string()
      .test('sectionSymbol', t('emailStep.email_is_invalid'), (value) => {
        if (value === undefined) {
          return true;
        }

        return !value?.includes('§');
      })
      .email(t('emailStep.email_is_invalid'))
      .required(t('validation.field-req')),
    message: Yup.string().max(
      1500,
      t('validation.max-length-error', {maxLength: 1500})
    ),
    cv_file: Yup.mixed()
      .test('fileSize', t('job-portal.max-file-size-10-mb'), (value) => {
        if (Boolean(value) && typeof value !== 'string') {
          return value.size <= MAX_FILE_SIZE;
        } else {
          return true;
        }
      })
      .required(t('validation.field-req')),
  });
};

const VacancyUnauthorizedApplication = ({
  positionTitle,
  clinicName,
  onSubmitClick,
  onCancelClick,
  isLoading,
}: VacancyUnauthorizedApplicationProps) => {
  const {t} = useTranslation(TextLocal.LOCALE_SOURCE.COMMON);
  const [isAgree, setIsAgree] = useState(false);
  const {defaultLocale, locale} = useRouter();
  // @ts-ignore
  const {PRIVACY_POLICY_ROUTE} = useContext(RouteContext);

  const handleOnAgreementClick = useCallback(() => {
    setIsAgree(!isAgree);
  }, [isAgree]);

  return (
    <div className={css.popup}>
      <Formik
        initialValues={{
          cv_file: null,
          message: '',
          first_name: '',
          last_name: '',
          email: '',
        }}
        onSubmit={onSubmitClick}
        validationSchema={getValidationSchema(t)}
      >
        {({errors}) => {
          return (
            <Form>
              <div className={css.heading}>
                <Text
                  fontSize={Text.FONT_SIZE.SIZE_32}
                  fontWeight={Text.FONT_WEIGHT.MEDIUM}
                  fontFamily={Text.FONT_FAMILY.RUBIK}
                >
                  <TextLocal id={'job-portal.application'} />
                </Text>
              </div>
              <Separator borderWeight={Separator.BORDER_WEIGHT.THIN} />
              <div className={css.form}>
                <div className={css.formContent}>
                  <div className={css.description}>
                    <Text
                      fontSize={Text.FONT_SIZE.SIZE_18}
                      display={Text.DISPLAY.INLINE}
                      fontWeight={Text.FONT_WEIGHT.NORMAL}
                      lineHeight={Text.LINE_HEIGHT.LINE_HEIGHT_24}
                    >
                      <TextLocal id={'job-portal.you-are-applying-to'} />
                    </Text>
                    <Text
                      fontSize={Text.FONT_SIZE.SIZE_18}
                      display={Text.DISPLAY.INLINE}
                      fontWeight={Text.FONT_WEIGHT.BOLD}
                      lineHeight={Text.LINE_HEIGHT.LINE_HEIGHT_24}
                    >
                      {`${positionTitle}.`}
                    </Text>
                  </div>
                  <div className={css.textInput}>
                    {/* @ts-ignore */}
                    <Input
                      placeholder={t('emailStep.first_name')}
                      name={'first_name'}
                      type={Input.TYPES.TEXT}
                      width={Input.WIDTH.WIDTH_FULL}
                      maxLength={MAX_TEXT_INPUT_LENGTH}
                      isRequired={true}
                    />
                  </div>
                  <div className={css.textInput}>
                    {/* @ts-ignore */}
                    <Input
                      placeholder={t('emailStep.last_name')}
                      name={'last_name'}
                      type={Input.TYPES.TEXT}
                      width={Input.WIDTH.WIDTH_FULL}
                      maxLength={MAX_TEXT_INPUT_LENGTH}
                      isRequired={true}
                    />
                  </div>
                  <div className={css.textInput}>
                    {/* @ts-ignore */}
                    <Input
                      placeholder={t('emailStep.email')}
                      name={'email'}
                      type={Input.TYPES.TEXT}
                      width={Input.WIDTH.WIDTH_FULL}
                      maxLength={MAX_TEXT_INPUT_LENGTH}
                      isRequired={true}
                    />
                  </div>
                  <div className={css.fileInput}>
                    {/* @ts-ignore */}
                    <FileInput
                      name={'cv_file'}
                      isRequired={true}
                      placeholder={t('job-portal.attach-your-cv')}
                      allowedFileTypes={'.pdf, .doc, .docx, .rtf, .txt'}
                    />
                  </div>
                  <div className={css.textArea}>
                    <FormikRichTextEditor
                      name={'message'}
                      placeholder={t(
                        'job-portal.tell-something-about-yourself'
                      )}
                      maxLength={MESSAGE_MAX_LENGTH}
                      withCounter={true}
                    />
                  </div>
                  <div className={css.textAreaDescription}>
                    <Text
                      color={Text.COLOR.GREY}
                      fontSize={Text.FONT_SIZE.SIZE_12}
                      lineHeight={Text.LINE_HEIGHT.LINE_HEIGHT_1_4}
                      fontWeight={Text.FONT_WEIGHT.THIN}
                    >
                      <TextLocal
                        id={'job-portal.your-application-will-be-sent'}
                        params={{clinicName}}
                      />
                    </Text>
                  </div>
                  {Boolean(errors.general) && (
                    // @ts-ignore
                    <div className={css.errorField}>{errors.general}</div>
                  )}
                  <Separator borderWeight={Separator.BORDER_WEIGHT.THIN} />

                  <div className={css.agreement}>
                    <CheckboxCommon
                      id={'agree-apply-job'}
                      name={'agree-apply-job'}
                      label={
                        <span
                          dangerouslySetInnerHTML={{
                            __html: t('job-portal.agree-application', {
                              link: `${
                                defaultLocale !== locale ? `/${locale}` : ''
                              }${PRIVACY_POLICY_ROUTE.getLink()}`,
                            }),
                          }}
                        />
                      }
                      checked={isAgree}
                      isSemibold={undefined}
                      error={undefined}
                      onChange={handleOnAgreementClick}
                    />
                  </div>
                </div>
                <Separator borderWeight={Separator.BORDER_WEIGHT.THIN} />
                <div className={css.buttonsBlock}>
                  <div
                    className={css.cancelPseudoButton}
                    onClick={onCancelClick}
                  >
                    <Text
                      fontSize={Text.FONT_SIZE.SIZE_16}
                      fontWeight={Text.FONT_WEIGHT.MEDIUM}
                      color={Text.COLOR.GREY}
                    >
                      <TextLocal id={'general.cancel'} />
                    </Text>
                  </div>
                  <Button
                    isDisabled={!isAgree}
                    color={Button.COLOR.PRIMARY}
                    height={Button.HEIGHT.SMALL}
                    type={Button.TYPES.SUBMIT}
                    isLoading={isLoading}
                  >
                    <TextLocal id={'job-portal.apply'} />
                  </Button>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default VacancyUnauthorizedApplication;
