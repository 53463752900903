import {REST_METHODS} from '@/src/app/constants/api';
import authorizedFetch from '@/src/app/utils/authorizedFetch';

const requestTargetClinicDataById = (clinicId: number): Promise<any> => {
  // @ts-ignore
  return authorizedFetch({
    method: REST_METHODS.GET,
    path: `/api/v1/clinics/clinics/show_by_id/${clinicId}`,
  });
};

export default requestTargetClinicDataById;
