import cn from 'clsx';
import {useField} from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import CustomSelect, {components} from 'react-select';

import DDIcon from '@/public/icons/dropDownIndicator.svg';
import useIsMobile from '@/src/hooks/useIsMobile';

import css from '../styles/styles.module.css';

export const DropdownIndicator = (props) => {
  const {isFocused} = props;

  return (
    <components.DropdownIndicator {...props}>
      <div
        className={cn(css.selectIcon, {
          [css.selectIconFocused]: isFocused,
        })}
      >
        <DDIcon />
      </div>
    </components.DropdownIndicator>
  );
};

DropdownIndicator.propTypes = {
  isFocused: PropTypes.bool,
};

const Select = ({
  options,
  maxMenuHeight,
  placeholder,
  isRequired,
  onChange,
  ...props
}) => {
  const {isMaxWidth900} = useIsMobile();
  // @ts-ignore
  // eslint-disable-next-line no-unused-vars
  const [field, meta, helpers] = useField(props);

  const handleChange = (value) => {
    helpers.setValue(value);

    if (onChange) {
      onChange(value);
    }
  };

  const error = meta.touched && meta.error;

  return (
    <div
      className={cn(css.select, {
        [css.errorLabel]: error,
      })}
    >
      <CustomSelect
        instanceId={field.name}
        className="select"
        styles={getCustomStyles(isMaxWidth900)}
        value={meta.value && meta.value.label ? meta.value : null}
        onChange={handleChange}
        options={options}
        components={{DropdownIndicator}}
        maxMenuHeight={maxMenuHeight}
        placeholder={placeholder}
      />
      {isRequired && <div className={css.requiredIcon}>*</div>}
      {Boolean(error) && <div className={css.errorField}>{error}</div>}
    </div>
  );
};

export default Select;

export const getCustomStyles = (
  isMaxWidth900: boolean,
  size?: 'small' | undefined,
  isError?: boolean
) => {
  const controlHeight = size === 'small' ? '48px' : '60px';

  return {
    container: (provided) => {
      return {
        ...provided,
        borderRadius: '8px',
      };
    },

    indicatorSeparator: (provided) => {
      return {
        ...provided,
        display: 'none',
      };
    },

    control: (provider) => {
      delete provider['&:hover'];
      return {
        ...provider,
        height: isMaxWidth900 ? '40px' : controlHeight,
        paddingLeft: isMaxWidth900 ? '20px' : '28px',
        paddingRight: isMaxWidth900 ? '20px' : '0',
        borderRadius: '8px',
        boxShadow: 'none',
        flex: 'auto',
        border: isError ? '1px solid red' : '1px solid rgba(29, 66, 109, 0.3)',
      };
    },

    valueContainer: (provider) => {
      return {
        ...provider,
        'overflow-x': 'hidden',
        'overflow-y': 'visible',
        height: '100%',
        padding: '2px 8px 2px 0',
      };
    },

    indicatorsContainer: (provider) => {
      return {
        ...provider,
        padding: isMaxWidth900 ? '4px 14px' : '8px 18px',
        transition: '.3s',
        position: isMaxWidth900 ? 'absolute' : 'static',
        top: '0',
        right: '0',
      };
    },

    menu: (provider) => {
      return {
        ...provider,
        marginTop: '10px',
        borderRadius: '8px',
        zIndex: 10,
      };
    },

    option: (provider, state) => {
      let background = '#FFFFFF';
      let color = 'var(--primatyColor)';

      if (state.isSelected && !state.isFocused) {
        background = 'var(--colorGradient)';
        color = '#FFFFFF';
      }

      if (state.isFocused) {
        background = 'var(--primatyColor)';
        color = '#FFFFFF';
      }

      if (state.isDisabled && !state.isSelected && !state.isFocused) {
        color = 'var(--primatyColorLight)';
      }

      return {
        ...provider,
        color,
        background,
      };
    },

    singleValue: () => {
      return {
        position: 'absolute',
        lineHeight: '1.3',
        color: 'var(--primatyColor)',
        paddingRight: isMaxWidth900 ? '18px' : 0,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        width: '100%',
      };
    },

    placeholder: (provider) => {
      return {
        ...provider,
        color: 'var(--primatyColorLight)',
      };
    },
  };
};

Select.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
      value: PropTypes.string,
    })
  ),
  maxMenuHeight: PropTypes.number,
  placeholder: PropTypes.string,
  isRequired: PropTypes.bool,
  onChange: PropTypes.func,
};

Select.defaultProps = {
  options: [],
  maxMenuHeight: 300,
  placeholder: '',
  onChange: () => {},
};
