import {useRouter} from 'next/router';
import {useContext, useEffect, useMemo, useState} from 'react';

import {ROLES_NAMES} from '@/src/app/constants/roles';
import useUserActions from '@/src/app/store/user/useUserActions';
import {getAuthorizationToken} from '@/src/app/utils/authorizationToken';
import NoAccess from '@/src/noAccess/containers/NoAccess';
import RouteContext from '@/src/routes/utils/RouteContext';
import {LoadingWrapper} from '@/src/ui';

import css from '../styles/JopPortalManagePermission.module.css';

interface JopPortalManagePermissionProps {
  children: JSX.Element;
  shouldSkipCheck?: boolean;
}

const JopPortalManagePermission = ({
  children,
  shouldSkipCheck,
}: JopPortalManagePermissionProps) => {
  const {user: userData} = useUserActions();
  const {isReady, push} = useRouter();
  const [isLoading, setIsLoading] = useState(true);
  // @ts-ignore
  const {SIGN_IN_ROUTE} = useContext(RouteContext);
  const authorizationToken = getAuthorizationToken();

  useEffect(() => {
    setIsLoading(!isReady);
  }, [isReady]);

  useEffect(() => {
    if (isReady && !authorizationToken && !shouldSkipCheck) {
      push(SIGN_IN_ROUTE.getLink());
    }
  }, [isReady, authorizationToken, shouldSkipCheck]);

  const shouldShowContent = useMemo(
    () =>
      shouldSkipCheck ||
      !(
        (isReady &&
          getAuthorizationToken() &&
          userData?.user?.id &&
          userData?.user?.type !== ROLES_NAMES.CLINIC) ||
        !getAuthorizationToken()
      ),
    [isReady, userData?.user]
  );

  return (
    <LoadingWrapper isLoading={isLoading} hideContentBeforeLoad>
      {shouldShowContent ? (
        <div>{children}</div>
      ) : (
        <span className={css.errorMessageWrapper}>
          <NoAccess />
        </span>
      )}
    </LoadingWrapper>
  );
};

export default JopPortalManagePermission;
