import cn from 'clsx';
import PropTypes from 'prop-types';

import {
  COLOR,
  DISPLAY,
  FONT_FAMILY,
  FONT_SIZE,
  FONT_WEIGHT,
  LINE_HEIGHT,
  TAG,
  TRANSFORM,
} from '../constants/textConstants';
import css from '../styles/Text.module.css';

const Text = ({
  fontSize,
  fontWeight,
  color,
  children,
  fontFamily,
  lineHeight,
  transform,
  className,
  isOneLine,
  tagComponent: TagComponent,
  display,
}) => {
  return (
    <TagComponent
      className={cn(
        css.text,
        css[fontSize],
        css[fontWeight],
        css[color],
        css[fontFamily],
        css[lineHeight],
        css[transform],
        css[display],
        className,
        {
          [css.oneLine]: isOneLine,
        }
      )}
    >
      {children}
    </TagComponent>
  );
};

Text.COLOR = COLOR;
Text.FONT_WEIGHT = FONT_WEIGHT;
Text.FONT_SIZE = FONT_SIZE;
Text.FONT_FAMILY = FONT_FAMILY;
Text.LINE_HEIGHT = LINE_HEIGHT;
Text.TRANSFORM = TRANSFORM;
Text.TAG = TAG;
Text.DISPLAY = DISPLAY;

Text.propTypes = {
  isOneLine: PropTypes.bool,
  fontSize: PropTypes.oneOf(Object.values(FONT_SIZE)),
  fontWeight: PropTypes.oneOf(Object.values(FONT_WEIGHT)),
  lineHeight: PropTypes.oneOf(Object.values(LINE_HEIGHT)),
  transform: PropTypes.oneOf(Object.values(TRANSFORM)),
  color: PropTypes.oneOf(Object.values(COLOR)),
  className: PropTypes.string,
  fontFamily: PropTypes.oneOf(Object.values(FONT_FAMILY)),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  tagComponent: PropTypes.oneOf(Object.values(TAG)),
  display: PropTypes.oneOf(Object.values(DISPLAY)),
};

Text.defaultProps = {
  fontSize: FONT_SIZE.SIZE_18,
  fontWeight: FONT_WEIGHT.NORMAL,
  COLOR: COLOR.GREY,
  fontFamily: FONT_FAMILY.POPPINS,
  lineHeight: LINE_HEIGHT.LINE_HEIGHT_1_3,
  transform: TRANSFORM.INITIAL_CASE,
  tagComponent: TAG.DIV,
  isOneLine: false,
  display: DISPLAY.BLOCK,
};

export default Text;
