import useIsMobile from '@/src/hooks/useIsMobile';
import {CLINIC_POSITION_STATUSES} from '@/src/jobPortal/clinicPositionPreview/constants/clinicPositionStatuses';
import TextLocal from '@/src/textLocal/containers/TextLocal';
import {Button, Text} from '@/src/ui';

import css from '../styles/PreviewModeNotice.module.css';

interface PreviewModeNoticeProps {
  onPublishClick: (...args: any) => any;
  onEditClick: (...args: any) => any;
  onDashboardClick: (...args: any) => any;
  isLoading: boolean;
  positionStatus: string;
}

const PreviewModeNotice = ({
  onPublishClick,
  onEditClick,
  onDashboardClick,
  isLoading,
  positionStatus,
}: PreviewModeNoticeProps) => {
  const {isMaxWidth1100} = useIsMobile();

  if (positionStatus === CLINIC_POSITION_STATUSES.ACTIVE) {
    return null;
  }

  return (
    <div className={css.widget}>
      <div className={css.content}>
        <div className={css.leftWidgetPart}>
          <div className={css.firstRow}>
            <Text
              color={Text.COLOR.GREY}
              fontWeight={Text.FONT_WEIGHT.BOLD}
              fontSize={Text.FONT_SIZE.SIZE_14}
            >
              <TextLocal id={'job-portal.preview-mode'} />
            </Text>
            <Text
              color={Text.COLOR.GREY}
              fontWeight={Text.FONT_WEIGHT.NORMAL}
              fontSize={
                isMaxWidth1100 ? Text.FONT_SIZE.SIZE_12 : Text.FONT_SIZE.SIZE_14
              }
            >
              <TextLocal id={'job_portal.how-your-position-will-look'} />
            </Text>
          </div>
          <div>
            <Text
              color={Text.COLOR.GREY}
              fontWeight={Text.FONT_WEIGHT.NORMAL}
              fontSize={
                isMaxWidth1100 ? Text.FONT_SIZE.SIZE_12 : Text.FONT_SIZE.SIZE_14
              }
            >
              <TextLocal id={'job_portal.position-saved-as-draft'} />
            </Text>
          </div>
        </div>
        {!isMaxWidth1100 && (
          <div className={css.rightWidgetPart}>
            <Button
              color={Button.COLOR.PRIMARY}
              height={Button.HEIGHT.SMALL}
              onClick={onPublishClick}
              isLoading={isLoading}
            >
              <TextLocal id={'job-portal.publish'} />
            </Button>
            <Button
              color={Button.COLOR.SECONDARY}
              height={Button.HEIGHT.SMALL}
              onClick={onEditClick}
            >
              <TextLocal id={'profile.edit'} />
            </Button>
            <div className={css.dashboardButton} onClick={onDashboardClick}>
              <Text
                color={Text.COLOR.PRIMARY}
                fontSize={Text.FONT_SIZE.SIZE_16}
                fontWeight={Text.FONT_WEIGHT.MEDIUM}
              >
                <TextLocal id={'job-portal.dashboard'} />
              </Text>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PreviewModeNotice;
