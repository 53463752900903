import {REST_METHODS} from '@/src/app/constants/api';
import authorizedFetch from '@/src/app/utils/authorizedFetch';

const requestGetFilterCount = () => {
  // @ts-ignore
  return authorizedFetch({
    method: REST_METHODS.GET,
    path: '/api/v1/clinics/vacancies_filters',
  });
};

export default requestGetFilterCount;
