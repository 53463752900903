import {useRouter} from 'next/router';
import {useCallback, useEffect, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {RESPONSE_STATUSES} from '@/src/app/constants/api';
import {
  ORDER_BY_FILTER,
  ORDER_FILTER,
  SEARCH_FILTERS,
} from '@/src/app/constants/search';
import {
  selectRecentlyAddedCourses,
  setRecentlyAddedCourses,
} from '@/src/app/store/promoSections/promoSectionsSlice';
import transforCoursesData from '@/src/app/utils/transforCoursesData';
import useIsMobile from '@/src/hooks/useIsMobile';
import useRoutePaths from '@/src/hooks/useRoutePaths';
import TextLocal from '@/src/textLocal/containers/TextLocal';

import {Carousel, CourseCardLarge} from '../../ui';
import CommonSectionLayout from '../components/CommonSectionLayout';
import getRecentlyAddedCourses from '../utils/getRecentlyAddedCourses';

const RecentlyCoursesContainer = () => {
  const recentlyAddedCourses = useSelector(selectRecentlyAddedCourses);
  const dispatch = useDispatch();
  const {locale} = useRouter();
  const {isMaxWidth900} = useIsMobile();
  const {SEARCH_COURSES} = useRoutePaths();

  const requestGetCourse = useCallback(async () => {
    const {status, responseBody} = await getRecentlyAddedCourses(locale);

    if (status === RESPONSE_STATUSES.SUCCESS) {
      dispatch(
        setRecentlyAddedCourses(transforCoursesData(responseBody?.data, locale))
      );
    }
  }, []);

  useEffect(() => {
    !recentlyAddedCourses.length && requestGetCourse();
  }, []);

  const seeAllUrl = useMemo(() => {
    return `${SEARCH_COURSES.getLink()}?${SEARCH_FILTERS.OFFSET}=0&${
      SEARCH_FILTERS.ORDER
    }=${ORDER_FILTER.ASC}&${SEARCH_FILTERS.ORDER_BY}=${
      ORDER_BY_FILTER.APPROVED_AT
    }&${SEARCH_FILTERS.LANGUAGE}=${locale}`;
  }, [SEARCH_COURSES, locale]);

  return (
    <>
      <CommonSectionLayout
        fullWidth
        seeAllUrl={seeAllUrl}
        title={<TextLocal id={'promoSection.recently-added-courses'} />}
        content={
          recentlyAddedCourses.length > 0 ? (
            <Carousel
              paginationMobileToRight
              navigationPosition={Carousel.NAVIGATION_POSITION.FLOATING}
              slideGap={Carousel.SLIDE_GAP.SLIDE_GAP_20}
              highlighted
              settings={{
                loop: true,
                centeredSlides: !isMaxWidth900,
                breakpoints: {
                  320: {
                    slidesPerView: 1,
                  },
                  601: {
                    slidesPerView: 'auto',
                  },
                },
              }}
            >
              {recentlyAddedCourses.map((item) => (
                <CourseCardLarge {...item} key={item.id} />
              ))}
            </Carousel>
          ) : null
        }
      />
    </>
  );
};

export default RecentlyCoursesContainer;
