import {useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {RESPONSE_STATUSES} from '@/src/app/constants/api';
import {
  resetSinglePositionAction,
  selectSingleJobPosition,
  setIsFetchingAction,
  setIsSavingAction,
  setSinglePositionAction,
} from '@/src/app/store/jobPortal/singleJobPositionSlice';
import useS3Upload from '@/src/hooks/useS3Upload';
import requestGetSingleJobPosition from '@/src/jobPortal/common/utils/requestGetSingleJobPosition';
import requestSavePosition from '@/src/jobPortal/common/utils/requestSavePosition';

const useSingleJobPosition = (clinicId?: number) => {
  const dispatch = useDispatch();
  const {data, isSaving, isFetching} = useSelector(selectSingleJobPosition);
  const {getDuplicateFiles} = useS3Upload();

  const saveJobPosition = useCallback(
    async (values, positionId): Promise<any> => {
      dispatch(setIsSavingAction(true));
      const {status, responseBody} = await requestSavePosition({
        data: values,
        clinicId: clinicId,
        positionId,
      });

      if (status === RESPONSE_STATUSES.SUCCESS) {
        dispatch(setSinglePositionAction(responseBody));
      }

      dispatch(setIsSavingAction(false));

      return {status, responseBody};
    },
    [clinicId]
  );

  const getJobPosition = useCallback(
    async (positionId: string | string[], isDuplicate?: boolean) => {
      dispatch(setIsFetchingAction(true));

      const {status, responseBody} = await requestGetSingleJobPosition(
        positionId
      );

      if (status === RESPONSE_STATUSES.SUCCESS) {
        let duplicatedFiles = [];

        if (isDuplicate) {
          const mediaAttachments: Array<any> = await getDuplicateFiles(
            responseBody.media_attachments.map((item) => item.filename)
          );

          duplicatedFiles = mediaAttachments.reduce(
            (previousValue, currentValue) => {
              if (!currentValue.value.location.length) {
                return previousValue;
              } else {
                return [
                  ...previousValue,
                  {
                    url: currentValue.value.location,
                    // @ts-ignore
                    id: currentValue.value.key,
                  },
                ];
              }
            },
            []
          );
        }

        dispatch(
          setSinglePositionAction({
            ...responseBody,
            media_attachments: isDuplicate
              ? duplicatedFiles
              : responseBody.media_attachments,
          })
        );
      }

      dispatch(setIsFetchingAction(false));
    },
    []
  );

  const resetJopPosition = useCallback(() => {
    dispatch(resetSinglePositionAction());
  }, []);

  return {
    data,
    isFetching,
    isSaving,
    saveJobPosition,
    getJobPosition,
    resetJopPosition,
  };
};

export default useSingleJobPosition;
