import cn from 'clsx';
import {useRouter} from 'next/router';
import {FC, useCallback, useState} from 'react';

import useRoutePaths from '@/src/hooks/useRoutePaths';
import postUserEmail from '@/src/landing/utils/postUserEmail';
import {Button, Text} from '@/src/ui';

import css from '../../styles/HeroSignup.module.css';

interface HeroSignupProps {
  blok: {
    buttonTitle: string;
    placeholder: string;
  };
}

export const HeroSignupS: FC<HeroSignupProps> = ({blok}: HeroSignupProps) => {
  const [email, setEmail] = useState('');
  const {locale} = useRouter();
  const {buttonTitle, placeholder} = blok;

  const {SIGN_UP_ROUTE_USER} = useRoutePaths();
  const {push} = useRouter();

  const onSignupClick = (email) => {
    postUserEmail(email);
    push({
      pathname: SIGN_UP_ROUTE_USER.getLink('main'),
      query: {
        email,
      },
    });
  };

  const handleChangeEmail = useCallback((event) => {
    setEmail(event.target.value);
  }, []);

  const handleSubmit = useCallback(
    (event) => {
      event.preventDefault();
      onSignupClick(email);
    },
    [email]
  );

  return (
    <div className={css.main}>
      <form className={css.form} onSubmit={handleSubmit}>
        <input
          value={email}
          onChange={handleChangeEmail}
          className={cn(css.input, css[`${locale}input`])}
          type="email"
          name="email"
          placeholder={placeholder}
          required={true}
        />
        <div className={css.button}>
          <Button
            color={Button.COLOR.PRIMARY}
            type={Button.TYPES.SUBMIT}
            height={Button.HEIGHT.SMALL}
          >
            <Text
              fontSize={Text.FONT_SIZE.SIZE_16}
              fontWeight={Text.FONT_WEIGHT.BOLD}
            >
              {buttonTitle}
            </Text>
          </Button>
        </div>
      </form>
    </div>
  );
};
