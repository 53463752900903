import {REST_METHODS} from '@/src/app/constants/api';
import {getAuthorizationToken} from '@/src/app/utils/authorizationToken';
import authorizedFetch from '@/src/app/utils/authorizedFetch';
import unauthorizedFetch from '@/src/app/utils/unauthorizedFetch';

const requestTargetClinicEmployees = (
  clinicId: number,
  offset: number,
  perPage: number
): Promise<any> => {
  const requestMethod = getAuthorizationToken()
    ? authorizedFetch
    : unauthorizedFetch;

  // @ts-ignore
  return requestMethod({
    method: REST_METHODS.GET,
    path: '/api/v1/clinics/employees',
    queryParams: [
      {
        name: 'clinic_id',
        value: clinicId,
      },
      {
        name: 'offset',
        value: offset,
      },
      {
        name: 'per_page',
        value: perPage,
      },
    ],
  });
};

export default requestTargetClinicEmployees;
