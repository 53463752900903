import {sbEditable} from '@storyblok/storyblok-editable';
import cn from 'clsx';
import PropTypes from 'prop-types';

import DynamicComponent from '../../../dynamicComponents/components/DynamicComponent';
import css from '../styles/Button.module.css';

const Button = ({blok}) => {
  const {
    color,
    height,
    border,
    arrow,
    onClick,
    type,
    tag: TagComponent,
    body,
    width,
  } = blok;

  return (
    <div className={cn(css.buttonBlock, css[width])} {...sbEditable(blok)}>
      <TagComponent
        className={cn(
          css.button,
          css[color],
          css[height],
          css[border],
          css[arrow]
        )}
        onClick={onClick}
        type={type}
      >
        <span>
          {body
            ? body.map((blok) => (
                <DynamicComponent blok={blok} key={blok._uid} />
              ))
            : null}
        </span>
      </TagComponent>
    </div>
  );
};

Button.propTypes = {
  blok: PropTypes.object.isRequired,
};

Button.defaultProps = {
  blok: {},
};

export default Button;
