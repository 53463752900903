import Slider from '@mui/material/Slider';
import PropTypes from 'prop-types';
import Cropper from 'react-easy-crop';

import TextLocal from '@/src/textLocal/containers/TextLocal';

import {Button, PageTitle, Separator, Text} from '../../../ui';
import {PICTURE_POPUP_TYPES} from '../constants/picturePopupTypes';
import css from '../styles/EditProfileImagePopupLayout.module.css';

export const CROP_TYPES = {
  ROUND: 'round',
  RECTANGULAR: 'rect',
};

const CROP_ASPECT = {
  ROUND: 1,
  RECTANGULAR: 11,
};

const getHeadingTranslation = (popupType) => {
  return popupType === PICTURE_POPUP_TYPES.PICTURE
    ? 'profile.profile-image'
    : 'profile.cover-image';
};

const EditProfileImagePopupLayout = ({
  fileInputRef,
  onImageChange,
  onNewImageClick,
  crop,
  onCropChange,
  newImage,
  onZoomChange,
  zoom,
  onCropComplete,
  onApplyClick,
  isLoading,
  popupType,
  onDeleteImageClick,
  isApplyButtonDisabled,
  errorMessage,
}) => {
  return (
    <div className={css.popupContainer}>
      <div className={css.heading}>
        <PageTitle
          title={<TextLocal id={getHeadingTranslation(popupType)} />}
          offset={PageTitle.BOTTOM_OFFSET.OFFSET_30}
        />
      </div>
      {Boolean(newImage) && (
        <>
          <div className={css.cropperBlock}>
            <Cropper
              crop={crop}
              onCropChange={onCropChange}
              image={newImage}
              // @ts-ignore
              cropShape={
                popupType === PICTURE_POPUP_TYPES.PICTURE
                  ? CROP_TYPES.ROUND
                  : CROP_TYPES.RECTANGULAR
              }
              aspect={
                popupType === PICTURE_POPUP_TYPES.PICTURE
                  ? CROP_ASPECT.ROUND
                  : CROP_ASPECT.RECTANGULAR
              }
              zoom={zoom}
              onZoomChange={onZoomChange}
              onCropComplete={onCropComplete}
            />
          </div>
          <div className={css.zoomLabel}>
            <Text
              fontWeight={Text.FONT_WEIGHT.BOLD}
              fontSize={Text.FONT_SIZE.SIZE_18}
            >
              <TextLocal id={'profile.zoom-in'} />
            </Text>
          </div>
          <div className={css.sliderBlock}>
            <Slider
              value={zoom}
              min={1}
              max={3}
              step={0.1}
              aria-labelledby="Zoom"
              onChange={(event, newZoom) => onZoomChange(newZoom)}
              track={false}
              classes={{
                rail: css.sliderRail,
                thumb: css.sliderThumb,
              }}
            />
          </div>
        </>
      )}
      {Boolean(errorMessage.length) && (
        <div className={css.error}>
          <Text color={Text.COLOR.RED} fontSize={Text.FONT_SIZE.SIZE_14}>
            {errorMessage}
          </Text>
        </div>
      )}

      <Separator />
      <div className={css.buttonsBlock}>
        <div className={css.deleteImageBlock} onClick={onDeleteImageClick}>
          <Text
            color={Text.COLOR.RED}
            fontSize={Text.FONT_SIZE.SIZE_16}
            fontWeight={Text.FONT_WEIGHT.BOLD}
          >
            <TextLocal id={'profile.delete-image'} />
          </Text>
        </div>
        <div className={css.secondaryButtonsBlock}>
          <Button
            color={Button.COLOR.SECONDARY}
            height={Button.HEIGHT.SMALL}
            onClick={onNewImageClick}
          >
            <TextLocal id={'profile.upload-new'} />
          </Button>
          <Button
            color={Button.COLOR.PRIMARY}
            height={Button.HEIGHT.SMALL}
            onClick={onApplyClick}
            isLoading={isLoading}
            isDisabled={isApplyButtonDisabled}
          >
            <TextLocal id={'profile.apply'} />
          </Button>
          <div className={css.fileInput}>
            <input
              type={'file'}
              accept="image/*"
              ref={fileInputRef}
              onChange={onImageChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

EditProfileImagePopupLayout.propTypes = {
  fileInputRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  onImageChange: PropTypes.func.isRequired,
  onDeleteImageClick: PropTypes.func.isRequired,
  onNewImageClick: PropTypes.func.isRequired,
  crop: PropTypes.object.isRequired,
  onCropChange: PropTypes.func.isRequired,
  newImage: PropTypes.string,
  onZoomChange: PropTypes.func.isRequired,
  zoom: PropTypes.number.isRequired,
  onCropComplete: PropTypes.func.isRequired,
  onApplyClick: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  popupType: PropTypes.oneOf(Object.values(PICTURE_POPUP_TYPES)),
  isApplyButtonDisabled: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string,
};

EditProfileImagePopupLayout.defaultProps = {
  errorMessage: '',
};

export default EditProfileImagePopupLayout;
