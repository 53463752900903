import {ROLES_NAMES} from '@/src/app/constants/roles';

import getRootServerUrl from './getRootServerUrl';

const transforCoursesData = (courses, currentLanguage, selectedLocales) => {
  return courses?.map((course) => {
    let localTranslation;

    const isClinic = course?.creator?.user.type === ROLES_NAMES.CLINIC;

    const userPicture = course?.creator?.profile?.picture
      ? `${getRootServerUrl()}${course.creator.profile.picture}`
      : null;
    const clinicPicture = course?.creator?.clinic?.picture
      ? `${getRootServerUrl()}${course.creator.clinic.picture}`
      : null;

    if (!selectedLocales) {
      localTranslation = course.course_translations.find(
        (translation) => translation.language === currentLanguage
      );
    } else if (selectedLocales.includes(currentLanguage)) {
      localTranslation = course.course_translations.find(
        (translation) => translation.language === currentLanguage
      );
    } else {
      localTranslation = course.course_translations.find(
        (translation) => translation.language !== currentLanguage
      );
    }

    if (!localTranslation) {
      localTranslation = course.course_translations[0];
    }

    return {
      id: course.id,
      isBookmarked: course.is_bookmarked,
      languages: course.languages,
      creator: {
        type: course?.creator?.user.type,
        name: isClinic
          ? course.creator.clinic.organization_name
          : course?.creator?.user?.first_name,
        lastName: isClinic ? '' : course?.creator.user.last_name,
        picture: isClinic ? clinicPicture : userPicture,
        slug: isClinic
          ? course?.creator?.clinic?.slug
          : course?.creator?.user?.username,
      },
      rating: course.score,
      topicCount: localTranslation?.topics_count,
      previewImage: localTranslation.preview_image
        ? `${getRootServerUrl()}${localTranslation.preview_image}`
        : null,
      verticalPreviewImage: localTranslation.preview_image_vertically
        ? `${getRootServerUrl()}${localTranslation.preview_image_vertically}`
        : null,
      originalPreviewImage: localTranslation.original_preview_image
        ? `${getRootServerUrl()}${localTranslation.original_preview_image}`
        : null,
      title: localTranslation.title,
      description: localTranslation.description,
      level: course.difficulty_level,
      language: localTranslation.language,
      status: localTranslation.status,
      createdAt: localTranslation.created_at,
      slug: localTranslation.slug,
    };
  });
};

export default transforCoursesData;
