import {Button, Text} from '@/src/ui';

import css from '../styles/ConfirmPopup.module.css';

interface ConfirmPopupProps {
  onCancelClick: (...args: any[]) => any;
  onConfirmClick: (...args: any[]) => any;
  mainText: string;
  secondaryText: string;
  confirmButtonText: string;
  cancelButtonText: string;
  reverseConfirm?: boolean;
}

const ConfirmPopup = ({
  onCancelClick,
  onConfirmClick,
  mainText,
  secondaryText,
  confirmButtonText,
  cancelButtonText,
  reverseConfirm,
}: ConfirmPopupProps) => {
  return (
    <div className={css.confirmPopup}>
      <div className={css.mainText}>
        <Text
          fontSize={Text.FONT_SIZE.SIZE_24}
          fontWeight={Text.FONT_WEIGHT.MEDIUM}
          fontFamily={Text.FONT_FAMILY.RUBIK}
        >
          {mainText}
        </Text>
      </div>
      <div className={css.descriptionText}>
        <Text fontSize={Text.FONT_SIZE.SIZE_16} color={Text.COLOR.GREY}>
          {secondaryText}
        </Text>
      </div>
      <div className={css.buttons}>
        <div className={css.button}>
          <Button
            onClick={onCancelClick}
            height={Button.HEIGHT.SMALL}
            color={reverseConfirm ? Button.COLOR.PRIMARY : Button.COLOR.RED}
          >
            <Text
              fontSize={Text.FONT_SIZE.SIZE_16}
              fontWeight={Text.FONT_WEIGHT.BOLD}
            >
              {cancelButtonText}
            </Text>
          </Button>
        </div>
        <div className={css.button}>
          <Button
            onClick={onConfirmClick}
            height={Button.HEIGHT.SMALL}
            border={Button.BORDER.BOLD}
            color={reverseConfirm ? Button.COLOR.RED : Button.COLOR.PRIMARY}
          >
            <Text
              fontSize={Text.FONT_SIZE.SIZE_16}
              fontWeight={Text.FONT_WEIGHT.BOLD}
            >
              {confirmButtonText}
            </Text>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmPopup;
