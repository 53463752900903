import Proptypes from 'prop-types';
import {memo} from 'react';

import css from '../styles/PreviewImageVideo.module.css';

interface PreviewImageVideoProps {
  image: string;
}
const PreviewImageVideo = ({image}: PreviewImageVideoProps) => {
  return (
    <div className={css.preview}>
      <img src={image} loading="lazy" alt="preview video image" />
    </div>
  );
};

PreviewImageVideo.propTypes = {
  image: Proptypes.string,
};

export default memo(PreviewImageVideo);
