import cn from 'clsx';
import {ReactNode} from 'react';

import css from '../styles/Container.module.css';

interface SectionContainerProps {
  children: ReactNode | ReactNode[];
  widePaddingOnDesktop?: boolean;
}
const SectionContainer = ({
  children,
  widePaddingOnDesktop,
}: SectionContainerProps) => {
  return (
    <section
      className={cn(css.container, {
        [css.widePaddingOnDesktop]: widePaddingOnDesktop,
      })}
    >
      {children}
    </section>
  );
};

SectionContainer.defaultProps = {
  children: <></>,
};

export default SectionContainer;
