import moment from 'moment';

import {REST_METHODS} from '@/src/app/constants/api';
import {SERVER_FORMAT} from '@/src/app/constants/date';
import authorizedFetch from '@/src/app/utils/authorizedFetch';

interface requestSavePositionProps {
  data: any;
  clinicId: number;
  positionId: number;
}

const requestSavePosition = ({
  data,
  clinicId,
  positionId,
}: requestSavePositionProps) => {
  //@ts-ignore
  return authorizedFetch({
    method: positionId ? REST_METHODS.PUT : REST_METHODS.POST,
    path: `/api/v1/clinics/vacancies/${positionId ? positionId : ''}`,
    body: {
      clinic_id: clinicId,
      vacancy: {
        position_id: data.position.value,
        specialty_id: data.specialty.value,
        country_id: data.country_id,
        state_id: data.state_id,
        city_id: data.city_id,
        title: data.title,
        currency: data.currency.value,
        work_type: data.participation,
        min_salary: data.salary,
        expire_date: moment(data.end_date).format(SERVER_FORMAT),
        description: data.jobDescription,
        what_to_expect: data.whatToExpect,
        responsibilities: data.responsibilities,
        requirements: data.requirements,
        media_attachments_attributes: data.mediaFiles,
        entry_date: data.entry_date.isImmediately
          ? ''
          : moment(data.entry_date.date).format(SERVER_FORMAT),
      },
    },
  });
};

export default requestSavePosition;
