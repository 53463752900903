import PropTypes from 'prop-types';
import {useCallback, useEffect, useRef, useState} from 'react';
import {findDOMNode} from 'react-dom';
import screenfull from 'screenfull';

import SimpleVideoPlayerLayout from '@/src/ui/videoPlayer/components/SimpleVideoPlayerLayout';

interface SimpleVideoPlayerProps {
  url: string;
  id: string;
  onPreviousVideoClick: () => void;
  onNextVideoClick: () => void;
  isNextDisable: boolean;
  isPreviousDisable: boolean;
  playButton?: React.ReactNode;
  videoRef: React.MutableRefObject<HTMLVideoElement | null>;
  poster: string;
  onEnded?: () => void;
  onPlaying?: () => void;
  additionalContent: React.ReactNode;
}

const SimpleVideoPlayer = ({
  url,
  id,
  onPreviousVideoClick,
  onNextVideoClick,
  isNextDisable,
  isPreviousDisable,
  playButton,
  videoRef,
  poster,
  onEnded,
  onPlaying,
  additionalContent,
}: SimpleVideoPlayerProps) => {
  const videoPlayerRef = useRef(null);
  const videoWithControlsRef = useRef(null);
  const [playing, setPlaying] = useState(false);
  const [muted, setMuted] = useState(false);
  const [played, setPlayed] = useState(0);
  const [seeking, setSeeking] = useState(false);
  const [loaded, setLoaded] = useState(0);

  useEffect(() => {
    if (onPlaying && playing) {
      onPlaying();
    }
  }, [playing]);

  const handleSeekMouseDown = useCallback(() => {
    setSeeking(true);
  }, [seeking]);

  const handleSeekChange = useCallback(
    (event) => {
      setPlayed(parseFloat(event.target.value));
    },
    [played]
  );

  useEffect(() => {
    videoRef.current = videoWithControlsRef.current;
  }, [videoWithControlsRef]);

  const handleSeekMouseUp = useCallback(
    (event) => {
      setSeeking(false);
      videoPlayerRef.current.seekTo(parseFloat(event.target.value));
    },
    [seeking]
  );

  const handleSetPlayingClick = useCallback(() => {
    setPlaying(!playing);
  }, [playing]);

  const handleSetMutedClick = useCallback(() => {
    setMuted(!muted);
  }, [muted]);

  const handleProgressChange = useCallback(
    ({played, loaded}) => {
      if (!seeking) {
        setPlayed(played);
        setLoaded(loaded);
      }
    },
    [seeking]
  );

  const handleFullscreenClick = useCallback(() => {
    if (screenfull.isEnabled) {
      // eslint-disable-next-line react/no-find-dom-node
      screenfull.toggle(findDOMNode(videoWithControlsRef.current));
    }
  }, []);

  const handleOnEnded = () => {
    if (onEnded) {
      onEnded();
    }
    setPlaying(false);
    setMuted(true);
  };

  return (
    <SimpleVideoPlayerLayout
      additionalContent={additionalContent}
      onEnded={handleOnEnded}
      key={id}
      id={id}
      url={url}
      onFullScreenClick={handleFullscreenClick}
      onProgressChange={handleProgressChange}
      onSetPlayingClick={handleSetPlayingClick}
      onSetMutedClick={handleSetMutedClick}
      onSeekMouseUp={handleSeekMouseUp}
      onSeekChange={handleSeekChange}
      onSeekMouseDown={handleSeekMouseDown}
      loaded={loaded}
      played={played}
      isMuted={muted}
      isPlaying={playing}
      videoWithControlsRef={videoWithControlsRef}
      videoPlayerRef={videoPlayerRef}
      onNextVideoClick={onNextVideoClick}
      onPreviousVideoClick={onPreviousVideoClick}
      isNextDisable={isNextDisable}
      isPreviousDisable={isPreviousDisable}
      playButton={playButton}
      poster={poster}
    />
  );
};

SimpleVideoPlayer.propTypes = {
  url: PropTypes.string.isRequired,
  id: PropTypes.number,
  onPreviousVideoClick: PropTypes.func.isRequired,
  onNextVideoClick: PropTypes.func.isRequired,
  isNextDisable: PropTypes.bool.isRequired,
  isPreviousDisable: PropTypes.bool.isRequired,
  playButton: PropTypes.node,
  videoRef: PropTypes.object,
  poster: PropTypes.string,
};

export default SimpleVideoPlayer;
