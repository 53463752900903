import cn from 'clsx';
import {FieldHookConfig, useField} from 'formik';
import {memo, useCallback, useMemo, useState} from 'react';

import TextLocal from '@/src/textLocal/containers/TextLocal';

import Text from '../../text/components/Text';
import css from '../styles/Rating.module.css';
import SelectableStar from './SelectableStar';

interface SelectableProps {
  props: FieldHookConfig<any>;
}
const Selectable = (props: SelectableProps) => {
  // @ts-ignore
  // eslint-disable-next-line no-unused-vars
  const [field, meta, helpers] = useField(props);
  const [rating, setRating] = useState(field.value);

  const handleMouseEnterStar = useCallback((rating) => {
    setRating(rating);
  }, []);

  const handleMouseLeaveStar = useCallback(() => {
    setRating(field.value);
  }, [field.value]);

  const handleSaveNewRating = useCallback((rating) => {
    helpers.setValue(rating);
  }, []);

  const normalizeRating = rating * 2;

  const starsNode = useMemo(() => {
    const stars = [];
    for (let i = 0; i < 10; i++) {
      let fill = false;
      if (normalizeRating > i && normalizeRating !== null) {
        fill = true;
      }

      const calculatedRating = (i + 1) / 2;

      stars.push(
        <li
          onMouseEnter={() => handleMouseEnterStar(calculatedRating)}
          onMouseLeave={() => handleMouseLeaveStar()}
          onClick={() => handleSaveNewRating(calculatedRating)}
          className={cn(
            css.starLarge,
            css.item,
            i % 2 === 0 ? css.full : css.empty
          )}
          key={i}
        >
          <SelectableStar fill={fill} />
        </li>
      );
    }
    return stars;
  }, [rating, field.value]);

  return (
    <div className={css.row}>
      <ul className={cn(css.wrapper, css.alignEnd)}>{starsNode}</ul>
      <Text
        fontSize={Text.FONT_SIZE.SIZE_16}
        fontFamily={Text.FONT_FAMILY.POPPINS}
        lineHeight={Text.LINE_HEIGHT.LINE_HEIGHT_1_6}
        color={Text.COLOR.GREY}
      >
        {rating} <TextLocal id={'general.starts'} />
      </Text>
    </div>
  );
};

export default memo(Selectable);
