import {useContext} from 'react';

import MainIcon from '@/public/icons/dicover/thankyouMainIcon.svg';
import PopupContext from '@/src/popup/utils/PopupContext';
import TextLocal from '@/src/textLocal/containers/TextLocal';
import {Button} from '@/src/ui';

import css from '../ThankYouPopup.module.css';

const ThankYouPopup = () => {
  const {closePopup} = useContext(PopupContext);

  return (
    <div className={css.main}>
      <div className={css.icon}>
        <MainIcon />
      </div>
      <div className={css.title}>
        <TextLocal id={'thankYou.title'} />
      </div>
      <div className={css.description}>
        <TextLocal id={'thankYou.description'} />
      </div>
      <div className={css.buttonWrapper}>
        <Button height={Button.HEIGHT.SMALL} onClick={closePopup}>
          <TextLocal id={'thankYou.continue'} />
        </Button>
      </div>
    </div>
  );
};

export default ThankYouPopup;
