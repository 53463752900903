import cn from 'clsx';
import {ChangeEvent, memo, ReactNode} from 'react';

import css from '../styles/RadioButtonCommon.module.css';

interface RadioButtonCommonProps {
  id: string;
  name: string;
  label: string | ReactNode | JSX.Element;
  checked: boolean;
  isSemibold?: boolean;
  error?: string;
  type?: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

const RadioButtonCommon = ({
  id,
  name,
  label,
  checked,
  isSemibold,
  error,
  ...rest
}: RadioButtonCommonProps) => (
  <>
    <div className={css.radioButtonBlock}>
      <input
        className={css.radioButtonInput}
        id={id}
        type="radio"
        name={name}
        checked={checked}
        {...rest}
      />
      <label
        className={cn(css.radioButtonLabel, {
          [css.semibold]: isSemibold,
        })}
        htmlFor={id}
      >
        <div
          className={cn(css.radioButtonCheckmark, {[css.isActive]: checked})}
        />
        {label}
      </label>
    </div>
    {Boolean(error) && <div className={css.errorField}>{error}</div>}
  </>
);

RadioButtonCommon.defaultProps = {
  checked: false,
  isSemibold: false,
};

export default memo(RadioButtonCommon);
