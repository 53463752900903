import cn from 'clsx';
import {memo} from 'react';
import {ChangeEvent, ReactNode} from 'react';

import css from '../styles/CheckboxCommon.module.css';

interface CheckboxProps {
  id?: string;
  name?: string;
  label: string | ReactNode;
  checked: boolean;
  isSemibold?: boolean;
  error?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  isOneLine?: boolean;
  value?: string | number;
}

const Checkbox = ({
  id,
  name,
  label,
  checked,
  isSemibold,
  error,
  ...rest
}: CheckboxProps) => (
  <>
    <div className={css.checkboxBlock}>
      <input
        className={css.checkboxInput}
        id={id}
        type="checkbox"
        name={name}
        checked={checked}
        {...rest}
      />
      <label
        className={cn(css.checkboxLabel, {
          [css.isActive]: checked,
          [css.semibold]: isSemibold,
        })}
        htmlFor={id}
      >
        <span
          className={cn({
            // @ts-ignore
            [css.oneLine]: rest.isOneLine,
          })}
        >
          {label}
        </span>
      </label>
    </div>
    {Boolean(error) && <div className={css.errorField}>{error}</div>}
  </>
);

Checkbox.defaultProps = {
  checked: false,
  isSemibold: false,
};

export default memo(Checkbox);
