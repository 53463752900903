import {pathOr} from 'ramda';
import {useCallback, useContext} from 'react';
import {useSelector} from 'react-redux';

import {ROLES_NAMES} from '@/src/app/constants/roles';
import {selectClinicData} from '@/src/app/store/clinic/clinicDataSlice';
import useUserActions from '@/src/app/store/user/useUserActions';
import getProfilePicture from '@/src/header/utils/getProfilePicture';
import PopupContext from '@/src/popup/utils/PopupContext';
import {
  getUserFullName,
  getUserInitials,
} from '@/src/profile/common/containers/MyProfileWrapper';

import ProfileDesktopMenuLayout from '../components/ProfileDesktopMenuLayout';
import useSignOut from '../utils/useSignOut';
import SupportPopup from './SupportPopup';

const ProfileDesktopMenu = () => {
  const {user} = useUserActions();
  const picture = pathOr('', ['profile', 'picture'], user);
  const {handleSignOutClick} = useSignOut();
  const {openPopup} = useContext(PopupContext);
  const clinicData = useSelector(selectClinicData);

  const handleSupportClick = useCallback(() => {
    // @ts-ignore
    openPopup({
      popup: <SupportPopup />,
      options: {withCloseButton: true},
    });
  }, []);

  return (
    <ProfileDesktopMenuLayout
      picture={getProfilePicture(picture, clinicData, user)}
      fullName={getUserFullName(
        user,
        user.user.type === ROLES_NAMES.CLINIC,
        clinicData,
        user?.profile?.prefixed_academic_title
      )}
      userInitials={getUserInitials(
        user,
        user.user.type === ROLES_NAMES.CLINIC,
        clinicData
      )}
      onSupportClick={handleSupportClick}
      onSignOutClick={() => handleSignOutClick()}
    />
  );
};

export default ProfileDesktopMenu;
