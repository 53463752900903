import cn from 'clsx';
import React from 'react';

import css from '../styles/ApplicationContainer.module.css';

interface ApplicationContainerProps {
  children: React.ReactNode;
  isDisableBackground?: boolean;
}
const ApplicationContainer = ({
  children,
  isDisableBackground,
}: ApplicationContainerProps) => {
  return (
    <div
      className={cn(css.wrapper, {
        [css.disableBackground]: isDisableBackground,
      })}
    >
      {children}
    </div>
  );
};

export default ApplicationContainer;

ApplicationContainer.defaultProps = {
  children: React.createElement('div'),
  isDisableBackground: false,
};
