import SubscribeToNewsletter from '@/src/landing/components/CallToAction/SubscribeToNewsletter';
import TryMedyoucate from '@/src/landing/components/CallToAction/TryMedyoucate';

import css from '../../styles/CallToAction.module.css';

const CallToAction = ({blok}) => {
  const {
    leftTitle,
    leftDescription,
    leftButtonTitle,
    rightTitle,
    rightButtonTitle,
    inputPlaceholder,
  } = blok;
  return (
    <div className={css.main}>
      <TryMedyoucate
        title={leftTitle}
        description={leftDescription}
        buttonTitle={leftButtonTitle}
      />
      <SubscribeToNewsletter
        title={rightTitle}
        buttonTitle={rightButtonTitle}
        placeholder={inputPlaceholder}
      />
    </div>
  );
};

export default CallToAction;
