import {useRouter} from 'next/router';
import {pathOr} from 'ramda';
import {useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {RESPONSE_STATUSES} from '@/src/app/constants/api';
import {
  resetClinicData,
  selectTargetClinic,
  setFollowClinic,
  setTargetClinicAwards,
  setTargetClinicData,
  setTargetClinicEmployees,
  setTargetClinicHighlightedSurgeries,
  setTargetClinicImages,
  setTargetClinicImagesLoading,
  setTargetClinicLinks,
  setTargetClinicPdfs,
  setTargetClinicSpecialties,
  setTargetClinicStatistic,
  setTargetClinicSurgeries,
  setTargetClinicVacancies,
  setTargetUserClinic,
  setTargetUserClinicLoading,
} from '@/src/app/store/targetClinic/clinicTargetSlice';
import {getAuthorizationToken} from '@/src/app/utils/authorizationToken';
import requestToggleFollow from '@/src/contentCreatorWidget/utils/requestToggleFollow';
import useRoutePaths from '@/src/hooks/useRoutePaths';
import fetchClinicAttachments from '@/src/profile/aboutMe/utils/fetchClinicAttachments';
import fetchClinicAwards from '@/src/profile/aboutMe/utils/fetchClinicAwards';
import fetchClinicCompetences from '@/src/profile/aboutMe/utils/fetchClinicCompetences';
import fetchClinicHighlightedSurgeries from '@/src/profile/aboutMe/utils/fetchClinicHighlightedSurgeries';
import fetchClinicLinks from '@/src/profile/aboutMe/utils/fetchClinicLinks';
import fetchClinicSurgeries from '@/src/profile/aboutMe/utils/fetchClinicSurgeries';
import getImagesClinicGallery from '@/src/profile/aboutMe/utils/getImagesClinicGallery';
import fetchClinicVacancies from '@/src/profile/clinicTarget/utils/fetchClinicVacancies';
import requestTargetClinicData from '@/src/profile/clinicTarget/utils/requestTargetClinicData';
import requestTargetClinicDataById from '@/src/profile/clinicTarget/utils/requestTargetClinicDataById';
import requestTargetClinicEmployees from '@/src/profile/clinicTarget/utils/requestTargetClinicEmployees';
import requestTargetClinicStatistic from '@/src/profile/clinicTarget/utils/requestTargetClinicStatistic';
import requestTargetuserClinic from '@/src/profile/clinicTarget/utils/requestTargetuserClinic';

const useTargetClinic = () => {
  const {
    clinic,
    statistic,
    images,
    clinicUser,
    clinicAwards,
    clinicSurgeries,
    clinicHighlightedSurgeries,
    clinicSpecialties,
    clinicLinks,
    clinicPdfs,
    clinicEmployees,
    clinicVacancies,
  } = useSelector(selectTargetClinic);
  const dispatch = useDispatch();

  const {push} = useRouter();
  // @ts-ignore
  const {SIGN_IN_ROUTE, USER_IS_DEACTIVATED} = useRoutePaths();

  const requestTargetClinic = async (slug): Promise<void | boolean> => {
    const {responseBody, status} = await requestTargetClinicData(slug);

    if (status === RESPONSE_STATUSES.SUCCESS) {
      if (responseBody.inactive) {
        return push({
          pathname: USER_IS_DEACTIVATED.getLink(),
        });
      }

      dispatch(setTargetClinicData(responseBody));

      requestClinicStatistic(responseBody.user_id);
      requestUserClinic(responseBody.user_id);
    }
  };

  const requestTargetClinicById = async (clinicId: number) => {
    const {responseBody, status} = await requestTargetClinicDataById(clinicId);

    if (status === RESPONSE_STATUSES.SUCCESS) {
      dispatch(setTargetClinicData(responseBody));

      requestClinicStatistic(responseBody.user_id);
      requestUserClinic(responseBody.user_id);
    }
  };

  const requestClinicStatistic = useCallback(
    async (id: number): Promise<void> => {
      const {responseBody, status} = await requestTargetClinicStatistic(id);

      if (status === RESPONSE_STATUSES.SUCCESS) {
        dispatch(setTargetClinicStatistic(responseBody));
      }
    },
    []
  );

  const requestUserClinic = useCallback(async (id: number): Promise<void> => {
    dispatch(setTargetUserClinicLoading(true));
    const {responseBody, status} = await requestTargetuserClinic(id);

    if (status === RESPONSE_STATUSES.SUCCESS) {
      dispatch(setTargetUserClinic(responseBody.data[0]));
    }

    dispatch(setTargetUserClinicLoading(false));
  }, []);

  const requestClinicImagesAndVideo = useCallback(async (id) => {
    dispatch(setTargetClinicImagesLoading(true));
    const {status, responseBody} = await getImagesClinicGallery(id);

    if (status === RESPONSE_STATUSES.SUCCESS) {
      dispatch(setTargetClinicImages(responseBody.data));
    }

    dispatch(setTargetClinicImagesLoading(false));
  }, []);

  const requestUpdateFollow = useCallback(async () => {
    const targetUserId = pathOr(null, ['data', 'user', 'id'], clinic);
    const isFollowed = pathOr(false, ['data', 'is_followed'], clinic);

    if (!getAuthorizationToken()) {
      push(SIGN_IN_ROUTE.getLink());
    }

    dispatch(setTargetUserClinicLoading(true));

    const {status} = await requestToggleFollow(targetUserId, isFollowed);

    if (status === RESPONSE_STATUSES.SUCCESS) {
      dispatch(setFollowClinic(!isFollowed));
    }

    dispatch(setTargetUserClinicLoading(false));
  }, [clinic]);

  const requestClinicAwards = useCallback(async (clinicId: number) => {
    const {responseBody, status} = await fetchClinicAwards(clinicId);

    if (status === RESPONSE_STATUSES.SUCCESS) {
      dispatch(setTargetClinicAwards(responseBody.data));
    }
  }, []);

  const requestClinicSurgeries = useCallback(async (clinicId: number) => {
    const {responseBody, status} = await fetchClinicSurgeries(clinicId);

    if (status === RESPONSE_STATUSES.SUCCESS) {
      dispatch(setTargetClinicSurgeries(responseBody.data));
    }
  }, []);

  const requestClinicHighlightedSurgeries = useCallback(
    async (clinicId: number) => {
      const {responseBody, status} = await fetchClinicHighlightedSurgeries(
        clinicId
      );

      if (status === RESPONSE_STATUSES.SUCCESS) {
        dispatch(setTargetClinicHighlightedSurgeries(responseBody.data));
      }
    },
    []
  );

  const requestClinicSpecialties = useCallback(async (clinicId: number) => {
    const {responseBody, status} = await fetchClinicCompetences(clinicId);

    if (status === RESPONSE_STATUSES.SUCCESS) {
      dispatch(setTargetClinicSpecialties(responseBody.data));
    }
  }, []);

  const requestClinicLinks = useCallback(async (clinicId: number) => {
    const {responseBody, status} = await fetchClinicLinks(clinicId);

    if (status === RESPONSE_STATUSES.SUCCESS) {
      dispatch(setTargetClinicLinks(responseBody.data));
    }
  }, []);

  const requestClinicPdfs = useCallback(async (clinicId: number) => {
    const {responseBody, status} = await fetchClinicAttachments(clinicId);

    if (status === RESPONSE_STATUSES.SUCCESS) {
      dispatch(setTargetClinicPdfs(responseBody.data));
    }
  }, []);

  const requestClinicVacancies = useCallback(async (clinicId: number) => {
    const {responseBody, status} = await fetchClinicVacancies(clinicId);

    if (status === RESPONSE_STATUSES.SUCCESS) {
      dispatch(
        setTargetClinicVacancies({
          data: responseBody.data,
          totalNumber: responseBody.total_amount,
        })
      );
    }
  }, []);

  const requestClinicEmployees = useCallback(
    async (clinicId: number, offset: number, perPage: number) => {
      const {responseBody, status} = await requestTargetClinicEmployees(
        clinicId,
        offset,
        perPage
      );

      if (status === RESPONSE_STATUSES.SUCCESS) {
        dispatch(
          setTargetClinicEmployees({
            data: responseBody.data,
            totalAmount: responseBody.total_amount,
          })
        );
      }
    },
    []
  );

  const resetTargetClinic = useCallback(() => {
    dispatch(resetClinicData());
  }, []);

  return {
    clinic,
    statistic,
    images,
    clinicUser,
    requestTargetClinic,
    requestTargetClinicById,
    resetTargetClinic,
    requestClinicImagesAndVideo,
    requestUpdateFollow,
    requestClinicAwards,
    requestClinicEmployees,
    requestClinicSpecialties,
    clinicAwards,
    requestClinicSurgeries,
    requestClinicHighlightedSurgeries,
    clinicSurgeries,
    clinicHighlightedSurgeries,
    clinicSpecialties,
    clinicPdfs,
    clinicLinks,
    requestClinicLinks,
    requestClinicPdfs,
    clinicEmployees,
    clinicVacancies,
    requestClinicVacancies,
  };
};

export default useTargetClinic;
