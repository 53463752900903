import PublicationFileIcon from '@/public/icons/publicationFileIcon.svg';
import getRootServerUrl from '@/src/app/utils/getRootServerUrl';
import TextLocal from '@/src/textLocal/containers/TextLocal';
import {Text} from '@/src/ui';

import css from '../styles/DocumentAttachment.module.css';

interface DocumentAttachmentProps {
  id: number;
  fileName: string;
  url: string;
}

const DocumentAttachment = ({url, id, fileName}: DocumentAttachmentProps) => {
  return (
    <div className={css.documentItem}>
      <a
        target={'_blank'}
        className={css.publication}
        href={`${getRootServerUrl()}${url}`}
        rel={'noreferrer'}
        key={id}
      >
        <div className={css.publicationContent}>
          <div className={css.icon}>{<PublicationFileIcon />}</div>
          <div className={css.description}>
            <Text
              color={Text.COLOR.PRIMARY}
              fontSize={Text.FONT_SIZE.SIZE_14}
              fontWeight={Text.FONT_WEIGHT.MEDIUM}
            >
              <TextLocal id={'profile.document'} />
            </Text>
            <Text
              color={Text.COLOR.PRIMARY}
              fontSize={Text.FONT_SIZE.SIZE_16}
              fontWeight={Text.FONT_WEIGHT.BOLD}
            >
              {fileName}
            </Text>
          </div>
        </div>
      </a>
    </div>
  );
};

export default DocumentAttachment;
