import cn from 'clsx';
import PropTypes from 'prop-types';
import {memo} from 'react';

import {Text} from '../../index';
import css from '../styles/Tabs.module.css';

interface TabsProps {
  tabsList: {label: string | JSX.Element; value: string}[];
  active: string;
  onChange: (value: string) => void;
}

const Tabs = ({tabsList, active, onChange}: TabsProps) => {
  return (
    <div className={css.main} role="tablist">
      {tabsList.map((item) => (
        <div
          onClick={() => onChange(item.value)}
          key={item.value}
          role="tab"
          aria-selected={active === item.value}
          tabIndex={active === item.value ? 0 : -1}
          className={cn(css.tab, {
            [css.activeTab]: active === item.value,
          })}
        >
          <Text
            fontSize={Text.FONT_SIZE.SIZE_16}
            fontWeight={Text.FONT_WEIGHT.BOLD}
            lineHeight={Text.LINE_HEIGHT.LINE_HEIGHT_1_6}
          >
            {item.label}
          </Text>
        </div>
      ))}
    </div>
  );
};

Tabs.propTypes = {
  tabsList: PropTypes.array.isRequired,
  active: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default memo(Tabs);
