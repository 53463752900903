import cn from 'clsx';

import css from '../styles/BookmarkButton.module.css';

interface BookmarkButtonProps {
  isBookmarked: boolean;
  onClick?: () => void;
}

const BookmarkButton = ({isBookmarked, ...rest}: BookmarkButtonProps) => {
  return (
    <button
      className={cn(css.bookmarkButton, {
        [css.bookmarkButtonActive]: isBookmarked,
      })}
      {...rest}
    >
      <svg
        width="28"
        height="35"
        viewBox="0 0 28 35"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g>
          <path
            d="M8 24V8C8 6.89543 8.89543 6 10 6H18C19.1046 6 20 6.89543 20 8V24L14 19.3043L8 24Z"
            fill={isBookmarked ? 'url(#paint0_linear)' : 'white'}
          />
          <path
            d="M8 24H7C7 24.3825 7.21816 24.7314 7.562 24.899C7.90583 25.0665 8.31511 25.0232 8.61631 24.7875L8 24ZM14 19.3043L14.6163 18.5168C14.2543 18.2335 13.7457 18.2335 13.3837 18.5168L14 19.3043ZM20 24L19.3837 24.7875C19.6849 25.0232 20.0942 25.0665 20.438 24.899C20.7818 24.7314 21 24.3825 21 24H20ZM7 8V24H9V8H7ZM8.61631 24.7875L14.6163 20.0919L13.3837 18.5168L7.38369 23.2125L8.61631 24.7875ZM13.3837 20.0919L19.3837 24.7875L20.6163 23.2125L14.6163 18.5168L13.3837 20.0919ZM21 24V8H19V24H21ZM18 5H10V7H18V5ZM21 8C21 6.34315 19.6569 5 18 5V7C18.5523 7 19 7.44772 19 8H21ZM9 8C9 7.44772 9.44772 7 10 7V5C8.34315 5 7 6.34315 7 8H9Z"
            fill={isBookmarked ? 'url(#paint1_linear)' : 'white'}
          />
        </g>
        <defs>
          <linearGradient
            id="paint0_linear"
            x1="20"
            y1="24"
            x2="8"
            y2="24"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#2091EF" />
            <stop offset="0.311106" stopColor="#1A9DE1" />
            <stop offset="0.738896" stopColor="#0DBCCD" />
            <stop offset="1" stopColor="#01D6B9" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="20"
            y1="24"
            x2="8"
            y2="24"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#2091EF" />
            <stop offset="0.311106" stopColor="#1A9DE1" />
            <stop offset="0.738896" stopColor="#0DBCCD" />
            <stop offset="1" stopColor="#01D6B9" />
          </linearGradient>
        </defs>
      </svg>
    </button>
  );
};

BookmarkButton.defaultProps = {
  isBookmarked: false,
};

export default BookmarkButton;
