import PriceCardMobile from '@/src/promoSections/components/PricingSectionLayout/PriceCardMobile';

import css from '../../styles/PricingSection.module.css';

interface PricingMobileProps {
  pricingList: Array<any>;
  currentSymbol: string;
  isYearly: boolean;
}

const PricingMobile = ({
  pricingList,
  currentSymbol,
  isYearly,
}: PricingMobileProps) => {
  return (
    <div className={css.mobilePricesWrapper}>
      {pricingList.map(
        ({
          id,
          title,
          price,
          yearlyPrice,
          discount,
          description,
          slug,
          buttonText,
          oldPrice,
          oldPriceYearly,
          features,
          descriptionMonthly,
        }) => (
          <div key={id}>
            <PriceCardMobile
              title={title}
              price={price}
              yearlyPrice={yearlyPrice}
              discount={discount}
              description={isYearly ? description : descriptionMonthly}
              slug={slug}
              buttonText={buttonText}
              oldPrice={oldPrice}
              oldPriceYearly={oldPriceYearly}
              features={features}
              currentSymbol={currentSymbol}
              isYearly={isYearly}
            />
          </div>
        )
      )}
    </div>
  );
};

export default PricingMobile;
