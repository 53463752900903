import cn from 'clsx';
import PropTypes from 'prop-types';
import {ReactNode} from 'react';
import {Collapse} from 'react-collapse';

import css from '../styles/SimpleCollapse.module.css';

interface SimpleCollapseControlledProps {
  children: ReactNode;
  headTitle: ReactNode;
  isOpen: boolean;
  onClick: () => void;
}

const SimpleCollapseControlled = ({
  children,
  headTitle,
  isOpen,
  onClick,
}: SimpleCollapseControlledProps): JSX.Element => {
  return (
    <div className={css.mainWrapper}>
      <div className={css.header} onClick={onClick}>
        {headTitle}
        <div className={cn(css.toogleIcon, {[css.isOpen]: isOpen})} />
      </div>
      <Collapse isOpened={isOpen} theme={{collapse: css.content}}>
        {children}
      </Collapse>
    </div>
  );
};

SimpleCollapseControlled.propTypes = {
  children: PropTypes.node.isRequired,
  headTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

SimpleCollapseControlled.defaultProps = {};

export default SimpleCollapseControlled;
