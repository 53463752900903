import cn from 'clsx';
import {Field, Form, Formik} from 'formik';
import {useRouter} from 'next/router';
import {useTranslation} from 'next-i18next';
import React, {useCallback} from 'react';

import {
  ORDER_BY_FILTER,
  ORDER_FILTER,
  SEARCH_FILTERS,
} from '@/src/app/constants/search';
import useRoutePaths from '@/src/hooks/useRoutePaths';
import TextLocal from '@/src/textLocal/containers/TextLocal';

import {Button} from '../../ui';
import css from '../styles/SearchForm.module.css';
import SearchWords from './SearchWords';

const SearchForm = () => {
  const {t} = useTranslation('common');
  const {push, locale} = useRouter();
  const {SEARCH_UNITED_ROUTE} = useRoutePaths();

  const handleSubmit = useCallback((values) => {
    const searchWord =
      values.search.length > 0
        ? `${SEARCH_FILTERS.SEARCH}=${values.search}&`
        : '';

    push({
      pathname: SEARCH_UNITED_ROUTE.getLink(),
      query: `${searchWord}${SEARCH_FILTERS.OFFSET}=0&${SEARCH_FILTERS.ORDER}=${ORDER_FILTER.DESC}&${SEARCH_FILTERS.ORDER_BY}=${ORDER_BY_FILTER.APPROVED_AT}&${SEARCH_FILTERS.LANGUAGE}=${locale}`,
    });
  }, []);

  return (
    <>
      <Formik initialValues={{search: ''}} onSubmit={handleSubmit}>
        <Form>
          <div className={css.customSearch}>
            <Field
              className={cn(
                css.customSearchInput,
                css[`${locale}customSearchInput`]
              )}
              name="search"
              type="search"
              placeholder={t('promoSection.search')}
            />

            <div className={css.customSearchButton}>
              <Button type={Button.TYPES.SUBMIT} height={Button.HEIGHT.SMALL}>
                <TextLocal id={'promoSection.searchButton'} />
              </Button>
            </div>
          </div>
        </Form>
      </Formik>
      <SearchWords />
    </>
  );
};

export default React.memo(SearchForm);
