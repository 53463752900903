import NavLink from 'next/link';

import DynamicComponent from '@/src/dynamicComponents/components/DynamicComponent';
import useRoutePaths from '@/src/hooks/useRoutePaths';
import {Button, SectionContainer, Text} from '@/src/ui';

import css from '../../styles/WeAreTheBest.module.css';

const WeAreTheBest = ({blok}) => {
  const {title, buttonTitle, content} = blok;
  const {SIGN_UP_ROUTE} = useRoutePaths();

  return (
    <SectionContainer>
      <div className={css.main}>
        <div>
          <div className={css.titleWrapper}>
            <Text
              fontSize={Text.FONT_SIZE.SIZE_40}
              fontFamily={Text.FONT_FAMILY.RUBIK}
              fontWeight={Text.FONT_WEIGHT.MEDIUM}
              tagComponent={Text.TAG.H2}
            >
              {title}
            </Text>
          </div>

          <div className={css.buttonWrapper}>
            <NavLink href={SIGN_UP_ROUTE.getLink()}>
              <a>
                <Button height={Button.HEIGHT.SMALL}>{buttonTitle}</Button>
              </a>
            </NavLink>
          </div>
        </div>
        <div>
          {content
            ? content.map((blok) => (
                <DynamicComponent blok={blok} key={blok._uid} />
              ))
            : null}
        </div>
      </div>
    </SectionContainer>
  );
};

export default WeAreTheBest;
