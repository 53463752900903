import {memo, useMemo} from 'react';

import {Carousel, SectionContainer} from '@/src/ui';

import css from '../../styles/UserReviews.module.css';

const Review = ({image, text, name, position}) => {
  return (
    <div className={css.review}>
      <div className={css.textInfo}>
        <div className={css.reviewText}>
          <p className={css.userReviewText}>“{text}”</p>
        </div>
        <div className={css.userInfo}>
          <img
            className={css.mobileImage}
            src={image}
            loading="lazy"
            alt="user picture"
          />
          <div>
            <div className={css.name}>{name}</div>
            <div className={css.position}>{position}</div>
          </div>
        </div>
      </div>

      <img
        className={css.image}
        src={image}
        loading="lazy"
        alt="user picture"
      />
    </div>
  );
};

const MemoizedReview = memo(Review);

const UserReviews = ({blok}) => {
  const {list} = blok;

  const reviewsNodes = useMemo(
    () =>
      list.map((review, index) => (
        <div className={css.carouselItem} key={index}>
          <MemoizedReview
            image={review.image.filename}
            text={review.text}
            name={review.name}
            position={review.position}
          />
        </div>
      )),
    [list]
  );

  return (
    <SectionContainer>
      <div className={css.carouselWrapper}>
        <Carousel
          slideGap={Carousel.SLIDE_GAP.SLIDE_GAP_10}
          settings={{
            autoHeight: true,
            slidesPerView: 1,
          }}
        >
          {reviewsNodes}
        </Carousel>
      </div>
    </SectionContainer>
  );
};

export default memo(UserReviews);
