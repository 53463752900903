import {REST_METHODS} from '@/src/app/constants/api';
import unauthorizedFetch from '@/src/app/utils/unauthorizedFetch';

const getCategories = () => {
  // @ts-ignore
  return unauthorizedFetch({
    method: REST_METHODS.GET,
    path: `/api/v1/categories`,
  });
};

export default getCategories;
