import cn from 'clsx';
import {useRouter} from 'next/router';
import {useCallback, useEffect, useRef, useState} from 'react';

import {SIDE_BAR} from '@/src/app/constants/howToStoryblok';
import requestStoryBlok from '@/src/app/utils/requestStoryBlok';
import useOutsideClick from '@/src/hooks/useOutsideClick';
import HowToSideBar from '@/src/howTo/components/sideBar/HowToSideBar.storyblok';
import TextLocal from '@/src/textLocal/containers/TextLocal';
import {Text} from '@/src/ui';

import LoadingIcon from '../../../public/icons/loaing-dark.svg';
import css from '../styles/HowToMobileMenu.module.css';

const HowToMobileMenu = () => {
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const [menu, setMenu] = useState(null);
  const {asPath, locale} = useRouter();

  const menuRef = useRef();
  // State for our modal
  // Call hook passing in the ref and a function to call on outside click
  useOutsideClick(menuRef, () => setIsOpenMenu(false));

  const toggleMenu = useCallback(() => {
    setIsOpenMenu(!isOpenMenu);
  }, [isOpenMenu]);

  const getHowToMenu = useCallback(async () => {
    const {data} = await requestStoryBlok({
      slug: SIDE_BAR,
      query: {
        language: locale,
        version: 'publish',
        resolve_links: 'story',
      },
    });

    setMenu(data.story);
  }, []);

  useEffect(() => {
    getHowToMenu();
  }, []);

  useEffect(() => {
    setIsOpenMenu(false);
  }, [asPath]);

  return (
    <div className={css.howToMobile} ref={menuRef}>
      <div className={css.title} onClick={toggleMenu}>
        <Text
          fontSize={Text.FONT_SIZE.SIZE_14}
          fontWeight={Text.FONT_WEIGHT.BOLD}
          lineHeight={Text.LINE_HEIGHT.LINE_HEIGHT_1_6}
        >
          <TextLocal id={'wiki-how.title'} />
        </Text>
        <div
          className={cn(css.toggleIcon, {
            [css.isOpen]: isOpenMenu,
          })}
        />
      </div>
      {isOpenMenu && (
        <div className={css.howToMenuWrapper}>
          <div className={css.howToMenu}>
            {menu ? (
              <HowToSideBar blok={menu.content} borderOff />
            ) : (
              <LoadingIcon />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default HowToMobileMenu;
