import {Form, Formik} from 'formik';
import {useTranslation} from 'next-i18next';
import PropTypes from 'prop-types';
import * as Yup from 'yup';

import TextLocal from '@/src/textLocal/containers/TextLocal';

import {Button, Separator, Text, Textarea} from '../../ui';
import css from '../styles/SupportPopupLayout.module.css';

const COMMENT_MAX_LENGTH = 1500;

const getValidationSchema = (t) => {
  return Yup.object().shape({
    comment: Yup.string().required(t('validation.field-req')),
  });
};

const SupportPopupLayout = ({onSubmitClick, onCancelClick}) => {
  const {t} = useTranslation('common');

  return (
    <div className={css.popup}>
      <div className={css.heading}>
        <Text
          color={Text.COLOR.PRIMARY}
          fontSize={Text.FONT_SIZE.SIZE_32}
          fontFamily={Text.FONT_FAMILY.RUBIK}
          fontWeight={Text.FONT_WEIGHT.MEDIUM}
        >
          <TextLocal id={'header.leave-your-message'} />
        </Text>
      </div>
      <div className={css.popupContent}>
        <Formik
          initialValues={{comment: ''}}
          onSubmit={onSubmitClick}
          validationSchema={getValidationSchema(t)}
        >
          <Form>
            <div className={css.textareaBlock}>
              {/*@ts-ignore*/}
              <Textarea
                maxLength={COMMENT_MAX_LENGTH}
                placeholder={t('header.leave-your-comment')}
                name={'comment'}
                minRows={Textarea.ROWS.ROWS_6}
              />
            </div>
            <div className={css.separatorBlock}>
              <Separator />
            </div>
            <div className={css.buttonsBlock}>
              <div className={css.cancelPseudoButton} onClick={onCancelClick}>
                <Text
                  color={Text.COLOR.GREY}
                  fontWeight={Text.FONT_WEIGHT.MEDIUM}
                  fontSize={Text.FONT_SIZE.SIZE_16}
                >
                  <TextLocal id={'create-course.cancel'} />
                </Text>
              </div>
              <Button
                color={Button.COLOR.PRIMARY}
                height={Button.HEIGHT.REGULAR}
                type={Button.TYPES.SUBMIT}
              >
                <TextLocal id={'header.send'} />
              </Button>
            </div>
          </Form>
        </Formik>
      </div>
    </div>
  );
};

SupportPopupLayout.propTypes = {
  onCancelClick: PropTypes.func.isRequired,
  onSubmitClick: PropTypes.func.isRequired,
};

export default SupportPopupLayout;
