import {useRouter} from 'next/router';
import {useCallback, useEffect} from 'react';

import {getAuthorizationToken} from '@/src/app/utils/authorizationToken';
import ClinicPositionPreview from '@/src/jobPortal/clinicPositionPreview/containers/ClinicPositionPreview';
import useSingleJobPosition from '@/src/jobPortal/common/hooks/useSingleJobPosition';
import requestUpdateUniqueViews from '@/src/jobPortal/targetPosition/utils/requestUpdateUniqueViews';

const LOCAL_STORAGE_VIEWED_POSITIONS = 'LOCAL_STORAGE_VIEWED_POSITIONS';

const TargetPosition = () => {
  const {isReady} = useRouter();
  const {data} = useSingleJobPosition();

  const updateUniqueViews = useCallback(
    async (pageId: string | string[]) => {
      const viewedPositions = JSON.parse(
        localStorage.getItem(LOCAL_STORAGE_VIEWED_POSITIONS) || '[]'
      );
      const isViewed = viewedPositions.includes(pageId);

      if (!isViewed && !getAuthorizationToken()) {
        await requestUpdateUniqueViews(pageId);

        localStorage.setItem(
          LOCAL_STORAGE_VIEWED_POSITIONS,
          JSON.stringify([...viewedPositions, pageId])
        );
      }

      if (getAuthorizationToken()) {
        await requestUpdateUniqueViews(pageId);
      }
    },
    [data, isReady]
  );

  useEffect(() => {
    if (isReady && data.id) {
      updateUniqueViews(data.id);
    }
  }, [isReady, data.id]);

  return <ClinicPositionPreview isTargetPage />;
};

export default TargetPosition;
