import cn from 'clsx';
import Image from 'next/image';
import PropTypes from 'prop-types';
import React from 'react';

import TextLocal from '@/src/textLocal/containers/TextLocal';
import {Avatar} from '@/src/ui';

import Rating from '../../rating/components/Rating';
import Text from '../../text/components/Text';
import css from '../styles/UserPreview.module.css';

const THEME = {
  LIGHT: 'light',
  DARK: 'dark',
};

const SIZE = {
  SMALL: 'smallProfileImage',
  NORMAL: 'normalProfileImage',
};

const getFullUserName = (prefixedAcademicTitle, name) => {
  let fullName = '';

  if (prefixedAcademicTitle) {
    fullName += `${prefixedAcademicTitle} `;
  }

  fullName += name;

  return fullName;
};

const UserPreview = ({
  image,
  name,
  rating,
  theme,
  size,
  noMargin,
  isMobileColumn,
  isSmallUserName,
  cityName,
  countryName,
  prefixedAcademicTitle,
  jobTitle,
  withRating,
  showZeroRating,
}) => {
  return (
    <div
      className={cn(css.profileInfo, {
        [css.noMargin]: noMargin,
        [css.mobileColumn]: isMobileColumn,
      })}
    >
      <div className={cn(css.profileImage, css[size])}>
        <Avatar
          firstName={name.split(' ')[0]}
          lastName={name.split(' ')[1] || ''}
          size={size}
          picture={image ? image : null}
        />
      </div>
      <div className={cn(css.name, css[theme])} title={name}>
        <Text
          fontSize={
            isSmallUserName ? Text.FONT_SIZE.SIZE_11 : Text.FONT_SIZE.SIZE_16
          }
          tagComponent={Text.TAG.DIV}
          fontWeight={Text.FONT_WEIGHT.BOLD}
          lineHeight={Text.LINE_HEIGHT.LINE_HEIGHT_1_6}
          isOneLine
        >
          {getFullUserName(prefixedAcademicTitle, name)}
        </Text>
        {Boolean(jobTitle) && (
          <Text
            fontSize={Text.FONT_SIZE.SIZE_12}
            lineHeight={Text.LINE_HEIGHT.LINE_HEIGHT_1_6}
          >
            {jobTitle}
          </Text>
        )}
        {withRating ? (
          <div className={css.rating}>
            {typeof rating === 'number' && (showZeroRating || rating !== 0) && (
              <Rating rating={rating} fontWeight={Rating.FONT_WEIGHT.NORMAL} />
            )}
            {typeof rating === 'number' && !showZeroRating && rating === 0 && (
              <Text fontSize={Text.FONT_SIZE.SIZE_12} color={Text.COLOR.GREY}>
                <TextLocal id={'search.new-on-the-platform'} />
              </Text>
            )}
          </div>
        ) : (
          Boolean(countryName) &&
          Boolean(cityName) && (
            <div className={css.location}>
              <Image
                src={`/icons/locationIcon.svg`}
                loading="lazy"
                alt="true"
                width="9"
                height="12"
              />
              <Text
                fontSize={Text.FONT_SIZE.SIZE_12}
                color={Text.COLOR.GREY}
                lineHeight={Text.LINE_HEIGHT.LINE_HEIGHT_1_6}
              >
                {`${cityName}, ${countryName}`}
              </Text>
            </div>
          )
        )}
      </div>
    </div>
  );
};

UserPreview.THEME = THEME;
UserPreview.SIZE = SIZE;

UserPreview.propTypes = {
  image: PropTypes.string,
  cityName: PropTypes.string,
  countryName: PropTypes.string,
  prefixedAcademicTitle: PropTypes.string,
  jobTitle: PropTypes.string,
  name: PropTypes.string.isRequired,
  theme: PropTypes.oneOf(Object.values(THEME)),
  size: PropTypes.string,
  noMargin: PropTypes.bool,
  isMobileColumn: PropTypes.bool,
  isSmallUserName: PropTypes.bool,
  withRating: PropTypes.bool,
  rating: PropTypes.bool || PropTypes.number,
  showZeroRating: PropTypes.bool,
};

UserPreview.defaultProps = {
  image: null,
  name: 'name',
  theme: 'dark',
  size: 'noraml',
  noMargin: false,
  isMobileColumn: false,
  isSmallUserName: false,
  cityName: '',
  countryName: '',
  prefixedAcademicTitle: '',
  jobTitle: '',
  showZeroRating: false,
};

export default UserPreview;
