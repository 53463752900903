import DynamicComponent from '@/src/dynamicComponents/components/DynamicComponent';
import HeroBenefits from '@/src/landing/components/HeroWithEmail/HeroBenefits';
import HeroCheckmarks from '@/src/landing/components/HeroWithEmail/HeroCheckmarks';
import HeroSubTitle from '@/src/landing/components/HeroWithEmail/HeroSubTitle';
import HeroTitle from '@/src/landing/components/HeroWithEmail/HeroTitle';

import css from '../../styles/HeroWithEmail.module.css';

const HeroWithEmail = ({blok}) => {
  const {
    title,
    subTitle,
    innerContent,
    benefits = [],
    heroCheckmarks = [],
    bgImage,
    isBgColorWrapper,
  } = blok;

  const bgColor = {
    background: isBgColorWrapper ? 'rgba(27, 46, 69, 0.81)' : 'transparent',
  };

  const mainBgImage = {
    backgroundImage: bgImage?.filename
      ? `url(${bgImage.filename})`
      : 'url(/images/hero/HeroWithEmailbg.jpg)',
  };

  const preparedCheckmarks = heroCheckmarks.map((checkmark) => ({
    title: checkmark.title,
    icon: checkmark.icon.filename,
  }));

  const preparedBenefits = benefits.map((benefit) => ({
    title: benefit.title,
    icon: benefit.icon.filename,
  }));

  return (
    <div style={mainBgImage} className={css.bg}>
      <div className={css.main} style={bgColor}>
        <div className={css.topContent}>
          <div className={css.contentWrapper}>
            <div className={css.title}>
              <HeroTitle title={title} />
            </div>
            <div className={css.description}>
              <HeroSubTitle subTitle={subTitle} />
            </div>
            <div className={css.innterContent}>
              {innerContent
                ? innerContent.map((blok) => (
                    <DynamicComponent blok={blok} key={blok._uid} />
                  ))
                : null}
            </div>
            <HeroBenefits items={preparedBenefits} />
          </div>
        </div>
        <HeroCheckmarks items={preparedCheckmarks} />
      </div>
    </div>
  );
};

export default HeroWithEmail;
