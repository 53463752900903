import {REST_METHODS} from '@/src/app/constants/api';
import authorizedFetch from '@/src/app/utils/authorizedFetch';

const fetchAvailablePositions = (): Promise<any> => {
  // @ts-ignore
  return authorizedFetch({
    method: REST_METHODS.GET,
    path: `/api/v1/job_portal/preferable_positions`,
  });
};

export default fetchAvailablePositions;
