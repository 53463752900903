const SUBSCRIPTION_STATUSES = {
  NOT_SUBSCRIBED: 'not_subscribed',
  ACTIVE: 'active',
  INACTIVE: 'inactive',
  EXPIRED: 'expired',
  FAILED: 'failed',
  INCOMPLETE_REQUEST_ACTION: 'incomplete_requires_action',
};

export const SUBSCRIPTION_STRIPE_STATUSES = {
  INCOMPLETE: 'incomplete',
  INCOMPLETE_EXPIRED: 'incomplete_expired',
  TRIALING: 'trialing',
  ACTIVE: 'active',
  PAST_DUE: 'past_due',
  CANCELED: 'canceled',
  UNPAID: 'unpaid',
};

export default SUBSCRIPTION_STATUSES;
