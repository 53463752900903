import {useDispatch, useSelector} from 'react-redux';

import {
  setTargetUserDataAction,
  unsetTargetUserDataAction,
} from './targetUserReducer';

const useTargetUserActions = () => {
  const selectUser = ({targetUser}) => targetUser;
  const targetUser = useSelector(selectUser);

  const dispatch = useDispatch();

  const setTargetUserData = (user) => {
    dispatch(setTargetUserDataAction(user));
  };

  const unsetTargetUserData = () => {
    dispatch(unsetTargetUserDataAction());
  };

  return {
    setTargetUserData,
    unsetTargetUserData,
    targetUser,
  };
};

export default useTargetUserActions;
