import Link from 'next/link';

import {ROLES_NAMES} from '@/src/app/constants/roles';
import useRoutePaths from '@/src/hooks/useRoutePaths';
import TextLocal from '@/src/textLocal/containers/TextLocal';

import {Button, Text} from '../..';
import css from '../styles/CourseCardLargeLayout.module.css';

const MAX_TITLE_LENGTH = 65;

const getSlicedTitle = (title: string): string => {
  if (!title) {
    return '';
  }

  return `${title.slice(0, MAX_TITLE_LENGTH)}${
    title?.length >= MAX_TITLE_LENGTH ? '...' : ''
  }`;
};

interface CourseCardLargeLayoutProps {
  verticalPreviewImage: string;
  title: string;
  description: string;
  creator: {
    name: string;
    lastName: string;
    slug: string;
    type?: string;
  };
  slug: string;
  withDescription: boolean;
  isUser: boolean;
}

const CourseCardLargeLayout = ({
  verticalPreviewImage,
  title,
  description,
  creator,
  slug,
  withDescription,
  isUser,
}: CourseCardLargeLayoutProps) => {
  // @ts-ignore
  const {COURSES_ROUTE, USER_PROFILE, CLINIC_TARGET_PROFILE} = useRoutePaths();

  const creatorLink =
    creator?.type === ROLES_NAMES.CLINIC
      ? CLINIC_TARGET_PROFILE.getLink(creator?.slug)
      : USER_PROFILE.getLink(creator?.slug);

  return (
    <div className={css.card}>
      <div className={css.content}>
        <div className={css.author}>
          <Link href={creatorLink}>
            <a>
              <Text
                color={Text.COLOR.GREY}
                fontFamily={Text.FONT_FAMILY.POPPINS}
                fontSize={Text.FONT_SIZE.SIZE_14}
              >
                {`${creator?.name} ${creator?.lastName}`}
              </Text>
            </a>
          </Link>
        </div>
        <div className={css.title}>
          <Text
            fontFamily={Text.FONT_FAMILY.RUBIK}
            fontWeight={Text.FONT_WEIGHT.MEDIUM}
            fontSize={Text.FONT_SIZE.SIZE_26}
            lineHeight={Text.LINE_HEIGHT.LINE_HEIGHT_1_4}
          >
            {getSlicedTitle(title)}
          </Text>
        </div>
        {withDescription && (
          <div className={css.description}>
            <Text
              color={Text.COLOR.SECONDARY}
              fontSize={Text.FONT_SIZE.SIZE_12}
              lineHeight={Text.LINE_HEIGHT.LINE_HEIGHT_1_4}
            >
              {description}
            </Text>
          </div>
        )}
        <div className={css.actions}>
          <Link href={COURSES_ROUTE.getLink(slug)}>
            <a>
              <Button
                type={Button.TYPES.BUTTON}
                height={Button.HEIGHT.SMALL}
                color={Button.COLOR.SECONDARY}
              >
                <TextLocal
                  id={
                    isUser ? 'promoSection.see-more' : 'promoSection.watch-now'
                  }
                />
              </Button>
            </a>
          </Link>
        </div>
      </div>
      <div className={css.media}>
        {verticalPreviewImage && (
          <img
            src={verticalPreviewImage}
            className={css.mediaElement}
            loading="lazy"
            alt=""
          />
        )}
      </div>
    </div>
  );
};

CourseCardLargeLayout.defaultProps = {
  verticalPreviewImage: null,
  title: 'Title',
  creator: '',
};

export default CourseCardLargeLayout;
