import cn from 'clsx';
import ReactPaginate from 'react-paginate';

import ArrowIcon from '@/public/images/paginationArrow.svg';

import css from '../styles/Pagination.module.css';

interface PaginationContainerProps {
  startPage: number;
  totalItems: number;
  onPageChange: (data: {selected: number}) => void;
  limitPerPage: number;
}

const PaginationContainer = ({
  startPage,
  totalItems,
  onPageChange,
  limitPerPage,
}: PaginationContainerProps) => {
  let pagesCount = Math.ceil(totalItems / limitPerPage) || 1;

  if (totalItems <= limitPerPage) {
    return null;
  }

  return (
    <ReactPaginate
      forcePage={startPage}
      breakLabel="..."
      nextLabel={<ArrowIcon />}
      onPageChange={onPageChange}
      pageRangeDisplayed={2}
      marginPagesDisplayed={1}
      pageCount={pagesCount}
      previousLabel={<ArrowIcon />}
      renderOnZeroPageCount={null}
      containerClassName={css.pagination}
      pageLinkClassName={css.pageNumber}
      activeLinkClassName={css.activePage}
      previousLinkClassName={cn(css.arrow, css.arrowPrev)}
      nextLinkClassName={cn(css.arrow, css.arrowNext)}
      disabledClassName={css.disabled}
    />
  );
};

export default PaginationContainer;

PaginationContainer.defaultProps = {
  startPage: 0,
  limitPerPage: 10,
  totalItems: 0,
};
