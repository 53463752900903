import {useRouter} from 'next/router';
import PropTypes from 'prop-types';
import {useCallback, useEffect, useRef, useState} from 'react';

import {ROLES_NAMES} from '@/src/app/constants/roles';
import useUserActions from '@/src/app/store/user/useUserActions';
import useRoutePaths from '@/src/hooks/useRoutePaths';

import MyProfileNavbarLayout from '../components/MyProfileNavbarLayout';

const SCROLL_STORAGE_KEY = 'MY_PROFILE_NAVBAR_SCROLL_POSITION';

const MyProfileNavbar = ({currentPath, onNavbarLinkClick}) => {
  const containerRef = useRef();
  const [isInitialScrollMade, setIsInitialScrollMade] = useState(false);
  const {user} = useUserActions();
  const [isScrollTrackerActivated, setIsScrollTrackerActivated] =
    useState(false);
  const {push} = useRouter();
  const {
    MY_PROFILE_ABOUT_ME,
    MY_PROFILE_MY_CERTIFICATES,
    MY_PROFILE_SUBSCRIPTION,
    MY_PROFILE_ACCOUNT_SETTINGS,
    MY_PROFILE_WITHDRAW,
    MY_PROFILE_EMPLOYEES,
  } = useRoutePaths();

  const isAboutMeActive = currentPath === MY_PROFILE_ABOUT_ME.getLink();
  const isMyCertificatesActive =
    currentPath === MY_PROFILE_MY_CERTIFICATES.getLink();
  const isSubscriptionsActive =
    currentPath === MY_PROFILE_SUBSCRIPTION.getLink();
  const isWithdrawActive = currentPath === MY_PROFILE_WITHDRAW.getLink();
  const isAccountSettingsActive =
    currentPath === MY_PROFILE_ACCOUNT_SETTINGS.getLink();
  const isEmployeesActive = currentPath.includes(
    MY_PROFILE_EMPLOYEES.getLink()
  );

  const saveScrollPosition = useCallback(() => {
    if (containerRef.current) {
      // @ts-ignore
      localStorage.setItem(SCROLL_STORAGE_KEY, containerRef.current.scrollLeft);
    }
  }, []);

  useEffect(() => {
    if (containerRef.current && !isScrollTrackerActivated) {
      // @ts-ignore
      containerRef.current.addEventListener('scroll', saveScrollPosition);
      setIsScrollTrackerActivated(true);
    }

    if (containerRef.current && !isInitialScrollMade) {
      // @ts-ignore
      containerRef.current.scrollLeft =
        localStorage.getItem(SCROLL_STORAGE_KEY) || 0;
      setIsInitialScrollMade(true);
    }

    return () => {
      window.removeEventListener('scroll', saveScrollPosition);
    };
  }, [containerRef]);

  if (isSubscriptionsActive && user.external_domain) {
    push(MY_PROFILE_ABOUT_ME.getLink());
  }
  return (
    <MyProfileNavbarLayout
      isAboutMeActive={isAboutMeActive}
      isAccountSettingsActive={isAccountSettingsActive}
      isMyCertificatesActive={isMyCertificatesActive}
      isSubscriptionsActive={isSubscriptionsActive}
      isWithdrawActive={isWithdrawActive}
      isEmployeesActive={isEmployeesActive}
      isContentCreator={
        (user?.user?.type || ROLES_NAMES.CUSTOMER) ===
        ROLES_NAMES.COURSE_CREATOR
      }
      isClinic={
        (user?.user?.type || ROLES_NAMES.CUSTOMER) === ROLES_NAMES.CLINIC
      }
      onNavbarLinkClick={onNavbarLinkClick}
      containerRef={containerRef}
      userStatus={user?.user?.status}
      isExternalDomain={
        user.external_domain || user?.user?.type === ROLES_NAMES.COURSE_CREATOR
      }
    />
  );
};

MyProfileNavbar.propTypes = {
  currentPath: PropTypes.string.isRequired,
  onNavbarLinkClick: PropTypes.func.isRequired,
};

export default MyProfileNavbar;
