import cn from 'clsx';
import Link from 'next/link';
import {useRouter} from 'next/router';
import PropTypes from 'prop-types';
import React, {memo, useCallback, useMemo, useState} from 'react';

import OpenToJobIcon from '@/public/icons/openToJobIcon.svg';
import {RESPONSE_STATUSES} from '@/src/app/constants/api';
import {ROLES_LIST, ROLES_NAMES} from '@/src/app/constants/roles';
import USER_EMPTY_AVATAR from '@/src/app/constants/userEmptyAvatar';
import useTargetUserActions from '@/src/app/store/targetUser/useTargetUserActions';
import {getAuthorizationToken} from '@/src/app/utils/authorizationToken';
import useIsMobile from '@/src/hooks/useIsMobile';
import useLoading from '@/src/hooks/useLoading';
import useRoutePaths from '@/src/hooks/useRoutePaths';
import TextLocal from '@/src/textLocal/containers/TextLocal';

import requestToggleFollow from '../../../contentCreatorWidget/utils/requestToggleFollow';
import fetchUserByUsername from '../../../profile/target/utils/fetchUserByUsername';
import {Button, Text, UserPreview} from '../..';
import Rating from '../../rating/components/Rating';
import css from '../styles/UserCard.module.css';

const TARGET_CLINIC_ROUTE = '/clinic/';

const MAX_VISIBLE_LINK_LENGTH = 70;

const MAX_VISIBLE_LINK_LENGTH_TABLET = 50;

const getSlicedBiography = (
  biography: string,
  isMaxWidth850: boolean
): string => {
  const restrictedLength = isMaxWidth850
    ? MAX_VISIBLE_LINK_LENGTH_TABLET
    : MAX_VISIBLE_LINK_LENGTH;

  return `${biography.slice(0, restrictedLength)}${
    biography?.length >= restrictedLength ? '...' : ''
  }`;
};

interface UserCardLayoutProps {
  id: number;
  type: string;
  name: string;
  rating: number;
  image: string;
  slug: string;
  isFollowed: boolean;
  onFollowToggleCallback: () => void;
  currentUserId: number;
  jobTitle: string;
  prefixedAcademicTitle: string;
  countryName: string;
  cityName: string;
  biographySanitized: string;
  withOpenToJob: boolean;
}
const UserCardLayout = ({
  id,
  type,
  name,
  rating,
  image,
  slug,
  isFollowed,
  onFollowToggleCallback,
  currentUserId,
  jobTitle,
  prefixedAcademicTitle,
  countryName,
  cityName,
  biographySanitized,
  withOpenToJob,
}: UserCardLayoutProps) => {
  const {isLoading, setLoading} = useLoading();
  const [isUserFollowed, setUserFollowed] = useState(isFollowed);
  // @ts-ignore
  const {SIGN_IN_ROUTE, USER_PROFILE, CLINIC_TARGET_PROFILE} = useRoutePaths();
  const {setTargetUserData} = useTargetUserActions();
  const {isMaxWidth850, isMaxWidth600} = useIsMobile();
  const {
    push,
    query: {username},
    asPath,
  } = useRouter();

  const profileLink = useMemo(
    () => (type === ROLES_NAMES.CLINIC ? CLINIC_TARGET_PROFILE : USER_PROFILE),
    [type, CLINIC_TARGET_PROFILE, USER_PROFILE]
  );

  const handleFollowToggleClick = useCallback(async () => {
    if (!getAuthorizationToken()) {
      push(SIGN_IN_ROUTE.getLink());
      return;
    }

    setLoading(true);
    const {status} = await requestToggleFollow(id, isUserFollowed);

    if (status === RESPONSE_STATUSES.SUCCESS) {
      if (onFollowToggleCallback) {
        await onFollowToggleCallback();
      }

      setUserFollowed((state) => !state);

      if (username && !asPath.includes(TARGET_CLINIC_ROUTE)) {
        const {responseBody, status} = await fetchUserByUsername(username);

        if (status === RESPONSE_STATUSES.SUCCESS) {
          setTargetUserData(responseBody);
        }
      }
    }

    setLoading(false);
  }, [
    asPath,
    isUserFollowed,
    onFollowToggleCallback,
    setTargetUserData,
    username,
    id,
    isUserFollowed,
  ]);

  return (
    <div className={css.card}>
      <div className={css.cardBody}>
        <div
          className={cn(
            css.cardInfo,
            !withOpenToJob && css.cardInfoWithoutOpen
          )}
        >
          <div className={css.cardInfoLeft}>
            <div className={css.headTag}>
              <Text
                color={Text.COLOR.PRIMARY}
                fontSize={Text.FONT_SIZE.SIZE_12}
                fontWeight={Text.FONT_WEIGHT.MEDIUM}
                lineHeight={Text.LINE_HEIGHT.LINE_HEIGHT_1_4}
              >
                <TextLocal
                  id={
                    type &&
                    ROLES_LIST.find((level) => level.value === type).label
                  }
                />
              </Text>
            </div>
            {typeof rating === 'number' && rating !== 0 && (
              <Rating
                rating={rating}
                fontWeight={Rating.FONT_WEIGHT.NORMAL}
                withRating={false}
              />
            )}
          </div>
          {withOpenToJob && (
            <div className={css.cardInfoRight}>
              <OpenToJobIcon />
              <Text
                fontSize={Text.FONT_SIZE.SIZE_12}
                fontWeight={Text.FONT_WEIGHT.BOLD}
                color={Text.COLOR.GRADIENT}
              >
                <TextLocal id={'search.open'} />
              </Text>
            </div>
          )}
        </div>
        <div className={css.userPreview}>
          <UserPreview
            name={name}
            rating={rating}
            image={!name ? USER_EMPTY_AVATAR : image}
            prefixedAcademicTitle={prefixedAcademicTitle}
            jobTitle={jobTitle}
            countryName={countryName}
            cityName={cityName}
            size={isMaxWidth600 ? 'medium' : 'regular'}
          />
        </div>
        <div className={css.description}>
          <Text
            color={Text.COLOR.GREY}
            fontSize={Text.FONT_SIZE.SIZE_14}
            lineHeight={Text.LINE_HEIGHT.LINE_HEIGHT_21}
            fontFamily={Text.FONT_FAMILY.POPPINS}
            fontWeight={Text.FONT_WEIGHT.NORMAL}
          >
            {getSlicedBiography(biographySanitized, isMaxWidth850)}
          </Text>
        </div>
        {name && (
          <div className={css.controls}>
            <div className={css.controlButton}>
              {currentUserId !== id && (
                <Button
                  color={
                    isUserFollowed ? Button.COLOR.RED : Button.COLOR.PRIMARY
                  }
                  isLoading={isLoading}
                  height={Button.HEIGHT.SMALL}
                  onClick={handleFollowToggleClick}
                >
                  <TextLocal
                    id={
                      isUserFollowed
                        ? 'courseDetails.unfollow'
                        : 'courseDetails.follow'
                    }
                  />
                </Button>
              )}
            </div>
            <div className={css.controlButton}>
              <Link href={profileLink.getLink(slug)}>
                <a
                  className={css.viewProfileLink}
                  aria-label={'view profile link'}
                >
                  <Button
                    height={Button.HEIGHT.SMALL}
                    color={Button.COLOR.SECONDARY}
                    border={Button.BORDER.BOLD}
                  >
                    <TextLocal id={'courseDetails.view-profile'} />
                  </Button>
                </a>
              </Link>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

UserCardLayout.propTypes = {
  id: PropTypes.number,
  type: PropTypes.string,
  courseCount: PropTypes.number,
  name: PropTypes.string,
  rating: PropTypes.number,
  image: PropTypes.string,
  company: PropTypes.string,
  jobTitle: PropTypes.string,
  slug: PropTypes.string,
  isFollowed: PropTypes.bool,
  withOpenToJob: PropTypes.bool,
  onFollowToggleCallback: PropTypes.func,
  currentUserId: PropTypes.number,
  cityName: PropTypes.string,
  countryName: PropTypes.string,
  prefixedAcademicTitle: PropTypes.string,
  suffixedAcademicTitle: PropTypes.string,
  biographySanitized: PropTypes.string,
};

UserCardLayout.defaultProps = {
  company: '',
  jobTitle: '',
  biographySanitized: '',
  withOpenToJob: false,
};

export default memo(UserCardLayout);
