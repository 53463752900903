import {sbEditable} from '@storyblok/storyblok-editable';

import BulletListWithIcon from '@/src/landing/components/BulletListWithIcon';

import css from '../styles/WeAreTheBest.module.css';

const BulletListWithIconStoryblok = ({blok}) => {
  const {text, isGreyIcon} = blok;
  return (
    <div className={css.bulletWrapper}>
      <BulletListWithIcon
        text={text}
        isGreyIcon={isGreyIcon}
        {...sbEditable(blok)}
      />
    </div>
  );
};

export default BulletListWithIconStoryblok;
