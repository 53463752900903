import PropTypes from 'prop-types';
import React from 'react';

import BulletListWithIcon from '@/src/landing/components/BulletListWithIcon';

import css from '../styles/WhyBestItem.module.css';

const WhyBestItem = ({title, icon, list}) => {
  return (
    <div>
      <div className={css.title}>
        <div className={css.titleIcon}>
          <img src={icon} loading="lazy" alt="icon" />
        </div>
        {title}
      </div>
      <div>
        <ul className={css.list}>
          {list.map(({text, isGreyIcon}) => (
            <BulletListWithIcon
              text={text}
              isGreyIcon={isGreyIcon}
              key={text}
            />
          ))}
        </ul>
      </div>
    </div>
  );
};

WhyBestItem.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  list: PropTypes.array.isRequired,
};

export default WhyBestItem;
