import cn from 'clsx';
import React, {useCallback} from 'react';

import LoadingIcon from '@/public/icons/loaing.svg';
import DeleteIcon from '@/public/icons/trash-red.svg';
import ArrowIcon from '@/public/images/arrow-down.svg';
import TextLocal from '@/src/textLocal/containers/TextLocal';

import Button from '../../button/components/Button';
import Text from '../../text/components/Text';
import css from '../styles/DropZone.module.css';
import ProgressLine from './ProgressLine';

export const DROP_ZONE_TEST_IDS = {
  DROPZONE: 'dropZone',
  DELETE: 'delete',
};

interface DropZoneProps {
  onChange: (...args: any) => any;
  preview?: string;
  isLoading?: boolean;
  fileName?: string;
  handleRemoveItemClick?: () => void;
  isProgresLine?: boolean;
  progressPercent?: number;
  message?: string | JSX.Element;
  typeDescription?: string;
  accept?: string;
  isMultiple?: boolean;
  withoutIcon?: boolean;
}

const DropZone = ({
  onChange,
  preview,
  isLoading,
  fileName,
  handleRemoveItemClick,
  isProgresLine,
  progressPercent,
  message,
  typeDescription,
  accept,
  isMultiple,
  withoutIcon,
}: DropZoneProps) => {
  const handleOnDrop = useCallback(
    (event) => {
      event.stopPropagation();
      event.preventDefault();
      onChange({target: {files: event.dataTransfer.files}});
    },
    [onChange]
  );

  const handleOnDragOver = useCallback((event) => {
    event.stopPropagation();
    event.preventDefault();
  }, []);

  return (
    <label
      data-testid={DROP_ZONE_TEST_IDS.DROPZONE}
      className={css.dropzone}
      onDrop={handleOnDrop}
      onDragOver={handleOnDragOver}
    >
      {(isLoading || fileName) && (
        <div
          className={cn(css.dropzoneInner, css.dropzoneInnerFilled, {
            [css.dropzoneInnerAutoHeight]: withoutIcon,
          })}
        >
          {isLoading && !isProgresLine && <LoadingIcon />}
          {isLoading && isProgresLine && (
            <div className={css.progressWrapper}>
              <div className={css.progressWrapperBackground}>
                <ProgressLine persent={progressPercent} />
              </div>
            </div>
          )}
          {!isLoading && Boolean(fileName) && (
            <div className={css.uploadedVideo}>
              {preview ? (
                <div className={css.imgPreview}>
                  <img src={preview} loading="lazy" alt="preview" />
                </div>
              ) : (
                <div className={css.uploadedVideoIsUploaded}>
                  <TextLocal id={'general.video-uploaded'} />
                </div>
              )}
              <div className={css.videoInfo}>
                <span className={css.videoName}>{fileName}</span>
                <span
                  className={css.trash}
                  onClick={handleRemoveItemClick}
                  data-testid={DROP_ZONE_TEST_IDS.DELETE}
                >
                  <DeleteIcon />
                </span>
              </div>
            </div>
          )}
        </div>
      )}
      {!isLoading && !fileName && (
        <>
          <input
            type="file"
            multiple={isMultiple}
            className={css.fileInput}
            onChange={onChange}
            accept={accept}
          />
          <div
            className={cn(css.dropzoneInner, {
              [css.dropzoneInnerAutoHeight]: withoutIcon,
            })}
          >
            {!withoutIcon && (
              <div className={css.dropzoneIcon}>
                <ArrowIcon />
              </div>
            )}
            <div className={css.uploadDescription}>{message}</div>

            <div className={css.buttonWrapper}>
              <Button
                tagComponent={Button.TAG.DIV}
                color={Button.COLOR.SECONDARY}
                height={Button.HEIGHT.SMALL}
                border={Button.BORDER.BOLD}
              >
                <Text
                  fontWeight={Text.FONT_WEIGHT.MEDIUM}
                  fontSize={Text.FONT_SIZE.SIZE_16}
                >
                  <TextLocal id={'general.upload'} />
                </Text>
              </Button>
            </div>
            {typeDescription && (
              <div className={css.typeDescription}>{typeDescription}</div>
            )}
          </div>
        </>
      )}
    </label>
  );
};

export default DropZone;
