import cn from 'clsx';
import PropTypes from 'prop-types';

import css from '../styles/ViewWithSideBar.module.css';

const SIDEBAR_SIZE = {
  SIZE_280: 'sidebar280',
  DEFAULT: 'default',
};

const ViewWithSideBar = ({sideBar, mainContent, sideBarSize}) => {
  return (
    <section className={cn(css.main, css[sideBarSize])}>
      <aside>{sideBar}</aside>
      {mainContent}
    </section>
  );
};

ViewWithSideBar.SIDEBAR_SIZE = SIDEBAR_SIZE;
ViewWithSideBar.propTypes = {
  sideBar: PropTypes.node.isRequired,
  mainContent: PropTypes.node.isRequired,
  sideBarSize: PropTypes.oneOf(Object.values(SIDEBAR_SIZE)),
};

ViewWithSideBar.defaultProps = {
  sideBarSize: SIDEBAR_SIZE.DEFAULT,
};

export default ViewWithSideBar;
