import lgVideo from 'lightgallery/plugins/video';
import lgZoom from 'lightgallery/plugins/zoom';
import dynamic from 'next/dynamic';
import PropTypes from 'prop-types';
import {useCallback, useRef} from 'react';

import css from '../../styles/FileWithPreview.module.css';

const LightGallery = dynamic(() => import('lightgallery/react'), {
  ssr: false,
});

const ImageFile = ({src}) => {
  const lightGalleryRef = useRef(null);

  const handleOpenGallery = useCallback(() => {
    if (!lightGalleryRef.current) {
      return;
    }

    lightGalleryRef.current.click();
  }, [lightGalleryRef, src]);

  return (
    <div className={css.imageWrapper}>
      <LightGallery
        licenseKey={process.env.NEXT_PUBLIC_LIGHTGALLERY_LICENSE_KEY}
        speed={400}
        plugins={[lgZoom, lgVideo]}
        mobileSettings={{
          closable: true,
        }}
        download={false}
        zoom={false}
        counter={false}
      >
        <a href={src} ref={lightGalleryRef} aria-label={'open image gallary'}>
          <img loading="lazy" alt="" src={src} />
        </a>
      </LightGallery>
      <div className={css.zoomImage}>
        <button className={css.action} onClick={handleOpenGallery}>
          <img src="/icons/zoomIcon.svg" loading="lazy" alt="zoom image" />
        </button>
      </div>
    </div>
  );
};

ImageFile.propTypes = {
  src: PropTypes.string.isRequired,
};

export default ImageFile;
