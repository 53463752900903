import {sbEditable} from '@storyblok/storyblok-editable';
import PropType from 'prop-types';
import {useState} from 'react';

import useIsMobile from '@/src/hooks/useIsMobile';
import TextLocal from '@/src/textLocal/containers/TextLocal';

import {Carousel, Text} from '../../ui';
import css from '../styles/FeaturesSection.module.css';
import CommonSectionLayout from './CommonSectionLayout';
import FeatureSliderItem from './FeatureSliderItem';

const FeaturesSection = ({blok}) => {
  const {title, sliderItems} = blok;
  const [textSlider, setTextSlider] = useState(null);
  const {isMaxWidth600} = useIsMobile();

  return (
    <div className={css.section} {...sbEditable(blok)}>
      <CommonSectionLayout
        title={title}
        centeredTitle
        content={
          <div className={css.content}>
            <div className={css.textSlider}>
              <Carousel
                settings={{
                  effect: 'creative',
                  creativeEffect: {
                    prev: {
                      opacity: 0,
                    },
                    next: {
                      opacity: 0,
                    },
                  },
                  slidesPerView: 1,
                  loop: false,
                  onSwiper: setTextSlider,
                  pagination: false,
                  navigation: false,
                  enabled: false,

                  breakpoints: {
                    900: {
                      slidesPerView: 1,
                    },
                  },
                }}
              >
                {sliderItems.map(({title, text, _uid}) => (
                  <div className={css.slideInfo} key={_uid}>
                    <div className={css.textSliderTitle}>
                      <Text
                        color={Text.COLOR.GREY}
                        fontWeight={Text.FONT_WEIGHT.BOLD}
                        fontSize={Text.FONT_SIZE.SIZE_18}
                        lineHeight={Text.LINE_HEIGHT.LINE_HEIGHT_1_4}
                      >
                        <TextLocal id={title} />
                      </Text>
                    </div>
                    <Text
                      fontSize={
                        isMaxWidth600
                          ? Text.FONT_SIZE.SIZE_14
                          : Text.FONT_SIZE.SIZE_18
                      }
                      color={Text.COLOR.GREY}
                      lineHeight={Text.LINE_HEIGHT.LINE_HEIGHT_1_4}
                    >
                      <TextLocal id={text} />
                    </Text>
                  </div>
                ))}
              </Carousel>
            </div>
            <Carousel
              isResetOnMobile
              isVerticalCardsEffect
              settings={{controller: {control: textSlider}}}
            >
              {sliderItems.map(({_uid, image, vimeoVideo, video}) => {
                return (
                  <FeatureSliderItem
                    src={image || video?.filename || vimeoVideo}
                    key={_uid}
                  />
                );
              })}
            </Carousel>
          </div>
        }
      />
    </div>
  );
};

FeaturesSection.propTypes = {
  blok: PropType.object,
};

export default FeaturesSection;
