import {useRouter} from 'next/router';
import {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {RESPONSE_STATUSES} from '@/src/app/constants/api';
import {
  selectCategories,
  setCategories,
  setCategoryCourses,
} from '@/src/app/store/promoSections/promoSectionsSlice';
import transforCoursesData from '@/src/app/utils/transforCoursesData';

import CategoriesLayout from '../components/CategoriesLayout';
import getCategories from '../utils/getCategories';
import getCoursesByCategory from '../utils/getCoursesByCategory';

interface CategoriesContainerProps {
  blok?: any;
}

const CategoriesContainer = ({blok = {}}: CategoriesContainerProps) => {
  const {buttonTitle: storyblokButtonTitle, sectionTitle} = blok;
  const [activeCategoryId, setActiveCategoryId] = useState();
  const {categoriesData, categoryCourses} = useSelector(selectCategories);

  const dispatch = useDispatch();
  const {locale} = useRouter();

  const requestGetCategories = useCallback(async () => {
    const {status, responseBody} = await getCategories();

    if (status === RESPONSE_STATUSES.SUCCESS) {
      dispatch(
        setCategories([
          {id: 'all', name_de: 'Alle', name_en: 'All'},
          ...responseBody.categories,
        ])
      );
    }
  }, []);

  const requestGetCoursesByCategory = useCallback(async (id) => {
    const {status, responseBody} = await getCoursesByCategory(id, locale);
    if (status === RESPONSE_STATUSES.SUCCESS) {
      dispatch(
        setCategoryCourses(transforCoursesData(responseBody?.data, locale))
      );
    }
  }, []);

  useEffect(() => {
    !categoriesData.length && requestGetCategories();
  }, []);

  useEffect(() => {
    setActiveCategoryId(categoriesData[0]?.id || null);
  }, [categoriesData]);

  useEffect(() => {
    activeCategoryId && requestGetCoursesByCategory(activeCategoryId);
  }, [activeCategoryId]);

  const handleActiveCategoryIdClick = (id) => {
    setActiveCategoryId(id);
  };

  return (
    <CategoriesLayout
      categories={categoriesData}
      categoryCourses={categoryCourses}
      locale={locale}
      activeCategoryId={activeCategoryId}
      handleActiveCategoryIdClick={handleActiveCategoryIdClick}
      storyblokButtonTitle={storyblokButtonTitle}
      storyblokSectionTitle={sectionTitle}
    />
  );
};

export default CategoriesContainer;
