import {useRouter} from 'next/router';
import PropTypes from 'prop-types';
import {useCallback, useEffect, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {RESPONSE_STATUSES} from '@/src/app/constants/api';
import {
  ORDER_BY_FILTER,
  ORDER_FILTER,
  SEARCH_FILTERS,
} from '@/src/app/constants/search';
import {
  selectPopularCourses,
  setPopularCourses,
} from '@/src/app/store/promoSections/promoSectionsSlice';
import transforCoursesData from '@/src/app/utils/transforCoursesData';
import useRoutePaths from '@/src/hooks/useRoutePaths';
import TextLocal from '@/src/textLocal/containers/TextLocal';

import {Carousel, CourseCard} from '../../../ui';
import SectionDoubleColumnsLayout from '../../components/SectionDoubleColumnsLayout';
import getPopularCourses from '../../utils/getPopularCourses';

const PopularCoursesContainer = ({isLanding}) => {
  const popularCourses = useSelector(selectPopularCourses);
  const {locale} = useRouter();
  const dispatch = useDispatch();
  const {SEARCH_COURSES} = useRoutePaths();

  const requestGetCourse = useCallback(async () => {
    const {status, responseBody} = await getPopularCourses(locale);

    if (status === RESPONSE_STATUSES.SUCCESS) {
      dispatch(
        setPopularCourses(transforCoursesData(responseBody?.data, locale))
      );
    }
  }, []);

  useEffect(() => {
    !popularCourses.length && requestGetCourse();
  }, []);

  const seeAllUrl = useMemo(() => {
    return `${SEARCH_COURSES.getLink()}?${SEARCH_FILTERS.OFFSET}=0&${
      SEARCH_FILTERS.ORDER
    }=${ORDER_FILTER.DESC}&${SEARCH_FILTERS.ORDER_BY}=${
      ORDER_BY_FILTER.AVERAGE_SCORE
    }&${SEARCH_FILTERS.LANGUAGE}=${locale}`;
  }, [SEARCH_COURSES, locale]);

  return (
    <SectionDoubleColumnsLayout
      seeAllUrl={seeAllUrl}
      title={
        <>
          <TextLocal id={'promoSection.popular-courses'} />
        </>
      }
      subtitle={
        <TextLocal
          id={
            isLanding
              ? 'promoSection.value-access-global-knowledge'
              : 'promoSection.ambition-accepted'
          }
        />
      }
      rightSideContent={
        popularCourses.length > 0 ? (
          <Carousel
            paginationMobileToRight={!isLanding}
            slideGap={Carousel.SLIDE_GAP.SLIDE_GAP_WITH_COUNTER}
            settings={{
              loop: true,
            }}
            navigationPosition={Carousel.NAVIGATION_POSITION.BOTTOM}
          >
            {popularCourses.map((item, i) => (
              <CourseCard {...item} cardNumber={i + 1} key={item.id} />
            ))}
          </Carousel>
        ) : null
      }
      isLandingButtons={isLanding}
    />
  );
};

PopularCoursesContainer.propTypes = {
  isLanding: PropTypes.bool,
};

PopularCoursesContainer.defaultProps = {
  isLanding: false,
};

export default PopularCoursesContainer;
