import {REST_METHODS} from '@/src/app/constants/api';
import authorizedFetch from '@/src/app/utils/authorizedFetch';

const publishClinicPosition = (positionId: number) => {
  // @ts-ignore
  return authorizedFetch({
    method: REST_METHODS.PUT,
    path: `/api/v1/clinics/vacancies/${positionId}/publish`,
  });
};

export default publishClinicPosition;
