import {REST_METHODS} from '@/src/app/constants/api';
import {getAuthorizationToken} from '@/src/app/utils/authorizationToken';
import authorizedFetch from '@/src/app/utils/authorizedFetch';
import unauthorizedFetch from '@/src/app/utils/unauthorizedFetch';

const requestTargetuserClinic = (id: number): Promise<any> => {
  const requestMethod = getAuthorizationToken()
    ? authorizedFetch
    : unauthorizedFetch;

  // @ts-ignore
  return requestMethod({
    method: REST_METHODS.GET,
    path: '/api/v1/users/search',
    queryParams: [
      {
        name: 'user_id',
        value: id,
      },
    ],
  });
};

export default requestTargetuserClinic;
