import cn from 'clsx';
import moment from 'moment';
import Image from 'next/image';
import {useRouter} from 'next/router';
import {useTranslation} from 'next-i18next';
import PropTypes from 'prop-types';

import {DATE_MONTH_YEAR, SERVER_FORMAT} from '@/src/app/constants/date';
import getRootServerUrl from '@/src/app/utils/getRootServerUrl';
import TextLocal from '@/src/textLocal/containers/TextLocal';
import {Avatar, Button, PageTitle, Text} from '@/src/ui';

import css from '../styles/ProfessionalExperienceEditWidgetLayout.module.css';

export const getClinicAvatar = (avatarLink) => {
  if (!avatarLink) {
    return null;
  }

  return `${getRootServerUrl()}${avatarLink}`;
};

/**
 * @param {string} experienceItem.job_title
 * @param {string || null} experienceItem.department
 * @return {string}
 */
const getExperienceItemHeading = (experienceItem) => {
  return `${experienceItem.job_title}${
    experienceItem.department ? ', ' + experienceItem.department : ''
  }`;
};

/**
 * @param {string} experienceItem.start_date
 * @param {string || null} experienceItem.end_date
 * @return {string}
 */
const getExperienceItemDate = (experienceItem, t) => {
  return `${moment(experienceItem.start_date, SERVER_FORMAT).format(
    DATE_MONTH_YEAR
  )} - ${
    experienceItem.end_date
      ? moment(experienceItem.end_date, SERVER_FORMAT).format(DATE_MONTH_YEAR)
      : t('profile.till-now')
  }`;
};

const ProfessionalExperienceEditWidgetLayout = ({
  professionalExperience,
  onAddExperienceClick,
  onDeleteExperienceClick,
  withEditButtons,
}) => {
  const {t} = useTranslation('common');
  const {locale} = useRouter();

  return (
    <div className={css.widget}>
      <PageTitle
        title={<TextLocal id={'profile.professional-experience'} />}
        offset={PageTitle.BOTTOM_OFFSET.OFFSET_30}
      />
      {Boolean(professionalExperience.length) && (
        <div className={css.experienceList}>
          {professionalExperience.map((experienceItem) => (
            <div
              className={cn(
                css.experienceItem,
                withEditButtons && css.editableExperienceItem
              )}
              key={experienceItem.id}
            >
              <div className={css.experienceHeading}>
                <Text
                  fontSize={Text.FONT_SIZE.SIZE_18}
                  fontWeight={Text.FONT_WEIGHT.BOLD}
                >
                  {getExperienceItemHeading(experienceItem)}
                </Text>
              </div>
              <div className={css.experienceSubheading}>
                <div className={css.experienceItemSubheader}>
                  {Boolean(experienceItem.employment_type) && (
                    <Text
                      color={Text.COLOR.GREY}
                      fontSize={Text.FONT_SIZE.SIZE_16}
                      fontWeight={Text.FONT_WEIGHT.BOLD}
                    >
                      {t(`employment.${experienceItem.employment_type}`)}
                    </Text>
                  )}
                  <Text
                    color={Text.COLOR.GREY}
                    fontSize={Text.FONT_SIZE.SIZE_16}
                    fontWeight={Text.FONT_WEIGHT.BOLD}
                  >
                    {experienceItem.country['name_' + locale]}
                  </Text>
                  <Text
                    color={Text.COLOR.GREY}
                    fontSize={Text.FONT_SIZE.SIZE_16}
                    fontWeight={Text.FONT_WEIGHT.BOLD}
                  >
                    {experienceItem.city['name_' + locale]}
                  </Text>
                  <div className={css.clinicName}>
                    {Boolean(experienceItem.clinic) && (
                      <div className={css.experienceItemAvatar}>
                        <Avatar
                          size={'extraSmall'}
                          organizationName={
                            experienceItem.clinic.organization_name
                          }
                          picture={getClinicAvatar(
                            experienceItem.clinic.original_picture
                          )}
                        />
                      </div>
                    )}
                    <Text
                      color={Text.COLOR.GREY}
                      fontSize={Text.FONT_SIZE.SIZE_16}
                      fontWeight={Text.FONT_WEIGHT.BOLD}
                    >
                      {experienceItem.clinic
                        ? experienceItem?.clinic?.organization_name
                        : experienceItem.organization}
                    </Text>
                  </div>
                </div>
              </div>
              <Text
                color={Text.COLOR.GREY}
                fontWeight={Text.FONT_WEIGHT.BOLD}
                fontSize={Text.FONT_SIZE.SIZE_14}
              >
                {getExperienceItemDate(experienceItem, t)}
              </Text>
              <div className={css.experienceItemControls}>
                <div onClick={() => onAddExperienceClick(experienceItem)}>
                  <Image
                    src={`/icons/editPrimaryIcon.svg`}
                    loading="lazy"
                    alt="true"
                    width="24"
                    height="24"
                  />
                </div>
                <div onClick={() => onDeleteExperienceClick(experienceItem.id)}>
                  <Image
                    src={`/icons/trash-red.svg`}
                    loading="lazy"
                    alt="true"
                    width="16"
                    height="16"
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
      {withEditButtons && (
        <div className={css.addButton}>
          <Button
            onClick={() => onAddExperienceClick(null)}
            color={Button.COLOR.SECONDARY}
            height={Button.HEIGHT.SMALL}
            border={Button.BORDER.BOLD}
          >
            {'+ '}
            <TextLocal id={'general.add'} />
          </Button>
        </div>
      )}
    </div>
  );
};

ProfessionalExperienceEditWidgetLayout.propTypes = {
  professionalExperience: PropTypes.array,
  onAddExperienceClick: PropTypes.func,
  onDeleteExperienceClick: PropTypes.func,
  withEditButtons: PropTypes.bool,
};

ProfessionalExperienceEditWidgetLayout.defaultProps = {
  onAddExperienceClick: () => {},
  onDeleteExperienceClick: () => {},
  withEditButtons: true,
};

export default ProfessionalExperienceEditWidgetLayout;
