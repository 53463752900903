import Head from 'next/head';
import {useRouter} from 'next/router';
import {useTranslation} from 'next-i18next';
import {serverSideTranslations} from 'next-i18next/serverSideTranslations';

import {RESPONSE_STATUSES} from '@/src/app/constants/api';
import requestGetSingleJobPosition from '@/src/jobPortal/common/utils/requestGetSingleJobPosition';
import TargetPosition from '@/src/jobPortal/targetPosition/containers/TargetPosition';

export const PREVIEW_IMAGE_PATH = 'images/metaPreviewSocial-v2.png';

interface PositionPageProps {
  position: {
    host: string;
    description: string;
    title: string;
  };
}

const PositionPage = ({position}: PositionPageProps) => {
  const {t} = useTranslation('common');
  const {asPath} = useRouter();

  const fullPath = `https://${position.host}${asPath}`;
  const imagesPath = `https://${position.host}/${PREVIEW_IMAGE_PATH}`;

  const metaTitle = `${t('job-portal.position')}, ${position.title}`;
  const metaDescription = position.description;

  return (
    <>
      <Head>
        {/*Primary Meta Tags*/}
        <title>
          {`${t('general.company-name')} - ${t('job-portal.position')}, ${
            position.title
          }`}
        </title>
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="title" content={metaTitle} />
        <meta name="description" content={metaDescription} />

        {/*Open Graph / Facebook*/}
        <meta property="og:type" content="website" />
        <meta property="og:url" content={fullPath} />
        <meta property="og:title" content={metaTitle} />
        <meta property="og:description" content={metaDescription} />
        <meta property="og:image" content={imagesPath} />

        {/*Twitter*/}
        <meta name="twitter:site" content={position.host} />
        <meta name="twitter:title" content={metaTitle} />
        <meta name="twitter:description" content={metaDescription} />
        <meta name="twitter:image" content={imagesPath} />
      </Head>
      <TargetPosition />
    </>
  );
};

export default PositionPage;

export const getServerSideProps = async ({locale, query: {slug}, req}) => {
  try {
    const {responseBody, status} = await requestGetSingleJobPosition(slug);

    if (status === RESPONSE_STATUSES.NOT_FOUND) {
      return {
        redirect: {
          destination: '/404',
          permanent: true,
        },
      };
    }

    return {
      props: {
        position: {
          host: req.headers.host,
          ...responseBody,
        },
        ...(await serverSideTranslations(locale, ['common'])),
      },
    };
  } catch (error) {
    return {
      props: {
        ...(await serverSideTranslations(locale, ['common'])),
      },
    };
  }
};
