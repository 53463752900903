import 'react-phone-input-2/lib/style.css';

import {useRouter} from 'next/router';
import {pathOr} from 'ramda';
import {useRef} from 'react';
import PhoneInputNode from 'react-phone-input-2';
import de from 'react-phone-input-2/lang/de.json';

import css from '../styles/PhoneInputLayout.module.css';

const LOCALIZATION = {
  en: undefined,
  de: de,
};

const DEFAULT_PLACEHOLDER = 'xxx xxx xxx';

interface PhoneInputLayoutProps {
  value?: string;
  placeholder?: string;
  onInputChange: (...args: any) => any;
}

const PhoneInputLayout = ({
  value,
  onInputChange,
  placeholder,
}: PhoneInputLayoutProps) => {
  const {locale} = useRouter();
  const phoneInputRef = useRef(null);

  const handleInputChange = (phoneNumber) => {
    const inputElement = phoneInputRef.current.querySelector('input');

    onInputChange({
      withMask: pathOr('', ['value'], inputElement),
      phoneNumber,
    });
  };

  return (
    <div ref={phoneInputRef}>
      <PhoneInputNode
        localization={LOCALIZATION[locale]}
        value={value}
        buttonClass={css.buttonClass}
        inputClass={css.inputClass}
        placeholder={placeholder || DEFAULT_PLACEHOLDER}
        onChange={handleInputChange}
      />
    </div>
  );
};

export default PhoneInputLayout;
