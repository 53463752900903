import cn from 'clsx';
import Link from 'next/link';
import PropTypes from 'prop-types';

import useIsMobile from '@/src/hooks/useIsMobile';
import TextLocal from '@/src/textLocal/containers/TextLocal';

import {Button, Text} from '../../ui';
import css from '../styles/CommonSectionLayout.module.css';

const CommonSectionLayout = ({
  title,
  centeredTitle,
  seeAllUrl,
  fullWidth,
  content,
  isOutSideLink,
  buttonTitle,
}) => {
  const {isMaxWidth900} = useIsMobile();

  return (
    <section className={css.section}>
      <div
        className={cn(css.container, {
          [css.fullWidth]: fullWidth,
        })}
      >
        <div className={cn(css.title, {[css.centeredTitle]: centeredTitle})}>
          <Text
            fontSize={
              isMaxWidth900 ? Text.FONT_SIZE.SIZE_28 : Text.FONT_SIZE.SIZE_40
            }
            fontWeight={Text.FONT_WEIGHT.MEDIUM}
            fontFamily={Text.FONT_FAMILY.RUBIK}
            tagComponent={Text.TAG.H2}
          >
            {title}
          </Text>
        </div>
        <div className={css.content}>{content}</div>
        {seeAllUrl && (
          <div className={css.controls}>
            <Link href={seeAllUrl}>
              <a
                target={isOutSideLink && '_blank'}
                aria-label={`link to ${buttonTitle}`}
              >
                <Button
                  height={Button.HEIGHT.SMALL}
                  color={Button.COLOR.SECONDARY}
                >
                  {buttonTitle || <TextLocal id={'promoSection.see-all'} />}
                </Button>
              </a>
            </Link>
          </div>
        )}
      </div>
    </section>
  );
};

CommonSectionLayout.propTypes = {
  title: PropTypes.element,
  centeredTitle: PropTypes.bool,
  seeAllUrl: PropTypes.string,
  buttonTitle: PropTypes.string,
  isOutSideLink: PropTypes.bool,
  fullWidth: PropTypes.bool,
  content: PropTypes.node,
};

CommonSectionLayout.defaultProps = {
  seeAllUrl: null,
  showLogo: false,
};

export default CommonSectionLayout;
