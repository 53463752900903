import cn from 'clsx';
import {ErrorMessage, Field, Form, Formik} from 'formik';
import {useTranslation} from 'next-i18next';
import PropTypes from 'prop-types';
import {FC} from 'react';
import * as Yup from 'yup';

import NewsLetterIcon from '@/public/images/newsLetterIcon.svg';
import TextLocal from '@/src/textLocal/containers/TextLocal';

import {Text} from '../../ui';
import css from '../styles/footer.module.css';

const getValidationSchema = (t) => {
  return Yup.object().shape({
    email: Yup.string()
      .email(t('emailStep.email_is_invalid'))
      .required(t('emailStep.email_is_required')),
  });
};

interface SubscribeEmailProps {
  subscribeToNewsLetters: (values: any, formikHelpers: any) => void;
  isDark?: boolean;
  buttonTitle?: string;
  placeholder?: string;
}

const SubscribeEmail: FC<SubscribeEmailProps> = ({
  subscribeToNewsLetters,
  isDark,
  buttonTitle,
  placeholder,
}) => {
  const {t} = useTranslation('common');

  return (
    <div>
      <Formik
        onSubmit={subscribeToNewsLetters}
        validationSchema={getValidationSchema(t)}
        initialValues={{
          email: '',
          isAgree: false,
        }}
      >
        {({values}) => (
          <Form>
            <div
              className={cn(css.subscribeWrapper, {
                [css.subscribeWrapperDark]: isDark,
              })}
            >
              <div
                className={cn(css.subscribeIcon, {
                  [css.subscribeIconDark]: isDark,
                })}
              >
                <NewsLetterIcon />
              </div>
              <Field
                name="email"
                className={cn(css.subscribeInput, {
                  [css.subscribeInputDark]: isDark,
                })}
                placeholder={
                  placeholder ? placeholder : t('footer.email-placeholder')
                }
              />
              <button
                className={cn(css.subscribeButton, {
                  [css.subscribeButtonDark]: isDark,
                  [css.disabledButton]: !values.email,
                })}
                type={'submit'}
                disabled={!values.isAgree}
              >
                {/*@ts-ignore*/}
                {buttonTitle ? buttonTitle : t('footer.subscribe-now')}
              </button>
            </div>
            {values.isAgree && (
              <ErrorMessage
                name="email"
                render={(message) => <div className={css.error}>{message}</div>}
              />
            )}

            <label className={css.subscribeCheckboxWrapper}>
              <Field name={'isAgree'} type={'checkbox'} />
              <div
                className={cn(css.subscribeCheckbox, {
                  [css.subscribeCheckboxDark]: isDark,
                })}
              />
              <Text
                fontSize={
                  isDark ? Text.FONT_SIZE.SIZE_12 : Text.FONT_SIZE.SIZE_14
                }
                color={isDark ? Text.COLOR.PRIMARY : Text.COLOR.WHITE}
                lineHeight={Text.LINE_HEIGHT.LINE_HEIGHT_1_6}
                fontFamily={Text.FONT_FAMILY.POPPINS}
              >
                <TextLocal id={'footer.subscribe-agree'} />
              </Text>
            </label>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default SubscribeEmail;

SubscribeEmail.propTypes = {
  subscribeToNewsLetters: PropTypes.func.isRequired,
};
