import cn from 'clsx';
import Link from 'next/link';
import {useTranslation} from 'next-i18next';
import PropTypes from 'prop-types';
import {pathOr} from 'ramda';

import CrossIcon from '@/public/images/mobileMenuCrossIcon.svg';
import HamburgerIcon from '@/public/images/mobileMenuIcon.svg';
import {ROLES_NAMES} from '@/src/app/constants/roles';
import useUserActions from '@/src/app/store/user/useUserActions';
import MobileOpenForNewOpportunities from '@/src/header/containers/MobileOpenForNewOpportunities';
import useRoutePaths from '@/src/hooks/useRoutePaths';
import TextLocal from '@/src/textLocal/containers/TextLocal';

import SearchIcon from '../../../public/icons/searchIconWithoutBorder.svg';
import {Separator, Text} from '../../ui';
import css from '../styles/MobileMenuLayout.module.css';
import MobileMenuLinksWidget from './MobileMenuLinksWidget';

const MobileMenuLayout = ({
  isOpened,
  onMenuIconClick,
  onSearchQueryChange,
  searchQuery,
  picture,
  userInitials,
  fullName,
  userSubheader,
  currentPath,
  onSignOutClick,
  onSupportClick,
  onSubmitSearchClick,
  activeMenu,
}) => {
  const {t} = useTranslation('common');
  const {MY_PROFILE_ABOUT_ME, SIGN_IN_ROUTE} = useRoutePaths();

  const {user} = useUserActions();
  const userId = pathOr(null, ['user', 'id'], user);
  const isOpenForNewOpportunities = pathOr(
    null,
    ['profile', 'open_for_job'],
    user
  );

  const withShowOpenForNewJobsWidget =
    userId &&
    user.user.type !== ROLES_NAMES.CLINIC &&
    user.user.type === ROLES_NAMES.CUSTOMER;

  return (
    <span className={css.mobileMenu}>
      <div onClick={onMenuIconClick} className={css.menuIcon}>
        {!isOpened ? <HamburgerIcon /> : <CrossIcon />}
      </div>
      {isOpened && (
        <div className={css.sideBar}>
          <div className={css.sideBarOverlay} />
          <div className={css.sideBarGradient}>
            <div className={css.searchInputBlock}>
              <div className={css.searchBlockContent}>
                <form onSubmit={onSubmitSearchClick}>
                  <input
                    className={css.searchInput}
                    placeholder={t('promoSection.searchButton')}
                    value={searchQuery}
                    onChange={onSearchQueryChange}
                  />
                  <div className={css.searchInputOverlay} />
                  <div className={css.searchIcon}>
                    <button type={'submit'} onClick={onSubmitSearchClick}>
                      <SearchIcon />
                    </button>
                  </div>
                </form>
              </div>
            </div>
            {userId && (
              <Link href={MY_PROFILE_ABOUT_ME.getLink()}>
                <a>
                  <div className={css.userWidget}>
                    <div className={css.avatarWrapper}>
                      {picture ? (
                        <img
                          src={picture}
                          loading="lazy"
                          alt={'profile image'}
                          className={css.avatar}
                        />
                      ) : (
                        <Text
                          fontWeight={Text.FONT_WEIGHT.BOLD}
                          fontSize={Text.FONT_SIZE.SIZE_40}
                        >
                          {userInitials}
                        </Text>
                      )}
                    </div>
                    <div className={css.userFullName}>
                      <Text
                        fontFamily={Text.FONT_FAMILY.RUBIK}
                        fontSize={Text.FONT_SIZE.SIZE_16}
                        color={Text.COLOR.PRIMARY}
                        fontWeight={Text.FONT_WEIGHT.MEDIUM}
                      >
                        {fullName}
                      </Text>
                    </div>
                    <div className={css.userStatus}>
                      <Text
                        fontSize={Text.FONT_SIZE.SIZE_14}
                        color={Text.COLOR.GREY}
                      >
                        {userSubheader}
                      </Text>
                    </div>
                    <div className={css.separatorBlock}>
                      <Separator borderWeight={Separator.BORDER_WEIGHT.THIN} />
                    </div>
                  </div>
                </a>
              </Link>
            )}
            <MobileMenuLinksWidget
              currentPath={currentPath}
              onMenuToggle={onMenuIconClick}
              activeMenu={activeMenu}
            />
            <div
              className={cn(css.footer, {
                [css.footerWithOpenJobsWidget]: withShowOpenForNewJobsWidget,
              })}
            >
              {userId && (
                <div onClick={onSupportClick} className={css.supportBlock}>
                  <Text
                    color={Text.COLOR.PRIMARY}
                    fontSize={Text.FONT_SIZE.SIZE_20}
                  >
                    <TextLocal id={'header.support'} />
                  </Text>
                </div>
              )}

              {userId ? (
                <div onClick={onSignOutClick} className={css.signOutBlock}>
                  <Text
                    color={Text.COLOR.RED}
                    fontSize={Text.FONT_SIZE.SIZE_20}
                  >
                    <TextLocal id={'header.sign-out'} />
                  </Text>
                </div>
              ) : (
                <div className={css.signInBlock}>
                  <Link href={SIGN_IN_ROUTE.getLink()}>
                    <a>
                      <Text fontSize={Text.FONT_SIZE.SIZE_20}>
                        <TextLocal id={'userType.sign-in'} />
                      </Text>
                    </a>
                  </Link>
                </div>
              )}
            </div>
            {withShowOpenForNewJobsWidget && (
              <div className={css.jobWidget}>
                <MobileOpenForNewOpportunities
                  userId={userId}
                  isOpenForNewJobs={isOpenForNewOpportunities}
                />
              </div>
            )}
          </div>
        </div>
      )}
    </span>
  );
};

MobileMenuLayout.propTypes = {
  isOpened: PropTypes.bool.isRequired,
  onMenuIconClick: PropTypes.func.isRequired,
  picture: PropTypes.string,
  userInitials: PropTypes.string.isRequired,
  userSubheader: PropTypes.string.isRequired,
  currentPath: PropTypes.string.isRequired,
  onSignOutClick: PropTypes.func.isRequired,
  onSupportClick: PropTypes.func.isRequired,
  onSearchQueryChange: PropTypes.func.isRequired,
  searchQuery: PropTypes.string.isRequired,
  fullName: PropTypes.string.isRequired,
  onSubmitSearchClick: PropTypes.func.isRequired,
};

export default MobileMenuLayout;
