import TextLocal from '@/src/textLocal/containers/TextLocal';
import {Button, Text} from '@/src/ui';

import css from '../styles/OpenForNewOpportunities.module.css';

interface OpenForNewOpportunitiesInActiveProps {
  turnOnClick: (...args: any[]) => any;
  isHeader: boolean;
}

const OpenForNewOpportunitiesInActive = ({
  turnOnClick,
  isHeader,
}: OpenForNewOpportunitiesInActiveProps) => {
  return (
    <div>
      {!isHeader && (
        <div className={css.descriptionText}>
          <Text
            fontSize={Text.FONT_SIZE.SIZE_12}
            color={Text.COLOR.GREY}
            lineHeight={Text.LINE_HEIGHT.LINE_HEIGHT_1_6}
          >
            <TextLocal id={'open-opportunities.if-you-looking-description'} />
          </Text>
        </div>
      )}
      <div className={css.buttonTurnOn}>
        <Button
          height={Button.HEIGHT.SMALL}
          tagComponent={Button.TAG.BUTTON}
          onClick={turnOnClick}
        >
          <TextLocal id={'open-opportunities.turn-on'} />
        </Button>
      </div>
    </div>
  );
};

export default OpenForNewOpportunitiesInActive;
