import ConfettiImage from '@/public/images/jobPortal/confetti.svg';
import TextLocal from '@/src/textLocal/containers/TextLocal';
import {Button, Text} from '@/src/ui';

import css from '../styles/VacancyApplicationSuccessPopup.module.css';

interface ApplicationSuccessPopupProps {
  clinicName: string;
  onGotItClick: (...args: any) => any;
}

const VacancyApplicationSuccessPopup = ({
  clinicName,
  onGotItClick,
}: ApplicationSuccessPopupProps) => {
  return (
    <div className={css.popup}>
      <div className={css.content}>
        <div className={css.heading}>
          <Text
            fontFamily={Text.FONT_FAMILY.RUBIK}
            fontWeight={Text.FONT_WEIGHT.MEDIUM}
            fontSize={Text.FONT_SIZE.SIZE_24}
            lineHeight={Text.LINE_HEIGHT.LINE_HEIGHT_1_6}
          >
            <TextLocal
              id={'job-portal.your-application-has-been-sent'}
              params={{clinicName}}
            />
          </Text>
        </div>
        <div className={css.description}>
          <Text
            fontSize={Text.FONT_SIZE.SIZE_14}
            lineHeight={Text.LINE_HEIGHT.LINE_HEIGHT_21}
          >
            <TextLocal id={'job-portal.all-connections-via-email'} />
          </Text>
        </div>
        <div className={css.buttonBlock}>
          <div className={css.button}>
            <Button
              onClick={onGotItClick}
              color={Button.COLOR.PRIMARY}
              height={Button.HEIGHT.SMALL}
            >
              <TextLocal id={'job-portal.got-it'} />
            </Button>
          </div>
        </div>
      </div>
      <div className={css.confettiBackground}>
        <ConfettiImage />
      </div>
    </div>
  );
};

export default VacancyApplicationSuccessPopup;
