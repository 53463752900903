import cn from 'clsx';
import PropTypes from 'prop-types';

import LoadingIcon from '../../../../public/icons/loaing.svg';
import css from '../styles/Button.module.css';

const COLOR = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  WHITE: 'white',
  RED: 'red',
  RED_FILL: 'redFill',
  WHITE_SECONDARY: 'whiteSecondary',
  YELLOW: 'yellow',
  UNSTYLED_GRADIENT: 'unstyledGradient',
};

const ARROW = {
  NONE: 'none',
  UP: 'arrowUp',
  DOWN: 'arrowDown',
  RIGHT: 'arrowRight',
  LEFT: 'arrowLeft',
};

const HEIGHT = {
  // 40px
  SMALL: 'smallHeight',

  // 46
  REGULAR: 'regularHeight',

  // 50px
  MEDIUM: 'mediumHeight',

  // 60px
  NORMAL: 'normalHeight',

  // 100px
  BIG: 'bigHeight',
};

const TYPES = {
  SUBMIT: 'submit',
  BUTTON: 'button',
};

const BORDER = {
  // border: 1px solid
  NORMAL: 'borderNormal',

  // border: 2px solid
  BOLD: 'borderBold',
};

const TAG = {
  BUTTON: 'button',
  SPAN: 'span',
  P: 'p',
  DIV: 'div',
  H1: 'h1',
  H2: 'h2',
  H3: 'h3',
  H4: 'h4',
};

const Button = ({
  color,
  height,
  border,
  children,
  arrow,
  onClick,
  type,
  isLoading,
  isDisabled,
  tagComponent: TagComponent,
}) => {
  return (
    <div
      className={cn(css.buttonBlock, {
        [css.buttonBlockLoading]: isLoading,
        [css.buttonBlockDisabled]: isDisabled,
      })}
    >
      <TagComponent
        className={cn(
          css.button,
          css[color],
          css[height],
          css[border],
          css[arrow]
        )}
        onClick={onClick}
        type={type}
      >
        {isLoading && (
          <div className={css.loadingWrapper}>
            <LoadingIcon />
          </div>
        )}
        {!isLoading && <span className={css.children}>{children}</span>}
      </TagComponent>
    </div>
  );
};

Button.ARROW = ARROW;
Button.COLOR = COLOR;
Button.HEIGHT = HEIGHT;
Button.TYPES = TYPES;
Button.BORDER = BORDER;
Button.TAG = TAG;

Button.propTypes = {
  color: PropTypes.oneOf(Object.values(COLOR)),
  height: PropTypes.oneOf(Object.values(HEIGHT)),
  border: PropTypes.oneOf(Object.values(BORDER)),
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  onClick: PropTypes.func,
  type: PropTypes.oneOf(Object.values(TYPES)),
  isLoading: PropTypes.bool,
  tagComponent: PropTypes.oneOf(Object.values(TAG)),
  arrow: PropTypes.oneOf(Object.values(ARROW)),
  isDisabled: PropTypes.bool,
};

Button.defaultProps = {
  color: Button.COLOR.PRIMARY,
  height: Button.HEIGHT.NORMAL,
  border: Button.BORDER.NORMAL,
  children: '',
  type: null,
  isLoading: false,
  isDisabled: false,
  tagComponent: 'button',
  arrow: ARROW.NONE,
};

export default Button;
