import cn from 'clsx';
import PropTypes from 'prop-types';

import Footer from '../../footer/containers/FooterContainer';
import Header from '../../header/containers/Header';
import {ApplicationContainer} from '../../ui';
import css from '../styles/MainContainer.module.css';

const TOP_OFFSET = {
  OFFSET_80: 'offset80',
  OFFSET_50: 'offset50',
  OFFSET_15: 'offset15',
  OFFSET_0: 'offset0',
};

const CONTAINER_WIDTH = {
  FULL_WIDTH: 'fullWidth',
  EIX_WIDTH: 'fixedWidth',
};

const MainContainer = ({
  children,
  topOffset,
  widthContainer,
  isDisableBackground,
}) => {
  return (
    <ApplicationContainer isDisableBackground={isDisableBackground}>
      <div className={css.wrapper}>
        <Header />
        <div className={cn(css.main, css[topOffset], css[widthContainer])}>
          {children}
        </div>
        <Footer />
      </div>
    </ApplicationContainer>
  );
};

MainContainer.propTypes = {
  topOffset: PropTypes.oneOf(Object.values(TOP_OFFSET)),
  widthContainer: PropTypes.oneOf(Object.values(CONTAINER_WIDTH)),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  isDisableBackground: PropTypes.bool,
};

MainContainer.defaultProps = {
  topOffset: TOP_OFFSET.OFFSET_80,
  widthContainer: CONTAINER_WIDTH.EIX_WIDTH,
  isDisableBackground: false,
};

MainContainer.TOP_OFFSET = TOP_OFFSET;
MainContainer.CONTAINER_WIDTH = CONTAINER_WIDTH;

export default MainContainer;
