import cn from 'clsx';
import PropTypes from 'prop-types';

import {
  ORDER_BY_FILTER,
  ORDER_FILTER,
  SEARCH_FILTERS,
} from '@/src/app/constants/search';
import useIsMobile from '@/src/hooks/useIsMobile';
import useRoutePaths from '@/src/hooks/useRoutePaths';
import TextLocal from '@/src/textLocal/containers/TextLocal';

import {Carousel, CourseCard} from '../../ui';
import css from '../styles/CategoriesLayout.module.css';
import CommonSectionLayout from './CommonSectionLayout';

const CategoriesLayout = ({
  categories,
  locale,
  activeCategoryId,
  handleActiveCategoryIdClick,
  categoryCourses,
  storyblokButtonTitle,
  storyblokSectionTitle,
}) => {
  const {isMaxWidth900} = useIsMobile();
  const {SEARCH_COURSES} = useRoutePaths();

  const slidesNode = (categoryCourses) => {
    return categoryCourses.map((item) => (
      <CourseCard {...item} key={item.id} />
    ));
  };

  const categorySeeAllUrl = `${SEARCH_COURSES.getLink()}?${
    SEARCH_FILTERS.OFFSET
  }=0&${SEARCH_FILTERS.ORDER}=${ORDER_FILTER.DESC}&${SEARCH_FILTERS.ORDER_BY}=${
    ORDER_BY_FILTER.APPROVED_AT
  }&${SEARCH_FILTERS.LANGUAGE}=${locale}${
    activeCategoryId !== 'all'
      ? `&${SEARCH_FILTERS.FILTERS_CATEGORY_IDS}=["${activeCategoryId}"]`
      : ''
  }`;

  return (
    <CommonSectionLayout
      buttonTitle={storyblokButtonTitle}
      seeAllUrl={categorySeeAllUrl}
      title={
        storyblokSectionTitle?.length ? (
          storyblokSectionTitle
        ) : (
          <TextLocal id={'promoSection.categories'} />
        )
      }
      content={
        <>
          <div className={css.categoryList}>
            {categories?.map((itm) => (
              <div
                className={css.categoryItem}
                key={itm.id}
                onClick={() => handleActiveCategoryIdClick(itm.id)}
              >
                <button
                  className={cn(css.categoryButton, {
                    [css.categoryButtonActive]: activeCategoryId === itm.id,
                  })}
                >
                  {itm[`name_${locale}`]}
                </button>
              </div>
            ))}
          </div>
          <div className={css.cardList}>
            {categoryCourses?.length > 0 ? (
              isMaxWidth900 ? (
                <Carousel
                  slideGap={Carousel.SLIDE_GAP.SLIDE_GAP_20}
                  paginationMobileToRight
                >
                  {slidesNode(categoryCourses)}
                </Carousel>
              ) : (
                slidesNode(categoryCourses)
              )
            ) : null}
          </div>
        </>
      }
    />
  );
};

CategoriesLayout.propTypes = {
  categories: PropTypes.array,
  locale: PropTypes.string,
  activeCategoryId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  handleActiveCategoryIdClick: PropTypes.func,
  categoryCourses: PropTypes.array,
  storyblokButtonTitle: PropTypes.string,
  storyblokSectionTitle: PropTypes.string,
};

CategoriesLayout.defaultProps = {
  categories: [],
};

export default CategoriesLayout;
