import css from '../styles/ProgressLine.module.css';

interface ProgressLineProps {
  persent: number;
}
const ProgressLine = ({persent}: ProgressLineProps) => {
  return (
    <div className={css.main}>
      <div
        className={css.line}
        style={{
          width: `${persent}%`,
        }}
      />
    </div>
  );
};

export default ProgressLine;
