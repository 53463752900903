import moment from 'moment';

import {SERVER_FORMAT} from '@/src/app/constants/date';

const getDaysBeforeExpire = (
  startDate: string | Date,
  expiredDate: string,
  withCurrentDay?: boolean,
  withLastDay?: boolean
) => {
  const currentDate = moment(startDate);
  const expireDateFormatted = moment(expiredDate, SERVER_FORMAT);

  let leftDays = Math.round(
    moment.duration(expireDateFormatted.diff(currentDate)).asDays()
  );

  if (withCurrentDay) {
    leftDays += 1;
  }

  if (withLastDay) {
    leftDays += 1;
  }

  return leftDays;
};

export default getDaysBeforeExpire;
