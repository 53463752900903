import cn from 'clsx';
import PropTypes from 'prop-types';

import css from '../styles/Separator.module.css';

const COLOR = {
  GREY: 'grey',
  GREY_GRADIENT: 'greyGradient',
  WHITE: 'white',
};

const BORDER_WEIGHT = {
  THIN: 'thin',
  NORMAL: 'normal',
};

const Separator = ({borderWeight, color}) => {
  return <div className={cn(css.separator, css[borderWeight], css[color])} />;
};

Separator.BORDER_WEIGHT = BORDER_WEIGHT;
Separator.COLOR = COLOR;

Separator.defaultProps = {
  borderWeight: BORDER_WEIGHT.THIN,
  color: COLOR.GREY,
};

Separator.propTypes = {
  borderWeight: PropTypes.oneOf(Object.values(BORDER_WEIGHT)),
  color: PropTypes.oneOf(Object.values(COLOR)),
};

export default Separator;
