import cn from 'clsx';
import PropTypes from 'prop-types';

import css from '../styles/Tag.module.css';

const COLOR = {
  WHITE: 'whiteColor',
  PRIMARY: 'primaryColor',
};

const Tag = ({children, color}) => {
  return <div className={cn(css.tag, css[color])}>{children}</div>;
};

Tag.COlOR = COLOR;

Tag.propTypes = {
  children: PropTypes.node.isRequired,
  color: PropTypes.oneOf(Object.values(COLOR)),
};

Tag.defaultProps = {
  color: COLOR.PRIMARY,
};

export default Tag;
