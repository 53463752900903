import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';

import {Text} from '@/src/ui';

import css from '../styles/FileWithPreview.module.css';

const VimeoVideo = ({blok}) => {
  const {url, description} = blok;

  return (
    <div className={css.videoWrapper}>
      <div className={css.vimeoVideo}>
        <ReactPlayer url={url} width={'100%'} height={'auto'} controls />
      </div>
      <Text
        fontSize={Text.FONT_SIZE.SIZE_12}
        color={Text.COLOR.GREY}
        lineHeight={Text.LINE_HEIGHT.LINE_HEIGHT_1_6}
      >
        {description}
      </Text>
    </div>
  );
};

VimeoVideo.propTypes = {
  blok: PropTypes.object.isRequired,
};

export default VimeoVideo;
