import cn from 'clsx';
import Link from 'next/link';
import NavLink from 'next/link';
import {useRouter} from 'next/router';
import {useTranslation} from 'next-i18next';
import PropTypes from 'prop-types';
import {pathOr} from 'ramda';
import {useMemo} from 'react';

import {ROLES_NAMES} from '@/src/app/constants/roles';
import {
  ORDER_BY_FILTER,
  ORDER_FILTER,
  SEARCH_FILTERS,
} from '@/src/app/constants/search';
import useUserActions from '@/src/app/store/user/useUserActions';
import {ROOT_PAGE} from '@/src/header/containers/Header';
import useRoutePaths from '@/src/hooks/useRoutePaths';
import TextLocal from '@/src/textLocal/containers/TextLocal';

import {Separator, Text} from '../../ui';
import css from '../styles/MobileMenuLinksWidget.module.css';
import {notAuthorizedMenuList, TIMER_HAS_HASH} from './UnauthorizedHeader';

const getLinkItem = (isActive, text, link) => {
  return (
    <div className={css.link}>
      {isActive && <div className={css.activeLink} />}
      <Link href={link}>
        <a>
          <Text
            color={Text.COLOR.PRIMARY}
            fontSize={Text.FONT_SIZE.SIZE_20}
            fontWeight={
              isActive ? Text.FONT_WEIGHT.BOLD : Text.FONT_WEIGHT.NORMAL
            }
          >
            {text}
          </Text>
        </a>
      </Link>
    </div>
  );
};

const getNotAuthLinkItem = (isActive, text, onClick) => {
  return (
    <div className={css.link}>
      {isActive && <div className={css.activeLink} />}
      <div onClick={onClick} className={css.linkWithCursor}>
        <span>
          <Text
            color={Text.COLOR.PRIMARY}
            fontSize={Text.FONT_SIZE.SIZE_20}
            fontWeight={
              isActive ? Text.FONT_WEIGHT.BOLD : Text.FONT_WEIGHT.NORMAL
            }
          >
            {text}
          </Text>
        </span>
      </div>
    </div>
  );
};

const getLinkItemWithSubItems = (
  isActive: boolean,
  text: string,
  subLinks: {path: string; title: string}[],
  currentPath: string,
  click?: () => void
) => {
  return (
    <div>
      <div className={click ? css.clickAbleLink : css.link} onClick={click}>
        {isActive && <div className={css.activeLink} />}
        <div>
          <Text
            color={Text.COLOR.PRIMARY}
            fontSize={Text.FONT_SIZE.SIZE_20}
            fontWeight={
              isActive ? Text.FONT_WEIGHT.BOLD : Text.FONT_WEIGHT.NORMAL
            }
          >
            {text}
          </Text>
        </div>
      </div>
      <ul className={css.subLinks}>
        {subLinks.map((menu) => (
          <li
            key={menu.title}
            className={cn(css.subLinksItem, {
              [css.subLinksItemActive]: currentPath.includes(menu.path),
            })}
          >
            <NavLink href={menu.path}>
              <a>{menu.title}</a>
            </NavLink>
          </li>
        ))}
      </ul>
    </div>
  );
};

const MobileMenuLinksWidget = ({currentPath, onMenuToggle, activeMenu}) => {
  const {locale, asPath, pathname, push} = useRouter();
  const {t} = useTranslation('common');
  const {user} = useUserActions();
  const {permissions} = user;
  const {
    CREATOR_STUDIO,
    DISCOVER,
    INDEX_ROUTE,
    MY_PROFILE_ACCOUNT_SETTINGS,
    SEARCH_COURSES,
    WATCHLIST_ROUTE,
    WATCHLIST_BOOKMARKED_ROUTE,
    SEARCH_ALL_POSITIONS_ROUTE,
    JOB_PORTAL,
    JOB_PORTAL_MY_POSITIONS,
    JOB_PORTAL_MY_APPLIES,
    SEARCH_VACANCIES,
    ADVISER_BOARD,
  } = useRoutePaths();

  const isAuthorized = pathOr(false, ['user', 'id'], user);
  const isClinic = useMemo(
    () => user?.user?.type === ROLES_NAMES.CLINIC,
    [user?.user?.type]
  );
  const isJopPortalPageActive =
    asPath.includes(JOB_PORTAL.getLink()) ||
    asPath.includes(SEARCH_VACANCIES.getLink());

  const isRootPage = useMemo(
    () => '/landing-v2' === asPath || pathname === ROOT_PAGE,
    [asPath]
  );

  const handleMenuItemClick = async (id) => {
    if (!isRootPage) {
      await push(INDEX_ROUTE.getLink());
      setTimeout(() => {
        scrollToSection(id);
      }, TIMER_HAS_HASH);
    } else {
      scrollToSection(id);
    }
  };

  const scrollToSection = (id) => {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({behavior: 'smooth'});
    }
  };

  const notAuthorizedLinkListNode = useMemo(() => {
    return (
      <div className={css.links} onClick={onMenuToggle}>
        {notAuthorizedMenuList.map((menu) =>
          getNotAuthLinkItem(menu.link === activeMenu, t(menu.value), () =>
            handleMenuItemClick(menu.link)
          )
        )}
        <div className={css.link}>
          {isJopPortalPageActive && <div className={css.activeLink} />}
          <Link href={SEARCH_VACANCIES.getLink()}>
            <a>
              <Text
                color={Text.COLOR.PRIMARY}
                fontSize={Text.FONT_SIZE.SIZE_20}
                fontWeight={
                  isJopPortalPageActive
                    ? Text.FONT_WEIGHT.BOLD
                    : Text.FONT_WEIGHT.NORMAL
                }
              >
                <TextLocal id={'menu.job-portal'} />
              </Text>
            </a>
          </Link>
        </div>
      </div>
    );
  }, []);

  const linksListNode = useMemo(() => {
    return (
      <div className={css.links}>
        {getLinkItemWithSubItems(
          currentPath.includes(DISCOVER.getLink()) ||
            currentPath.includes(ADVISER_BOARD.getLink()),
          t('menu.discover'),
          [
            {
              path: ADVISER_BOARD.getLink(),
              title: t('menu.our-adviser-bord'),
            },
          ],
          currentPath,
          () =>
            push({
              pathname: DISCOVER.getLink(),
            })
        )}
        {getLinkItem(
          currentPath.includes(SEARCH_COURSES.getLink()),
          t('menu.all-videos'),
          `${SEARCH_COURSES.getLink()}?${SEARCH_FILTERS.OFFSET}=0&${
            SEARCH_FILTERS.ORDER
          }=${ORDER_FILTER.DESC}&${SEARCH_FILTERS.ORDER_BY}=${
            ORDER_BY_FILTER.APPROVED_AT
          }&${SEARCH_FILTERS.LANGUAGE}=${locale}`
        )}
        {isAuthorized &&
          getLinkItem(
            currentPath.includes(WATCHLIST_ROUTE.getLink()),
            t('menu.watchlist'),
            `${WATCHLIST_BOOKMARKED_ROUTE.getLink()}?${
              SEARCH_FILTERS.OFFSET
            }=0&${SEARCH_FILTERS.ORDER}=${ORDER_FILTER.DESC}&${
              SEARCH_FILTERS.ORDER_BY
            }=${ORDER_BY_FILTER.APPROVED_AT}&${
              SEARCH_FILTERS.LANGUAGE
            }=${locale}`
          )}
        {permissions?.accessCreatorStudioCourseList &&
          getLinkItem(
            currentPath.includes(CREATOR_STUDIO.getLink()),
            t('menu.creator-studio'),
            CREATOR_STUDIO.getLink()
          )}
        {!isClinic &&
          isAuthorized &&
          getLinkItemWithSubItems(
            currentPath.includes(JOB_PORTAL.getLink()) ||
              currentPath.includes(SEARCH_VACANCIES.getLink()),
            t('menu.job-portal'),
            [
              {
                path: SEARCH_VACANCIES.getLink(),
                title: t('menu.job-portal-search'),
              },
              {
                path: JOB_PORTAL_MY_APPLIES.getLink(),
                title: t('job-portal.my-applications'),
              },
            ],
            currentPath
          )}
        {isClinic &&
          isAuthorized &&
          getLinkItemWithSubItems(
            currentPath.includes(JOB_PORTAL.getLink()) ||
              currentPath.includes(SEARCH_ALL_POSITIONS_ROUTE?.getLink()),
            t('menu.job-portal'),
            [
              {
                path: SEARCH_ALL_POSITIONS_ROUTE?.getLink(),
                title: t('menu.job-portal-search'),
              },
              {
                path: JOB_PORTAL_MY_POSITIONS.getLink(),
                title: t('job-portal.my-positions'),
              },
            ],
            currentPath
          )}
        {!isAuthorized &&
          getLinkItem(
            currentPath.includes(SEARCH_ALL_POSITIONS_ROUTE?.getLink()),
            t('menu.job-portal'),
            SEARCH_ALL_POSITIONS_ROUTE?.getLink()
          )}
        <div className={css.separatorBlock}>
          <Separator borderWeight={Separator.BORDER_WEIGHT.THIN} />
        </div>
        {isAuthorized &&
          getLinkItem(
            currentPath.includes(MY_PROFILE_ACCOUNT_SETTINGS.getLink()),
            t('header.settings'),
            MY_PROFILE_ACCOUNT_SETTINGS.getLink()
          )}
      </div>
    );
  }, [currentPath, t, locale, isAuthorized, isClinic]);

  return (
    <div className={css.widget}>
      {isAuthorized ? linksListNode : notAuthorizedLinkListNode}
    </div>
  );
};

MobileMenuLinksWidget.propTypes = {
  currentPath: PropTypes.string.isRequired,
  onMenuToggle: PropTypes.func.isRequired,
};

export default MobileMenuLinksWidget;
