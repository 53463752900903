import {REST_METHODS} from '@/src/app/constants/api';
import authorizedFetch from '@/src/app/utils/authorizedFetch';

const requestGetMyJobPositions = (params) => {
  const preparedParams = Object.keys(params).map((key) => ({
    name: key,
    value: params[key],
  }));

  // @ts-ignore
  return authorizedFetch({
    method: REST_METHODS.GET,
    path: `/api/v1/clinics/vacancies`,
    queryParams: preparedParams,
  });
};

export default requestGetMyJobPositions;
