import PropTypes from 'prop-types';
import {useCallback, useRef, useState} from 'react';

import SimpleVideoPlayer from '@/src/ui/videoPlayer/containers/SimpleVideoPlayer';

import css from '../../styles/FileWithPreview.module.css';

export const TEST_ID = 'video-test-id';

const VideoFile = ({src, withDownloadButton}) => {
  const videoRef = useRef();
  const [isControls, setIsControls] = useState(false);

  const toggleControls = useCallback(() => {
    setIsControls(!isControls);
  }, [isControls]);

  return (
    <div className={css.videoWrapper} data-testid={TEST_ID}>
      {/*@ts-ignore*/}
      <SimpleVideoPlayer
        videoRef={videoRef}
        onNextVideoClick={() => {}}
        isNextDisable={true}
        onPreviousVideoClick={() => {}}
        id={src}
        url={src}
        isPreviousDisable={true}
        playButton={
          <button className={css.videoPlayButton} onClick={toggleControls}>
            <img
              src="/icons/howToVideoPlay.svg"
              loading="lazy"
              alt="play video"
            />
          </button>
        }
      />
      {withDownloadButton && (
        <div className={css.videoAction}>
          <a href={src} aria-label={'download video'}>
            <button className={css.action}>
              <img
                src="/icons/downloadVideoIcon.svg"
                loading="lazy"
                alt="download video"
              />
            </button>
          </a>
        </div>
      )}
    </div>
  );
};

VideoFile.propTypes = {
  src: PropTypes.string.isRequired,
  withDownloadButton: PropTypes.bool,
};

VideoFile.defaultProps = {
  withDownloadButton: true,
};

export default VideoFile;
