import {useRouter} from 'next/router';
import {useCallback, useContext, useMemo, useState} from 'react';

import {RESPONSE_STATUSES} from '@/src/app/constants/api';
import {getAuthorizationToken} from '@/src/app/utils/authorizationToken';
import useLoading from '@/src/hooks/useLoading';
import VacancyApplicationPopupLayout from '@/src/jobPortal/applicationPopup/components/VacancyApplicationPopupLayout';
import VacancyApplicationSuccessPopup from '@/src/jobPortal/applicationPopup/components/VacancyApplicationSuccessPopup';
import VacancyUnauthorizedApplication from '@/src/jobPortal/applicationPopup/components/VacancyUnauthorizedApplication';
import postVacancyApplication from '@/src/jobPortal/applicationPopup/utils/postVacancyApplication';
import PopupContext from '@/src/popup/utils/PopupContext';

interface ApplicationPopupProps {
  positionTitle: string;
  clinicName: string;
  positionId: number;
  onSuccessfulApplicationCallback: (...args: any[]) => any;
}

const VacancyApplicationPopup = ({
  positionTitle,
  positionId,
  clinicName,
  onSuccessfulApplicationCallback,
}: ApplicationPopupProps) => {
  const {closePopup} = useContext(PopupContext);
  const [isSuccessPopup, setIsSuccessPopup] = useState(false);
  const {isLoading, setLoading} = useLoading();
  const isAuthorized = Boolean(getAuthorizationToken());
  const {locale} = useRouter();

  const handleSubmitClick = useCallback(
    async (values, {setFieldError}) => {
      setLoading(true);

      const {status, responseBody} = await postVacancyApplication(
        {
          vacancyId: positionId,
          ...values,
        },
        isAuthorized,
        locale
      );

      setLoading(false);

      if (
        responseBody &&
        responseBody.error &&
        typeof responseBody.error === 'object'
      ) {
        for (const key in responseBody.error) {
          if (Object.prototype.hasOwnProperty.call(responseBody.error, key)) {
            setFieldError(key, responseBody.error[key]);
          }
        }
      }

      if (
        responseBody &&
        responseBody.error &&
        typeof responseBody.error !== 'object'
      ) {
        setFieldError('general', responseBody.error);
      }

      if (status === RESPONSE_STATUSES.SUCCESS) {
        setIsSuccessPopup(true);

        if (
          Boolean(onSuccessfulApplicationCallback) &&
          typeof onSuccessfulApplicationCallback === 'function'
        ) {
          onSuccessfulApplicationCallback();
        }
      }
    },
    [positionId, isAuthorized, locale, onSuccessfulApplicationCallback]
  );

  const handleCancelClick = useCallback(() => {
    closePopup();
  }, []);

  const layoutNode = useMemo(() => {
    const commonLayoutProps = {
      positionTitle,
      clinicName,
      isLoading,
      onSubmitClick: handleSubmitClick,
      onCancelClick: handleCancelClick,
    };

    if (isSuccessPopup) {
      return (
        <VacancyApplicationSuccessPopup
          clinicName={clinicName}
          onGotItClick={handleCancelClick}
        />
      );
    } else if (isAuthorized) {
      return <VacancyApplicationPopupLayout {...commonLayoutProps} />;
    } else {
      return <VacancyUnauthorizedApplication {...commonLayoutProps} />;
    }
  }, [
    clinicName,
    handleCancelClick,
    positionTitle,
    isLoading,
    handleSubmitClick,
    isAuthorized,
    isSuccessPopup,
    locale,
  ]);

  return layoutNode;
};

export default VacancyApplicationPopup;
