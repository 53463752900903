import cn from 'clsx';

import EditIcon from '@/public/icons/editIcon.svg';
import PointIcon from '@/public/icons/locationPoint.svg';
import TextLocal from '@/src/textLocal/containers/TextLocal';
import {Button, Text} from '@/src/ui';

import css from '../styles/OpenForNewOpportunities.module.css';

interface OpenForNewOpportunitiesInActiveProps {
  turnOffClick: (...args: any[]) => any;
  editClick: (...args: any[]) => any;
  location: string;
  participation: string;
  positions: Array<string>;
  isHeader: boolean;
}

const OpenForNewOpportunitiesInActive = ({
  turnOffClick,
  editClick,
  location,
  participation,
  positions,
  isHeader,
}: OpenForNewOpportunitiesInActiveProps) => {
  return (
    <div>
      <div
        className={cn(css.jobDetails, {
          [css.jobDetailsWithEdit]: isHeader,
        })}
      >
        <div>
          <PointIcon width="9" height="12" />
        </div>
        <div title={location}>{location}</div>
        <div>|</div>
        <div>
          <TextLocal id={`employment.${participation}`} />
        </div>
        {isHeader && (
          <button onClick={editClick} className={css.headerEditButton}>
            <EditIcon />
          </button>
        )}
      </div>

      <div className={css.jobsList}>
        <div>
          <Text
            color={Text.COLOR.GREY}
            fontSize={Text.FONT_SIZE.SIZE_14}
            tagComponent={Text.TAG.SPAN}
            display={Text.DISPLAY.INLINE}
          >
            <TextLocal id={'open-opportunities.looking-for'} />
          </Text>
          <Text
            fontSize={Text.FONT_SIZE.SIZE_14}
            tagComponent={Text.TAG.SPAN}
            display={Text.DISPLAY.INLINE}
          >
            {' '}
            {positions.map(
              (title, index) =>
                `${title}${index === Number(positions.length) - 1 ? '.' : ', '}`
            )}
          </Text>
        </div>
      </div>
      <div className={css.buttonsList}>
        <div className={css.buttonOnActive}>
          <Button
            color={Button.COLOR.RED_FILL}
            height={Button.HEIGHT.SMALL}
            onClick={turnOffClick}
          >
            <TextLocal id={'open-opportunities.turn-off'} />
          </Button>
        </div>
        {!isHeader && (
          <div className={css.buttonOnActive}>
            <Button
              border={Button.BORDER.BOLD}
              height={Button.HEIGHT.SMALL}
              color={Button.COLOR.SECONDARY}
              onClick={editClick}
            >
              <TextLocal id={'profile.edit'} />
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default OpenForNewOpportunitiesInActive;
