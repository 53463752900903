import {SbEditableBlok} from '@storyblok/storyblok-editable';
import cn from 'clsx';

import css from '../styles/Spacer.module.css';

interface SpacerProps {
  blok: SbEditableBlok;
}

const Spacer = ({blok}: SpacerProps) => {
  const {space, id} = blok;
  return <div className={cn(css[space], css.spacer)} id={id} />;
};

export default Spacer;
