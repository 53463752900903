import PropTypes from 'prop-types';

import {LOCALES} from '@/src/app/constants/locales';
import TextLocal from '@/src/textLocal/containers/TextLocal';

import {LanguageSwitcher, Text} from '../../ui';
import css from '../styles/footer.module.css';

const FooterBottom = ({locale, handleChangeLang, currentYear}) => {
  return (
    <div className={css.footerBottom}>
      <Text
        fontSize={Text.FONT_SIZE.SIZE_14}
        color={Text.COLOR.WHITE}
        fontWeight={Text.FONT_WEIGHT.MEDIUM}
      >
        © {currentYear} <TextLocal id={'general.company-name'} />
      </Text>
      <div className={css.languageWrapper}>
        <LanguageSwitcher
          color={LanguageSwitcher.COLORS.SECONDARY}
          activeLocale={locale}
          onChange={handleChangeLang}
        />
      </div>
    </div>
  );
};

export default FooterBottom;

FooterBottom.propTypes = {
  locale: PropTypes.oneOf(Object.values(LOCALES)).isRequired,
  handleChangeLang: PropTypes.func.isRequired,
  currentYear: PropTypes.number.isRequired,
};
