import {REST_METHODS} from '@/src/app/constants/api';
import unauthorizedFetch from '@/src/app/utils/unauthorizedFetch';

const postUserEmail = (email: string) => {
  // @ts-ignore
  return unauthorizedFetch({
    method: REST_METHODS.POST,
    path: `/api/v1/hero_users`,
    body: {
      email,
    },
  });
};

export default postUserEmail;
