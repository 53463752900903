import NavLink from 'next/link';
import {useRouter} from 'next/router';
import PropTypes from 'prop-types';
import {pathOr} from 'ramda';
import {useCallback, useEffect, useMemo} from 'react';

import useRoutePaths from '@/src/hooks/useRoutePaths';
import {Text} from '@/src/ui';
import SimpleCollapseControlled from '@/src/ui/Collapse/components/SimpleCollapseControlled';

import css from '../../styles/SidebarItemWithSubmenu.module.css';

const SidebarItemWithSubmenu = ({
  title,
  menuList,
  order,
  activeMenu,
  setActiveMenu,
  _uid,
}) => {
  const {asPath} = useRouter();
  const {HOW_T0} = useRoutePaths();

  const mainMenuActive = useMemo(
    () =>
      menuList.some((item) => {
        const link = `/${pathOr('', ['story', 'full_slug'], item.href)}`;
        const preparedPath = asPath.split('?')[0];
        return link.includes(preparedPath) && preparedPath !== HOW_T0.path;
      }),
    [menuList, asPath]
  );

  useEffect(() => {
    if (mainMenuActive) {
      setActiveMenu(_uid);
    }
  }, [asPath]);

  const handleOpenMenu = useCallback(() => {
    if (activeMenu !== _uid) {
      return setActiveMenu(_uid);
    }

    setActiveMenu(null);
  }, [activeMenu, _uid]);

  return (
    <SimpleCollapseControlled
      isOpen={activeMenu === _uid}
      onClick={handleOpenMenu}
      headTitle={
        <div onClick={handleOpenMenu}>
          <Text
            fontSize={Text.FONT_SIZE.SIZE_16}
            fontWeight={Text.FONT_WEIGHT.BOLD}
            color={mainMenuActive ? Text.COLOR.GRADIENT : Text.COLOR.PRIMARY}
          >
            {order}. {title}
          </Text>
        </div>
      }
    >
      <ul className={css.subMenu}>
        {menuList.map((menu, index) => {
          const link = `/${pathOr('', ['story', 'full_slug'], menu.href)}`;
          const preparedPath = asPath.split('?')[0];

          return (
            <li key={menu._uid} className={css.subMenuItem}>
              <NavLink href={link}>
                <a>
                  <Text
                    fontSize={Text.FONT_SIZE.SIZE_14}
                    fontWeight={Text.FONT_WEIGHT.NORMAL}
                    color={
                      link.includes(preparedPath) &&
                      preparedPath !== HOW_T0.path
                        ? Text.COLOR.GRADIENT
                        : Text.COLOR.PRIMARY
                    }
                  >
                    {order}.{index + 1} {menu.title}
                  </Text>
                </a>
              </NavLink>
            </li>
          );
        })}
      </ul>
    </SimpleCollapseControlled>
  );
};

SidebarItemWithSubmenu.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  menuList: PropTypes.array.isRequired,
  order: PropTypes.number.isRequired,
  activeMenu: PropTypes.string.isRequired,
  setActiveMenu: PropTypes.func.isRequired,
  _uid: PropTypes.string.isRequired,
};
export default SidebarItemWithSubmenu;
