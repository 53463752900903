import {sbEditable} from '@storyblok/storyblok-editable';
import cn from 'clsx';
import PropTypes from 'prop-types';

import useIsMobile from '@/src/hooks/useIsMobile';

import DynamicComponent from '../../../dynamicComponents/components/DynamicComponent';
import {Carousel, CourseCardOriginal, Text} from '../../../ui';
import ViewWithSideBar from '../../../ui/views/ViewWithSideBar/components/ViewWithSideBar';
import css from '../../styles/TopContentCreatorsContainer.module.css';

const TopContentCreatorsContainer = ({blok}) => {
  const {title, description, secondaryContent, id, doctors} = blok;
  const {isMaxWidth900} = useIsMobile();

  const sideBarNode = (
    <div className={css.actionWrapper} {...sbEditable(blok)}>
      <div
        className={cn(css.action, {
          [css.cssActionMobile]: isMaxWidth900,
        })}
      >
        <div className={cn(css.cell)}>
          <Text
            fontSize={Text.FONT_SIZE.SIZE_40}
            fontWeight={Text.FONT_WEIGHT.MEDIUM}
            fontFamily={Text.FONT_FAMILY.RUBIK}
            lineHeight={Text.LINE_HEIGHT.LINE_HEIGHT_1_2}
            tagComponent={Text.TAG.H2}
          >
            {title}
          </Text>
        </div>

        <div className={css.cell}>
          <Text
            fontSize={Text.FONT_SIZE.SIZE_18}
            color={Text.COLOR.GREY}
            lineHeight={Text.LINE_HEIGHT.LINE_HEIGHT_1_6}
          >
            {description}
          </Text>
        </div>
        {secondaryContent.map((blok) => (
          <div key={blok._uid} className={css.cell}>
            <DynamicComponent blok={blok} />
          </div>
        ))}
      </div>
    </div>
  );

  return (
    <div className={css.main} id={id}>
      <div className={css.sectionOffset}>
        <ViewWithSideBar
          sideBar={sideBarNode}
          mainContent={
            <div className={css.sliderWrapper}>
              <Carousel
                slideGap={Carousel.SLIDE_GAP.SLIDE_GAP_WITH_COUNTER}
                navigationPosition={Carousel.NAVIGATION_POSITION.BOTTOM}
                settings={{
                  loop: true,
                }}
              >
                {doctors &&
                  Boolean(doctors.length) &&
                  doctors.map(({image, subTitle, title, id, link}, index) => (
                    // @ts-ignore
                    <CourseCardOriginal
                      isContentCreators
                      verticalPreviewImage={image.filename}
                      title={title}
                      location={subTitle}
                      key={`${id} + ${index}`}
                      isOutSideLink
                      slug={link}
                    />
                  ))}
              </Carousel>
            </div>
          }
        />
      </div>
    </div>
  );
};

TopContentCreatorsContainer.propTypes = {
  blok: PropTypes.object.isRequired,
};

export default TopContentCreatorsContainer;
