import {ROLES_NAMES} from '@/src/app/constants/roles';
import getRootServerUrl from '@/src/app/utils/getRootServerUrl';

const getProfilePicture = (picture, clinicData, userData) => {
  if (userData?.user?.type === ROLES_NAMES.CLINIC && clinicData.isFetched) {
    return clinicData?.clinicData?.picture
      ? `${getRootServerUrl()}${clinicData.clinicData.picture}`
      : null;
  }

  return picture ? `${getRootServerUrl()}${picture}` : null;
};

export default getProfilePicture;
