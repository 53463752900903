import {REST_METHODS} from '@/src/app/constants/api';
import {SEARCH_FILTERS} from '@/src/app/constants/search';
import {getAuthorizationToken} from '@/src/app/utils/authorizationToken';
import authorizedFetch from '@/src/app/utils/authorizedFetch';
import unauthorizedFetch from '@/src/app/utils/unauthorizedFetch';

const MAX_VACANCIES = 3;

const fetchClinicVacancies = (clinicId: number): Promise<any> => {
  const requestMethod = getAuthorizationToken()
    ? authorizedFetch
    : unauthorizedFetch;

  // @ts-ignore
  return requestMethod({
    method: REST_METHODS.GET,
    path: `/api/v1/job_portal/vacancies_search`,
    queryParams: [
      {
        name: 'clinic_id',
        value: clinicId,
      },
      {
        name: SEARCH_FILTERS.PER_PAGE,
        value: MAX_VACANCIES,
      },
    ],
  });
};

export default fetchClinicVacancies;
