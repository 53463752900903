import {useRouter} from 'next/router';
import {useCallback, useContext} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {RESPONSE_STATUSES} from '@/src/app/constants/api';
import {
  resetJobOpportunities as resetJobOpportunitiesAction,
  selectJobOpportunitiesSlice,
  setJobOpportunities,
  setJobOpportunitiesLoading,
} from '@/src/app/store/user/jobOpportunitiesSlice';
import useUserActions from '@/src/app/store/user/useUserActions';
import PopupContext from '@/src/popup/utils/PopupContext';
import ConfirmCancelOpenForJobsPopup from '@/src/profile/common/components/ConfirmCancelOpenForJobsPopup';
import OpenForNewOpportunitiesAddPopup from '@/src/profile/common/containers/OpenForNewOpportunitiesAddPopup';
import requestOpportunitiesTurnOff from '@/src/profile/common/utils/requestOpportunitiesTurnOff';
import requestUserOpenForOpportunities from '@/src/profile/common/utils/requestUserOpenForOpportunities';

const prepareJobOpportunities = (response, locale) => ({
  participation: response.work_type,
  positions: response.preferable_positions.map((position) => ({
    id: position.id,
    name: position[`name_${locale}`],
  })),
  location: {
    id: response.country.id,
    name: response.country[`name_${locale}`],
  },
});

const useOpenForNewOpportunities = () => {
  const dispatch = useDispatch();
  const {locale} = useRouter();
  const {openPopup, closePopup} = useContext(PopupContext);
  const {data, isLoading} = useSelector(selectJobOpportunitiesSlice);
  const {requestUserData} = useUserActions();

  const {participation, positions, location} = data;

  const getUserOpenForNewOpportunities = useCallback(
    async (userId: number | string): Promise<void> => {
      const {status, responseBody} = await requestUserOpenForOpportunities(
        userId
      );

      if (status === RESPONSE_STATUSES.SUCCESS && responseBody.id) {
        const preparedData = prepareJobOpportunities(responseBody, locale);

        dispatch(setJobOpportunities(preparedData));
      }
    },
    []
  );

  const handleTurnOffJobOpportunity = useCallback(async (userId) => {
    dispatch(setJobOpportunitiesLoading(true));
    const {status} = await requestOpportunitiesTurnOff();

    if (status === RESPONSE_STATUSES.SUCCESS) {
      await requestUserData();
      getUserOpenForNewOpportunities(userId);
    }

    dispatch(setJobOpportunitiesLoading(false));
  }, []);

  const handleTurnOffJobOpportunityWithConfirm = useCallback(
    (userId) => {
      // @ts-ignore
      openPopup({
        popup: (
          <ConfirmCancelOpenForJobsPopup
            onCancelClick={closePopup}
            onConfirmClick={() => {
              handleTurnOffJobOpportunity(userId);
              closePopup();
            }}
          />
        ),
        options: {withCloseButton: true},
      });
    },
    [closePopup, handleTurnOffJobOpportunity]
  );

  const handleOpenEditForm = useCallback((userId: number) => {
    // @ts-ignore
    openPopup({
      popup: <OpenForNewOpportunitiesAddPopup userId={userId} />,
      options: {withCloseButton: true},
    });
  }, []);

  const resetJobOpportunities = useCallback(() => {
    dispatch(resetJobOpportunitiesAction());
  }, []);

  return {
    participation,
    positions,
    location,
    isLoading,
    handleTurnOffJobOpportunity,
    getUserOpenForNewOpportunities,
    handleOpenEditForm,
    handleTurnOffJobOpportunityWithConfirm,
    resetJobOpportunities,
  };
};
export default useOpenForNewOpportunities;
