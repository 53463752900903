import cn from 'clsx';
import NavLink from 'next/link';
import {MouseEventHandler, useCallback, useContext, useMemo} from 'react';

import NewsletterIcon from '@/public/icons/newsletterIcon.svg';
import PencilIcon from '@/public/icons/pencil.svg';
import DeleteIcon from '@/public/icons/trash-red.svg';
import getDaysBeforeExpire from '@/src/app/utils/getDaysBeforeExpire';
import css from '@/src/jobPortal/clinicPositions/styles/ActiveJobPositionsLayout.module.css';
import RouteContext from '@/src/routes/utils/RouteContext';
import TextLocal from '@/src/textLocal/containers/TextLocal';
import {Text, TooltipWrapper} from '@/src/ui';

export const ACTIVE_POSITIONS_ROW_TEST_ID = {
  ARCHIVE: 'ACTIVE_POSITIONS_ROW_TEST_ARCHIVE',
  DELETE: 'ACTIVE_POSITIONS_ROW_TEST_DELETE',
};

export const MIN_DAYS_TO_EXPIRE = 3;
export const MAX_DAYS_TO_EXPIRE = 10;

interface ActivePositionsRowLayoutProps {
  title: string;
  onDeleteClick: MouseEventHandler<HTMLSpanElement>;
  onArchiveClick: MouseEventHandler<HTMLSpanElement>;
  editLink: string;
  createdAt: string;
  expiredDate: string;
  vacancyApplicationsCount: number;
  isMobile: boolean;
  id: number;
  uniqueViews: number;
  openVacancyApplicants: (...args: any[]) => any;
}

const ActivePositionsRowLayout = ({
  title,
  onDeleteClick,
  onArchiveClick,
  editLink,
  createdAt,
  vacancyApplicationsCount,
  isMobile,
  expiredDate,
  id,
  uniqueViews,
  openVacancyApplicants,
}: ActivePositionsRowLayoutProps) => {
  // @ts-ignore
  const {JOB_PORTAL_MY_POSITION_PREVIEW} = useContext(RouteContext);
  const isZeroApplicants = Number(vacancyApplicationsCount) === 0;

  const daysLeft = useMemo(
    () => getDaysBeforeExpire(new Date(), expiredDate, true, true),
    [expiredDate]
  );

  const getDaysLeftColor = useCallback(
    (leftDays) => {
      switch (true) {
        case Number(leftDays) <= MIN_DAYS_TO_EXPIRE:
          return Text.COLOR.RED;
        case Number(leftDays) <= MAX_DAYS_TO_EXPIRE:
          return Text.COLOR.ORANGE;
        default:
          return Text.COLOR.PRIMARY;
      }
    },
    [daysLeft]
  );

  return isMobile ? (
    <tr className={css.tableContent}>
      <td>
        <NavLink href={JOB_PORTAL_MY_POSITION_PREVIEW.getLink(id)}>
          <a>
            <Text
              color={Text.COLOR.GRADIENT}
              fontWeight={Text.FONT_WEIGHT.BOLD}
              lineHeight={Text.LINE_HEIGHT.LINE_HEIGHT_1_6}
            >
              {title}
            </Text>
          </a>
        </NavLink>
        <Text
          fontWeight={Text.FONT_WEIGHT.THIN}
          fontSize={Text.FONT_SIZE.SIZE_14}
        >
          <TextLocal
            id={'job-portal.posted-date-value'}
            params={{
              date: createdAt,
            }}
          />
        </Text>
      </td>
      <td
        className={cn(css.viewsCount, isZeroApplicants && css.withoutPointer)}
        onClick={isZeroApplicants ? () => {} : () => openVacancyApplicants(id)}
      >
        <div className={css.newsletters}>
          <NewsletterIcon />
          <Text
            fontSize={Text.FONT_SIZE.SIZE_14}
            fontWeight={Text.FONT_WEIGHT.BOLD}
            color={isZeroApplicants ? Text.COLOR.GREY : Text.COLOR.GRADIENT}
          >
            {vacancyApplicationsCount}
          </Text>
          /
          <Text
            fontSize={Text.FONT_SIZE.SIZE_14}
            fontWeight={Text.FONT_WEIGHT.BOLD}
          >
            {uniqueViews}
          </Text>
        </div>
      </td>
      <td>
        <div className={css.actionIcon}>
          <span>
            <NavLink href={editLink}>
              <a>
                <PencilIcon />
              </a>
            </NavLink>
          </span>
          <span onClick={onArchiveClick}>
            <img
              src="/icons/closeIcon.png"
              loading="lazy"
              alt=""
              className={css.disableIcon}
            />
          </span>
          <span onClick={onDeleteClick}>
            <DeleteIcon />
          </span>
        </div>
      </td>
      <td>
        <Text
          fontSize={Text.FONT_SIZE.SIZE_14}
          fontWeight={Text.FONT_WEIGHT.BOLD}
          color={getDaysLeftColor(daysLeft)}
        >
          <TextLocal
            id={'job-portal.days-left'}
            params={{
              days: daysLeft,
            }}
          />
        </Text>
      </td>
    </tr>
  ) : (
    <tr className={css.tableContent}>
      <td>
        <NavLink href={JOB_PORTAL_MY_POSITION_PREVIEW.getLink(id)}>
          <a>
            <Text
              color={Text.COLOR.GRADIENT}
              fontWeight={Text.FONT_WEIGHT.BOLD}
              lineHeight={Text.LINE_HEIGHT.LINE_HEIGHT_1_6}
            >
              {title}
            </Text>
          </a>
        </NavLink>
        <Text
          fontWeight={Text.FONT_WEIGHT.THIN}
          fontSize={Text.FONT_SIZE.SIZE_14}
        >
          <TextLocal
            id={'job-portal.posted-date-value'}
            params={{
              date: createdAt,
            }}
          />
        </Text>
      </td>
      <td>
        <Text
          fontSize={Text.FONT_SIZE.SIZE_14}
          fontWeight={Text.FONT_WEIGHT.BOLD}
        >
          {uniqueViews}
        </Text>
      </td>
      <td
        className={cn(css.viewsCount, isZeroApplicants && css.withoutPointer)}
        onClick={isZeroApplicants ? () => {} : () => openVacancyApplicants(id)}
      >
        <div className={css.newsletters}>
          <NewsletterIcon />
          <Text
            fontSize={Text.FONT_SIZE.SIZE_14}
            fontWeight={Text.FONT_WEIGHT.BOLD}
            color={isZeroApplicants ? Text.COLOR.GREY : Text.COLOR.GRADIENT}
          >
            {vacancyApplicationsCount}
          </Text>
        </div>
      </td>
      <td>
        <Text
          fontSize={Text.FONT_SIZE.SIZE_14}
          color={getDaysLeftColor(daysLeft)}
          fontWeight={Text.FONT_WEIGHT.BOLD}
        >
          <TextLocal
            id={'job-portal.days-left'}
            params={{
              days: daysLeft,
            }}
          />
        </Text>
      </td>
      <td>
        <div className={css.actionIcon}>
          <span>
            <NavLink href={editLink}>
              <a>
                <PencilIcon />
              </a>
            </NavLink>
          </span>
          <span
            key={id}
            onClick={onArchiveClick}
            data-testid={ACTIVE_POSITIONS_ROW_TEST_ID.ARCHIVE}
          >
            <TooltipWrapper
              placement={'top'}
              tooltip={
                <Text fontSize={Text.FONT_SIZE.SIZE_14} color={Text.COLOR.GREY}>
                  <TextLocal id={'job-portal.archive-position'} />
                </Text>
              }
              trigger={
                <img
                  src="/icons/closeIcon.png"
                  loading="lazy"
                  alt=""
                  className={css.disableIcon}
                />
              }
            />
          </span>

          <span
            onClick={onDeleteClick}
            data-testid={ACTIVE_POSITIONS_ROW_TEST_ID.DELETE}
          >
            <DeleteIcon />
          </span>
        </div>
      </td>
    </tr>
  );
};

export default ActivePositionsRowLayout;
