import cn from 'clsx';
import PropTypes from 'prop-types';

import {LOCALES} from '@/src/app/constants/locales';

import css from '../styles/LanguageSwitcher.module.css';

const COLORS = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
};

/**
 * @param {string} locale - locale of item
 * @param {string} activeLocale - name of active locale
 * @param {string} color
 * @return {string} - css class, for example: "primaryActiveItem" or "secondaryInactiveItem"
 */
const getItemActivityClass = (locale, activeLocale, color) =>
  `${color}${activeLocale === locale ? 'Active' : 'Inactive'}Item`;

const LanguageSwitcher = ({activeLocale, onChange, color}) => {
  return (
    <div className={cn(css.switcherBlock, css[`${color}Block`])}>
      <div
        className={cn(
          css.item,
          css[`${color}FirstItem`],
          css[getItemActivityClass(LOCALES.EN, activeLocale, color)]
        )}
        onClick={() => {
          activeLocale === LOCALES.DE && onChange(LOCALES.EN);
        }}
      >
        EN
      </div>
      <div
        className={cn(
          css.item,
          css[getItemActivityClass(LOCALES.DE, activeLocale, color)]
        )}
        onClick={() => {
          activeLocale === LOCALES.EN && onChange(LOCALES.DE);
        }}
      >
        DE
      </div>
    </div>
  );
};

LanguageSwitcher.LOCALES = LOCALES;
LanguageSwitcher.COLORS = COLORS;

LanguageSwitcher.propTypes = {
  activeLocale: PropTypes.oneOf(Object.values(LOCALES)).isRequired,
  color: PropTypes.oneOf(Object.values(COLORS)),
  onChange: PropTypes.func.isRequired,
};

LanguageSwitcher.defaultProps = {
  color: COLORS.SECONDARY,
};

export default LanguageSwitcher;
