import {REST_METHODS} from '@/src/app/constants/api';
import authorizedFetch from '@/src/app/utils/authorizedFetch';

import {PICTURE_POPUP_TYPES} from '../constants/picturePopupTypes';

const deleteProfileImage = (type) => {
  // @ts-ignore
  return authorizedFetch({
    method: REST_METHODS.DELETE,
    path: `/api/v1/profiles/profiles/${
      type === PICTURE_POPUP_TYPES.PICTURE
        ? 'delete_picture'
        : 'delete_cover_image'
    }`,
  });
};

export default deleteProfileImage;
