import {REST_METHODS} from '@/src/app/constants/api';
import {getAuthorizationToken} from '@/src/app/utils/authorizationToken';
import authorizedFetch from '@/src/app/utils/authorizedFetch';
import unauthorizedFetch from '@/src/app/utils/unauthorizedFetch';

const fetchUserByUsername = (username) => {
  const requestMethod = getAuthorizationToken()
    ? authorizedFetch
    : unauthorizedFetch;

  // @ts-ignore
  return requestMethod({
    method: REST_METHODS.GET,
    path: `/api/v1/users/find_user_by_username`,
    queryParams: [
      {
        name: 'username',
        value: username,
      },
    ],
  });
};

export default fetchUserByUsername;
