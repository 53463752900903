import moment from 'moment';
import {pathOr} from 'ramda';

import {DATE_FORMAT, SERVER_FORMAT} from '@/src/app/constants/date';
import {LOCALES} from '@/src/app/constants/locales';

const getDataForVacancyItem = (
  vacancyItem,
  clinicData,
  employmentTypes,
  locale = LOCALES.EN,
  t
) => {
  const localName = `name_${locale}`;

  const mappedWorkTypes = vacancyItem?.work_type?.map((item) => {
    const workTypeLabel = employmentTypes.find((employmentType) => {
      return employmentType.value === item;
    })?.label;

    return {
      label: workTypeLabel,
      value: item,
    };
  });

  const entryDate = vacancyItem.entry_date
    ? moment(vacancyItem.entry_date, SERVER_FORMAT).format(DATE_FORMAT)
    : t('job-portal.immediately');

  return {
    title: pathOr('', ['title'], vacancyItem),
    clinicName: pathOr('', ['organization_name'], clinicData),
    clinicAvatar: pathOr('', ['picture'], clinicData),
    cityName: pathOr('', ['city', localName], vacancyItem),
    countryName: pathOr('', ['country', localName], vacancyItem),
    workType: mappedWorkTypes,
    minSalary: pathOr('', ['min_salary'], vacancyItem),
    publishedAt: pathOr('', ['published_at'], vacancyItem),
    description: pathOr('', ['description'], vacancyItem),
    currency: pathOr('', ['currency'], vacancyItem),
    expireDate: pathOr('', ['expire_date'], vacancyItem),
    key: pathOr('', ['id'], vacancyItem),
    id: pathOr('', ['id'], vacancyItem),
    slug: pathOr('', ['slug'], vacancyItem),
    isApplied: pathOr(false, ['is_applied'], vacancyItem),
    entryDate,
  };
};

export default getDataForVacancyItem;
