import {useRouter} from 'next/router';
import {memo, useCallback, useEffect, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {RESPONSE_STATUSES} from '@/src/app/constants/api';
import {
  ORDER_BY_FILTER,
  ORDER_FILTER,
  SEARCH_FILTERS,
} from '@/src/app/constants/search';
import {
  selectOriginalCourses,
  setOriginalCourses,
} from '@/src/app/store/promoSections/promoSectionsSlice';
import transforCoursesData from '@/src/app/utils/transforCoursesData';
import useRoutePaths from '@/src/hooks/useRoutePaths';
import TextLocal from '@/src/textLocal/containers/TextLocal';

import {Carousel, CourseCardOriginal} from '../../ui';
import SectionDoubleColumnsLayout from '../components/SectionDoubleColumnsLayout';
import getOriginalCourses from '../utils/getOriginalCourses';

const OriginalCoursesContainer = () => {
  const originalCourses = useSelector(selectOriginalCourses);
  const dispatch = useDispatch();
  const {locale} = useRouter();
  const {SEARCH_COURSES} = useRoutePaths();

  const requestGetCourse = useCallback(async () => {
    const {status, responseBody} = await getOriginalCourses(locale);

    if (status === RESPONSE_STATUSES.SUCCESS) {
      dispatch(
        setOriginalCourses(transforCoursesData(responseBody?.data, locale))
      );
    }
  }, []);

  useEffect(() => {
    !originalCourses.length && requestGetCourse();
  }, []);

  const seeAllUrl = useMemo(() => {
    return `${SEARCH_COURSES.getLink()}?${SEARCH_FILTERS.OFFSET}=0&${
      SEARCH_FILTERS.ORDER
    }=${ORDER_FILTER.DESC}&${SEARCH_FILTERS.ORDER_BY}=${
      ORDER_BY_FILTER.APPROVED_AT
    }&${SEARCH_FILTERS.LANGUAGE}=${locale}&${
      SEARCH_FILTERS.FILTERS_ORIGINAL
    }=true`;
  }, [SEARCH_COURSES, locale]);

  if (!originalCourses?.length) {
    return null;
  }

  return (
    <SectionDoubleColumnsLayout
      showLogo
      seeAllUrl={seeAllUrl}
      title={
        <>
          <TextLocal id={'promoSection.original-courses'} />
        </>
      }
      subtitle={<TextLocal id={'promoSection.ambition-accepted'} />}
      rightSideContent={
        originalCourses.length > 0 ? (
          <Carousel
            paginationMobileToRight
            slideGap={Carousel.SLIDE_GAP.SLIDE_GAP_20}
            navigationPosition={Carousel.NAVIGATION_POSITION.BOTTOM}
          >
            {originalCourses.map((item) => (
              <CourseCardOriginal {...item} key={item.id} />
            ))}
          </Carousel>
        ) : null
      }
    />
  );
};

export default memo(OriginalCoursesContainer);
