import cn from 'clsx';
import {useField} from 'formik';
import PropTypes from 'prop-types';
import {useCallback, useMemo, useState} from 'react';
import MaskedInput from 'react-text-mask';

import CloseEyeIcon from '@/public/icons/eyeClose.svg';
import OpenEyeIcon from '@/public/icons/eyeIcon.svg';
import ResetIcon from '@/public/images/resetInput.svg';

import css from '../styles/Input.module.css';

export const WIDTH = {
  WIDTH_150: 'width150',
  WIDTH_390: 'width390',
  WIDTH_FULL: 'widthFull',
};

export const SIZE = {
  REGULAR: 'regular',
  SMALL: 'small',
};

export const TYPES = {
  TEXT: 'text',
  EMAIL: 'email',
  PASSWORD: 'password',
  NUMBER: 'number',
};

export const MASKS = {
  CARD: [
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ],
  CVC: [/\d/, /\d/, /\d/, /\d/],
  EXP_DATE: [/\d/, /\d/, '/', /\d/, /\d/],
  TWO_DIGITS_NUMBER: [/\d/, /\d/],
  FIVE_DIGITS_NUMBER: [/\d/, /\d/, /\d/, /\d/, /\d/],
  NINE_DIGITS_NUMBER: [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
};

const Input = ({
  placeholder,
  width,
  mask,
  handleResetClick,
  disabled,
  isRequired,
  size,
  ...props
}) => {
  // @ts-ignore
  const [field, meta] = useField(props);
  const [type, setType] = useState(props.type || TYPES.TEXT);
  const error = meta.touched && meta.error;

  const handleChangePasswordVisible = useCallback(
    (event) => {
      event.preventDefault();
      setType((type) => (type === TYPES.TEXT ? TYPES.PASSWORD : TYPES.TEXT));
    },
    [type]
  );

  const isWithPasswordChange = useMemo(
    () => props.type === TYPES.PASSWORD,
    [props.type]
  );

  const inputProps = {
    className: css.inputField,
    ...field,
    ...props,
    placeholder: placeholder,
    disabled,
    type: type,
  };

  const InputComponent = mask ? (
    <MaskedInput {...inputProps} mask={mask} guide={false} />
  ) : (
    <input {...inputProps} />
  );

  return (
    <div className={cn(css.inputBlock, css[width])}>
      <div
        className={cn(
          error ? css.inputAreaError : css.inputAreaCommon,
          css.inputArea,
          css[size],
          disabled && css.inputAreaDisabled
        )}
      >
        {isRequired && <div className={css.requiredIcon}>*</div>}
        {InputComponent}
        {Boolean(handleResetClick) && (
          <div className={css.resetIcon} onClick={handleResetClick}>
            <ResetIcon />
          </div>
        )}
      </div>
      {isWithPasswordChange && (
        <button
          className={css.resetIcon}
          type={'button'}
          onClick={handleChangePasswordVisible}
        >
          {type === TYPES.PASSWORD ? <CloseEyeIcon /> : <OpenEyeIcon />}
        </button>
      )}
      {Boolean(error) && <div className={css.errorField}>{error}</div>}
    </div>
  );
};

Input.WIDTH = WIDTH;
Input.TYPES = TYPES;
Input.MASKS = MASKS;
Input.SIZE = SIZE;

Input.propTypes = {
  width: PropTypes.oneOf(Object.values(WIDTH)),
  placeholder: PropTypes.string.isRequired,
  type: PropTypes.oneOf(Object.values(TYPES)),
  mask: PropTypes.oneOf(Object.values(MASKS)),
  handleResetClick: PropTypes.func,
  disabled: PropTypes.bool,
  isRequired: PropTypes.bool,
};

Input.defaultProps = {
  width: WIDTH.WIDTH_390,
  mask: null,
  disabled: false,
};

export default Input;
