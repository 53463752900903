export const CURRENCY_SIGNS = {
  eur: '€',
  usd: '$',
  gbp: '£',
  chf: '₣',
  huf: 'Ft',
  czk: 'Kč',
  dkk: 'DKK',
  nok: 'NKr',
  pln: 'zł',
  ron: 'L',
  sek: 'SEK',
};

export const getCurrencySign = (currencyName) => {
  return CURRENCY_SIGNS[currencyName];
};

export const CURRENCY = {
  USD: 'usd',
  EUR: 'eur',
  GBP: 'gbr',
  CHF: 'chf',
};

export const CURRENCY_LABEL = {
  USD: 'USD',
  EUR: 'EUR',
  GBP: 'GBR',
  CHF: 'CHF',
};

export const LIST_OF_CURRENCIES = [
  {value: CURRENCY.USD, label: CURRENCY_LABEL.USD},
  {value: CURRENCY.EUR, label: CURRENCY_LABEL.EUR},
  // {value: CURRENCY.GBP, label: CURRENCY_LABEL.GBP}, // TODO: add when we will have GBP
  // {value: CURRENCY.CHF, label: CURRENCY_LABEL.CHF}, // TODO: add when we will have CHF
];
