import AWS from 'aws-sdk';
import {pathOr} from 'ramda';
import {v4 as uuidv4} from 'uuid';

import {REST_METHODS} from '../constants/api';
import authorizedFetch from './authorizedFetch';

const s3FilesUpload = async (file, fileName) => {
  const Key = uuidv4() + fileName;
  const params = {
    Body: file,
    Bucket: process.env.NEXT_PUBLIC_S3_BUCKET,
    Key,
  };

  const {location, key} = await getS3Uploader(params);

  return {location, key};
};

export async function getSessionAWSToken() {
  const {responseBody} = await authorizedFetch({
    method: REST_METHODS.GET,
    path: `/api/v1/credentials/aws_s3/token`,
  });

  return responseBody;
}

async function getS3Uploader(params) {
  const {access_key_id, secret_access_key, session_token} =
    await getSessionAWSToken();

  AWS.config.update({
    region: process.env.NEXT_PUBLIC_REGION,
    accessKeyId: access_key_id,
    secretAccessKey: secret_access_key,
    sessionToken: session_token,
  });

  const s3 = new AWS.S3({httpOptions: {timeout: 10 * 60 * 1000}});
  const options = {partSize: 5 * 1024 * 1024, queueSize: 10};

  return new Promise((resolve) => {
    s3.upload(params, options).send(function (err, data) {
      resolve({
        location: pathOr('', ['Location'], data),
        key: pathOr('', ['Key'], data),
      });
    });
  });
}

export default s3FilesUpload;
