import TextLocal from '@/src/textLocal/containers/TextLocal';
import {Button, Text} from '@/src/ui';

import css from '../styles/SuccessfulPublishPopupLayout.module.css';

interface SuccessfulPublishPopupLayoutProps {
  vacancyTitle: string;
  vacancyExpireDate: string;
  onGotItClick: (...args: any) => any;
}

const SuccessfulPublishPopupLayout = ({
  vacancyTitle,
  vacancyExpireDate,
  onGotItClick,
}: SuccessfulPublishPopupLayoutProps) => {
  return (
    <div className={css.popup}>
      <div className={css.content}>
        <div className={css.heading}>
          <Text
            fontFamily={Text.FONT_FAMILY.RUBIK}
            fontWeight={Text.FONT_WEIGHT.MEDIUM}
            fontSize={Text.FONT_SIZE.SIZE_24}
            lineHeight={Text.LINE_HEIGHT.LINE_HEIGHT_1_6}
          >
            <TextLocal id={'job-portal.you-posted-your-position'} />
          </Text>
        </div>
        <div className={css.description}>
          <Text
            fontSize={Text.FONT_SIZE.SIZE_14}
            lineHeight={Text.LINE_HEIGHT.LINE_HEIGHT_21}
          >
            <TextLocal
              id={'job-portal.your-position-is-active'}
              params={{vacancyTitle, vacancyExpireDate}}
            />
          </Text>
        </div>
        <Text
          fontSize={Text.FONT_SIZE.SIZE_14}
          lineHeight={Text.LINE_HEIGHT.LINE_HEIGHT_21}
          color={Text.COLOR.GREY}
        >
          <TextLocal id={'job-portal.wish-you-all-the-best'} />
        </Text>
        <div className={css.buttonBlock}>
          <div className={css.button}>
            <Button
              onClick={onGotItClick}
              color={Button.COLOR.PRIMARY}
              height={Button.HEIGHT.SMALL}
            >
              <TextLocal id={'job-portal.got-it'} />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuccessfulPublishPopupLayout;
