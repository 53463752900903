import fileDownload from 'js-file-download';
import PropTypes from 'prop-types';
import {useCallback} from 'react';

import css from '@/src/howTo/styles/FileToDownload.module.css';
import checkIsFileMedia from '@/src/howTo/utils/checkIsFileMedia';
import {Text} from '@/src/ui';

const FileToDownload = ({blok}) => {
  const {src} = blok;

  const downloadResource = useCallback(async (url, filename) => {
    if (!filename) {
      filename = url.split('\\').pop().split('/').pop();
    }

    url = url.replace('a.storyblok', 's3.amazonaws.com/a.storyblok');

    const response = await fetch(url, {
      headers: new Headers({
        Origin: location.origin,
      }),
      mode: 'cors',
    });

    const parsedResponse = await response.blob();
    fileDownload(parsedResponse, filename);
  }, []);

  const getContent = useCallback((src) => {
    const filename = src.split('\\').pop().split('/').pop();

    switch (true) {
      case checkIsFileMedia(src):
        return (
          <>
            <a
              href={src}
              target="_blank"
              rel="noreferrer"
              className={css.fileLink}
            >
              <Text
                tagComponent={Text.TAG.SPAN}
                color={Text.COLOR.GRADIENT}
                fontSize={Text.FONT_SIZE.SIZE_14}
                fontWeight={Text.FONT_WEIGHT.BOLD}
              >
                {filename}
              </Text>
            </a>
            <button
              className={css.downloadIcon}
              onClick={() => {
                downloadResource(src, filename);
              }}
            >
              <img
                src={'/icons/downloadGradientIcon.svg'}
                loading="lazy"
                alt={'download icon'}
              />
            </button>
          </>
        );

      default:
        return (
          <>
            <Text
              tagComponent={Text.TAG.SPAN}
              color={Text.COLOR.PRIMARY}
              fontSize={Text.FONT_SIZE.SIZE_14}
              fontWeight={Text.FONT_WEIGHT.BOLD}
            >
              {filename}
            </Text>
            <a
              href={src}
              target="_blank"
              rel="noreferrer"
              className={css.downloadIcon}
            >
              <img
                src={'/icons/downloadGradientIcon.svg'}
                loading="lazy"
                alt={'download icon'}
              />
            </a>
          </>
        );
    }
  }, []);

  if (!src.filename) {
    return null;
  }

  return <div className={css.fileCard}>{getContent(src?.filename)}</div>;
};

FileToDownload.propTypes = {
  blok: PropTypes.object.isRequired,
};

export default FileToDownload;
