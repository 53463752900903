import {useRouter} from 'next/router';
import {useEffect} from 'react';

import useUserActions from '@/src/app/store/user/useUserActions';
import useSingleJobPosition from '@/src/jobPortal/common/hooks/useSingleJobPosition';

interface SinglePositionBootstrapProps {
  children: JSX.Element;
  id?: number;
}

export const NEW_POSITION = 'new';

export const DUPLICATE_PARAM = 'duplicate';

const SinglePositionBootstrap = ({
  children,
}: SinglePositionBootstrapProps): JSX.Element => {
  const {user: userData} = useUserActions();
  const {query, isReady} = useRouter();

  const {getJobPosition, resetJopPosition} = useSingleJobPosition(
    userData?.clinic?.id
  );

  useEffect(() => {
    if (query.slug !== NEW_POSITION) {
      getJobPosition(query.slug);
    } else if (query.slug === NEW_POSITION && query[DUPLICATE_PARAM]) {
      getJobPosition(query[DUPLICATE_PARAM], true);
    } else {
      resetJopPosition();
    }
    return () => {
      resetJopPosition();
    };
  }, [query.slug, isReady]);

  return <>{children} </>;
};

export default SinglePositionBootstrap;
