import cn from 'clsx';
import PropsTypes from 'prop-types';

import Text from '../../text/components/Text';
import css from '../styles/PagetTitle.module.css';

const BOTTOM_OFFSET = {
  OFFSET_NONE: 'offsetNone',
  OFFSET_10: 'offset10',
  OFFSET_15: 'offset15',
  OFFSET_20: 'offset20',
  OFFSET_30: 'offset30',
  OFFSET_35: 'offset35',
  OFFSET_40: 'offset40',
};

const TAG = {
  H1: Text.TAG.H1,
  H2: Text.TAG.H2,
  H3: Text.TAG.H3,
  H4: Text.TAG.H4,
};

const PageTitle = ({title, offset, tag}) => {
  return (
    <div className={cn(css[offset])}>
      <Text
        fontFamily={Text.FONT_FAMILY.RUBIK}
        fontSize={Text.FONT_SIZE.SIZE_32}
        color={Text.COLOR.PRIMARY}
        fontWeight={Text.FONT_WEIGHT.MEDIUM}
        tagComponent={tag}
      >
        {title}
      </Text>
    </div>
  );
};

PageTitle.BOTTOM_OFFSET = BOTTOM_OFFSET;
PageTitle.TAG = TAG;

export default PageTitle;

PageTitle.propTypes = {
  title: PropsTypes.oneOfType([PropsTypes.string, PropsTypes.node]).isRequired,
  offset: PropsTypes.oneOf(Object.values(BOTTOM_OFFSET)),
  tag: PropsTypes.oneOf(Object.values(TAG)),
};

PageTitle.defaultProps = {
  offset: BOTTOM_OFFSET.OFFSET_30,
  tag: TAG.H3,
};
