import NavLink from 'next/link';
import PropTypes from 'prop-types';

import {Text} from '@/src/ui';

const SidebarItem = ({title, href, isActive, order}) => {
  return (
    <NavLink href={`/${href}`}>
      <a>
        <Text
          fontSize={Text.FONT_SIZE.SIZE_16}
          fontWeight={Text.FONT_WEIGHT.BOLD}
          color={isActive ? Text.COLOR.GRADIENT : Text.COLOR.PRIMARY}
        >
          {order}. {title}
        </Text>
      </a>
    </NavLink>
  );
};

SidebarItem.propTypes = {
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  order: PropTypes.number.isRequired,
  isActive: PropTypes.bool,
  href: PropTypes.string.isRequired,
};

export default SidebarItem;
