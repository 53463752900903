import {useRouter} from 'next/router';
import {useCallback} from 'react';
import {useDispatch} from 'react-redux';

import {unsetClinicData} from '@/src/app/store/clinic/clinicDataSlice';
import useUserActions from '@/src/app/store/user/useUserActions';
import {removeAuthorizationToken} from '@/src/app/utils/authorizationToken';
import useRoutePaths from '@/src/hooks/useRoutePaths';

import requestLogout from './requestLogout';

const useSignOut = () => {
  const dispatch = useDispatch();
  const {push} = useRouter();
  const {unsetUserData} = useUserActions();
  const {SIGN_IN_ROUTE} = useRoutePaths();

  const handleSignOutClick = useCallback(
    async (isAvoidRedirect?: boolean) => {
      requestLogout();
      removeAuthorizationToken();
      dispatch(unsetClinicData());
      unsetUserData();

      if (!isAvoidRedirect) {
        push(SIGN_IN_ROUTE.getLink());
      }
    },
    [push, dispatch]
  );

  return {
    handleSignOutClick,
  };
};

export default useSignOut;
