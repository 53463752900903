import cn from 'clsx';

import LoadingIcon from '@/public/icons/loaing-dark.svg';
import OpenForNewOpportunitiesActive from '@/src/profile/common/components/OpenForNewOpportunitiesActive';
import OpenForNewOpportunitiesInActive from '@/src/profile/common/components/OpenForNewOpportunitiesInActive';
import css from '@/src/profile/common/styles/OpenForNewOpportunities.module.css';
import TextLocal from '@/src/textLocal/containers/TextLocal';
import {Card, Separator, Text} from '@/src/ui';

interface placeProps {
  name: string;
  id: number;
}

interface MobileWrapperProps {
  children: JSX.Element;
}

const MobileWrapper = ({children}: MobileWrapperProps) => (
  <div className={css.headerMobileWrapper}>{children}</div>
);

interface OpenForNewOpportunitiesLayoutProps {
  isLoading: boolean;
  isHeader: boolean;
  isOpenForNewOpportunities: boolean;
  onTurnOffClick: (...args: any[]) => any;
  onEditClick: (...args: any[]) => any;
  location: placeProps;
  participation: string;
  positions: Array<placeProps>;
}

const OpenForNewOpportunitiesLayout = ({
  isLoading,
  isOpenForNewOpportunities,
  onTurnOffClick,
  onEditClick,
  location,
  participation,
  positions,
  isHeader,
}: OpenForNewOpportunitiesLayoutProps) => {
  const Wrapper = isHeader ? MobileWrapper : Card;

  return (
    <div
      className={cn(css.main, {
        [css.header]: isHeader,
      })}
    >
      <Wrapper>
        {!isLoading ? (
          <div className={css.content}>
            <div className={css.title}>
              <Text
                fontSize={Text.FONT_SIZE.SIZE_14}
                fontWeight={Text.FONT_WEIGHT.BOLD}
              >
                <TextLocal
                  id={'open-opportunities.open-for-new-job-opportunities'}
                />
              </Text>
            </div>
            {isOpenForNewOpportunities ? (
              <div className={css.line}>
                <Separator borderWeight={Separator.BORDER_WEIGHT.THIN} />
              </div>
            ) : null}
            {isOpenForNewOpportunities ? (
              <OpenForNewOpportunitiesActive
                isHeader={isHeader}
                turnOffClick={onTurnOffClick}
                editClick={onEditClick}
                location={location.name}
                participation={participation}
                positions={positions.map((position) => position.name)}
              />
            ) : (
              <OpenForNewOpportunitiesInActive
                turnOnClick={onEditClick}
                isHeader={isHeader}
              />
            )}
          </div>
        ) : (
          <div className={css.loadingWidget}>
            <LoadingIcon />
          </div>
        )}
      </Wrapper>
    </div>
  );
};

export default OpenForNewOpportunitiesLayout;
