import PropTypes from 'prop-types';
import {pathOr} from 'ramda';
import {useEffect, useRef, useState} from 'react';

import {ROLES_NAMES} from '@/src/app/constants/roles';
import useIsMobile from '@/src/hooks/useIsMobile';
import useRoutePaths from '@/src/hooks/useRoutePaths';
import BillType from '@/src/promoSections/components/PricingSectionLayout/BillType';
import PricingDesktop from '@/src/promoSections/components/PricingSectionLayout/PricingDesktop';
import PricingMobile from '@/src/promoSections/components/PricingSectionLayout/PricingMobile';
import PricingTablet from '@/src/promoSections/components/PricingSectionLayout/PricingTablet';
import {getCurrencySign} from '@/src/signUp/paymentDetails/utils/getCurrencySign';
import TextLocal from '@/src/textLocal/containers/TextLocal';

import DynamicComponent from '../../../dynamicComponents/components/DynamicComponent';
import CommonSectionLayout from '../CommonSectionLayout';

const INTERVALS = {
  MONTHLY: 'month',
  YEARLY: 'year',
};

const getPlanPrices = (plans, type) => {
  const price =
    plans.find(
      (plan) => plan.user_type === type && plan.interval === INTERVALS.MONTHLY
    ).price / 100;
  const yearlyPrice =
    plans.find(
      (plan) => plan.user_type === type && plan.interval === INTERVALS.YEARLY
    ).price / 100;

  return {
    price,
    yearlyPrice,
  };
};

const PricingSectionLayout = ({
  plans,
  doctorsTitle,
  doctorsDescription,
  doctorsButton,
  contentCreatorsTitle,
  contentCreatorsDescription,
  contentCreatorsButton,
  studentsTitle,
  studentsDiscount,
  studentsDescription,
  studentsButton,
  topContent,
  clinicTitle,
  clinicButton,
  clinicDescription,
  doctorsList,
  studentList,
  contentCreatorList,
  clinicList,
  studentsIcon,
  doctorsIcon,
  contentCreatorsIcon,
  clinicsIcon,
  isYearly,
  onBillTypeClick,
  contentCreatorMonthlyDescription,
  doctorMonthlyDescription,
  studentMonthlyDescription,
  clinicMonthlyDescription,
}) => {
  const {isMaxWidth750, isMaxWidth900, isMaxWidth1100} = useIsMobile();
  const currentSymbol = getCurrencySign(pathOr('', [0, 'currency'], plans));
  const {SIGN_UP_ROUTE} = useRoutePaths();

  const isMobile = isMaxWidth750 && isMaxWidth1100;
  const isTablet = !isMaxWidth750 && isMaxWidth1100;
  const isDesktop = !isMobile && !isMaxWidth1100;

  const {price: oldStudentPrice, yearlyPrice: oldYerlyStudentPrice} =
    getPlanPrices(plans, ROLES_NAMES.CUSTOMER);

  const [isSticky, setIsSticky] = useState(false);

  const startRef = useRef();
  const endRef = useRef();

  const handleOnScroll = () => {
    if (startRef.current && endRef.current) {
      // @ts-ignore
      const startSectionVisible = startRef.current.getBoundingClientRect().top;
      // @ts-ignore
      const endSectionVisible = endRef.current.getBoundingClientRect().top;

      setIsSticky(startSectionVisible < 0 && endSectionVisible > 0);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleOnScroll);

    return () => {
      window.removeEventListener('scroll', handleOnScroll);
    };
  }, [startRef.current, endRef.current]);

  const pricingMockDoctor = {
    id: 1,
    icon: doctorsIcon,
    title: doctorsTitle,
    buttonText: doctorsButton,
    discount: null,
    priceWithDiscount: null,
    description: doctorsDescription,
    descriptionMonthly: doctorMonthlyDescription,
    slug: `${SIGN_UP_ROUTE.getLink()}/${ROLES_NAMES.DOCTOR}`,
    ...getPlanPrices(plans, ROLES_NAMES.DOCTOR),
  };

  const pricingMockStudent = {
    id: 2,
    icon: studentsIcon,
    title: studentsTitle,
    buttonText: studentsButton,
    oldPrice: oldStudentPrice,
    oldPriceYearly: oldYerlyStudentPrice,
    discount: studentsDiscount,
    description: studentsDescription,
    descriptionMonthly: studentMonthlyDescription,
    slug: `${SIGN_UP_ROUTE.getLink()}/${ROLES_NAMES.STUDENT}`,
    ...getPlanPrices(plans, ROLES_NAMES.STUDENT),
  };

  const pricingMockContentCreator = {
    id: 3,
    icon: contentCreatorsIcon,
    title: contentCreatorsTitle,
    buttonText: contentCreatorsButton,
    discount: null,
    priceWithDiscount: null,
    description: contentCreatorsDescription,
    descriptionMonthly: contentCreatorMonthlyDescription,
    slug: `${SIGN_UP_ROUTE.getLink()}/${ROLES_NAMES.COURSE_CREATOR}`,
    ...getPlanPrices(plans, ROLES_NAMES.COURSE_CREATOR),
  };

  const pricingMockClinic = {
    id: 4,
    icon: clinicsIcon,
    title: clinicTitle,
    buttonText: clinicButton,
    discount: null,
    priceWithDiscount: null,
    description: clinicDescription,
    descriptionMonthly: clinicMonthlyDescription,
    slug: `${SIGN_UP_ROUTE.getLink()}/${ROLES_NAMES.COURSE_CREATOR}`,
    ...getPlanPrices(plans, ROLES_NAMES.CLINIC),
  };

  const pricingMockDesktop = [
    pricingMockDoctor,
    pricingMockStudent,
    pricingMockContentCreator,
    pricingMockClinic,
  ];

  const pricingMockTablet = [
    [pricingMockDoctor, pricingMockStudent],
    [pricingMockContentCreator, pricingMockClinic],
  ];

  const pricingMockMobile = [
    {
      ...pricingMockDoctor,
      features: doctorsList,
    },

    {
      ...pricingMockStudent,
      features: studentList,
    },

    {
      ...pricingMockContentCreator,
      features: contentCreatorList,
    },
    {
      ...pricingMockClinic,
      features: clinicList,
    },
  ];

  return (
    <div>
      <CommonSectionLayout
        fullWidth={isMaxWidth900}
        title={<TextLocal id={'promoSection.pricing'} />}
        content={
          <div>
            {topContent
              ? topContent.map((blok) => (
                  <DynamicComponent blok={blok} key={blok._uid} />
                ))
              : null}

            <div>
              <div>
                <BillType
                  isYearly={isYearly}
                  onChange={onBillTypeClick}
                  isSticky={isSticky}
                />
              </div>
              <div className={'startSection'} ref={startRef} />
              {isDesktop && (
                <PricingDesktop
                  doctorsList={doctorsList}
                  studentList={studentList}
                  contentCreatorList={contentCreatorList}
                  clinicList={clinicList}
                  pricingList={pricingMockDesktop}
                  currencySymbol={currentSymbol}
                  isYearly={isYearly}
                />
              )}
              {isTablet && (
                <PricingTablet
                  doctorsList={doctorsList}
                  studentList={studentList}
                  contentCreatorList={contentCreatorList}
                  clinicList={clinicList}
                  pricingList={pricingMockTablet}
                  currencySymbol={currentSymbol}
                  isYearly={isYearly}
                />
              )}

              {isMobile && (
                <PricingMobile
                  pricingList={pricingMockMobile}
                  currentSymbol={currentSymbol}
                  isYearly={isYearly}
                />
              )}

              <div className={'endSection'} ref={endRef} />
            </div>
          </div>
        }
      />
    </div>
  );
};

PricingSectionLayout.propTypes = {
  plans: PropTypes.array.isRequired,
  doctorsList: PropTypes.array.isRequired,
  contentCreatorList: PropTypes.array.isRequired,
  studentList: PropTypes.array.isRequired,
  topContent: PropTypes.array.isRequired,
  doctorsTitle: PropTypes.string.isRequired,
  doctorsDescription: PropTypes.string.isRequired,
  doctorsButton: PropTypes.string.isRequired,
  contentCreatorsTitle: PropTypes.string.isRequired,
  contentCreatorsDescription: PropTypes.string.isRequired,
  contentCreatorsButton: PropTypes.string.isRequired,
  studentsTitle: PropTypes.string.isRequired,
  studentsDiscount: PropTypes.string.isRequired,
  studentsDescription: PropTypes.string.isRequired,
  studentsButton: PropTypes.string.isRequired,
  clinicTitle: PropTypes.string.isRequired,
  clinicButton: PropTypes.string.isRequired,
  clinicDescription: PropTypes.string.isRequired,
  clinicList: PropTypes.array.isRequired,
  studentsIcon: PropTypes.string.isRequired,
  doctorsIcon: PropTypes.string.isRequired,
  contentCreatorsIcon: PropTypes.string.isRequired,
  clinicsIcon: PropTypes.string.isRequired,
  isYearly: PropTypes.bool.isRequired,
  onBillTypeClick: PropTypes.func.isRequired,
  contentCreatorMonthlyDescription: PropTypes.string.isRequired,
  doctorMonthlyDescription: PropTypes.string.isRequired,
  studentMonthlyDescription: PropTypes.string.isRequired,
  clinicMonthlyDescription: PropTypes.string.isRequired,
};

export default PricingSectionLayout;
