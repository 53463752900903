import cn from 'clsx';
import {ReactNode, useCallback, useState} from 'react';
import {Collapse} from 'react-collapse';

import css from '../styles/SimpleCollapse.module.css';

interface SimpleCollapseProps {
  children: ReactNode;
  headTitle: ReactNode;
  defaultIsOpen?: boolean;
  isDisabled?: boolean;
  isCircledIcon?: boolean;
}

const SimpleCollapse = ({
  children,
  headTitle,
  defaultIsOpen,
  isDisabled,
  isCircledIcon,
}: SimpleCollapseProps) => {
  const [isOpen, setIsOpen] = useState(defaultIsOpen);

  const toggleIsOpen = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  return (
    <div
      className={cn(css.mainWrapper, {
        [css.isDisabled]: isDisabled,
      })}
    >
      <div className={css.header} onClick={toggleIsOpen}>
        {headTitle}
        <div
          className={cn(css.toggleIcon, {
            [css.isOpen]: isOpen,
            [css.toggleIconWithCircle]: isCircledIcon,
          })}
        />
      </div>
      <Collapse isOpened={isOpen} theme={{collapse: css.content}}>
        {children}
      </Collapse>
    </div>
  );
};

export default SimpleCollapse;
