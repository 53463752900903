import {useCallback, useEffect} from 'react';

import OpenForNewOpportunitiesLayout from '@/src/profile/common/components/OpenForNewOpportunitiesLayout';
import useOpenForNewOpportunities from '@/src/profile/common/hooks/useOpenForNewOpportunities';

interface OpenForNewOpportunitiesProps {
  userId: number | string;
  isOpenForNewOpportunities: boolean;
  isHeader?: boolean;
}

const OpenForNewOpportunities = ({
  userId,
  isOpenForNewOpportunities,
  isHeader,
}: OpenForNewOpportunitiesProps): JSX.Element => {
  const {
    isLoading,
    location,
    participation,
    positions,
    getUserOpenForNewOpportunities,
    handleOpenEditForm,
    handleTurnOffJobOpportunityWithConfirm,
    resetJobOpportunities,
  } = useOpenForNewOpportunities();

  useEffect(() => {
    if (isOpenForNewOpportunities) {
      getUserOpenForNewOpportunities(userId);
    }

    return () => {
      resetJobOpportunities();
    };
  }, [isOpenForNewOpportunities]);

  const onEditClick = useCallback(
    () => handleOpenEditForm(Number(userId)),
    [userId]
  );

  const onTurnOffClick = useCallback(
    () => handleTurnOffJobOpportunityWithConfirm(Number(userId)),
    [userId]
  );

  return (
    <OpenForNewOpportunitiesLayout
      isHeader={isHeader}
      isLoading={isLoading}
      isOpenForNewOpportunities={isOpenForNewOpportunities}
      onTurnOffClick={onTurnOffClick}
      onEditClick={onEditClick}
      location={location}
      participation={participation}
      positions={positions}
    />
  );
};

export default OpenForNewOpportunities;
