import clsx from 'clsx';

import css from '@/src/profile/common/styles/ProfileHeaderImages.module.css';
import {Text} from '@/src/ui';

interface UserAvatarProps {
  picture: string;
  withEditButtons?: boolean;
  onEditProfileImageClick?: () => {};
  editImageNode?: JSX.Element;
  userInitials?: string;
  withShadow?: boolean;
  size?: 'regularSize' | 'smallSize' | 'extraSmallSize';
}
const UserAvatar = ({
  picture,
  withEditButtons,
  onEditProfileImageClick,
  editImageNode,
  userInitials,
  withShadow,
  size,
}: UserAvatarProps): JSX.Element => {
  return picture ? (
    <div className={clsx(css.pictureWrapper, css[size])}>
      <img
        src={picture}
        loading="lazy"
        alt={''}
        className={clsx({
          [css.imageShadow]: withShadow,
        })}
      />
      {withEditButtons && (
        <div
          className={css.editProfileImageButton}
          onClick={onEditProfileImageClick}
        >
          {editImageNode}
        </div>
      )}
    </div>
  ) : (
    <div className={css.profileDefaultImage}>
      <Text
        color={Text.COLOR.WHITE}
        fontWeight={Text.FONT_WEIGHT.BOLD}
        fontSize={Text.FONT_SIZE.SIZE_40}
      >
        {userInitials}
      </Text>
      {withEditButtons && (
        <div
          className={css.editProfileImageButton}
          onClick={onEditProfileImageClick}
        >
          {editImageNode}
        </div>
      )}
    </div>
  );
};

export default UserAvatar;
