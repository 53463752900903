import cn from 'clsx';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import ReactPlayer from 'react-player';
import screenfull from 'screenfull';

import ArrowControlNext from '@/public/images/v-control-arrow-next.svg';
import ArrowControlPrev from '@/public/images/v-control-arrow-prev.svg';
import FullscreenIcon from '@/public/images/v-control-fullscreen.svg';
import MuteIcon from '@/public/images/v-control-muted.svg';
import PlayIcon from '@/public/images/v-control-play.svg';
import StoppedIcon from '@/public/images/v-control-stopped.svg';
import UnMuteIcon from '@/public/images/v-control-unmuted.svg';
import useIsMobile from '@/src/hooks/useIsMobile';

import LoadingIcon from '../../../../public/icons/loaing.svg';
import PlayIconLarge from '../../../../public/icons/playVideoIcon.svg';
import css from '../styles/VideoPlayer.module.css';
import Duration from './Duration';

interface SimpleVideoPlayesLayoutProps {
  id: string;
  url: string;
  videoPlayerRef: React.MutableRefObject<ReactPlayer | null>;
  videoWithControlsRef: React.MutableRefObject<HTMLDivElement | null>;
  isPlaying: boolean;
  isMuted: boolean;
  loaded: number;
  played: number;
  onSeekChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onSeekMouseUp: (event: React.MouseEvent<HTMLInputElement>) => void;
  onSeekMouseDown: (event: React.MouseEvent<HTMLInputElement>) => void;
  onSetPlayingClick: () => void;
  onSetMutedClick: () => void;
  onFullScreenClick: () => void;
  onPreviousVideoClick: () => void;
  onNextVideoClick: () => void;
  isNextDisable: boolean;
  isPreviousDisable: boolean;
  onProgressChange: (state: {played: number; loaded: number}) => void;
  playButton: React.ReactNode;
  poster: string;
  onEnded?: () => void;
  additionalContent: React.ReactNode;
}
const SimpleVideoPlayesLayout = ({
  url,
  videoPlayerRef,
  videoWithControlsRef,
  isPlaying,
  isMuted,
  loaded,
  played,
  onSeekChange,
  onSeekMouseUp,
  onSeekMouseDown,
  onSetPlayingClick,
  onSetMutedClick,
  onFullScreenClick,
  onPreviousVideoClick,
  onNextVideoClick,
  isNextDisable,
  isPreviousDisable,
  onProgressChange,
  playButton,
  poster,
  onEnded,
  additionalContent,
}: SimpleVideoPlayesLayoutProps) => {
  const [isReady, setIsReady] = useState(false);
  const {isMaxWidth900} = useIsMobile();
  const [isFullscreenControls, setFullscreenControls] = useState(false);
  const [durationInSeconds, setDurationInSeconds] = useState(null);

  const cursorRef = useRef(null);

  useEffect(() => {
    setIsReady(false);
  }, [url]);

  const playVideoWrapperNode = useMemo(
    () => (
      <div className={css.iconButtonCenterWrapper}>
        {playButton ? playButton : <PlayIconLarge />}
      </div>
    ),
    []
  );

  const handleDuration = useCallback((duration) => {
    setDurationInSeconds(duration);
  }, []);

  const onMouseMoveCursor = useCallback(() => {
    if (cursorRef.current) {
      setFullscreenControls(true);
      clearTimeout(cursorRef.current);
    }

    cursorRef.current = setTimeout(() => {
      setFullscreenControls(false);
    }, 2000);
  }, [cursorRef]);

  return (
    <>
      <div
        ref={videoWithControlsRef}
        onMouseMove={onMouseMoveCursor}
        className={cn(css.mainWrapper, {
          // @ts-ignore
          [css.mainWrapperFullSize]: screenfull.isFullscreen,
          [css.mainWrapperFullSizeActiveCursor]: isFullscreenControls,
        })}
      >
        <div className={css.relativePosition} onClick={onSetPlayingClick}>
          {!isReady && !isMaxWidth900 && (
            <div className={css.loadingIcon}>
              <LoadingIcon />
            </div>
          )}
          {additionalContent && additionalContent}
          {isReady && !isPlaying && !isMaxWidth900 && playVideoWrapperNode}
          <ReactPlayer
            onEnded={onEnded}
            playsinline={isMaxWidth900}
            controls={isMaxWidth900}
            className={css.videoPlayer}
            ref={videoPlayerRef}
            url={url}
            width="100%"
            height="100%"
            playing={isPlaying}
            muted={isMuted}
            onReady={() => {
              setIsReady(true);
            }}
            onDuration={handleDuration}
            onProgress={onProgressChange}
            config={{
              file: {
                attributes: {
                  poster: poster ? '/imager/transparentImage.gif' : null,
                  style: {
                    width: ' 100%',
                    height: '100%',
                    backgroundImage: poster ? `url(${poster})` : null,
                  },
                },
              },
            }}
          />
        </div>
        {!isMaxWidth900 && (
          <div className={css.mainControls}>
            <label className={css.track}>
              <div
                className={css.loader}
                style={{
                  width: `${loaded * 100}%`,
                }}
              />
              <div
                className={css.currentTime}
                style={{
                  width: `${played * 100}%`,
                }}
              />

              <input
                type="range"
                min={0}
                max={0.999999}
                step="any"
                value={played || 0}
                onMouseDown={onSeekMouseDown}
                onChange={onSeekChange}
                onMouseUp={onSeekMouseUp}
              />
            </label>

            <div className={css.controls}>
              <div className={css.actions}>
                <button
                  disabled={isPreviousDisable}
                  className={css.controlButtons}
                  onClick={onPreviousVideoClick}
                >
                  <ArrowControlPrev />
                </button>
                <button
                  className={css.controlButtons}
                  onClick={onSetPlayingClick}
                >
                  {isPlaying ? <StoppedIcon /> : <PlayIcon />}
                </button>
                <button
                  className={css.controlButtons}
                  onClick={onNextVideoClick}
                  disabled={isNextDisable}
                >
                  <ArrowControlNext />
                </button>
              </div>
              <button
                className={cn(css.controlButtons, css.muteButton)}
                onClick={onSetMutedClick}
              >
                {isMuted ? <MuteIcon /> : <UnMuteIcon />}
              </button>

              <div className={css.time}>
                <span>
                  <Duration seconds={durationInSeconds * played || 0} />
                </span>
                /
                <span>
                  <Duration seconds={durationInSeconds || 0} />
                </span>
              </div>

              <button
                onClick={onFullScreenClick}
                className={cn(css.controlButtons, css.fullScreen)}
              >
                <FullscreenIcon />
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default SimpleVideoPlayesLayout;
