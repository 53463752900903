import clsx from 'clsx';
import cn from 'clsx';
import NavLink from 'next/link';
import {useRouter} from 'next/router';
import React, {MouseEventHandler, useContext, useMemo} from 'react';

import ArrowRight from '@/public/images/arrow-right-gradient.svg';
import useIsMobile from '@/src/hooks/useIsMobile';
import ClinicPositionAttachments from '@/src/jobPortal/clinicPositionPreview/components/ClinicPositionAttachments';
import ClinicPositionDescription from '@/src/jobPortal/clinicPositionPreview/components/ClinicPositionDescription';
import ClinicPositionHeading from '@/src/jobPortal/clinicPositionPreview/components/ClinicPositionHeading';
import ClinicPositionSidebar from '@/src/jobPortal/clinicPositionPreview/components/ClinicPositionSidebar';
import PositionDetailsList from '@/src/jobPortal/clinicPositionPreview/components/PositionDetailsList';
import PreviewModeNotice from '@/src/jobPortal/clinicPositionPreview/components/PreviewModeNotice';
import SocialMediaShareLinks from '@/src/jobPortal/clinicPositionPreview/components/SocialMediaShareLinks';
import {CLINIC_POSITION_STATUSES} from '@/src/jobPortal/clinicPositionPreview/constants/clinicPositionStatuses';
import OtherClinicPositions from '@/src/jobPortal/targetPosition/containers/OtherClinicPositions';
import RouteContext from '@/src/routes/utils/RouteContext';
import TextLocal from '@/src/textLocal/containers/TextLocal';
import {Button, Separator, Text} from '@/src/ui';

import css from '../styles/ClinicPositionPreviewLayout.module.css';

interface ClinicPositionPreviewLayoutProps {
  onApplyClick: MouseEventHandler<HTMLDivElement>;
  onPublishClick: (...args: any) => any;
  onEditClick: (...args: any) => any;
  onDashboardClick: (...args: any) => any;
  onArchiveClick: (...args: any) => any;
  onViewApplicantsClick: (vacancyId: number) => any;
  positionData: any;
  employmentTypes: Array<any>;
  clinicData: any;
  isLoading: boolean;
  pdfAttachments: Array<any>;
  imageAttachments: Array<any>;
  videoAttachments: Array<any>;
  isTargetPage: boolean;
  isApplied: boolean;
  isCurrentUserClinic: boolean;
  positionStatus: string;
  applicantsCount?: number;
}

const ClinicPositionPreviewLayout = ({
  onPublishClick,
  onEditClick,
  onDashboardClick,
  positionData,
  employmentTypes,
  clinicData,
  isCurrentUserClinic,
  isLoading,
  pdfAttachments,
  videoAttachments,
  imageAttachments,
  isTargetPage,
  onApplyClick,
  onArchiveClick,
  positionStatus,
  isApplied,
  onViewApplicantsClick,
  applicantsCount,
}: ClinicPositionPreviewLayoutProps) => {
  const {locale, back} = useRouter();
  const {isMaxWidth1100, isMaxWidth600} = useIsMobile();
  // @ts-ignore
  const {JOB_PORTAL_TARGET_POSITION, CLINIC_TARGET_PROFILE} =
    useContext(RouteContext);

  const isPositionArchived =
    positionStatus === CLINIC_POSITION_STATUSES.ARCHIVED;
  const isPositionExpired = positionStatus === CLINIC_POSITION_STATUSES.EXPIRED;

  const positionUrl = `${
    window.location.origin
  }${JOB_PORTAL_TARGET_POSITION.getLink(positionData.slug)}`;

  const withMedia = useMemo(
    () =>
      pdfAttachments?.length &&
      imageAttachments?.length &&
      videoAttachments?.length,
    [pdfAttachments, imageAttachments, videoAttachments]
  );

  return (
    <div>
      {!isTargetPage && !isPositionArchived && !isPositionExpired && (
        <PreviewModeNotice
          positionStatus={positionStatus}
          onDashboardClick={onDashboardClick}
          onEditClick={onEditClick}
          onPublishClick={onPublishClick}
          isLoading={isLoading}
        />
      )}

      {isTargetPage && (
        <div className={css.pageContentBack}>
          <span className={css.leftBackContent} />
          <div className={css.backLinkWrapper}>
            <span onClick={back} className={css.backLink}>
              <ArrowRight />
              <Text
                color={Text.COLOR.GRADIENT}
                fontWeight={Text.FONT_WEIGHT.BOLD}
              >
                <TextLocal id={'create-course.back'} />
              </Text>
            </span>
          </div>
          <span className={css.rightBackContent} />
        </div>
      )}

      <div className={css.pageContent}>
        {!isMaxWidth1100 && (
          <div className={css.socialMediaSidebar}>
            <div className={css.socialIcon}>
              <Text color={Text.COLOR.GREY} fontSize={Text.FONT_SIZE.SIZE_14}>
                <TextLocal id={'job-portal.share'} />:
              </Text>
              <SocialMediaShareLinks
                link={positionUrl}
                jobTitle={positionData.title}
                clinicName={clinicData.organization_name}
              />
            </div>
          </div>
        )}
        <div className={clsx(css.mainContentPart)}>
          <ClinicPositionHeading
            postedAt={positionData.published_at}
            expireDate={positionData.expire_date}
            title={positionData.title}
            workType={positionData.work_type}
            employmentTypes={employmentTypes}
            minSalary={positionData.min_salary}
            currency={positionData.currency}
            positionStatus={positionStatus}
          />

          <div>
            <PositionDetailsList
              countryName={positionData.country[`name_${locale}`]}
              stateName={positionData.state[`name_${locale}`]}
              cityName={positionData.city[`name_${locale}`]}
              entryDate={positionData.entry_date}
              speciality={positionData.specialty[`name_${locale}`]}
            />
          </div>

          {isMaxWidth600 && (
            <>
              <div className={css.mobileSeparator}>
                <Separator borderWeight={Separator.BORDER_WEIGHT.THIN} />
              </div>
              <div className={css.mobileClinicDescription}>
                <div className={css.mobileCenteredText}>
                  <Text
                    fontSize={Text.FONT_SIZE.SIZE_14}
                    fontWeight={Text.FONT_WEIGHT.BOLD}
                    color={Text.COLOR.GRADIENT}
                  >
                    <NavLink
                      href={CLINIC_TARGET_PROFILE.getLink(clinicData.slug)}
                    >
                      <a>{clinicData.organization_name}</a>
                    </NavLink>
                  </Text>
                </div>
                <div className={css.mobileCenteredText}>
                  <Text
                    fontSize={Text.FONT_SIZE.SIZE_12}
                    color={Text.COLOR.GREY}
                  >
                    {clinicData.website}
                  </Text>
                </div>
              </div>
              <div className={css.mobileSeparator}>
                <Separator borderWeight={Separator.BORDER_WEIGHT.THIN} />
              </div>
            </>
          )}

          <ClinicPositionDescription
            description={positionData.description}
            whatToExpect={positionData.what_to_expect}
            responsibilities={positionData.responsibilities}
            requirements={positionData.requirements}
          />
          {!isCurrentUserClinic && isTargetPage && Boolean(withMedia) && (
            <div className={css.applyWrapper}>
              <Separator borderWeight={Separator.BORDER_WEIGHT.THIN} />
              <div className={css.applyWrapperInner}>
                <Button
                  height={Button.HEIGHT.SMALL}
                  onClick={onApplyClick}
                  isDisabled={isApplied}
                >
                  <TextLocal
                    id={isApplied ? 'general.applied' : 'general.applyNow'}
                  />
                </Button>
              </div>
              <Separator borderWeight={Separator.BORDER_WEIGHT.THIN} />
            </div>
          )}
          <ClinicPositionAttachments
            pdfAttachments={pdfAttachments}
            videoAttachments={videoAttachments}
            imageAttachments={imageAttachments}
          />
          {isMaxWidth1100 && (
            <div
              className={cn(css.mobileShare, {
                [css.mobileTargetPageSocial]: !isTargetPage,
              })}
            >
              <SocialMediaShareLinks
                link={positionUrl}
                jobTitle={positionData.title}
                clinicName={clinicData.organization_name}
              />
            </div>
          )}
          {isTargetPage && !isCurrentUserClinic && (
            <div className={css.singleApply}>
              <Button
                height={Button.HEIGHT.SMALL}
                onClick={onApplyClick}
                isDisabled={isApplied}
              >
                <TextLocal
                  id={isApplied ? 'general.applied' : 'general.applyNow'}
                />
              </Button>
            </div>
          )}
        </div>
        <div className={css.sidebar}>
          <ClinicPositionSidebar
            isCurrentUserClinic={isCurrentUserClinic}
            isTargetPage={isTargetPage}
            isLoading={isLoading}
            clinicWebsite={clinicData.website}
            avatarLink={clinicData.picture}
            clinicName={clinicData.organization_name}
            establishDate={clinicData.founded_date}
            employeesNumber={clinicData.personnel_size}
            onPublishClick={onPublishClick}
            onEditClick={onEditClick}
            onApplyClick={onApplyClick}
            onDashboardClick={onDashboardClick}
            onViewApplicantsClick={onViewApplicantsClick}
            onArchiveClick={onArchiveClick}
            positionStatus={positionStatus}
            isApplied={isApplied}
            positionId={positionData.id}
            applicantsCount={applicantsCount}
            clinicSlug={clinicData.slug}
          />
        </div>
      </div>
      <div className={css.otherPositionsWrapper}>
        {isTargetPage && (
          <>
            <OtherClinicPositions
              clinicId={clinicData.id}
              excludePositionId={positionData.id}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default ClinicPositionPreviewLayout;
