import {useRouter} from 'next/router';
import PropTypes from 'prop-types';
import {useCallback} from 'react';

import useRoutePaths from '@/src/hooks/useRoutePaths';
import TextLocal from '@/src/textLocal/containers/TextLocal';

import NoAccessLayout from '../components/NoAccessLayout';

type NoAccessProps = {
  isRenewSubscription?: boolean;
  text?: string;
};

const NoAccess = ({isRenewSubscription, text}: NoAccessProps) => {
  const {push} = useRouter();
  const {DISCOVER, MY_PROFILE_SUBSCRIPTION} = useRoutePaths();

  const handleOnDiscoverButtonClick = useCallback(() => {
    return push(DISCOVER.getLink());
  }, []);

  const handleOnProfileSettingsButtonClick = useCallback(() => {
    return push(MY_PROFILE_SUBSCRIPTION.getLink());
  }, []);

  const noAccessReasonText = isRenewSubscription
    ? 'no-access.renew-subscription'
    : 'no-access.info';
  const customText = text ? text : noAccessReasonText;

  return (
    <NoAccessLayout
      onButtonClick={
        isRenewSubscription
          ? handleOnProfileSettingsButtonClick
          : handleOnDiscoverButtonClick
      }
      noAccessReason={
        <div>
          <div className={'mb4'}>
            <TextLocal id={'no-access.info'} />
          </div>
          <div className={'mb2'}>
            <TextLocal id={customText} />
          </div>
        </div>
      }
      buttonText={
        isRenewSubscription ? (
          <TextLocal id={'no-access.renew-button'} />
        ) : (
          <TextLocal id={'no-access.button'} />
        )
      }
    />
  );
};

NoAccess.propTypes = {
  isRenewSubscription: PropTypes.bool,
};

NoAccess.defaultProps = {
  isRenewSubscription: false,
};

export default NoAccess;
