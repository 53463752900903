import {i18n} from 'next-i18next';

import {PATHS} from '@/src/routes/containers/Routes';

import {
  RESPONSE_STATUSES,
  RESPONSE_TYPES,
  REST_METHODS,
} from '../constants/api';
import {LOCALES} from '../constants/locales';
import {
  removeAuthorizationToken,
  setAuthorizationToken,
} from './authorizationToken';
import getRootServerUrl from './getRootServerUrl';

const unauthorizedFetch = async ({
  method = REST_METHODS.POST,
  body,
  path,
  formData,
  queryParams = [],
  responseType = RESPONSE_TYPES.JSON,
  headers = {},
  shouldSkipUnauthorizedStatus = false,
}) => {
  const {INTERNAL_ERROR_ROUTE, NOT_FOUND_ROUTE, SIGN_IN_ROUTE} = PATHS;
  const {defaultLocale} = i18n;
  const baseUrl = new URL(`${getRootServerUrl()}${path}`);
  const locale = i18n?.language;
  const withLocal = locale === defaultLocale ? '' : `/${locale}`;

  queryParams.forEach((param) => {
    baseUrl.searchParams.append(param.name, param.value);
  });

  const response = await fetch(decodeURIComponent(baseUrl.href), {
    method: method,
    headers: {
      'User-Locale': locale || LOCALES.EN,
      ...(formData ? {} : {'Content-Type': 'application/json;charset=utf-8'}),
      ...headers,
    },
    body: formData ? formData : JSON.stringify(body),
  });

  const token = response.headers.get('authorization');

  if (token) {
    setAuthorizationToken(token);
  }

  if (
    response.status === RESPONSE_STATUSES.UNAUTHORIZED_STATUS &&
    typeof window !== 'undefined' &&
    !shouldSkipUnauthorizedStatus
  ) {
    removeAuthorizationToken();
    window.location.replace(`${withLocal}${SIGN_IN_ROUTE.getLink()}`);
  }

  if (
    response.status === RESPONSE_STATUSES.INTERNAL_SERVER_ERROR &&
    typeof window !== 'undefined'
  ) {
    window.location.replace(`${withLocal}${INTERNAL_ERROR_ROUTE.getLink()}`);
  } else if (
    response.status === RESPONSE_STATUSES.NOT_FOUND &&
    typeof window !== 'undefined'
  ) {
    window.location.replace(`${withLocal}${NOT_FOUND_ROUTE.getLink()}`);
  }

  let parsedResponse;

  try {
    parsedResponse =
      responseType === RESPONSE_TYPES.BLOB
        ? await response.blob()
        : await response.json();
  } catch (error) {
    parsedResponse = null;
  }

  return {
    responseBody: parsedResponse,
    status: response.status,
  };
};

export default unauthorizedFetch;
