import {useRouter} from 'next/router';
import {useCallback, useContext} from 'react';

import SuccessfulPublishPopupLayout from '@/src/jobPortal/clinicPositionPreview/components/SuccessfulPublishPopupLayout';
import PopupContext from '@/src/popup/utils/PopupContext';
import RouteContext from '@/src/routes/utils/RouteContext';

interface SuccessfulPublishPopupProps {
  vacancyTitle: string;
  vacancyExpireDate: string;
}

const SuccessfulPublishPopup = ({
  vacancyTitle,
  vacancyExpireDate,
}: SuccessfulPublishPopupProps) => {
  const {closePopup} = useContext(PopupContext);
  const {push} = useRouter();
  // @ts-ignore
  const {JOB_PORTAL_MY_POSITIONS} = useContext(RouteContext);

  const handleGotItClick = useCallback(() => {
    push(JOB_PORTAL_MY_POSITIONS.getLink());
    closePopup();
  }, []);

  return (
    <SuccessfulPublishPopupLayout
      onGotItClick={handleGotItClick}
      vacancyTitle={vacancyTitle}
      vacancyExpireDate={vacancyExpireDate.replaceAll('/', '.')}
    />
  );
};

export default SuccessfulPublishPopup;
