import DynamicComponent from '@/src/dynamicComponents/components/DynamicComponent';
import useIsMobile from '@/src/hooks/useIsMobile';
import {SimpleCollapse, Text} from '@/src/ui';

import css from '../styles/SimpleCollapse.module.css';

const SimpleCollapseStoryblok = ({blok}) => {
  const {title, content, titleIcon, withCircledArrow} = blok;
  const {isMaxWidth600} = useIsMobile();

  const image = titleIcon ? (
    <span className={css.icon}>
      {' '}
      <img src={titleIcon.filename} loading="lazy" alt="" />
    </span>
  ) : null;
  const preparedTitle = (
    <div className={css.collapseTitle}>
      {image}{' '}
      <Text
        fontSize={
          isMaxWidth600 ? Text.FONT_SIZE.SIZE_20 : Text.FONT_SIZE.SIZE_24
        }
        fontWeight={Text.FONT_WEIGHT.BOLD}
      >
        {title}
      </Text>
    </div>
  );
  return (
    <div className={css.collapseWrapper}>
      <SimpleCollapse
        headTitle={preparedTitle}
        isCircledIcon={withCircledArrow}
      >
        <div className={css.contentWrapper}>
          {content
            ? content.map((blok) => (
                <DynamicComponent blok={blok} key={blok._uid} />
              ))
            : null}
        </div>
      </SimpleCollapse>
    </div>
  );
};

export default SimpleCollapseStoryblok;
