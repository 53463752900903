import Image from 'next/image';
import PropTypes from 'prop-types';

import useIsMobile from '@/src/hooks/useIsMobile';
import TextLocal from '@/src/textLocal/containers/TextLocal';

import {Separator, Text} from '../../../ui';
import css from '../styles/MyProfileVisitBlock.module.css';

const getDescriptionText = (isClinic, isContentCreator) => {
  if (isClinic) {
    return 'profile.share-your-credentials-clinic';
  }

  if (isContentCreator) {
    return 'profile.share-your-credentials-content-creator';
  }

  return 'profile.share-your-credentials';
};

const MyProfileVisitBlock = ({
  onVisitMyProfileClick,
  isClinic,
  isContentCreator,
}) => {
  const {isMaxWidth900} = useIsMobile();

  return (
    <div className={css.widget}>
      {isMaxWidth900 && (
        <div className={css.topSeparator}>
          <Separator borderWeight={Separator.BORDER_WEIGHT.THIN} />
        </div>
      )}
      <div className={css.visitBlock} onClick={onVisitMyProfileClick}>
        <Text color={Text.COLOR.GRADIENT} fontSize={Text.FONT_SIZE.SIZE_18}>
          <TextLocal id={'profile.view-my-profile'} />
        </Text>
        <div className={css.arrowBlock}>
          <Image
            src={`/icons/topRightArrow.svg`}
            loading="lazy"
            alt="true"
            width="12"
            height="11"
          />
        </div>
      </div>
      {!isMaxWidth900 && (
        <div className={css.bottomSeparator}>
          <Separator borderWeight={Separator.BORDER_WEIGHT.THIN} />
        </div>
      )}
      <Text
        fontSize={Text.FONT_SIZE.SIZE_12}
        color={Text.COLOR.GREY}
        lineHeight={Text.LINE_HEIGHT.LINE_HEIGHT_24}
      >
        <TextLocal id={getDescriptionText(isClinic, isContentCreator)} />
      </Text>
    </div>
  );
};

MyProfileVisitBlock.propTypes = {
  onVisitMyProfileClick: PropTypes.func.isRequired,
  isClinic: PropTypes.bool.isRequired,
  isContentCreator: PropTypes.bool.isRequired,
};

export default MyProfileVisitBlock;
