import {REST_METHODS} from '@/src/app/constants/api';
import authorizedFetch from '@/src/app/utils/authorizedFetch';

/**
 * @param {boolean} currentStatus - is in user follow this content creator - true; is in user not follow this content
 * creator -false
 * @param {boolean, number} userId - user ID to follow
 */
const requestToggleFollow = (userId, currentStatus) => {
  const endpointEnding = currentStatus ? 'unfollow' : 'follow';

  // @ts-ignore
  return authorizedFetch({
    method: REST_METHODS.POST,
    path: `/api/v1/follows/${endpointEnding}`,
    body: {
      user_id: userId,
    },
  });
};

export default requestToggleFollow;
