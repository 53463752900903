import {useRouter} from 'next/router';
import {memo, useCallback, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {RESPONSE_STATUSES} from '@/src/app/constants/api';
import {
  ORDER_BY_FILTER,
  ORDER_FILTER,
  SEARCH_FILTERS,
} from '@/src/app/constants/search';
import {
  selectSearchWords,
  setSearchWords,
} from '@/src/app/store/promoSections/promoSectionsSlice';
import useRoutePaths from '@/src/hooks/useRoutePaths';

import css from '../styles/SearchWords.module.css';
import getSearchWords from '../utils/getSearchWords';

const HeroSectionContainer = () => {
  const searchWords = useSelector(selectSearchWords);
  const dispatch = useDispatch();
  const {locale, push} = useRouter();
  const {SEARCH_COURSES} = useRoutePaths();

  const requestGetSearchWords = useCallback(async () => {
    const {status, responseBody} = await getSearchWords();

    if (status === RESPONSE_STATUSES.SUCCESS) {
      dispatch(setSearchWords(responseBody?.data || []));
    }
  }, []);

  useEffect(() => {
    requestGetSearchWords();
  }, []);

  const handleSearchWordsClick = useCallback((searchhWord) => {
    push({
      pathname: SEARCH_COURSES.getLink(),
      query: `${SEARCH_FILTERS.SEARCH}=${searchhWord}&${SEARCH_FILTERS.OFFSET}=0&${SEARCH_FILTERS.ORDER}=${ORDER_FILTER.DESC}&${SEARCH_FILTERS.ORDER_BY}=${ORDER_BY_FILTER.APPROVED_AT}&${SEARCH_FILTERS.LANGUAGE}=${locale}`,
    });
  }, []);

  return (
    <div className={css.wordsList}>
      {searchWords?.map((itm) => (
        <button
          key={itm.id}
          onClick={() => handleSearchWordsClick(itm[`name_${locale}`])}
          className={css.wordsButton}
        >
          {itm[`name_${locale}`]}
        </button>
      ))}
    </div>
  );
};

export default memo(HeroSectionContainer);
