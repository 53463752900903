import cn from 'clsx';
import Link from 'next/link';
import PropTypes from 'prop-types';
import {useCallback, useState} from 'react';

import {RESPONSE_STATUSES} from '@/src/app/constants/api';
import {DIFFICULTY_LEVELS_WITH_LABELS} from '@/src/app/constants/course';
import {ROLES_NAMES} from '@/src/app/constants/roles';
import {getAuthorizationToken} from '@/src/app/utils/authorizationToken';
import useRoutePaths from '@/src/hooks/useRoutePaths';
import TextLocal from '@/src/textLocal/containers/TextLocal';

import requestToggleCourseBookmark from '../../../courseDetails/utils/requestToggleCourseBookmark';
import {Rating, Text, UserPreview} from '../../index';
import css from '../styles/CourseCardLayout.module.css';
import BookmarkButton from './BookmarkButton';

const convertLanguagesToString = (languages = [], language) => {
  return languages.length <= 1 ? language : 'EN, DE';
};

interface CourseCardLayoutProps {
  id: string;
  cardNumber?: number;
  topicCount: number;
  level: string;
  previewImage: string;
  title: string;
  description: string;
  rating: number;
  creator: {
    name: string;
    lastName: string;
    slug: string;
    type: string;
    picture: string;
  };
  language: string;
  isBookmarked: boolean;
  slug: string;
  languages: string[];
}

const CourseCardLayout = ({
  id,
  cardNumber,
  topicCount,
  level,
  previewImage,
  title,
  description,
  rating,
  creator,
  language,
  isBookmarked,
  slug,
  languages,
}: CourseCardLayoutProps) => {
  const [isCourseBookmarked, setCourseBookmarked] = useState(isBookmarked);
  // @ts-ignore
  const {COURSES_ROUTE, USER_PROFILE, CLINIC_TARGET_PROFILE} = useRoutePaths();

  const creatorLink =
    creator?.type === ROLES_NAMES.CLINIC
      ? CLINIC_TARGET_PROFILE.getLink(creator?.slug)
      : USER_PROFILE.getLink(creator?.slug);

  const handleToggleCourseBookmarkClick = useCallback(async () => {
    const {status} = await requestToggleCourseBookmark(id, isCourseBookmarked);

    if (status === RESPONSE_STATUSES.SUCCESS) {
      setCourseBookmarked((state) => !state);
    }
  }, [id, isCourseBookmarked]);

  return (
    <div
      className={cn(css.card, {
        [css.withNumber]: cardNumber,
      })}
    >
      {cardNumber && <div className={css.cardNumber}>{cardNumber}</div>}
      <div className={css.cardBody}>
        {getAuthorizationToken() && (
          <div className={css.cardControls}>
            <BookmarkButton
              onClick={handleToggleCourseBookmarkClick}
              isBookmarked={isCourseBookmarked}
            />
          </div>
        )}
        <Link href={COURSES_ROUTE.getLink(slug)}>
          <a className={css.cardLink} aria-label={`zoom image`}>
            <figure
              className={cn(css.media, {[css.mediaPlaceholder]: !previewImage})}
            >
              {previewImage && (
                <img className={css.mediaElement} src={previewImage} alt={''} />
              )}
              <figcaption className={css.mediaCaption}>
                <Text
                  color={Text.COLOR.WHITE}
                  fontSize={Text.FONT_SIZE.SIZE_12}
                  lineHeight={Text.LINE_HEIGHT.LINE_HEIGHT_1_4}
                  transform={Text.TRANSFORM.CAPITALIZE}
                  fontFamily={Text.FONT_FAMILY.POPPINS}
                  fontWeight={Text.FONT_WEIGHT.NORMAL}
                >
                  {`${topicCount} `}
                  <TextLocal
                    id={
                      topicCount > 1 || topicCount === 0
                        ? 'general.topics'
                        : 'general.topic'
                    }
                  />
                  {level && (
                    <>
                      {' | '}
                      <TextLocal
                        id={
                          DIFFICULTY_LEVELS_WITH_LABELS.find(
                            (difficulty) => difficulty.value === level
                          )?.label
                        }
                      />
                    </>
                  )}
                </Text>
              </figcaption>
            </figure>
          </a>
        </Link>
        <div className={css.cardContainer}>
          <div className={css.cardInfo}>
            <Rating
              rating={parseFloat(String(rating))}
              fontSize={Rating.FONT_SIZE.SIZE_12}
              fontWeight={Rating.FONT_WEIGHT.MEDIUM}
              color={Text.COLOR.GREY}
            />
            <div className={css.langInfo}>
              <Text
                fontSize={Text.FONT_SIZE.SIZE_12}
                fontWeight={Text.FONT_WEIGHT.MEDIUM}
                transform={Text.TRANSFORM.UPPERCASE}
                color={Text.COLOR.GREY}
              >
                {convertLanguagesToString(languages, language)}
              </Text>
            </div>
          </div>
          <div className={css.title} title={title}>
            <Link href={COURSES_ROUTE.getLink(slug)}>
              <a>{title}</a>
            </Link>
          </div>
          <div className={css.description}>
            <Text
              color={Text.COLOR.SECONDARY}
              fontSize={Text.FONT_SIZE.SIZE_12}
              lineHeight={Text.LINE_HEIGHT.LINE_HEIGHT_1_4}
            >
              {description}
            </Text>
          </div>
          {creator && (
            <div className={css.author}>
              <Link href={creatorLink}>
                <a>
                  <UserPreview
                    size={'extraSmall'}
                    image={creator?.picture}
                    name={`${creator?.name} ${creator?.lastName}`}
                    isSmallUserName
                    noMargin
                  />
                </a>
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

CourseCardLayout.propTypes = {
  id: PropTypes.number,
  cardNumber: PropTypes.number,
  topicCount: PropTypes.number,
  level: PropTypes.string,
  previewImage: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  rating: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  creator: PropTypes.object,
  language: PropTypes.string,
  isBookmarked: PropTypes.bool,
  slug: PropTypes.string,
  languages: PropTypes.array,
};

CourseCardLayout.defaultProps = {
  slug: '',
  cardNumber: null,
  topicCount: 1,
  level: null,
  previewImage: null,
  title: 'Title',
  description: 'Description',
  rating: '0',
  creator: null,
  language: 'EN',
  isBookmarked: false,
  languages: [],
};

export default CourseCardLayout;
