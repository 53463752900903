import {sbEditable} from '@storyblok/storyblok-editable';
import cn from 'clsx';

import TrustedSection from '@/src/promoSections/components/TrustedSection.storyblok';

import css from '../styles/TrustedSection.module.css';

export const Sponsors = ({blok}) => {
  if (!blok) {
    return null;
  }

  const {list, title, background, isFullWidth, isEnable, speed, sliderOff} =
    blok;

  if (isEnable) {
    return null;
  }

  return (
    <div
      key={blok.uuid}
      {...sbEditable(blok)}
      className={cn(css.standAloneTrasted, {
        [css.container]: !isFullWidth,
      })}
    >
      <TrustedSection
        blok={{
          images: list,
          title: title,
          background: background,
          speed: speed ? speed * 1000 : 4000,
          sliderOff: sliderOff,
        }}
      />
    </div>
  );
};
