import cn from 'clsx';
import {useRouter} from 'next/router';
import {useCallback, useContext, useMemo} from 'react';

import CalendarIcon from '@/public/icons/gradientCalendar.svg';
import LocationIcon from '@/public/icons/locationIcon.svg';
import {ROLES_NAMES} from '@/src/app/constants/roles';
import useUserActions from '@/src/app/store/user/useUserActions';
import getDaysBeforeExpire from '@/src/app/utils/getDaysBeforeExpire';
import useApplicationPopup from '@/src/jobPortal/applicationPopup/utils/useApplicationPopup';
import {getDaysLeftColor} from '@/src/jobPortal/clinicPositionPreview/components/ClinicPositionHeading';
import {getClinicAvatar} from '@/src/profile/aboutMe/components/ProfessionalExperienceEditWidgetLayout';
import {getSlicedDescription} from '@/src/profile/clinicTarget/components/ClinicPositionMobileWidget';
import RouteContext from '@/src/routes/utils/RouteContext';
import {getCurrencySign} from '@/src/signUp/paymentDetails/utils/getCurrencySign';
import TextLocal from '@/src/textLocal/containers/TextLocal';
import {Avatar, Button, Text} from '@/src/ui';

import css from '../styles/ClinicPositionDesktopWidget.module.css';

export const formatMinSalary = (minSalary: number): string => {
  if (!minSalary) return '';
  return minSalary.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
};

interface ClinicPositionDesktopWidgetProps {
  title: string;
  clinicName: string;
  entryDate: string;
  clinicAvatar?: string | null;
  cityName: string;
  countryName: string;
  workType: Array<any>;
  minSalary: number;
  publishedAt: string;
  description: string;
  currency: string;
  expireDate: string;
  slug: string;
  id: number;
  isApplied: boolean;
  onSuccessfulApplicationCallback: (...args: any[]) => any;
}

const ClinicPositionDesktopWidget = ({
  title,
  clinicName,
  clinicAvatar,
  cityName,
  countryName,
  workType,
  minSalary,
  description,
  currency,
  expireDate,
  slug,
  id,
  isApplied,
  onSuccessfulApplicationCallback,
  entryDate,
}: ClinicPositionDesktopWidgetProps) => {
  const {user} = useUserActions();
  const daysBeforeExpire = getDaysBeforeExpire(
    new Date(),
    expireDate,
    true,
    true
  );
  // @ts-ignore
  const {JOB_PORTAL_TARGET_POSITION} = useContext(RouteContext);
  const {openApplicationPopup} = useApplicationPopup();
  const isClinic = user?.user?.type === ROLES_NAMES.CLINIC;
  const {push} = useRouter();

  const onVacancyClick = useCallback(() => {
    push(JOB_PORTAL_TARGET_POSITION.getLink(slug));
  }, [slug]);

  const mappedWorkTypesNode = useMemo(() => {
    return workType?.map((singleWorkType) => (
      <div className={css.workTypeBlock} key={singleWorkType.value}>
        <Text
          fontSize={Text.FONT_SIZE.SIZE_14}
          fontWeight={Text.FONT_WEIGHT.BOLD}
        >
          {singleWorkType.label}
        </Text>
      </div>
    ));
  }, [workType]);

  return (
    <div className={css.widget} onClick={onVacancyClick}>
      <div className={css.leftWidgetPart}>
        <div className={css.workTypesBlock}>{mappedWorkTypesNode}</div>
        <Text
          fontSize={Text.FONT_SIZE.SIZE_24}
          fontWeight={Text.FONT_WEIGHT.BOLD}
        >
          {title}
        </Text>
        <div className={css.clinicNameBlock}>
          {(Boolean(clinicAvatar) || Boolean(clinicName)) && (
            <Avatar
              size={'extraSmall'}
              organizationName={clinicName}
              picture={getClinicAvatar(clinicAvatar)}
            />
          )}
          <Text
            fontSize={Text.FONT_SIZE.SIZE_16}
            fontWeight={Text.FONT_WEIGHT.BOLD}
          >
            {clinicName}
          </Text>
        </div>
        <div className={css.positionDataRow}>
          <div className={css.entryDate}>
            <CalendarIcon width="20" height="22" />
            <Text fontSize={Text.FONT_SIZE.SIZE_16}>
              <TextLocal id={'job-portal.entry-date'} />:{' '}
              <span>{entryDate}</span>
            </Text>
          </div>
          <div className={css.clinicLocationBlock}>
            <div className={css.locationIcon}>
              <LocationIcon />
            </div>
            <Text fontSize={Text.FONT_SIZE.SIZE_16}>
              {`${cityName}, ${countryName}`}
            </Text>
          </div>
        </div>
        <div className={css.descriptionRow}>
          <Text
            color={Text.COLOR.GREY}
            fontSize={Text.FONT_SIZE.SIZE_14}
            lineHeight={Text.LINE_HEIGHT.LINE_HEIGHT_1_4}
          >
            {getSlicedDescription(description)}
          </Text>
        </div>
      </div>
      <div className={css.rightWidgetPart}>
        <div className={css.salaryBlock}>
          {Boolean(minSalary) && (
            <>
              <Text
                color={Text.COLOR.GREY}
                fontSize={Text.FONT_SIZE.SIZE_12}
                fontWeight={Text.FONT_WEIGHT.NORMAL}
              >
                <TextLocal id={'job-portal.min'} />
              </Text>
              <div className={css.minSalary}>
                <Text
                  fontSize={Text.FONT_SIZE.SIZE_18}
                  fontWeight={Text.FONT_WEIGHT.BOLD}
                >
                  {`${getCurrencySign(currency)} ${formatMinSalary(minSalary)}`}
                </Text>
              </div>
              <Text
                color={Text.COLOR.GREY}
                fontSize={Text.FONT_SIZE.SIZE_12}
                fontWeight={Text.FONT_WEIGHT.NORMAL}
              >
                <TextLocal id={'job-portal.per-year'} />
              </Text>
            </>
          )}
        </div>
        <div
          className={cn(css.applyNowButton, {
            [css.isHiddenApply]: isClinic,
          })}
        >
          <Button
            isDisabled={isApplied}
            color={Button.COLOR.PRIMARY}
            height={Button.HEIGHT.SMALL}
            onClick={(event) =>
              openApplicationPopup(event, {
                positionId: id,
                positionTitle: title,
                clinicName,
                onSuccessfulApplicationCallback,
              })
            }
          >
            <TextLocal
              id={isApplied ? 'general.applied' : 'pricing.apply-now'}
            />
          </Button>
        </div>

        <Text
          color={getDaysLeftColor(daysBeforeExpire)}
          fontSize={Text.FONT_SIZE.SIZE_12}
          fontWeight={Text.FONT_WEIGHT.BOLD}
        >
          <TextLocal
            id={'job-portal.days-left'}
            params={{days: daysBeforeExpire}}
          />
        </Text>
      </div>
    </div>
  );
};

export default ClinicPositionDesktopWidget;
