import {Text} from '@/src/ui';

import css from '../../styles/CountWithTitle.module.css';

const CountWithTitle = ({count, title}) => {
  return (
    <div className={css.main}>
      <div>
        <Text
          fontWeight={Text.FONT_WEIGHT.MEDIUM}
          fontFamily={Text.FONT_FAMILY.RUBIK}
          fontSize={Text.FONT_SIZE.SIZE_40}
        >
          {count}
        </Text>
      </div>
      <div>
        <Text
          fontSize={Text.FONT_SIZE.SIZE_16}
          fontWeight={Text.FONT_WEIGHT.THIN}
        >
          {title}
        </Text>
      </div>
    </div>
  );
};

export default CountWithTitle;
