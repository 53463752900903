import {REST_METHODS} from '@/src/app/constants/api';
import authorizedFetch from '@/src/app/utils/authorizedFetch';

const fetchClinicAttachments = (clinicId: number) => {
  // @ts-ignore
  return authorizedFetch({
    method: REST_METHODS.GET,
    path: `/api/v1/clinics/pdf_attachments`,
    queryParams: [
      {
        name: 'clinic_id',
        value: clinicId,
      },
    ],
  });
};

export default fetchClinicAttachments;
