import clsx from 'clsx';

import {getUserInitials} from '@/src/profile/common/containers/MyProfileWrapper';

import css from '../styles/Avatar.module.css';

interface AvatarProps {
  size:
    | 'extraSmall'
    | 'small'
    | 'regular'
    | 'almostMedium'
    | 'medium'
    | 'large'
    | 'tiny';
  picture?: string | null;
  firstName?: string;
  lastName?: string;
  organizationName?: string;
  color?: 'white';
}

const Avatar = ({
  picture,
  firstName,
  lastName,
  organizationName,
  size,
  color,
}: AvatarProps): JSX.Element => {
  const isClinic = Boolean(organizationName?.length);

  return (
    <div
      className={clsx(css[size], css.avatar, css[color], {
        [css.avatarWithOutBackground]: picture,
      })}
    >
      {picture ? (
        <img
          src={picture}
          loading="lazy"
          alt={isClinic ? organizationName : `${firstName} ${lastName}`}
        />
      ) : (
        <div className={color === 'white' ? css.gradientText : null}>
          {getUserInitials(
            {
              user: {
                first_name: firstName,
                last_name: lastName,
              },
            },
            isClinic,
            {
              clinicData: {
                organization_name: organizationName,
              },
            }
          )}
        </div>
      )}
    </div>
  );
};

export default Avatar;
