import PropTypes from 'prop-types';

import useRoutePaths from '@/src/hooks/useRoutePaths';
import TextLocal from '@/src/textLocal/containers/TextLocal';

import {Text} from '../../../ui';
import css from '../styles/MyProfileNavbarLayout.module.css';

const MyProfileNavbarLayout = ({
  isClinic,
  containerRef,
  onNavbarLinkClick,
  isAboutMeActive,
  isMyCertificatesActive,
  isSubscriptionsActive,
  isAccountSettingsActive,
  isEmployeesActive,
  isExternalDomain,
}) => {
  const {
    MY_PROFILE_ABOUT_ME,
    MY_PROFILE_MY_CERTIFICATES,
    MY_PROFILE_SUBSCRIPTION,
    MY_PROFILE_ACCOUNT_SETTINGS,
    // MY_PROFILE_WITHDRAW,
    MY_PROFILE_EMPLOYEES,
  } = useRoutePaths();

  return (
    <div className={css.widget} ref={containerRef}>
      <div
        className={css.linkItem}
        onClick={() => onNavbarLinkClick(MY_PROFILE_ABOUT_ME.getLink())}
      >
        <Text
          fontSize={Text.FONT_SIZE.SIZE_18}
          color={isAboutMeActive ? Text.COLOR.PRIMARY : Text.COLOR.GREY}
          fontWeight={
            isAboutMeActive ? Text.FONT_WEIGHT.BOLD : Text.FONT_WEIGHT.NORMAL
          }
        >
          <TextLocal id={isClinic ? 'profile.about-us' : 'profile.about-me'} />
        </Text>
        {isAboutMeActive && <div className={css.linkItemHighlight} />}
      </div>
      {!isClinic && (
        <div
          className={css.linkItem}
          onClick={() =>
            onNavbarLinkClick(MY_PROFILE_MY_CERTIFICATES.getLink())
          }
        >
          <Text
            fontSize={Text.FONT_SIZE.SIZE_18}
            color={
              isMyCertificatesActive ? Text.COLOR.PRIMARY : Text.COLOR.GREY
            }
            fontWeight={
              isMyCertificatesActive
                ? Text.FONT_WEIGHT.BOLD
                : Text.FONT_WEIGHT.NORMAL
            }
          >
            <TextLocal id={'profile.my-certificates'} />
          </Text>
          {isMyCertificatesActive && <div className={css.linkItemHighlight} />}
        </div>
      )}
      {!isExternalDomain && (
        <div
          className={css.linkItem}
          onClick={() => onNavbarLinkClick(MY_PROFILE_SUBSCRIPTION.getLink())}
        >
          <Text
            fontSize={Text.FONT_SIZE.SIZE_18}
            color={isSubscriptionsActive ? Text.COLOR.PRIMARY : Text.COLOR.GREY}
            fontWeight={
              isSubscriptionsActive
                ? Text.FONT_WEIGHT.BOLD
                : Text.FONT_WEIGHT.NORMAL
            }
          >
            <TextLocal id={'profile.subscriptions'} />
          </Text>
          {isSubscriptionsActive && <div className={css.linkItemHighlight} />}
        </div>
      )}

      {/*HIDE IT FOR NOW*/}
      {/*{(isContentCreator || isClinic) &&*/}
      {/*  userStatus !== USER_STATUSES.DECLINED &&*/}
      {/*  userStatus !== USER_STATUSES.BLOCKED && (*/}
      {/*    <div*/}
      {/*      className={css.linkItem}*/}
      {/*      onClick={() => onNavbarLinkClick(MY_PROFILE_WITHDRAW.getLink())}*/}
      {/*    >*/}
      {/*      <Text*/}
      {/*        fontSize={Text.FONT_SIZE.SIZE_18}*/}
      {/*        color={isWithdrawActive ? Text.COLOR.PRIMARY : Text.COLOR.GREY}*/}
      {/*        fontWeight={*/}
      {/*          isWithdrawActive*/}
      {/*            ? Text.FONT_WEIGHT.BOLD*/}
      {/*            : Text.FONT_WEIGHT.NORMAL*/}
      {/*        }*/}
      {/*      >*/}
      {/*        <TextLocal id={'profile.withdraw'} />*/}
      {/*      </Text>*/}
      {/*      {isWithdrawActive && <div className={css.linkItemHighlight} />}*/}
      {/*    </div>*/}
      {/*  )}*/}
      <div
        className={css.linkItem}
        onClick={() => onNavbarLinkClick(MY_PROFILE_ACCOUNT_SETTINGS.getLink())}
      >
        <Text
          fontSize={Text.FONT_SIZE.SIZE_18}
          color={isAccountSettingsActive ? Text.COLOR.PRIMARY : Text.COLOR.GREY}
          fontWeight={
            isAccountSettingsActive
              ? Text.FONT_WEIGHT.BOLD
              : Text.FONT_WEIGHT.NORMAL
          }
        >
          <TextLocal id={'profile.account-settings'} />
        </Text>
        {isAccountSettingsActive && <div className={css.linkItemHighlight} />}
      </div>
      {isClinic && (
        <div
          className={css.linkItem}
          onClick={
            isEmployeesActive
              ? null
              : () => onNavbarLinkClick(MY_PROFILE_EMPLOYEES.getLink())
          }
        >
          <Text
            fontSize={Text.FONT_SIZE.SIZE_18}
            color={isEmployeesActive ? Text.COLOR.PRIMARY : Text.COLOR.GREY}
            fontWeight={
              isEmployeesActive
                ? Text.FONT_WEIGHT.BOLD
                : Text.FONT_WEIGHT.NORMAL
            }
          >
            <TextLocal id={'profile.employees'} />
          </Text>
          {isEmployeesActive && <div className={css.linkItemHighlight} />}
        </div>
      )}
    </div>
  );
};

MyProfileNavbarLayout.propTypes = {
  containerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  onNavbarLinkClick: PropTypes.func.isRequired,
  isAboutMeActive: PropTypes.bool.isRequired,
  isMyCertificatesActive: PropTypes.bool.isRequired,
  isSubscriptionsActive: PropTypes.bool.isRequired,
  isWithdrawActive: PropTypes.bool.isRequired,
  isAccountSettingsActive: PropTypes.bool.isRequired,
  isContentCreator: PropTypes.bool.isRequired,
  isEmployeesActive: PropTypes.bool.isRequired,
  isClinic: PropTypes.bool.isRequired,
  userStatus: PropTypes.string.isRequired,
};

export default MyProfileNavbarLayout;
