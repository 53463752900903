import Image from 'next/image';
import Link from 'next/link';
import PropTypes from 'prop-types';

import useRoutePaths from '@/src/hooks/useRoutePaths';

import {Text, UserPreview} from '../..';
import css from '../styles/CourseCardOriginalLayout.module.css';

interface CourseCardOriginalLayoutProps {
  type: string;
  verticalPreviewImage: string;
  title: string;
  creator: {
    name: string;
    lastName: string;
    slug: string;
    picture: string;
  };
  location: string;
  slug: string;
  isOutSideLink: boolean;
  isLinkDisabled: boolean;
  isContentCreators: boolean;
}

const CourseCardOriginalLayout = ({
  type,
  verticalPreviewImage,
  title,
  creator,
  location,
  slug,
  isOutSideLink,
  isLinkDisabled,
  isContentCreators,
}: CourseCardOriginalLayoutProps) => {
  // @ts-ignore
  const {COURSES_ROUTE, USER_PROFILE} = useRoutePaths();

  return (
    <div className={css.card}>
      {isLinkDisabled ? (
        // @ts-ignore
        <span className={css.media} target={isOutSideLink && '_blank'}>
          {verticalPreviewImage && (
            <div className={css.mediaElement}>
              <Image
                src={verticalPreviewImage}
                alt={''}
                width={360}
                height={576}
              />
            </div>
          )}
        </span>
      ) : (
        <Link href={isOutSideLink ? slug : `${COURSES_ROUTE.getLink(slug)}`}>
          <a
            className={css.media}
            target={isOutSideLink && '_blank'}
            aria-label={`link to course`}
          >
            {verticalPreviewImage && (
              <div className={css.mediaElement}>
                <Image
                  src={verticalPreviewImage}
                  alt={''}
                  width={360}
                  height={576}
                />
              </div>
            )}
          </a>
        </Link>
      )}

      <div className={css.content}>
        {type && (
          <div className={css.label}>
            <Text
              color={Text.COLOR.WHITE}
              fontSize={Text.FONT_SIZE.SIZE_11}
              fontWeight={Text.FONT_WEIGHT.MEDIUM}
              lineHeight={Text.LINE_HEIGHT.LINE_HEIGHT_1_4}
            >
              {type}
            </Text>
          </div>
        )}
        <div className={css.cardContainer}>
          {isLinkDisabled ? (
            // @ts-ignore
            <span className={css.title} target={isOutSideLink && '_blank'}>
              <Text
                color={Text.COLOR.WHITE}
                fontSize={
                  isContentCreators
                    ? Text.FONT_SIZE.SIZE_18
                    : Text.FONT_SIZE.SIZE_24
                }
                fontWeight={Text.FONT_WEIGHT.MEDIUM}
                lineHeight={Text.LINE_HEIGHT.LINE_HEIGHT_1_4}
              >
                {title}
              </Text>
            </span>
          ) : (
            <Link
              href={isOutSideLink ? slug : `${COURSES_ROUTE.getLink(slug)}`}
            >
              <a
                className={css.title}
                target={isOutSideLink && '_blank'}
                aria-label={`link to ${title}`}
              >
                <Text
                  color={Text.COLOR.WHITE}
                  fontSize={
                    isContentCreators
                      ? Text.FONT_SIZE.SIZE_18
                      : Text.FONT_SIZE.SIZE_24
                  }
                  fontWeight={Text.FONT_WEIGHT.MEDIUM}
                  lineHeight={Text.LINE_HEIGHT.LINE_HEIGHT_1_4}
                >
                  {title}
                </Text>
              </a>
            </Link>
          )}

          {location && (
            <Text
              color={Text.COLOR.WHITE}
              fontSize={Text.FONT_SIZE.SIZE_12}
              fontWeight={Text.FONT_WEIGHT.MEDIUM}
              lineHeight={Text.LINE_HEIGHT.LINE_HEIGHT_1_4}
            >
              {location}
            </Text>
          )}
          {creator && (
            <Link href={USER_PROFILE.getLink(creator?.slug)}>
              <a>
                <UserPreview
                  size={'extraSmall'}
                  theme={UserPreview.THEME.LIGHT}
                  image={creator?.picture}
                  name={`${creator?.name} ${creator?.lastName}`}
                  noMargin
                  isSmallUserName
                />
              </a>
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};

CourseCardOriginalLayout.propTypes = {
  type: PropTypes.string,
  verticalPreviewImage: PropTypes.string,
  title: PropTypes.string,
  creator: PropTypes.object,
  location: PropTypes.string,
  slug: PropTypes.string,
  isOutSideLink: PropTypes.bool,
  isLinkDisabled: PropTypes.bool,
  isContentCreators: PropTypes.bool,
};

CourseCardOriginalLayout.defaultProps = {
  type: null,
  verticalPreviewImage: null,
  title: 'Title',
  creator: null,
  isContentCreators: false,
};

export default CourseCardOriginalLayout;
