import cn from 'clsx';
import Image from 'next/image';
import {useRouter} from 'next/router';
import {useCallback, useContext, useMemo} from 'react';

import CalendarIcon from '@/public/icons/gradientCalendar.svg';
import {ROLES_NAMES} from '@/src/app/constants/roles';
import useUserActions from '@/src/app/store/user/useUserActions';
import getDaysBeforeExpire from '@/src/app/utils/getDaysBeforeExpire';
import useApplicationPopup from '@/src/jobPortal/applicationPopup/utils/useApplicationPopup';
import {getDaysLeftColor} from '@/src/jobPortal/clinicPositionPreview/components/ClinicPositionHeading';
import {getClinicAvatar} from '@/src/profile/aboutMe/components/ProfessionalExperienceEditWidgetLayout';
import {formatMinSalary} from '@/src/profile/clinicTarget/components/ClinicPositionDesktopWidget';
import RouteContext from '@/src/routes/utils/RouteContext';
import {getCurrencySign} from '@/src/signUp/paymentDetails/utils/getCurrencySign';
import TextLocal from '@/src/textLocal/containers/TextLocal';
import {Avatar, Button, Text} from '@/src/ui';

import css from '../styles/ClinicPositionMobileWidget.module.css';

export const MAX_VISIBLE_DESCRIPTION_LENGTH = 70;

export const getSlicedDescription = (description) => {
  const strippedDescription = description.replace(/(<([^>]+)>)/gi, ' ');

  return `${strippedDescription.slice(0, MAX_VISIBLE_DESCRIPTION_LENGTH)}${
    description?.length >= MAX_VISIBLE_DESCRIPTION_LENGTH ? '...' : ''
  }`;
};

interface ClinicPositionMobileWidgetProps {
  title: string;
  clinicName: string;
  cityName: string;
  countryName: string;
  workType: Array<any>;
  minSalary: number;
  publishedAt: string;
  description: string;
  currency: string;
  expireDate: string;
  slug: string;
  id: number;
  isApplied: boolean;
  onSuccessfulApplicationCallback: (...args: any[]) => any;
  withoutOffset?: boolean;
  clinicAvatar: string;
  entryDate: string;
}

const ClinicPositionMobileWidget = ({
  title,
  clinicName,
  cityName,
  countryName,
  workType,
  minSalary,
  clinicAvatar,
  currency,
  expireDate,
  slug,
  id,
  isApplied,
  onSuccessfulApplicationCallback,
  withoutOffset,
  entryDate,
}: ClinicPositionMobileWidgetProps) => {
  const {user} = useUserActions();
  const daysBeforeExpire = getDaysBeforeExpire(
    new Date(),
    expireDate,
    true,
    true
  );
  // @ts-ignore
  const {JOB_PORTAL_TARGET_POSITION} = useContext(RouteContext);
  const {openApplicationPopup} = useApplicationPopup();
  const isClinic = user?.user?.type === ROLES_NAMES.CLINIC;
  const {push} = useRouter();

  const onVacancyClick = useCallback(() => {
    push(JOB_PORTAL_TARGET_POSITION.getLink(slug));
  }, [slug]);

  const mappedWorkTypesNode = useMemo(() => {
    return workType?.map((singleWorkType) => (
      <div className={css.workTypeBlock} key={singleWorkType.value}>
        <Text
          fontSize={Text.FONT_SIZE.SIZE_12}
          fontWeight={Text.FONT_WEIGHT.BOLD}
        >
          {singleWorkType.label}
        </Text>
      </div>
    ));
  }, [workType]);

  return (
    <div
      className={cn(css.card, {
        [css.withoutOffset]: withoutOffset,
      })}
      onClick={onVacancyClick}
    >
      <div className={css.firstRow}>
        <div className={css.salaryBlock}>
          {Boolean(minSalary) && (
            <>
              <Text
                color={Text.COLOR.GREY}
                fontSize={Text.FONT_SIZE.SIZE_12}
                fontWeight={Text.FONT_WEIGHT.NORMAL}
              >
                <TextLocal id={'job-portal.min'} />
              </Text>
              <div className={css.minSalary}>
                <Text
                  fontSize={Text.FONT_SIZE.SIZE_16}
                  fontWeight={Text.FONT_WEIGHT.BOLD}
                >
                  {`${getCurrencySign(currency)} ${formatMinSalary(minSalary)}`}
                </Text>
              </div>
              <Text
                color={Text.COLOR.GREY}
                fontSize={Text.FONT_SIZE.SIZE_12}
                fontWeight={Text.FONT_WEIGHT.NORMAL}
              >
                <TextLocal id={'job-portal.per-year'} />
              </Text>
            </>
          )}
        </div>
        <div className={css.workTypesBlock}>{mappedWorkTypesNode}</div>
      </div>
      <div className={css.titleBlock}>
        <Text
          fontSize={Text.FONT_SIZE.SIZE_18}
          fontWeight={Text.FONT_WEIGHT.BOLD}
          lineHeight={Text.LINE_HEIGHT.LINE_HEIGHT_1_4}
        >
          {title}
        </Text>
      </div>
      <div>
        <div className={css.clinicNameBlock}>
          <div className={css.clinicIcon}>
            <Avatar
              size={'tiny'}
              organizationName={clinicName}
              picture={getClinicAvatar(clinicAvatar)}
            />
          </div>
          <Text
            fontSize={Text.FONT_SIZE.SIZE_12}
            lineHeight={Text.LINE_HEIGHT.LINE_HEIGHT_1_4}
          >
            {clinicName}
          </Text>
        </div>
        <div className={css.entryDate}>
          <div className={css.entryDateIcon}>
            <CalendarIcon width="20" height="22" />
          </div>
          <Text fontSize={Text.FONT_SIZE.SIZE_12}>
            <TextLocal id={'job-portal.entry-date'} />: <span>{entryDate}</span>
          </Text>
        </div>
        <div className={css.locationBlock}>
          <div className={css.locationIcon}>
            <Image
              src={`/icons/locationIcon.svg`}
              loading="lazy"
              alt="true"
              width="10"
              height="13"
            />
          </div>
          <Text fontSize={Text.FONT_SIZE.SIZE_12}>
            {`${cityName}, ${countryName}`}
          </Text>
        </div>
      </div>

      {!isClinic && (
        <div className={css.applyNowButton}>
          <Button
            isDisabled={isApplied}
            color={Button.COLOR.PRIMARY}
            height={Button.HEIGHT.SMALL}
            onClick={(event) =>
              openApplicationPopup(event, {
                positionId: id,
                positionTitle: title,
                clinicName,
                onSuccessfulApplicationCallback,
              })
            }
          >
            <TextLocal
              id={isApplied ? 'general.applied' : 'pricing.apply-now'}
            />
          </Button>
        </div>
      )}
      <div className={css.daysLeftBlock}>
        <Text
          color={getDaysLeftColor(daysBeforeExpire)}
          fontSize={Text.FONT_SIZE.SIZE_12}
          fontWeight={Text.FONT_WEIGHT.BOLD}
        >
          <TextLocal
            id={'job-portal.days-left'}
            params={{days: daysBeforeExpire}}
          />
        </Text>
      </div>
    </div>
  );
};

export default ClinicPositionMobileWidget;
