import {sbEditable} from '@storyblok/storyblok-editable';
import NextLink from 'next/link';

import DynamicComponent from '../../dynamicComponents/components/DynamicComponent';

interface LinkProps {
  blok: {
    target: string;
    href: string;
    body: any[];
  };
}
const Link = ({blok}: LinkProps) => {
  const {target, href, body} = blok;

  return (
    // @ts-ignore
    <NextLink href={href} target={target} {...sbEditable(blok)}>
      <a>
        {body
          ? body.map((blok) => <DynamicComponent blok={blok} key={blok._uid} />)
          : null}
      </a>
    </NextLink>
  );
};

export default Link;
