import useWindowSize from './useWindowSize';

const MAX_1400_WIDTH = 1400;
const MAX_1300_WIDTH = 1300;
const MAX_1200_WIDTH = 1200;
const MAX_1100_WIDTH = 1100;
const MAX_1000_WIDTH = 1000;
const MAX_900_WIDTH = 900;
const MAX_850_WIDTH = 850;
const MAX_750_WIDTH = 750;
const MAX_600_WIDTH = 600;
const MAX_400_WIDTH = 400;
const MAX_320_WIDTH = 320;
const MAX_480_WIDTH = 480;
const MAX_768_WIDTH = 768;
const MAX_992_WIDTH = 992;
const MAX_1280_WIDTH = 1280;
const MAX_1536_WIDTH = 1536;

const useIsMobileEnvironment = () => {
  const windowSize = useWindowSize();

  return {
    isMaxWidth1400: windowSize.width <= MAX_1400_WIDTH,
    isMaxWidth1300: windowSize.width <= MAX_1300_WIDTH,
    isMaxWidth1200: windowSize.width <= MAX_1200_WIDTH,
    isMaxWidth1100: windowSize.width <= MAX_1100_WIDTH,
    isMaxWidth1000: windowSize.width <= MAX_1000_WIDTH,
    isMaxWidth900: windowSize.width <= MAX_900_WIDTH,
    isMaxWidth850: windowSize.width <= MAX_850_WIDTH,
    isMaxWidth750: windowSize.width <= MAX_750_WIDTH,
    isMaxWidth600: windowSize.width <= MAX_600_WIDTH,
    isMaxWidth400: windowSize.width <= MAX_400_WIDTH,
    isMaxWidth320: windowSize.width <= MAX_320_WIDTH,
    isMaxWidth480: windowSize.width <= MAX_480_WIDTH,
    isMaxWidth768: windowSize.width <= MAX_768_WIDTH,
    isMaxWidth992: windowSize.width <= MAX_992_WIDTH,
    isMaxWidth1280: windowSize.width <= MAX_1280_WIDTH,
    isMaxWidth1536: windowSize.width <= MAX_1536_WIDTH,
  };
};

export default useIsMobileEnvironment;
