import {useTranslation} from 'next-i18next';
import {MouseEventHandler} from 'react';

import useIsMobile from '@/src/hooks/useIsMobile';
import getDataForVacancyItem from '@/src/jobPortal/allVacancies/styles/getDataForVacancyItem';
import ClinicPositionDesktopWidget from '@/src/profile/clinicTarget/components/ClinicPositionDesktopWidget';
import ClinicPositionMobileWidget from '@/src/profile/clinicTarget/components/ClinicPositionMobileWidget';
import TextLocal from '@/src/textLocal/containers/TextLocal';
import {Button, Carousel, Separator, Text} from '@/src/ui';

import css from '../styles/OtherClinicPositionsLayout.module.css';

interface OtherClinicPositionsLayoutProps {
  onSeeAllClick: MouseEventHandler<HTMLButtonElement>;
  positions: Array<any>;
  employmentTypes: Array<any>;
  locale: string;
  withSeeAllButton: boolean;
  onSuccessfulApplicationCallback: (...args: any) => any;
}

const OtherClinicPositionsLayout = ({
  onSeeAllClick,
  positions,
  employmentTypes,
  locale,
  withSeeAllButton,
  onSuccessfulApplicationCallback,
}: OtherClinicPositionsLayoutProps) => {
  const {isMaxWidth900} = useIsMobile();
  const {t} = useTranslation('common');

  return (
    <>
      <div className={css.otherPositionsWrapper}>
        <Separator borderWeight={Separator.BORDER_WEIGHT.THIN} />
      </div>
      <div>
        <div className={css.title}>
          <Text
            fontSize={Text.FONT_SIZE.SIZE_24}
            fontWeight={Text.FONT_WEIGHT.BOLD}
          >
            <TextLocal id={'job-portal.our-other-positions'} />
          </Text>
        </div>

        <div className={css.positionsWrapper}>
          {isMaxWidth900 ? (
            <div>
              <Carousel
                offset={Carousel.OFFSET.TINY}
                offsetY={Carousel.OFFSET_Y.NONE}
                slideGap={Carousel.SLIDE_GAP.SLIDE_GAP_0}
                settings={{
                  slidesPerView: 'auto',
                  loop: false,
                  pagination: false,
                  navigation: false,
                  enabled: true,
                }}
              >
                {positions.map((position) => {
                  return (
                    <div key={position.id} className={css.mobilePositions}>
                      <ClinicPositionMobileWidget
                        key={position.id}
                        {...getDataForVacancyItem(
                          position,
                          position.clinic,
                          employmentTypes,
                          locale,
                          t
                        )}
                        onSuccessfulApplicationCallback={
                          onSuccessfulApplicationCallback
                        }
                      />
                    </div>
                  );
                })}
              </Carousel>
            </div>
          ) : (
            <div className={css.positionsList}>
              {positions.map((position) => (
                <ClinicPositionDesktopWidget
                  key={position.id}
                  {...getDataForVacancyItem(
                    position,
                    position.clinic,
                    employmentTypes,
                    locale,
                    t
                  )}
                  onSuccessfulApplicationCallback={
                    onSuccessfulApplicationCallback
                  }
                />
              ))}
            </div>
          )}
        </div>

        {withSeeAllButton && (
          <div className={css.buttons}>
            <Button
              onClick={onSeeAllClick}
              height={Button.HEIGHT.SMALL}
              color={Button.COLOR.SECONDARY}
            >
              <TextLocal id={'profile.see-all'} />
            </Button>
          </div>
        )}
      </div>
    </>
  );
};

export default OtherClinicPositionsLayout;
