import lgVideo from 'lightgallery/plugins/video';
import lgZoom from 'lightgallery/plugins/zoom';
import dynamic from 'next/dynamic';
import Props from 'prop-types';
import {useCallback, useRef} from 'react';
import ReactPlayer from 'react-player';

import VideoFile from '@/src/howTo/components/fileWithPreview/VideoFile';

import css from '../styles/FeaturesSection.module.css';

const VIMEO = 'https://vimeo.com/';
const VIDEO_FORMATS = ['.mp4', '.webm', '.ogg'];

const LightGallery = dynamic(() => import('lightgallery/react'), {
  ssr: false,
});

const VideoProvider = ({children}) => (
  <div className={css.slide}>
    <div className={css.slideMedia}>
      <div className={css.videoWrapper}>
        <div className={css.video}>{children}</div>
      </div>
    </div>
  </div>
);

const FeatureSliderItem = ({src}) => {
  const lightGalleryRef = useRef(null);

  const handleOpenGallery = useCallback(() => {
    if (!lightGalleryRef.current) return;
    lightGalleryRef.current.click();
  }, [lightGalleryRef, src]);

  if (src.includes(VIMEO)) {
    return (
      <VideoProvider>
        <div className={css.vimeoVideo}>
          <ReactPlayer url={src} width={'100%'} height={'auto'} controls />
        </div>
      </VideoProvider>
    );
  }

  if (VIDEO_FORMATS.some((format) => src.includes(format))) {
    return (
      <VideoProvider>
        <VideoFile src={src} withDownloadButton={false} />
      </VideoProvider>
    );
  }

  return (
    <div className={css.slide} onClick={handleOpenGallery}>
      <div className={css.slideMedia}>
        <LightGallery
          licenseKey={process.env.NEXT_PUBLIC_LIGHTGALLERY_LICENSE_KEY}
          speed={400}
          plugins={[lgZoom, lgVideo]}
          mobileSettings={{
            closable: true,
          }}
          download={false}
          zoom={false}
          counter={false}
        >
          <a href={src} aria-label={'zoom image'}>
            <img loading="lazy" alt="image" src={src} />
          </a>
        </LightGallery>
      </div>
    </div>
  );
};

FeatureSliderItem.propTypes = {
  src: Props.string.isRequired,
};

VideoProvider.propTypes = {
  children: Props.node.isRequired,
};

export default FeatureSliderItem;
