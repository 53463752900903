import {REST_METHODS} from '@/src/app/constants/api';
import authorizedFetch from '@/src/app/utils/authorizedFetch';

const postSupportMessage = (message) => {
  // @ts-ignore
  return authorizedFetch({
    method: REST_METHODS.POST,
    path: `/api/v1/support_messages`,
    body: {
      message,
    },
  });
};

export default postSupportMessage;
