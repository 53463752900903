import cn from 'clsx';
import Image from 'next/image';
import {useTranslation} from 'next-i18next';
import PropTypes from 'prop-types';
import ReactCountryFlag from 'react-country-flag';

import {
  PLACE_SELECTS_WIDTH,
  SIZE,
} from '@/src/signUp/emailStep/constants/selectsWidthConstants';
import TextLocal from '@/src/textLocal/containers/TextLocal';
import {Text} from '@/src/ui';

import css from '../styles/CountriesSelectLayout.module.css';

const CountriesSelectLayout = ({
  error,
  setIsSelectOpened,
  inputValue,
  onInputChange,
  selectRef,
  onSelectItemClick,
  countriesDictionary,
  isSelectOpened,
  field,
  isRequired,
  selectedIsoCode,
  width,
  countryFieldName,
  size,
}) => {
  const {t} = useTranslation(TextLocal.LOCALE_SOURCE.COMMON);

  return (
    <div ref={selectRef} className={css.widget}>
      <div
        className={cn(
          css.inputBlock,
          css[`${width}InputBlock`],
          css[size],
          Boolean(error) && css.inputWithError
        )}
      >
        {field.value && (
          <div className={css.inputFlag}>
            <ReactCountryFlag countryCode={selectedIsoCode} />
          </div>
        )}
        {isRequired && <div className={css.requiredIcon}>*</div>}
        <input
          className={css.inputField}
          value={inputValue}
          onChange={onInputChange}
          onFocus={() => setIsSelectOpened(true)}
          placeholder={t('paymentDetails.country')}
          autoComplete="none"
          role={'country select'}
        />
        <div
          className={cn(css.dropDownIcon, {
            [css.dropDownIconReversed]: isSelectOpened,
          })}
          onClick={() => setIsSelectOpened(true)}
        >
          <Image
            src={`/icons/dropDownIndicator.svg`}
            loading="lazy"
            alt="true"
            width="10"
            height="10"
          />
        </div>
      </div>

      {Boolean(error?.length) && (
        <div className={css.errorField}>
          <Text color={Text.COLOR.RED} fontSize={Text.FONT_SIZE.SIZE_12}>
            {error}
          </Text>
        </div>
      )}

      {isSelectOpened && (
        <div className={cn(css.selectBlockWrapper, css[`${width}SelectBlock`])}>
          <div className={css.selectBlock}>
            {!countriesDictionary?.length && (
              <div className={css.noResultsItem}>
                <Text fontSize={Text.FONT_SIZE.SIZE_16} color={Text.COLOR.GREY}>
                  <TextLocal id={'profile.no-results'} />
                </Text>
              </div>
            )}
            {countriesDictionary.map((countryItem) => (
              <div
                className={cn(css.selectItem)}
                key={countryItem.id}
                onClick={() => onSelectItemClick(countryItem)}
              >
                <div className={cn(css.selectItemContent)}>
                  <div className={css.flagIcon}>
                    <ReactCountryFlag countryCode={countryItem.iso2_code} />
                  </div>
                  <span className={css.skillName}>
                    {countryItem[countryFieldName]}
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

CountriesSelectLayout.propTypes = {
  countryFieldName: PropTypes.string.isRequired,
  width: PropTypes.oneOf(Object.values(PLACE_SELECTS_WIDTH)),
  size: PropTypes.oneOf(Object.values(SIZE)),
  error: PropTypes.string,
  setIsSelectOpened: PropTypes.func.isRequired,
  inputValue: PropTypes.string,
  onInputChange: PropTypes.func.isRequired,
  selectRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  onSelectItemClick: PropTypes.func.isRequired,
  countriesDictionary: PropTypes.array,
  isSelectOpened: PropTypes.bool.isRequired,
  field: PropTypes.object,
  isRequired: PropTypes.bool,
  selectedIsoCode: PropTypes.string,
};

CountriesSelectLayout.defaultProps = {
  width: PLACE_SELECTS_WIDTH.WIDTH_390PX,
};

export default CountriesSelectLayout;
