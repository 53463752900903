import PropTypes from 'prop-types';

import useIsMobile from '@/src/hooks/useIsMobile';

import {Text} from '../../../ui';
import css from '../../styles/SectionDoubleColumnsLayout.module.css';

const SectionDoubleColumnsLayout = ({
  title,
  subtitle,
  rightSideContent,
  actions,
}) => {
  const {isMaxWidth900} = useIsMobile();

  return (
    <section className={css.section}>
      <div className={css.container}>
        <div className={css.content}>
          <div className={css.title}>
            <Text
              fontSize={
                isMaxWidth900 ? Text.FONT_SIZE.SIZE_28 : Text.FONT_SIZE.SIZE_40
              }
              fontWeight={Text.FONT_WEIGHT.BOLD}
              fontFamily={Text.FONT_FAMILY.RUBIK}
              tagComponent={Text.TAG.H2}
            >
              {title}
            </Text>
          </div>
          <div className={css.subtitle}>
            <Text
              color={Text.COLOR.SECONDARY}
              fontSize={Text.FONT_SIZE.SIZE_18}
              lineHeight={Text.LINE_HEIGHT.LINE_HEIGHT_1_6}
            >
              {subtitle}
            </Text>
          </div>
          {!isMaxWidth900 && <div className={css.controls}>{actions}</div>}
        </div>
        <div className={css.slider}>{rightSideContent}</div>
        {isMaxWidth900 && <div className={css.controls}>{actions}</div>}
      </div>
    </section>
  );
};

SectionDoubleColumnsLayout.propTypes = {
  title: PropTypes.element,
  subtitle: PropTypes.element,
  rightSideContent: PropTypes.node,
  actions: PropTypes.node,
};

SectionDoubleColumnsLayout.defaultProps = {
  seeAllUrl: '#',
  showLogo: false,
};

export default SectionDoubleColumnsLayout;
