import cn from 'clsx';
import Link from 'next/link';
import NavLink from 'next/link';
import {useRouter} from 'next/router';
import {pathOr} from 'ramda';
import {useMemo} from 'react';

import {ROLES_NAMES} from '@/src/app/constants/roles';
import {
  ORDER_BY_FILTER,
  ORDER_FILTER,
  SEARCH_FILTERS,
} from '@/src/app/constants/search';
import useUserActions from '@/src/app/store/user/useUserActions';
import useRoutePaths from '@/src/hooks/useRoutePaths';
import TextLocal from '@/src/textLocal/containers/TextLocal';

import {Text} from '../../ui';
import css from '../styles/menu.module.css';

const Menu = ({isRootPage}) => {
  const {asPath: pathname, locale, push} = useRouter();
  const {
    user: {permissions, user},
  } = useUserActions();

  const isClinic = useMemo(
    () => user?.type === ROLES_NAMES.CLINIC,
    [user?.type]
  );

  const isNotApprovedClinic = useMemo(
    () => !(isClinic && !permissions.accessCreatePosition),
    [isClinic, permissions?.accessCreatePosition]
  );

  const {
    SEARCH_COURSES,
    CREATOR_STUDIO,
    DISCOVER,
    WATCHLIST_ROUTE,
    WATCHLIST_BOOKMARKED_ROUTE,
    JOB_PORTAL,
    JOB_PORTAL_MY_POSITIONS,
    SEARCH_ALL_POSITIONS_ROUTE,
    JOB_PORTAL_MY_APPLIES,
    SEARCH_VACANCIES,
    ADVISER_BOARD,
  } = useRoutePaths();

  const isAuthorized = pathOr(false, ['id'], user);

  const menuListNode = useMemo(() => {
    return [
      {
        path: DISCOVER.getLink(),
        title: 'menu.discover',
        isActive:
          pathname.includes(DISCOVER.getLink()) ||
          pathname.includes(ADVISER_BOARD.getLink()),
        isDropdown: true,
        isClickable: true,
        dropdownList: [
          {
            path: ADVISER_BOARD?.getLink() || '#',
            title: 'menu.our-adviser-bord',
          },
        ],
      },
      {
        path: `${SEARCH_COURSES.getLink()}?${SEARCH_FILTERS.OFFSET}=0&${
          SEARCH_FILTERS.ORDER
        }=${ORDER_FILTER.DESC}&${SEARCH_FILTERS.ORDER_BY}=${
          ORDER_BY_FILTER.APPROVED_AT
        }&${SEARCH_FILTERS.LANGUAGE}=${locale}`,
        title: 'menu.all-videos',
        isActive: pathname.includes(SEARCH_COURSES.getLink()),
      },
      ...(isAuthorized
        ? [
            {
              path: `${WATCHLIST_BOOKMARKED_ROUTE.getLink()}?${
                SEARCH_FILTERS.OFFSET
              }=0&${SEARCH_FILTERS.ORDER}=${ORDER_FILTER.DESC}&${
                SEARCH_FILTERS.ORDER_BY
              }=${ORDER_BY_FILTER.APPROVED_AT}&${
                SEARCH_FILTERS.LANGUAGE
              }=${locale}`,
              title: 'menu.watchlist',
              isActive: pathname.includes(WATCHLIST_ROUTE.getLink()),
            },
          ]
        : []),
      ...(permissions?.accessCreatorStudioCourseList
        ? [
            {
              path: CREATOR_STUDIO.getLink(),
              title: 'menu.creator-studio',
              isActive: pathname.includes(CREATOR_STUDIO.getLink()),
            },
          ]
        : []),
      ...(isClinic
        ? [
            {
              path: JOB_PORTAL.getLink(),
              title: 'menu.job-portal',
              isActive:
                pathname.includes(JOB_PORTAL.getLink()) ||
                pathname.includes(SEARCH_ALL_POSITIONS_ROUTE?.getLink()),
              isDropdown: true,
              dropdownList: [
                {
                  path: SEARCH_ALL_POSITIONS_ROUTE?.getLink() || '#',
                  title: 'menu.job-portal-search',
                },
                {
                  path: JOB_PORTAL_MY_POSITIONS.getLink(),
                  title: 'job-portal.my-positions',
                },
              ],
            },
          ]
        : [
            {
              path: !isNotApprovedClinic
                ? SEARCH_VACANCIES.getLink()
                : JOB_PORTAL.getLink(),
              title: 'menu.job-portal',
              isActive:
                pathname.includes(JOB_PORTAL.getLink()) ||
                pathname.includes(SEARCH_VACANCIES.getLink()) ||
                pathname.includes(SEARCH_ALL_POSITIONS_ROUTE?.getLink()),
              isDropdown: isNotApprovedClinic,
              dropdownList: [
                {
                  path: SEARCH_VACANCIES.getLink(),
                  title: 'menu.job-portal-search',
                },
                {
                  path: JOB_PORTAL_MY_APPLIES.getLink(),
                  title: 'job-portal.my-applications',
                },
              ],
            },
          ]),
    ];
  }, [
    isAuthorized,
    pathname,
    locale,
    isNotApprovedClinic,
    permissions?.accessCreatorStudioCourseList,
    permissions?.accessCreatePosition,
  ]);

  return (
    <div className={css.menuWrapper}>
      <ul className={css.menu}>
        {menuListNode.map((menu) => {
          const {isActive} = menu;

          return (
            <li
              key={menu.path}
              className={cn(css.linkItem, {
                [css.withoutLink]: menu.isDropdown,
              })}
            >
              {menu.isDropdown ? (
                <div className={css.dropdownMenu}>
                  <div
                    onClick={
                      menu.isClickable
                        ? () => {
                            push({
                              pathname: menu.path,
                            });
                          }
                        : undefined
                    }
                    className={cn(css.link, css.menuItemWithArrow, {
                      [css.activeMenu]: isActive,
                      [css.whiteIcon]: isRootPage,
                    })}
                  >
                    <Text
                      color={isRootPage ? Text.COLOR.WHITE : Text.COLOR.PRIMARY}
                      fontSize={Text.FONT_SIZE.SIZE_16}
                      fontWeight={
                        isActive
                          ? Text.FONT_WEIGHT.BOLD
                          : Text.FONT_WEIGHT.NORMAL
                      }
                    >
                      <TextLocal id={menu.title} />
                    </Text>
                  </div>
                  <div className={css.dropdownWrapper}>
                    <ul className={css.dropdownList}>
                      {menu.dropdownList.map((menu) => (
                        <li
                          key={menu.path}
                          className={cn(css.dropdownListItem, {
                            [css.dropdownListItemActive]: pathname.includes(
                              menu.path
                            ),
                          })}
                        >
                          <NavLink href={menu.path}>
                            <a>
                              <TextLocal id={menu.title} />
                            </a>
                          </NavLink>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              ) : (
                <Link href={menu.path}>
                  <a
                    className={cn(css.link, {
                      [css.activeMenu]: isActive,
                    })}
                  >
                    <Text
                      color={isRootPage ? Text.COLOR.WHITE : Text.COLOR.PRIMARY}
                      fontSize={Text.FONT_SIZE.SIZE_17}
                      fontWeight={
                        isActive
                          ? Text.FONT_WEIGHT.BOLD
                          : Text.FONT_WEIGHT.NORMAL
                      }
                    >
                      <TextLocal id={menu.title} />
                    </Text>
                  </a>
                </Link>
              )}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default Menu;
