import cn from 'clsx';
import {useField} from 'formik';
import Image from 'next/image';
import PropTypes from 'prop-types';

import css from '../styles/Checkbox.module.css';

const MAX_TEXT_WIDTH = {
  WIDTH_362: 'width362',
};

const SIZE = {
  medium: '19',
  small: '14',
};

const Checkbox = ({
  name,
  label,
  maxTextWidth,
  isLabelPrimary,
  isDisabled,
  size,
}) => {
  // eslint-disable-next-line
  const [field, meta, helpers] = useField(name);
  const error = meta.touched && meta.error;

  return (
    <>
      <div
        className={cn(css.checkboxBlock, {
          [css.checkboxDisabled]: isDisabled,
        })}
        onClick={() => {
          helpers.setValue(!meta.value);
        }}
      >
        <div className={css.iconBlock}>
          <Image
            src={`/icons/${meta.value ? 'selected' : 'unselected'}Checkbox.svg`}
            loading="lazy"
            alt="true"
            width={SIZE[size]}
            height={SIZE[size]}
          />
        </div>
        <div
          className={cn(
            css.label,
            css[maxTextWidth],
            isLabelPrimary && css.labelPrimary
          )}
        >
          <div>{label}</div>
        </div>
      </div>
      {Boolean(error) && <div className={css.errorField}>{error}</div>}
    </>
  );
};

Checkbox.MAX_TEXT_WIDTH = MAX_TEXT_WIDTH;
Checkbox.SIZE = SIZE;

Checkbox.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  maxTextWidth: PropTypes.oneOf(Object.values(MAX_TEXT_WIDTH)),
  isLabelPrimary: PropTypes.bool,
  isDisabled: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'medium']),
};

Checkbox.defaultProps = {
  maxTextWidth: MAX_TEXT_WIDTH.WIDTH_362,
  isLabelPrimary: false,
  isDisabled: false,
  size: 'small',
};

export default Checkbox;
