import {sbEditable} from '@storyblok/storyblok-editable';
import {useRouter} from 'next/router';
import PropTypes from 'prop-types';
import {useCallback, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {RESPONSE_STATUSES} from '@/src/app/constants/api';
import {
  selectPopularCourses,
  setPopularCourses,
} from '@/src/app/store/promoSections/promoSectionsSlice';
import transforCoursesData from '@/src/app/utils/transforCoursesData';

import DynamicComponent from '../../../dynamicComponents/components/DynamicComponent';
import {Carousel, CourseCard} from '../../../ui';
import SectionDoubleColumnsLayout from '../../components/SectionDoubleColumnsLayout/SectionDoubleColumnsLayout.storyblok';
import Styles from '../../styles/PoplarCoursesSection.module.css';
import getPopularCourses from '../../utils/getPopularCourses';

const PopularCoursesSection = ({blok}) => {
  const {title, subTitle, actions} = blok;

  const popularCourses = useSelector(selectPopularCourses);
  const {locale} = useRouter();
  const dispatch = useDispatch();

  const requestGetCourse = useCallback(async () => {
    const {status, responseBody} = await getPopularCourses(locale);

    if (status === RESPONSE_STATUSES.SUCCESS) {
      dispatch(
        setPopularCourses(transforCoursesData(responseBody?.data, locale))
      );
    }
  }, []);

  useEffect(() => {
    !popularCourses.length && requestGetCourse();
  }, []);

  return (
    <SectionDoubleColumnsLayout
      {...sbEditable(blok)}
      title={title}
      subtitle={subTitle}
      actions={
        actions ? (
          <div className={Styles.buttonActions}>
            {actions.map((blok) => (
              <div key={blok._uid}>
                <DynamicComponent blok={blok} />
              </div>
            ))}
          </div>
        ) : null
      }
      rightSideContent={
        popularCourses.length > 0 ? (
          <Carousel
            slideGap={Carousel.SLIDE_GAP.SLIDE_GAP_WITH_COUNTER}
            settings={{
              loop: true,
            }}
            navigationPosition={Carousel.NAVIGATION_POSITION.BOTTOM}
          >
            {popularCourses.map((item, i) => (
              <CourseCard {...item} cardNumber={i + 1} key={item.id} />
            ))}
          </Carousel>
        ) : null
      }
    />
  );
};

PopularCoursesSection.propTypes = {
  blok: PropTypes.object.isRequired,
};

export default PopularCoursesSection;
