import {REST_METHODS} from '@/src/app/constants/api';
import {getAuthorizationToken} from '@/src/app/utils/authorizationToken';
import authorizedFetch from '@/src/app/utils/authorizedFetch';
import unauthorizedFetch from '@/src/app/utils/unauthorizedFetch';

const getImagesClinicGallery = async (clinicId) => {
  const requestMethod = getAuthorizationToken()
    ? authorizedFetch
    : unauthorizedFetch;

  // @ts-ignore
  return requestMethod({
    method: REST_METHODS.GET,
    path: '/api/v1/clinics/images',
    queryParams: [
      {
        name: 'clinic_id',
        value: clinicId,
      },
    ],
  });
};

export default getImagesClinicGallery;
