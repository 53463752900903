import CheckMarkIcon from '@/public/icons/checkmark.svg';
import css from '@/src/promoSections/styles/PricingSection.module.css';
import {Text} from '@/src/ui';

interface PriceFeaturesTableProps {
  features: any;
}

const PriceFeaturesTable = ({features}: PriceFeaturesTableProps) => {
  return features.map((item, index) => (
    <div key={index} className={css.priceFeaturesColumn}>
      {item ? (
        <div className={css.featureText}>
          <span className={css.featureicon}>
            <CheckMarkIcon />
          </span>{' '}
          <Text fontSize={Text.FONT_SIZE.SIZE_16}>{item}</Text>
        </div>
      ) : (
        <div className={css.featureEmptyWrapper}>
          <span className={css.featureEmpty} />
        </div>
      )}
    </div>
  ));
};

export default PriceFeaturesTable;
