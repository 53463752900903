import Link from 'next/link';
import PropTypes from 'prop-types';

import useRoutePaths from '@/src/hooks/useRoutePaths';
import TextLocal from '@/src/textLocal/containers/TextLocal';

import {Separator, Text} from '../../ui';
import css from '../styles/ProfileDesktopMenuLayout.module.css';

const ProfileDesktopMenuLayout = ({
  picture,
  fullName,
  userInitials,
  onSupportClick,
  onSignOutClick,
}) => {
  const {MY_PROFILE_ABOUT_ME, MY_PROFILE_ACCOUNT_SETTINGS} = useRoutePaths();

  return (
    <div className={css.container}>
      <div className={css.arrow} />
      <div className={css.widget}>
        <Link href={MY_PROFILE_ABOUT_ME.getLink()}>
          <a>
            <div className={css.widgetHeader}>
              <div className={css.userAvatar}>
                {picture ? (
                  <img
                    src={picture}
                    loading="lazy"
                    alt={'profile image'}
                    className={css.profileAvatar}
                  />
                ) : (
                  <Text fontWeight={Text.FONT_WEIGHT.BOLD}>{userInitials}</Text>
                )}
              </div>
              <div className={css.fullNameBlock}>
                <Text
                  fontSize={Text.FONT_SIZE.SIZE_14}
                  color={Text.COLOR.PRIMARY}
                >
                  {fullName}
                </Text>
              </div>
            </div>
          </a>
        </Link>
        <Separator borderWeight={Separator.BORDER_WEIGHT.THIN} />
        <div className={css.widgetBody}>
          <Link href={MY_PROFILE_ACCOUNT_SETTINGS.getLink()}>
            <a>
              <Text
                color={Text.COLOR.PRIMARY}
                fontSize={Text.FONT_SIZE.SIZE_16}
              >
                <TextLocal id={'header.settings'} />
              </Text>
            </a>
          </Link>
          <div onClick={onSupportClick}>
            <Text color={Text.COLOR.PRIMARY} fontSize={Text.FONT_SIZE.SIZE_16}>
              <TextLocal id={'header.support'} />
            </Text>
          </div>
        </div>
        <Separator borderWeight={Separator.BORDER_WEIGHT.THIN} />
        <div className={css.widgetFooter}>
          <div onClick={onSignOutClick}>
            <Text color={Text.COLOR.RED} fontSize={Text.FONT_SIZE.SIZE_16}>
              <TextLocal id={'header.sign-out'} />
            </Text>
          </div>
        </div>
      </div>
    </div>
  );
};

ProfileDesktopMenuLayout.propTypes = {
  onSupportClick: PropTypes.func.isRequired,
  picture: PropTypes.string,
  fullName: PropTypes.string.isRequired,
  userInitials: PropTypes.string.isRequired,
  onSignOutClick: PropTypes.func.isRequired,
};

export default ProfileDesktopMenuLayout;
