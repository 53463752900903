import {useRouter} from 'next/router';
import {useTranslation} from 'next-i18next';
import PropTypes from 'prop-types';
import {useCallback, useContext, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {RESPONSE_STATUSES} from '@/src/app/constants/api';
import {ROLES_NAMES} from '@/src/app/constants/roles';
import {
  selectClinicData,
  setClinicData,
} from '@/src/app/store/clinic/clinicDataSlice';
import useUserActions from '@/src/app/store/user/useUserActions';
import getRootServerUrl from '@/src/app/utils/getRootServerUrl';
import useRoutePaths from '@/src/hooks/useRoutePaths';
import PopupContext from '@/src/popup/utils/PopupContext';
import fetchClinicData from '@/src/signUp/additionalInfo/utils/fetchClinicData';
import fetchUserAction from '@/src/signUp/paymentDetails/utils/fetchUserAction';

import MyProfileWrapperLayout from '../components/MyProfileWrapperLayout';
import EditProfileImagePopup from './EditProfileImagePopup';

export const getUserInitials = (
  {user: {first_name, last_name}},
  isClinic,
  clinicData
) => {
  if (isClinic) {
    const organizationNameByWords =
      clinicData?.clinicData?.organization_name.split(' ');
    let clinicInitials = '';

    if (!organizationNameByWords) {
      return '';
    }

    clinicInitials += organizationNameByWords[0].charAt(0).toUpperCase();
    clinicInitials +=
      organizationNameByWords.length > 1
        ? organizationNameByWords[1].charAt(0).toUpperCase()
        : '';

    return clinicInitials;
  }

  let firstLetter = '';
  let secondLetter = '';

  if (first_name) {
    firstLetter = first_name.charAt(0).toUpperCase();
  }

  if (secondLetter) {
    secondLetter = last_name.charAt(0).toUpperCase();
  }

  return `${firstLetter}${secondLetter}`;
};

export const getUserFullName = (
  {user: {first_name, last_name}},
  isClinic,
  clinicData,
  prefixedAcademicTitle
) => {
  if (isClinic) {
    return clinicData?.clinicData?.organization_name;
  }

  if (prefixedAcademicTitle) {
    return `${prefixedAcademicTitle} ${first_name} ${last_name}`;
  }

  return `${first_name} ${last_name}`;
};

export const getUserSubheader = (
  t,
  userData,
  isClinic,
  clinicData,
  suffixedAcademicTitle
) => {
  if (isClinic) {
    return clinicData.clinicData.website || '';
  }

  let userTypeText;
  let userUniversityText;
  let userCompanyText;

  switch (userData.user.type) {
    case ROLES_NAMES.COURSE_CREATOR: {
      userTypeText = t('userType.content-creator');
      break;
    }
    case ROLES_NAMES.CUSTOMER: {
      userTypeText = t('userType.normal-user');
      break;
    }
    case ROLES_NAMES.DOCTOR: {
      userTypeText = t('userType.doctor');
      break;
    }
    case ROLES_NAMES.STUDENT: {
      userTypeText = t('userType.student');
    }
  }

  userUniversityText =
    Boolean(userData?.last_education) && `${userData.last_education.school}`;

  userCompanyText =
    Boolean(userData?.last_professional_experience) &&
    `${userData.last_professional_experience.organization}`;

  if (userUniversityText || userCompanyText) {
    return userCompanyText || userUniversityText || '';
  }

  if (suffixedAcademicTitle) {
    return suffixedAcademicTitle;
  }

  return userTypeText;
};

const getPicture = (isClinic, userData, clinicData) => {
  if (isClinic) {
    return clinicData.clinicData.picture
      ? `${getRootServerUrl()}${clinicData.clinicData.picture}`
      : null;
  }

  return userData.profile.picture
    ? `${getRootServerUrl()}${userData.profile.picture}`
    : null;
};

const getCoverImage = (isClinic, userData, clinicData) => {
  if (isClinic) {
    return clinicData.clinicData.cover_image
      ? `${getRootServerUrl()}${clinicData.clinicData.cover_image}`
      : null;
  }

  return userData.profile.cover_image
    ? `${getRootServerUrl()}${userData.profile.cover_image}`
    : null;
};

const MyProfileWrapper = ({children}) => {
  const dispatch = useDispatch();
  const {t} = useTranslation('common');
  const {push, asPath: currentPath} = useRouter();
  const {user: userData, setUserData} = useUserActions();
  const {openPopup} = useContext(PopupContext);
  const {USER_PROFILE, SIGN_IN_ROUTE, CLINIC_TARGET_PROFILE} = useRoutePaths();
  const clinicData = useSelector(selectClinicData);

  useEffect(() => {
    if (userData.user.id === null) {
      return null;
    }

    const fetchClinic = async () => {
      const {status, responseBody} = await fetchClinicData();

      if (status === RESPONSE_STATUSES.SUCCESS) {
        dispatch(
          setClinicData({
            clinicData: responseBody,
            isFetched: true,
          })
        );
      }
    };

    if (userData.user.type === ROLES_NAMES.CLINIC && !clinicData.isFetched) {
      fetchClinic();
    }
  }, [userData, clinicData]);

  const handleNavbarLinkClick = useCallback(
    (link) => {
      push(link);
    },
    [push]
  );

  const handleVisitMyProfileClick = useCallback(() => {
    if (userData.user.type === ROLES_NAMES.CLINIC) {
      push(CLINIC_TARGET_PROFILE.getLink(userData.clinic.slug));
    } else {
      push(USER_PROFILE.getLink(userData.user.username));
    }
  }, [userData, push]);

  const handleEditProfileImageClick = useCallback(() => {
    // @ts-ignore
    openPopup({
      popup: (
        <EditProfileImagePopup
          image={
            userData.user.type === ROLES_NAMES.CLINIC
              ? clinicData.clinicData.original_picture
              : userData.profile.original_picture
          }
          type={EditProfileImagePopup.TYPES.PICTURE}
          isClinic={userData.user.type === ROLES_NAMES.CLINIC}
          clinicId={clinicData?.clinicData?.id}
        />
      ),
      options: {withCloseButton: true},
    });
  }, [openPopup, userData, clinicData]);

  const handleEditCoverImageClick = useCallback(() => {
    // @ts-ignore
    openPopup({
      popup: (
        <EditProfileImagePopup
          image={
            userData.user.type === ROLES_NAMES.CLINIC
              ? clinicData.clinicData.original_cover_image
              : userData.profile.original_cover_image
          }
          type={EditProfileImagePopup.TYPES.COVER_IMAGE}
          isClinic={userData.user.type === ROLES_NAMES.CLINIC}
          clinicId={clinicData?.clinicData?.id}
        />
      ),
      options: {withCloseButton: true},
    });
  }, [openPopup, userData, clinicData]);

  useEffect(() => {
    const fetchUser = async () => {
      if (userData.user.id === null) {
        const {status, responseBody} = await fetchUserAction();

        if (status === RESPONSE_STATUSES.SUCCESS) {
          setUserData(responseBody);
        } else {
          push(SIGN_IN_ROUTE.getLink());
        }
      }
    };

    fetchUser();
  }, [userData]);

  if (
    userData.user.id === null ||
    (userData.user.type === ROLES_NAMES.CLINIC && !clinicData.isFetched)
  ) {
    return null;
  }

  return (
    <MyProfileWrapperLayout
      isCustomer={userData.user.type === ROLES_NAMES.CUSTOMER}
      isClinic={userData.user.type === ROLES_NAMES.CLINIC}
      isContentCreator={userData.user.type === ROLES_NAMES.COURSE_CREATOR}
      isOpenForNewOpportunities={userData?.profile?.open_for_job}
      userId={userData.user.id}
      userInitials={getUserInitials(
        userData,
        userData.user.type === ROLES_NAMES.CLINIC,
        clinicData
      )}
      userFullName={getUserFullName(
        userData,
        userData.user.type === ROLES_NAMES.CLINIC,
        clinicData,
        userData?.profile?.prefixed_academic_title
      )}
      userSubheader={getUserSubheader(
        t,
        userData,
        userData.user.type === ROLES_NAMES.CLINIC,
        clinicData,
        userData?.profile?.suffixed_academic_title
      )}
      onVisitMyProfileClick={handleVisitMyProfileClick}
      currentPath={currentPath}
      onNavbarLinkClick={handleNavbarLinkClick}
      onEditProfileImageClick={handleEditProfileImageClick}
      onEditCoverImageClick={handleEditCoverImageClick}
      picture={getPicture(
        userData.user.type === ROLES_NAMES.CLINIC,
        userData,
        clinicData
      )}
      coverImage={getCoverImage(
        userData.user.type === ROLES_NAMES.CLINIC,
        userData,
        clinicData
      )}
    >
      {children}
    </MyProfileWrapperLayout>
  );
};

MyProfileWrapper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default MyProfileWrapper;
