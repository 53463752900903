import {sbEditable} from '@storyblok/storyblok-editable';
import PropTypes from 'prop-types';

import useIsMobile from '@/src/hooks/useIsMobile';

import {Text} from '../../ui';
import css from '../styles/AboutMedyoucateSection.module.css';
import ForWhom from './ForWhom';

const AboutMedyoucateSection = ({blok}) => {
  const {isMaxWidth900} = useIsMobile();

  return (
    <div className={css.wrapper} {...sbEditable(blok)} id={blok.id}>
      <div className={css.aboutMedyoucate}>
        <div className={css.title}>
          <Text
            fontSize={
              isMaxWidth900 ? Text.FONT_SIZE.SIZE_28 : Text.FONT_SIZE.SIZE_40
            }
            fontFamily={Text.FONT_FAMILY.RUBIK}
            fontWeight={Text.FONT_WEIGHT.BOLD}
            lineHeight={Text.LINE_HEIGHT.LINE_HEIGHT_1_4}
            tagComponent={Text.TAG.H2}
          >
            {blok.title}
          </Text>
        </div>
        <div className={css.description}>
          <Text
            fontSize={Text.FONT_SIZE.SIZE_18}
            color={Text.COLOR.GREY}
            lineHeight={Text.LINE_HEIGHT.LINE_HEIGHT_1_6}
            fontWeight={Text.FONT_WEIGHT.NORMAL}
          >
            {blok.description}
          </Text>
        </div>
      </div>

      {blok.list.map(
        ({
          title,
          subTitle,
          description,
          image,
          vimeoVideo,
          video,
          isReverse,
        }) => (
          <ForWhom
            key={title}
            isReverse={isReverse === 'true'}
            image={image || video?.filename || vimeoVideo}
            title={title}
            subTitle={subTitle}
            description={description}
          />
        )
      )}
    </div>
  );
};

AboutMedyoucateSection.propTypes = {
  blok: PropTypes.object.isRequired,
};

export default AboutMedyoucateSection;
