import {useField} from 'formik';
import Image from 'next/image';

import css from '../styles/RadioButton.module.css';

interface RadioButtonProps {
  name: string;
  label: string;
  option: string;
}

const RadioButton = ({name, label, option}: RadioButtonProps) => {
  // eslint-disable-next-line
  const [field, meta, helpers] = useField(name);

  return (
    <div
      className={css.radioButtonBlock}
      onClick={() => {
        helpers.setValue(option);
      }}
    >
      <div className={css.iconBlock}>
        <Image
          src={`/icons/${
            meta.value === option ? 'checked' : 'unchecked'
          }RadioButton.svg`}
          loading="lazy"
          alt="true"
          width="24"
          height="24"
        />
      </div>
      <div className={css.label}>{label}</div>
    </div>
  );
};

export default RadioButton;
