import {sbEditable, SbEditableBlok} from '@storyblok/storyblok-editable';
import PropTypes from 'prop-types';

import DynamicComponent from '../../../../dynamicComponents/components/DynamicComponent';
import css from '../styles/ViewWithSideBar.module.css';

interface ViewWithSideBarProps {
  blok: SbEditableBlok;
}

const ViewWithSideBar = ({blok}: ViewWithSideBarProps) => {
  const {sideBar, mainContent} = blok;

  return (
    <section className={css.main} {...sbEditable(blok)}>
      <aside>
        <div className={css.sideBarWrapper}>
          {/*ASIDE ADD STYLES */}
          <div className={css.sideBarMain}>
            {sideBar
              ? sideBar.map((blok) => (
                  <div className={css.sideBarCell} key={blok._uid}>
                    <DynamicComponent blok={blok} />
                  </div>
                ))
              : null}
          </div>
        </div>
      </aside>
      {mainContent
        ? mainContent.map((blok) => (
            <DynamicComponent blok={blok} key={blok._uid} />
          ))
        : null}
    </section>
  );
};

ViewWithSideBar.propTypes = {
  blok: PropTypes.object.isRequired,
};

export default ViewWithSideBar;
