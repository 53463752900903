import {sbEditable} from '@storyblok/storyblok-editable';
import cn from 'clsx';
import Link from 'next/link';
import PropTypes from 'prop-types';
import {useCallback, useContext} from 'react';

import LoadingIcon from '@/public/icons/loaing-dark.svg';
import useRoutePaths from '@/src/hooks/useRoutePaths';
import TextLocal from '@/src/textLocal/containers/TextLocal';

import PlayIcon from '../../../../public/icons/playPreviewWhite.svg';
import PopupContext from '../../../popup/utils/PopupContext';
import {Button, Text} from '../../../ui';
import css from '../../styles/HeroSection.module.css';

const HeroSectionLayout = ({blok}) => {
  const {
    title,
    subTitle,
    videoLink,
    playTitle,
    courseCount,
    courseCountTitle,
    contentCreatorsCount,
    contentCreatorsCountTitle,
  } = blok;
  const {openPopup} = useContext(PopupContext);
  const {DISCOVER, SIGN_UP_ROUTE} = useRoutePaths();

  const VideoPopupContent = () => (
    <div className={css.videoPopupContent}>
      <div className={css.loadingVideoIcon}>
        <LoadingIcon />
      </div>
      <iframe
        className="embedly-embed"
        src={videoLink}
        frameBorder="0"
        allow="autoplay; fullscreen"
        allowFullScreen={true}
      />
    </div>
  );

  const handleOpenModalClick = useCallback(() => {
    // @ts-ignore
    openPopup({
      popup: <VideoPopupContent />,
      options: {withCloseButton: true, withTransparentBackground: true},
    });
  }, []);

  return (
    <section
      className={cn(css.section, css.sectionWithoutMargin)}
      {...sbEditable(blok)}
    >
      <div className={cn(css.container, css.containerWithoutBorder)}>
        <div className={css.content}>
          <div className={css.title}>
            <Text
              fontSize={Text.FONT_SIZE.SIZE_40}
              fontWeight={Text.FONT_WEIGHT.MEDIUM}
              fontFamily={Text.FONT_FAMILY.RUBIK}
              lineHeight={Text.LINE_HEIGHT.LINE_HEIGHT_1_4}
            >
              {title}
            </Text>
          </div>
          <div className={css.subtitle}>
            <Text
              color={Text.COLOR.SECONDARY}
              fontSize={Text.FONT_SIZE.SIZE_18}
              fontWeight={Text.FONT_WEIGHT.THIN}
              lineHeight={Text.LINE_HEIGHT.LINE_HEIGHT_1_6}
            >
              {subTitle}
            </Text>
          </div>
          <div className={css.controls}>
            <Link href={SIGN_UP_ROUTE.getLink()}>
              <a>
                <Button
                  height={Button.HEIGHT.SMALL}
                  tagComponent={Button.TAG.SPAN}
                >
                  <TextLocal id={'general.startFreeTrial'} />
                </Button>
              </a>
            </Link>
            <Link href={DISCOVER.getLink()}>
              <a>
                <Button
                  tagComponent={Button.TAG.SPAN}
                  color={Button.COLOR.SECONDARY}
                  height={Button.HEIGHT.SMALL}
                >
                  <TextLocal id={'general.discoverContent'} />
                </Button>
              </a>
            </Link>
          </div>
        </div>
        <div className={css.additional}>
          <div className={cn(css.mediaContainer, css.mediaContainerLanding)} />
          <div className={css.infobox}>
            <div
              className={cn(css.infoboxCell, css.infoboxCellGradient)}
              onClick={handleOpenModalClick}
            >
              <div className={css.infoboxСontentIcon}>
                <div className={css.infoboxIcon}>
                  <PlayIcon width="100%" height="100%" />
                </div>

                <div className={css.infoboxSubtitle}>
                  <Text
                    color={Text.COLOR.WHITE}
                    fontSize={Text.FONT_SIZE.SIZE_18}
                    fontWeight={Text.FONT_WEIGHT.THIN}
                  >
                    {playTitle}
                  </Text>
                </div>
              </div>
            </div>
            <div className={css.infoboxCell}>
              <div className={css.infoboxСontent}>
                <div className={css.infoboxTitle}>
                  <Text
                    color={Text.COLOR.WHITE}
                    fontSize={Text.FONT_SIZE.SIZE_32}
                    fontWeight={Text.FONT_WEIGHT.BOLD}
                  >
                    {courseCount}
                  </Text>
                </div>

                <div className={css.infoboxSubtitle}>
                  <Text
                    color={Text.COLOR.WHITE}
                    fontSize={Text.FONT_SIZE.SIZE_18}
                    fontWeight={Text.FONT_WEIGHT.THIN}
                  >
                    {courseCountTitle}
                  </Text>
                </div>
              </div>
            </div>
            <div className={css.infoboxCell}>
              <div className={css.infoboxСontent}>
                <div className={css.infoboxTitle}>
                  <Text
                    color={Text.COLOR.WHITE}
                    fontSize={Text.FONT_SIZE.SIZE_32}
                    fontWeight={Text.FONT_WEIGHT.BOLD}
                  >
                    {contentCreatorsCount}
                  </Text>
                </div>

                <div className={css.infoboxSubtitle}>
                  <Text
                    color={Text.COLOR.WHITE}
                    fontSize={Text.FONT_SIZE.SIZE_18}
                    fontWeight={Text.FONT_WEIGHT.THIN}
                  >
                    {contentCreatorsCountTitle}
                  </Text>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

HeroSectionLayout.propTypes = {
  blok: PropTypes.object.isRequired,
};

export default HeroSectionLayout;
