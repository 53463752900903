import {CalendarPickerView} from '@material-ui/lab';
import {TextField} from '@mui/material';
import {DatePicker as MaterialUiDatePicker} from '@mui/x-date-pickers/DatePicker';
import {useField} from 'formik';

import css from '../styles/DatePicker.module.css';

interface DatePickerProps {
  label: string;
  name: string;
  maxDate?: any;
  minDate?: any;
  onChange?: (newValue: Date) => void;
  withEuropeanDateFormat?: boolean;
  isRequired?: boolean;
  view?: CalendarPickerView[];
  placeholder?: string;
  inputFormat?: string;
}

const DatePicker = ({
  label,
  name,
  maxDate,
  minDate,
  onChange,
  withEuropeanDateFormat,
  isRequired,
  view,
  placeholder,
  inputFormat,
}: DatePickerProps) => {
  // eslint-disable-next-line
  const [field, meta, helpers] = useField(name);
  const error = meta.touched && meta.error;

  return (
    <div className={css.widget}>
      {isRequired && <div className={css.requiredIcon}>*</div>}
      <MaterialUiDatePicker
        views={view.length ? view : null}
        onChange={(newValue) => {
          helpers.setValue(newValue);

          if (onChange) {
            onChange(newValue);
          }
        }}
        value={meta.value || null}
        renderInput={(params) => (
          <TextField
            classes={{
              root: error ? css.errorRoot : css.root,
              // @ts-ignore
              input: css.input,
            }}
            {...params}
            InputLabelProps={{
              classes: {
                root: css.labelRoot,
                focused: css.labelFocused,
                filled: css.labelFocused,
              },
            }}
            inputProps={{
              className: css.input,
              ...params.inputProps,
              ...(withEuropeanDateFormat
                ? {placeholder: placeholder || 'DD/MM/YYYY'}
                : {}),
            }}
          />
        )}
        label={label}
        maxDate={maxDate}
        minDate={minDate}
        // @ts-ignore
        popperClassName={css.root}
        {...(withEuropeanDateFormat
          ? {inputFormat: inputFormat || 'dd/MM/yyyy'}
          : {})}
      />
      {Boolean(error) && <div className={css.error}>{error}</div>}
    </div>
  );
};

DatePicker.defaultProps = {
  onChange: () => {},
  withEuropeanDateFormat: true,
  isRequired: false,
  view: [],
};

export default DatePicker;
