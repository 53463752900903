import {sbEditable} from '@storyblok/storyblok-editable';
import PropTypes from 'prop-types';

import {Text} from '../../ui';
import css from '../styles/SpecialField.module.css';
import CommonSectionLayout from './CommonSectionLayout';

const SpecialField = ({blok}) => {
  return (
    <div className={css.wrapper} {...sbEditable(blok)}>
      <CommonSectionLayout
        title={blok.title}
        content={
          <>
            <Text
              lineHeight={Text.LINE_HEIGHT.LINE_HEIGHT_1_6}
              color={Text.COLOR.GREY}
              className={css.subtitle}
            >
              {blok.description}
            </Text>
            <ul className={css.list}>
              {blok.list.map(({field, _uid}) => (
                <li className={css.listItem} key={_uid}>
                  {field}
                </li>
              ))}
            </ul>
          </>
        }
      />
    </div>
  );
};

SpecialField.propTypes = {
  blok: PropTypes.object.isRequired,
};

export default SpecialField;
