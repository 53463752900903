import cn from 'clsx';
import React from 'react';

import css from '@/src/landing/styles/WhyBestItem.module.css';

const BulletListWithIcon = ({text, isGreyIcon}) => {
  return (
    <li
      className={cn(css.listItem, {
        [css.greyIcon]: isGreyIcon,
      })}
    >
      {text}
    </li>
  );
};

export default BulletListWithIcon;
