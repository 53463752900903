import css from '@/src/profile/common/styles/ProfileHeaderImages.module.css';

interface CoverUserImageProps {
  coverImage: string;
  onEditCoverImageClick?: () => {};
  withEditButtons?: boolean;
  editImageNode?: JSX.Element;
}

const CoverUserImage = ({
  coverImage,
  onEditCoverImageClick,
  withEditButtons,
  editImageNode,
}: CoverUserImageProps): JSX.Element => {
  return coverImage ? (
    <div className={css.coverImageExisting}>
      <img src={coverImage} loading="lazy" alt={'cover image'} />
      {withEditButtons && (
        <div
          className={css.editCoverImageButton}
          onClick={onEditCoverImageClick}
        >
          {editImageNode}
        </div>
      )}
    </div>
  ) : (
    <div className={css.coverImage}>
      {withEditButtons && (
        <div
          className={css.editCoverImageButton}
          onClick={onEditCoverImageClick}
        >
          {editImageNode}
        </div>
      )}
    </div>
  );
};

export default CoverUserImage;
