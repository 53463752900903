export const CARRER_LINKS = {
  title: 'footer.carrer',
  links: [
    {
      href: '/',
      name: 'footer.carrer-at-medyoucate',
    },
    {
      href: '/',
      name: 'footer.open-position',
    },
  ],
};

export const CONTACT_US = {
  title: 'footer.contact-us',
  links: [
    {
      href: 'mailto:office@medyoucate.com',
      name: 'footer.main-email',
    },
  ],
};
