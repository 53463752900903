import Image from 'next/image';

import {Text} from '@/src/ui';

import css from '../../styles/HeroCheckmarks.module.css';

const HeroCheckmarksItem = ({title, icon}) => {
  return (
    <div className={css.checkItem}>
      <div className={css.checkIcon}>
        <Image src={icon} loading="lazy" alt={''} width={25} height={25} />
      </div>
      <Text>{title}</Text>
    </div>
  );
};

const HeroCheckmarks = ({items}) => {
  return (
    <div className={css.main}>
      <div className={css.checkItems}>
        {items.map((item) => (
          <HeroCheckmarksItem
            key={item.title}
            title={item.title}
            icon={item.icon}
          />
        ))}
      </div>
    </div>
  );
};

export default HeroCheckmarks;
