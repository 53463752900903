import FacebookIcon from '@/public/icons/social/facebook-white-icon.svg';
import Linkedin from '@/public/icons/social/inkedin-white-icon.svg';
import InstaIcon from '@/public/icons/social/nstagram-white-icon.svg';
import TiktokIcon from '@/public/icons/social/tiktok-white-icon.svg';
import TwitterIcon from '@/public/icons/social/twitter-white-icon.svg';

import css from '../styles/footer.module.css';

const FACEBOOK_LINK = 'https://www.facebook.com/medyoucate';
const TIKTOK_LINK = 'https://www.tiktok.com/@medyoucate';
const INSTAGRAM_LINK = 'https://www.instagram.com/medyoucate_com';
const TWITTER_LINK = 'https://twitter.com/medyoucate_';
const LINKED_IN_LINK = 'https://www.linkedin.com/company/medyoucate';

const SocialLinks = () => {
  return (
    <ul className={css.socialLinks}>
      <li>
        <a href={FACEBOOK_LINK} target={'_blank'} rel="noreferrer">
          <FacebookIcon />
        </a>
      </li>
      <li>
        <a href={TIKTOK_LINK} target={'_blank'} rel="noreferrer">
          <TiktokIcon />
        </a>
      </li>
      <li>
        <a href={LINKED_IN_LINK} target={'_blank'} rel="noreferrer">
          <Linkedin />
        </a>
      </li>
      <li>
        <a href={INSTAGRAM_LINK} target={'_blank'} rel="noreferrer">
          <InstaIcon />
        </a>
      </li>
      <li>
        <a href={TWITTER_LINK} target={'_blank'} rel="noreferrer">
          <TwitterIcon />
        </a>
      </li>
    </ul>
  );
};

export default SocialLinks;
