import cn from 'clsx';
import PropTypes from 'prop-types';

import TextLocal from '@/src/textLocal/containers/TextLocal';

import {Text} from '../../../ui';
import css from '../../styles/HeroSection.module.css';
import SearchForm from '../SearchForm';

const HeroSectionLayout = () => {
  return (
    <section className={css.section}>
      <div className={cn(css.container)}>
        <div className={css.content}>
          <div className={css.title}>
            <Text
              fontSize={Text.FONT_SIZE.SIZE_40}
              fontWeight={Text.FONT_WEIGHT.MEDIUM}
              fontFamily={Text.FONT_FAMILY.RUBIK}
              lineHeight={Text.LINE_HEIGHT.LINE_HEIGHT_1_6}
              tagComponent={Text.TAG.H1}
              color={Text.COLOR.WHITE}
            >
              <TextLocal id={'promoSection.heroTitle'} />
            </Text>
          </div>
          <div className={css.subtitle}>
            <Text
              fontSize={Text.FONT_SIZE.SIZE_18}
              fontWeight={Text.FONT_WEIGHT.NORMAL}
              lineHeight={Text.LINE_HEIGHT.LINE_HEIGHT_1_6}
              tagComponent={Text.TAG.H2}
              color={Text.COLOR.WHITE}
            >
              <TextLocal id={'promoSection.heroSubtitle'} />
            </Text>
          </div>
          <SearchForm />
        </div>
        <div className={css.coursesWithAnimaion}>
          <div className={css.mainPosition}>
            <div className={css.courses1} />
            <div className={css.courses2} />
            <div className={css.courses3} />
          </div>
        </div>
      </div>
    </section>
  );
};

HeroSectionLayout.propTypes = {
  isLandingHero: PropTypes.bool,
};

export default HeroSectionLayout;
