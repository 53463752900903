import {useCallback, useContext} from 'react';

import VacancyApplicationPopup from '@/src/jobPortal/applicationPopup/containers/VacancyApplicationPopup';
import PopupContext from '@/src/popup/utils/PopupContext';

const useApplicationPopup = () => {
  const {openPopup} = useContext(PopupContext);

  const openApplicationPopup = useCallback(
    (
      event,
      {positionTitle, positionId, clinicName, onSuccessfulApplicationCallback}
    ) => {
      event?.preventDefault();
      event?.stopPropagation();

      // @ts-ignore
      openPopup({
        popup: (
          <VacancyApplicationPopup
            positionTitle={positionTitle}
            positionId={positionId}
            clinicName={clinicName}
            onSuccessfulApplicationCallback={onSuccessfulApplicationCallback}
          />
        ),
        options: {withCloseButton: true},
      });
    },
    []
  );

  return {openApplicationPopup};
};

export default useApplicationPopup;
