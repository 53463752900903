import useIsMobile from '@/src/hooks/useIsMobile';
import TextLocal from '@/src/textLocal/containers/TextLocal';

import {Carousel} from '../../ui';
import CarouselCardLarge from '../../ui/carousel/components/CarouselCardLarge';
import CommonSectionLayout from '../components/CommonSectionLayout';
import useContentCreators from '../utils/useContentCreators';

const RecentlyCoursesContainer = () => {
  const {isMaxWidth900} = useIsMobile();
  const {doctors} = useContentCreators();

  return (
    <>
      <CommonSectionLayout
        fullWidth
        isOutSideLink
        seeAllUrl={'http://info.medyoucate.com'}
        title={<TextLocal id={'promoSection.medicalAdvisory'} />}
        content={
          doctors.length > 0 ? (
            <Carousel
              navigationPosition={Carousel.NAVIGATION_POSITION.FLOATING}
              slideGap={Carousel.SLIDE_GAP.SLIDE_GAP_20}
              highlighted
              settings={{
                loop: true,
                centeredSlides: !isMaxWidth900,
                breakpoints: {
                  768: {
                    slidesPerView: 'auto',
                  },
                },
              }}
            >
              {doctors.map((item) => (
                <CarouselCardLarge
                  {...item}
                  isUser
                  withDescription
                  // @ts-ignore
                  key={item.title}
                />
              ))}
            </Carousel>
          ) : null
        }
      />
    </>
  );
};

export default RecentlyCoursesContainer;
