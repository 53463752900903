import {sbEditable} from '@storyblok/storyblok-editable';
import Link from 'next/link';
import {useTranslation} from 'next-i18next';
import PropTypes from 'prop-types';

import useIsMobile from '@/src/hooks/useIsMobile';
import TextLocal from '@/src/textLocal/containers/TextLocal';

import {Button, Text} from '../../../ui';
import css from '../../styles/PromoLinks.module.css';

const DEFAULT_TITLE_SIZE = '32';
const DEFAULT_ITEMS_IN_ROW = 4;

const getItemsPerRow = ({
  isMaxWidth320,
  isMaxWidth480,
  isMaxWidth768,
  isMaxWidth992,
  isMaxWidth1280,
  isMaxWidth1536,
  xs,
  sm,
  md,
  lg,
  xl,
  xxl,
}) => {
  if (isMaxWidth320) {
    return xs || sm || md || lg || xl || xxl || DEFAULT_ITEMS_IN_ROW;
  }

  if (isMaxWidth480) {
    return sm || md || lg || xl || xxl || DEFAULT_ITEMS_IN_ROW;
  }

  if (isMaxWidth768) {
    return md || lg || xl || xxl || DEFAULT_ITEMS_IN_ROW;
  }

  if (isMaxWidth992) {
    return lg || xl || xxl || DEFAULT_ITEMS_IN_ROW;
  }

  if (isMaxWidth1280) {
    return xl || xxl;
  }

  if (isMaxWidth1536) {
    return xxl || DEFAULT_ITEMS_IN_ROW;
  }

  return xxl || DEFAULT_ITEMS_IN_ROW;
};

const PromoLinks = ({blok}) => {
  const {cardList, id, xxl, xl, lg, md, sm, xs} = blok;

  const {
    isMaxWidth320,
    isMaxWidth480,
    isMaxWidth768,
    isMaxWidth992,
    isMaxWidth1280,
    isMaxWidth1536,
  } = useIsMobile();

  const {t} = useTranslation('common');

  const itemsPerRow = getItemsPerRow({
    isMaxWidth320,
    isMaxWidth480,
    isMaxWidth768,
    isMaxWidth992,
    isMaxWidth1280,
    isMaxWidth1536,
    xs,
    sm,
    md,
    lg,
    xl,
    xxl,
  });

  return (
    <div
      className={css.list}
      {...sbEditable(blok)}
      id={id}
      style={{
        gridTemplateColumns: `repeat(${itemsPerRow}, 1fr)`,
      }}
    >
      {cardList.map((item) => {
        const titleSizeKey = `SIZE_${item.titleSize || DEFAULT_TITLE_SIZE}`;

        return (
          <div className={css.card} key={item._uid}>
            <figure className={css.media}>
              <img
                src={item.image}
                //@ts-ignore
                layout="responsive"
                className={css.mediaElement}
              />
            </figure>
            <div className={css.cardBody}>
              <div className={css.title}>
                <Text
                  fontSize={Text.FONT_SIZE[titleSizeKey]}
                  fontWeight={Text.FONT_WEIGHT.MEDIUM}
                  lineHeight={Text.LINE_HEIGHT.LINE_HEIGHT_1_4}
                >
                  {item.title}
                </Text>
              </div>
              <div>
                <Text color={Text.COLOR.GREY} fontSize={Text.FONT_SIZE.SIZE_12}>
                  <div
                    className={css.description}
                    dangerouslySetInnerHTML={{
                      __html: item.description,
                    }}
                  />
                </Text>
              </div>
              <div className={css.actions}>
                <Link href={item.link}>
                  <a
                    target="_blank"
                    aria-label={`link to ${t('general.learnMore')}`}
                  >
                    <Button
                      color={Button.COLOR.SECONDARY}
                      height={Button.HEIGHT.SMALL}
                    >
                      <TextLocal id={'general.learnMore'} />
                    </Button>
                  </a>
                </Link>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

PromoLinks.propTypes = {
  blok: PropTypes.object,
};

export default PromoLinks;
