import NavLink from 'next/link';

import WarningIcon from '@/public/icons/fullfiledWarningIcon.svg';
import useRoutePaths from '@/src/hooks/useRoutePaths';
import TextLocal from '@/src/textLocal/containers/TextLocal';

import css from '../styles/TrialDaysLeft.module.css';

const TrialDaysLeft = (): JSX.Element => {
  const {MY_PROFILE_SUBSCRIPTION} = useRoutePaths();

  return (
    <div className={css.main}>
      <div className={css.content}>
        <span className={css.warningIcon}>
          <WarningIcon />{' '}
        </span>
        <TextLocal id={'subscription.subs-ended'} />
        <NavLink href={MY_PROFILE_SUBSCRIPTION.path}>
          <a>
            <TextLocal id={'subscription.renew'} />{' '}
          </a>
        </NavLink>
      </div>
    </div>
  );
};

export default TrialDaysLeft;
