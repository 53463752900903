import {useEffect, useRef} from 'react';

import removeTagsFromString from '@/src/app/utils/removeTagsFromString';
import TextLocal from '@/src/textLocal/containers/TextLocal';
import {Text} from '@/src/ui';

import css from '../styles/ClinicPositionDescription.module.css';

interface ClinicPositionDescriptionProps {
  description: string;
  whatToExpect?: string | null;
  responsibilities?: string | null;
  requirements?: string | null;
}

const ClinicPositionDescription = ({
  description,
  whatToExpect,
  responsibilities,
  requirements,
}: ClinicPositionDescriptionProps) => {
  const widgetRef = useRef();

  const changeAnchorTags = (parent) => {
    const anchors = parent.querySelectorAll('a');

    for (let i = 0; i < anchors.length; i++) {
      let href = anchors[i].getAttribute('href');

      if (href && !href.includes('//')) {
        href = '//' + href;
        anchors[i].setAttribute('href', href);
      }
    }
  };

  useEffect(() => {
    if (widgetRef?.current) {
      changeAnchorTags(widgetRef.current);
    }
  }, [widgetRef?.current]);

  return (
    <div className={css.widget} ref={widgetRef}>
      <div className={css.responsibilitiesBlock}>
        <Text
          fontSize={Text.FONT_SIZE.SIZE_24}
          fontWeight={Text.FONT_WEIGHT.BOLD}
        >
          <TextLocal id={'job-portal.general-position-description'} />
        </Text>
        <Text
          color={Text.COLOR.GREY}
          fontSize={Text.FONT_SIZE.SIZE_14}
          fontWeight={Text.FONT_WEIGHT.NORMAL}
          lineHeight={Text.LINE_HEIGHT.LINE_HEIGHT_21}
        >
          <div
            className={css.responsibilitiesText}
            dangerouslySetInnerHTML={{__html: description}}
          />
        </Text>
      </div>
      {Boolean(removeTagsFromString(whatToExpect)) && (
        <div className={css.responsibilitiesBlock}>
          <Text
            fontSize={Text.FONT_SIZE.SIZE_24}
            fontWeight={Text.FONT_WEIGHT.BOLD}
          >
            <TextLocal id={'job-portal.what-to-expect'} />
          </Text>
          <Text
            color={Text.COLOR.GREY}
            fontSize={Text.FONT_SIZE.SIZE_14}
            fontWeight={Text.FONT_WEIGHT.NORMAL}
            lineHeight={Text.LINE_HEIGHT.LINE_HEIGHT_21}
          >
            <div
              className={css.responsibilitiesText}
              dangerouslySetInnerHTML={{__html: whatToExpect}}
            />
          </Text>
        </div>
      )}
      {Boolean(removeTagsFromString(responsibilities)) && (
        <div className={css.responsibilitiesBlock}>
          <Text
            fontSize={Text.FONT_SIZE.SIZE_24}
            fontWeight={Text.FONT_WEIGHT.BOLD}
          >
            <TextLocal id={'job-portal.responsibilities-tasks'} />
          </Text>
          <Text
            color={Text.COLOR.GREY}
            fontSize={Text.FONT_SIZE.SIZE_14}
            fontWeight={Text.FONT_WEIGHT.NORMAL}
            lineHeight={Text.LINE_HEIGHT.LINE_HEIGHT_21}
          >
            <div
              className={css.responsibilitiesText}
              dangerouslySetInnerHTML={{__html: responsibilities}}
            />
          </Text>
        </div>
      )}
      {Boolean(removeTagsFromString(requirements)) && (
        <div className={css.responsibilitiesBlock}>
          <Text
            fontSize={Text.FONT_SIZE.SIZE_24}
            fontWeight={Text.FONT_WEIGHT.BOLD}
          >
            <TextLocal id={'job-portal.requirements'} />
          </Text>
          <Text
            color={Text.COLOR.GREY}
            fontSize={Text.FONT_SIZE.SIZE_14}
            fontWeight={Text.FONT_WEIGHT.NORMAL}
            lineHeight={Text.LINE_HEIGHT.LINE_HEIGHT_21}
          >
            <div
              className={css.responsibilitiesText}
              dangerouslySetInnerHTML={{__html: requirements}}
            />
          </Text>
        </div>
      )}
    </div>
  );
};

export default ClinicPositionDescription;
