import {useField} from 'formik';
import {useCallback} from 'react';

import MultiSelect from '@/src/ui/select/containers/MultiSelect';

import {OptionProps} from '../components/MultiSelectLayout';

interface FormikMultiSelectProps {
  options: Array<OptionProps>;
  name: string;
  placeholder: string;
  isRequired?: boolean;
}

const FormikMultiSelect = ({
  options,
  name,
  placeholder,
  isRequired,
}: FormikMultiSelectProps) => {
  // eslint-disable-next-line no-unused-vars
  const [field, meta, helpers] = useField(name);
  const error = meta.touched && meta.error;
  const {setValue} = helpers;

  const handleRemoveOption = useCallback(
    (removedOption: OptionProps) => {
      const newValues = field.value.filter(
        (option) => option.value !== removedOption.value
      );

      setValue(newValues.length ? newValues : null);
    },
    [field]
  );

  const handleAppOption = useCallback(
    (value: OptionProps) => {
      const previousValues = field.value || [];
      setValue([...previousValues, value]);
    },
    [field]
  );

  return (
    <MultiSelect
      errorMessage={error}
      isRequired={isRequired}
      selectedOptions={field.value}
      options={options}
      onRemoveOption={handleRemoveOption}
      onAddOption={handleAppOption}
      placeholder={placeholder}
    />
  );
};

export default FormikMultiSelect;
