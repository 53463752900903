import {serialize} from 'object-to-formdata';

import {REST_METHODS} from '@/src/app/constants/api';
import authorizedFetch from '@/src/app/utils/authorizedFetch';

const postProfileImage = (profileFields) => {
  const formData = serialize({
    profile: profileFields,
  });

  // @ts-ignore
  return authorizedFetch({
    method: REST_METHODS.POST,
    path: `/api/v1/profiles/profiles`,
    formData,
  });
};

export default postProfileImage;
