import {sbEditable} from '@storyblok/storyblok-editable';
import PropTypes from 'prop-types';

import DynamicComponent from './DynamicComponent';

const Page = ({blok}) => (
  <div {...sbEditable(blok)} key={blok._uid}>
    {blok.body
      ? blok.body.map((blok) => (
          <DynamicComponent blok={blok} key={blok._uid} />
        ))
      : null}
  </div>
);

Page.propTypes = {
  blok: PropTypes.object.isRequired,
};

export default Page;
