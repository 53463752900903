import {useRouter} from 'next/router';
import {pathOr} from 'ramda';
import React, {useMemo} from 'react';
import {useSelector} from 'react-redux';

import {ROLES_NAMES} from '@/src/app/constants/roles';
import {USER_STATUSES} from '@/src/app/constants/userStatuses';
import {selectClinicData} from '@/src/app/store/clinic/clinicDataSlice';
import useUserActions from '@/src/app/store/user/useUserActions';
import {getAuthorizationToken} from '@/src/app/utils/authorizationToken';
import BannerSubsEnded from '@/src/header/components/BannerSubsEnded';
import CommonWarningBanner from '@/src/header/components/CommonWarningBanner';
import useSubscribeBannerStatus from '@/src/header/hooks/useSubscribeBannerStatus';
import getProfilePicture from '@/src/header/utils/getProfilePicture';
import useIsMobile from '@/src/hooks/useIsMobile';
import useRoutePaths from '@/src/hooks/useRoutePaths';
import {getUserInitials} from '@/src/profile/common/containers/MyProfileWrapper';
import TextLocal from '@/src/textLocal/containers/TextLocal';

import HeaderLayout from '../components/HeaderLayout';

export const ROOT_PAGE = '/';

const Header = () => {
  const {user} = useUserActions();
  const picture = pathOr('', ['profile', 'picture'], user);
  const {pathname, asPath, locale} = useRouter();
  const {isMaxWidth1100} = useIsMobile();
  const {HOW_T0, DISCOVER} = useRoutePaths();
  const clinicData = useSelector(selectClinicData);

  const isContentCreatorWaitingForApprove =
    user?.user?.type === ROLES_NAMES.COURSE_CREATOR &&
    user?.user?.status === USER_STATUSES.REVIEW;

  const isDiscoverPage = asPath.includes(DISCOVER.getLocalizationLink(locale));

  const isRootPage = useMemo(
    () => '/landing-v2' === asPath || pathname === ROOT_PAGE || isDiscoverPage,
    [pathname, asPath, isDiscoverPage]
  );

  const {isSubscribeEnded} = useSubscribeBannerStatus();

  return (
    <>
      <HeaderLayout
        isRootPage={isRootPage}
        isAuthorized={Boolean(getAuthorizationToken())}
        userInitials={getUserInitials(
          user,
          user.user.type === ROLES_NAMES.CLINIC,
          clinicData
        )}
        picture={getProfilePicture(picture, clinicData, user)}
        user={user}
        isHowToMenu={asPath.includes(HOW_T0.path) && isMaxWidth1100}
      />
      {/*<TrialDaysLeft*/}
      {/*  daysLeft={leftDays}*/}
      {/*  isShow={isShow}*/}
      {/*  onCloseClick={onCloseClick}*/}
      {/*/>*/}
      {isSubscribeEnded && <BannerSubsEnded />}
      {isContentCreatorWaitingForApprove && (
        <CommonWarningBanner>
          <TextLocal
            id={'subscription.content-creator-in-need-to-be-approved'}
          />
        </CommonWarningBanner>
      )}
    </>
  );
};

export default Header;
