import PropTypes from 'prop-types';
import {memo} from 'react';

import IconNoAccessWarning from '@/public/images/noAccessWarningIcon.svg';

import {Button, Text} from '../../ui';
import css from '../styles/NoAccess.module.css';

const NoAccessLayout = ({onButtonClick, buttonText, noAccessReason}) => {
  return (
    <div className={css.main}>
      <div className={css.borderIcon}>
        <div className={css.roundCardIcon}>
          <IconNoAccessWarning />
        </div>
      </div>
      <div className={css.infoText}>
        <Text
          fontSize={Text.FONT_SIZE.SIZE_16}
          fontWeight={Text.FONT_WEIGHT.THIN}
          color={Text.COLOR.GREY}
          lineHeight={Text.LINE_HEIGHT.LINE_HEIGHT_1_6}
          fontFamily={Text.FONT_FAMILY.POPPINS}
        >
          {noAccessReason}
        </Text>
      </div>
      <div>
        <Button
          height={Button.HEIGHT.REGULAR}
          onClick={onButtonClick}
          type={Button.TYPES.BUTTON}
        >
          {buttonText}
        </Button>
      </div>
    </div>
  );
};

NoAccessLayout.propTypes = {
  onButtonClick: PropTypes.func.isRequired,
  buttonText: PropTypes.node.isRequired,
  noAccessReason: PropTypes.node.isRequired,
};

export default memo(NoAccessLayout);
