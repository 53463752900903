interface DurationProps {
  seconds: number;
}
const Duration = ({seconds}: DurationProps) => {
  return (
    <time dateTime={`P${Math.round(seconds)}S`}>{formatDuration(seconds)}</time>
  );
};

export default Duration;

function formatDuration(totalSeconds: number) {
  const date = new Date(totalSeconds * 1000);
  const hours = date.getUTCHours();
  const minutes = date.getUTCMinutes();
  const seconds = getFormattedTime(date.getUTCSeconds());

  if (hours) {
    return `${hours}:${getFormattedTime(minutes)}:${seconds}`;
  }

  return `${minutes}:${seconds}`;
}

function getFormattedTime(string) {
  return ('0' + string).slice(-2);
}
