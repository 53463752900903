import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-video.css';

import lgVideo from 'lightgallery/plugins/video';
import lgZoom from 'lightgallery/plugins/zoom';
import dynamic from 'next/dynamic';
import PropTypes from 'prop-types';
import {useCallback, useRef} from 'react';

const LightGallery = dynamic(() => import('lightgallery/react'), {
  ssr: false,
});

interface videoProps {
  id: string;
  title: string;
  url: string;
}
interface VideoGalleryProps {
  children: JSX.Element;
  videos: Array<videoProps>;
}

const VideoGallery = ({children, videos}: VideoGalleryProps) => {
  const lightGalleryRef = useRef(null);

  const handleOpenGallery = useCallback(() => {
    lightGalleryRef.current.click();
  }, [lightGalleryRef]);

  return (
    <>
      <LightGallery
        licenseKey={process.env.NEXT_PUBLIC_LIGHTGALLERY_LICENSE_KEY}
        speed={400}
        plugins={[lgZoom, lgVideo]}
        mobileSettings={{
          closable: true,
        }}
        download={false}
        zoom={false}
        counter={videos.length > 1}
      >
        {videos.map((video, index) => (
          <a
            ref={index === 0 ? lightGalleryRef : null}
            key={video.id}
            data-video={`{"source": [{"src":"${video.url}", "type":"video/mp4"}], "attributes": {"preload": true, "controls": true}}`}
            data-sub-html={`<h4>${video.title}</h4/>`}
          />
        ))}
      </LightGallery>
      <div onClick={handleOpenGallery}>{children}</div>
    </>
  );
};

export default VideoGallery;

VideoGallery.propTypes = {
  children: PropTypes.node.isRequired,
  videos: PropTypes.array.isRequired,
};
