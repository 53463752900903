import React, {useCallback, useContext} from 'react';
import {render} from 'storyblok-rich-text-react-renderer';

import PlayButtonIcon from '@/public/icons/buttonPlay.svg';
import LoadingIcon from '@/public/icons/loaing-dark.svg';
import DynamicComponent from '@/src/dynamicComponents/components/DynamicComponent';
import CountWithTitle from '@/src/landing/components/LearningFromLeaders/CountWithTitle';
import PopupContext from '@/src/popup/utils/PopupContext';
import {Button, SectionContainer, Separator, Text} from '@/src/ui';

import cssVideo from '../../../promoSections/styles/HeroSection.module.css';
import css from '../../styles/LearningFromLeaders.module.css';

const LearningFromLeaders = ({blok}) => {
  const {
    description,
    title,
    sideImage,
    jobsTitle,
    videosCount,
    jobsCount,
    videosTitle,
    buttonTitle,
    videoUrl,
  } = blok;
  const {openPopup} = useContext(PopupContext);

  const VideoPopupContent = () => (
    <div className={cssVideo.videoPopupContent}>
      <div className={cssVideo.loadingVideoIcon}>
        <LoadingIcon />
      </div>
      <iframe
        data-cookiescript="accepted"
        data-src={videoUrl}
        src={videoUrl}
        data-cookiecategory="functionality"
        className="embedly-embed"
        frameBorder="0"
        allow="autoplay; fullscreen"
        allowFullScreen={true}
      />
    </div>
  );

  const handleOpenModalClick = useCallback(() => {
    // @ts-ignore
    openPopup({
      popup: <VideoPopupContent />,
      options: {withCloseButton: true, withTransparentBackground: true},
    });
  }, []);

  return (
    <SectionContainer>
      <div className={css.main}>
        <div className={css.leftContent}>
          <div className={css.title}>
            <Text
              fontSize={Text.FONT_SIZE.SIZE_40}
              fontWeight={Text.FONT_WEIGHT.MEDIUM}
              lineHeight={Text.LINE_HEIGHT.LINE_HEIGHT_1_6}
              fontFamily={Text.FONT_FAMILY.RUBIK}
              tagComponent={Text.TAG.H2}
            >
              {title}
            </Text>
          </div>
          <div className={css.description}>
            <Text
              fontSize={Text.FONT_SIZE.SIZE_16}
              lineHeight={Text.LINE_HEIGHT.LINE_HEIGHT_1_6}
              color={Text.COLOR.PRIMARY}
              fontWeight={Text.FONT_WEIGHT.NORMAL}
            >
              {typeof description === 'string'
                ? description
                : render(description, {
                    // eslint-disable-next-line react/display-name
                    defaultBlokResolver: (name, props) => (
                      <DynamicComponent
                        blok={{
                          component: name,
                          ...props,
                        }}
                      />
                    ),
                  })}
            </Text>
          </div>
          <div className={css.buttonWrapper}>
            <Button
              height={Button.HEIGHT.MEDIUM}
              onClick={handleOpenModalClick}
            >
              <div className={css.buttonContent}>
                <PlayButtonIcon />
                {buttonTitle}
              </div>
            </Button>
          </div>

          <div className={css.separatorWrapper}>
            <Separator borderWeight={Separator.BORDER_WEIGHT.THIN} />
          </div>

          <div className={css.countList}>
            <CountWithTitle title={videosTitle} count={videosCount} />
            <CountWithTitle title={jobsTitle} count={jobsCount} />
          </div>
        </div>
        <div className={css.rightContent}>
          <img
            className={css.image}
            src={sideImage.filename}
            alt="preview image"
          />
        </div>
      </div>
    </SectionContainer>
  );
};

export default LearningFromLeaders;
