export const COLOR = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  GREY: 'grey',
  WHITE: 'white',
  MUTED: 'muted',
  RED: 'red',
  GRADIENT: 'gradient',
  BLUE: 'blue',
  ORANGE: 'orange',
};

export const FONT_WEIGHT = {
  THIN: 'thinWeight',
  NORMAL: 'normalWeight',
  MEDIUM: 'mediumBold',
  BOLD: 'boldWeight',
};

export const FONT_SIZE = {
  SIZE_44: 'size44',
  SIZE_40: 'size40',
  SIZE_36: 'size36',
  SIZE_35: 'size35',
  SIZE_32: 'size32',
  SIZE_26: 'size26',
  SIZE_28: 'size28',
  SIZE_24: 'size24',
  SIZE_20: 'size20',
  SIZE_18: 'size18',
  SIZE_17: 'size17',
  SIZE_16: 'size16',
  SIZE_14: 'size14',
  SIZE_12: 'size12',
  SIZE_11: 'size11',
};

export const FONT_FAMILY = {
  RUBIK: 'rubik',
  POPPINS: 'poppins',
};

export const LINE_HEIGHT = {
  LINE_HEIGHT_2: 'lineHeight2',
  LINE_HEIGHT_32: 'lineHeight32',
  LINE_HEIGHT_21: 'lineHeight21',
  LINE_HEIGHT_24: 'lineHeight24',
  LINE_HEIGHT_1: 'lineHeight1',
  LINE_HEIGHT_1_2: 'lineHeight1-2',
  LINE_HEIGHT_1_3: 'lineHeight1-3',
  LINE_HEIGHT_1_4: 'lineHeight1-4',
  LINE_HEIGHT_1_6: 'lineHeight1-6',
};

export const TRANSFORM = {
  UPPERCASE: 'textUppercase',
  LOWERCASE: 'textLowercase',
  INITIAL_CASE: 'textInitialCase',
  CAPITALIZE: 'textCapitalize',
};

export const TAG = {
  SPAN: 'span',
  P: 'p',
  DIV: 'div',
  H1: 'h1',
  H2: 'h2',
  H3: 'h3',
  H4: 'h4',
};

export const DISPLAY = {
  BLOCK: 'displayBlock',
  INLINE: 'displayInline',
  INLINE_BLOCK: 'displayInlineBlock',
};
