import PropTypes from 'prop-types';
import {render} from 'storyblok-rich-text-react-renderer';

import css from '../styles/RichText.module.css';

const RichText = ({blok}) => {
  const {document} = blok;

  return (
    <div className={css.main}>
      {render(document, {
        nodeResolvers: {
          // eslint-disable-next-line react/display-name
          code_block: (children) => {
            // @ts-ignore
            const jsx = children?.join();
            return <span dangerouslySetInnerHTML={{__html: jsx}} />;
          },
        },
      })}
    </div>
  );
};

RichText.propTypes = {
  blok: PropTypes.object.isRequired,
};

export default RichText;
