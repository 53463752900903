import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/effect-creative';

import cn from 'clsx';
import PropTypes from 'prop-types';
import {Children, useEffect, useRef, useState} from 'react';
import {
  Autoplay,
  Controller,
  EffectCreative,
  Navigation,
  Pagination,
} from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/react';

import ShevronLeft from '@/public/images/shevron-left.svg';
import ShevronRight from '@/public/images/shevron-right.svg';

import css from '../styles/Carousel.module.css';

const NAVIGATION_POSITION = {
  NORMAL: 'navigationNormal',
  BOTTOM: 'navigationBottom',
  FLOATING: 'navigationFloating',
};

const SLIDE_GAP = {
  SLIDE_GAP_0: 'slideGap0',
  SLIDE_GAP_10: 'slideGap10',
  SLIDE_GAP_20: 'slideGap20',
  SLIDE_GAP_30: 'slideGap30',
  SLIDE_GAP_40: 'slideGap40',
  SLIDE_GAP_WITH_COUNTER: 'slideGapWithCounter',
};

const ARROWS_TOP_OFFSET = {
  OFFSET_0: 'arrowsOffset0',
  OFFSET_3: 'arrowsOffset3',
};

const OFFSET_Y = {
  NONE: 'offsetYNone',
  SMALL: 'offsetYSmall',
};

const OFFSET = {
  TINY: 'tinyOffset',
  SMALL: 'smallOffset',
  REGULAR: 'regularOffset',
};

interface ISliderRefProps {
  getBoundingClientRect: (...args: any) => any;
}

const Carousel = ({
  settings,
  navigationPosition,
  highlighted,
  withPagination,
  slideGap,
  isVerticalCardsEffect,
  withAutoplay,
  children,
  offset,
  offsetY,
  arrowsOffset,
  isResetOnMobile,
  paginationMobileToRight,
}) => {
  const parentRef = useRef(null);
  const slideRef = useRef<ISliderRefProps>();
  const [isLoop, setIsLoop] = useState(false);

  const childrenCount = Children.toArray(children)?.length;

  useEffect(() => {
    if (parentRef?.current && slideRef?.current) {
      const slideTotalWidth =
        slideRef.current.getBoundingClientRect().width * childrenCount;
      const carouselContainerWidth =
        parentRef.current.getBoundingClientRect().width;

      setIsLoop(slideTotalWidth > carouselContainerWidth);
    }
  }, [slideRef, parentRef]);

  const verticalCardsConfig = {
    effect: 'creative',
    creativeEffect: {
      limitProgress: 3,
      progressMultiplier: 1.5,
      prev: {
        translate: [0, '8%', -120],
        opacity: 0.75,
      },
      next: {
        translate: [0, '-8%', -120],
        opacity: 0.75,
      },
    },
    loopedSlides: null,
    slidesPerView: 1,
    loop: false,

    breakpoints: {
      900: {
        slidesPerView: 1,
      },
    },
  };

  const defaultSettings = {
    modules: [Navigation, Pagination, EffectCreative, Autoplay, Controller],
    spaceBetween: 0,
    loop: isLoop,
    cache: 'false',
    pagination: {
      dynamicMainBullets: 3,
      el: '.swiper-pagination',
      clickable: true,
      renderBullet: function (index, className) {
        return `<span class="${className} ${css.swiperBullet}"></span>`;
      },
    },
    navigation: {
      prevEl: '.swiper-prev',
      nextEl: '.swiper-next',
    },
    slidesPerView: 'auto',
    loopedSlides: childrenCount,
    ...(isVerticalCardsEffect ? {...verticalCardsConfig} : {}),
    ...settings,
  };

  return (
    <Swiper
      {...defaultSettings}
      ref={parentRef}
      className={cn(
        css.swiperContainer,
        css[navigationPosition],
        css[slideGap],
        css[offset],
        css[offsetY],
        css[arrowsOffset],
        css[arrowsOffset],
        {
          [css.highlighted]: highlighted,
          [css.withPagination]: withPagination,
          [css.withPagination]: withPagination,
          [css.paginationMobileToRight]: paginationMobileToRight,
          [css.verticalCardsEffect]: isVerticalCardsEffect,
          [css.withAutoplay]: withAutoplay,
          [css.resetMobile]: isResetOnMobile,
        }
      )}
    >
      {Children.map(children, (child) => (
        // @ts-ignore
        <SwiperSlide ref={slideRef} className={cn(css.slide, css[slideGap])}>
          {child}
        </SwiperSlide>
      ))}
      <div className={`swiper-pagination ${css.swiperPagination}`} />
      {defaultSettings?.navigation && (
        <>
          <div
            className={cn(
              'swiper-prev',
              css.swiperButton,
              css.swiperButtonPrev,
              css[navigationPosition]
            )}
          >
            <ShevronLeft />
          </div>
          <div
            className={cn(
              'swiper-next',
              css.swiperButton,
              css.swiperButtonNext
            )}
          >
            <ShevronRight />
          </div>
        </>
      )}
    </Swiper>
  );
};

Carousel.NAVIGATION_POSITION = NAVIGATION_POSITION;
Carousel.ARROWS_TOP_OFFSET = ARROWS_TOP_OFFSET;
Carousel.SLIDE_GAP = SLIDE_GAP;
Carousel.OFFSET = OFFSET;
Carousel.OFFSET_Y = OFFSET_Y;

Carousel.propTypes = {
  settings: PropTypes.object,
  navigationPosition: PropTypes.oneOf(Object.values(NAVIGATION_POSITION)),
  arrowsOffset: PropTypes.oneOf(Object.values(ARROWS_TOP_OFFSET)),
  slideGap: PropTypes.oneOf(Object.values(SLIDE_GAP)),
  offset: PropTypes.oneOf(Object.values(OFFSET)),
  offsetY: PropTypes.oneOf(Object.values(OFFSET_Y)),
  highlighted: PropTypes.bool,
  isVerticalCardsEffect: PropTypes.bool,
  withPagination: PropTypes.bool,
  withAutoplay: PropTypes.bool,
  isResetOnMobile: PropTypes.bool,
  paginationMobileToRight: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

Carousel.defaultProps = {
  slideGap: SLIDE_GAP.SLIDE_GAP_10,
  arrowsOffset: ARROWS_TOP_OFFSET.OFFSET_0,
  offset: OFFSET.REGULAR,
  offsetY: OFFSET_Y.NONE,
  settings: {},
  highlighted: false,
  withPagination: false,
  isResetOnMobile: false,
  paginationMobileToRight: false,
  children: <></>,
};

export default Carousel;
