import NavLink from 'next/link';
import {useTranslation} from 'next-i18next';
import {MouseEventHandler, useContext, useMemo} from 'react';

import ClinicIcon from '@/public/images/jobPortal/clinicIcon.svg';
import DoctorPrimaryIcon from '@/public/images/jobPortal/doctorPrimaryIcon.svg';
import DoctorSecondaryIcon from '@/public/images/jobPortal/doctorSecondaryIcon.svg';
import useIsMobile from '@/src/hooks/useIsMobile';
import {CLINIC_POSITION_STATUSES} from '@/src/jobPortal/clinicPositionPreview/constants/clinicPositionStatuses';
import {getClinicAvatar} from '@/src/profile/aboutMe/components/ProfessionalExperienceEditWidgetLayout';
import RouteContext from '@/src/routes/utils/RouteContext';
import TextLocal from '@/src/textLocal/containers/TextLocal';
import {Avatar, Button, Separator, Text} from '@/src/ui';

import css from '../styles/ClinicPositionSidebar.module.css';

export const PERSONNEL_SIZE_NOT_SPECIFIED = 'personnel_size_not_specified';

interface ClinicPositionSidebarProps {
  clinicName: string;
  establishDate?: string | null;
  clinicWebsite?: string | null;
  avatarLink?: string | null;
  employeesNumber?: string | null;
  onPublishClick: (...args: any) => any;
  onEditClick: (...args: any) => any;
  onDashboardClick: (...args: any) => any;
  isLoading: boolean;
  isTargetPage: boolean;
  isApplied: boolean;
  isCurrentUserClinic: boolean;
  onApplyClick: MouseEventHandler<HTMLDivElement>;
  onArchiveClick: (...args: any) => any;
  onViewApplicantsClick: (vacancyId: number) => any;
  positionStatus: string;
  positionId: number;
  applicantsCount?: number;
  clinicSlug: string;
}

const ClinicPositionSidebar = ({
  clinicName,
  isCurrentUserClinic,
  establishDate,
  employeesNumber,
  onPublishClick,
  onEditClick,
  onDashboardClick,
  clinicWebsite,
  avatarLink,
  isLoading,
  isTargetPage,
  onApplyClick,
  onArchiveClick,
  onViewApplicantsClick,
  positionStatus,
  isApplied,
  positionId,
  applicantsCount,
  clinicSlug,
}: ClinicPositionSidebarProps) => {
  const {t} = useTranslation(TextLocal.LOCALE_SOURCE.COMMON);
  const establishedText = useMemo(() => {
    return `${t('profile.start')} ${establishDate}`;
  }, [establishDate]);
  const {isMaxWidth1100} = useIsMobile();

  // @ts-ignore
  const {CLINIC_TARGET_PROFILE} = useContext(RouteContext);

  return (
    <div className={css.widget}>
      {!isMaxWidth1100 && (
        <div className={css.card}>
          <div className={css.clinicNameBlock}>
            <div className={css.iconBlock}>
              <Avatar
                size={'almostMedium'}
                organizationName={clinicName}
                picture={getClinicAvatar(avatarLink)}
              />
            </div>
            <div className={css.clinicNameText}>
              <Text
                color={Text.COLOR.GRADIENT}
                fontSize={Text.FONT_SIZE.SIZE_18}
                fontWeight={Text.FONT_WEIGHT.BOLD}
              >
                <NavLink href={CLINIC_TARGET_PROFILE.getLink(clinicSlug)}>
                  <a>{clinicName}</a>
                </NavLink>
              </Text>
            </div>
            <div className={css.clinicWebsiteText}>
              <Text fontSize={Text.FONT_SIZE.SIZE_14} color={Text.COLOR.GREY}>
                {clinicWebsite}
              </Text>
            </div>
          </div>
          {Boolean(establishDate) && (
            <div className={css.clinicEstablishmentBlock}>
              <div className={css.iconBlock}>
                <ClinicIcon width={24} height={24} />
              </div>
              <Text
                fontSize={Text.FONT_SIZE.SIZE_18}
                fontWeight={Text.FONT_WEIGHT.BOLD}
              >
                {establishedText}
              </Text>
            </div>
          )}
          {Boolean(employeesNumber) &&
            employeesNumber !== PERSONNEL_SIZE_NOT_SPECIFIED && (
              <div className={css.clinicEmployeesBlock}>
                <div className={css.employeesIcons}>
                  <div className={css.firstEmployeeIcon}>
                    <DoctorPrimaryIcon width={24} height={32} />
                  </div>
                  <div className={css.secondEmployeeIcon}>
                    <DoctorSecondaryIcon width={22} height={28} />
                  </div>
                </div>
                <Text
                  fontSize={Text.FONT_SIZE.SIZE_18}
                  fontWeight={Text.FONT_WEIGHT.BOLD}
                >
                  {`${t(`clinicSize.${employeesNumber}`)} ${t(
                    'profile.employees'
                  )}`}
                </Text>
              </div>
            )}

          {!isCurrentUserClinic && isTargetPage && (
            <div className={css.applyWrapper}>
              <Separator borderWeight={Separator.BORDER_WEIGHT.THIN} />
              <div className={css.applyWrapperInner}>
                <Button
                  height={Button.HEIGHT.SMALL}
                  onClick={onApplyClick}
                  isDisabled={isApplied}
                >
                  <TextLocal
                    id={isApplied ? 'general.applied' : 'general.applyNow'}
                  />
                </Button>
              </div>
            </div>
          )}
        </div>
      )}
      {!isTargetPage && (
        <>
          <div className={css.positionStatusBlock}>
            <Text
              fontSize={Text.FONT_SIZE.SIZE_16}
              fontWeight={Text.FONT_WEIGHT.NORMAL}
              color={Text.COLOR.GREY}
              display={Text.DISPLAY.INLINE}
            >
              <TextLocal id={'job-portal.status'} />
            </Text>
            <Text
              fontSize={Text.FONT_SIZE.SIZE_16}
              fontWeight={Text.FONT_WEIGHT.BOLD}
              color={Text.COLOR.PRIMARY}
              display={Text.DISPLAY.INLINE}
            >
              <TextLocal id={`job-portal.status-${positionStatus}`} />
            </Text>
          </div>
          <div className={css.publishBlock}>
            {!isMaxWidth1100 &&
              positionStatus === CLINIC_POSITION_STATUSES.DRAFT && (
                <Text
                  fontSize={Text.FONT_SIZE.SIZE_18}
                  fontWeight={Text.FONT_WEIGHT.BOLD}
                >
                  <TextLocal id={'job-portal.are-you-ready-to-publish'} />
                </Text>
              )}
            {positionStatus === CLINIC_POSITION_STATUSES.ACTIVE && (
              <div className={css.archiveButton}>
                <Button
                  color={Button.COLOR.RED}
                  height={Button.HEIGHT.SMALL}
                  onClick={onArchiveClick}
                  border={Button.BORDER.BOLD}
                >
                  <TextLocal id={'job-portal.archive'} />
                </Button>
              </div>
            )}
            {positionStatus === CLINIC_POSITION_STATUSES.DRAFT && (
              <div className={css.publishButton}>
                <Button
                  color={Button.COLOR.PRIMARY}
                  height={Button.HEIGHT.SMALL}
                  onClick={onPublishClick}
                  isLoading={isLoading}
                  border={Button.BORDER.BOLD}
                >
                  <TextLocal id={'job-portal.publish'} />
                </Button>
              </div>
            )}
            {(positionStatus === CLINIC_POSITION_STATUSES.DRAFT ||
              positionStatus === CLINIC_POSITION_STATUSES.ACTIVE) && (
              <div className={css.editButton}>
                <Button
                  color={Button.COLOR.SECONDARY}
                  height={Button.HEIGHT.SMALL}
                  onClick={onEditClick}
                  border={Button.BORDER.BOLD}
                >
                  <TextLocal id={'profile.edit'} />
                </Button>
              </div>
            )}
            <div className={css.dashboardButton} onClick={onDashboardClick}>
              <Text
                color={Text.COLOR.GREY}
                fontSize={Text.FONT_SIZE.SIZE_16}
                fontWeight={Text.FONT_WEIGHT.MEDIUM}
              >
                <TextLocal id={'job-portal.dashboard'} />
              </Text>
            </div>
          </div>
        </>
      )}
      {!isTargetPage && Boolean(applicantsCount) && (
        <div className={css.applicantsBlock}>
          <Separator borderWeight={Separator.BORDER_WEIGHT.THIN} />
          <div
            className={css.applicantsLink}
            onClick={() => onViewApplicantsClick(positionId)}
          >
            <Text
              fontSize={Text.FONT_SIZE.SIZE_16}
              fontWeight={Text.FONT_WEIGHT.BOLD}
              color={Text.COLOR.GRADIENT}
            >
              <TextLocal id={'job-portal.view-applicants'} />
            </Text>
          </div>
        </div>
      )}
    </div>
  );
};

export default ClinicPositionSidebar;
