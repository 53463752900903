import Link from 'next/link';
import PropTypes from 'prop-types';
import {memo, useMemo} from 'react';

import LogoSvg from '@/public/images/logo.svg';
import useIsMobile from '@/src/hooks/useIsMobile';
import useRoutePaths from '@/src/hooks/useRoutePaths';
import TextLocal from '@/src/textLocal/containers/TextLocal';

import {Button, Text} from '../../ui';
import css from '../styles/SectionDoubleColumnsLayout.module.css';

const SectionDoubleColumnsLayout = ({
  showLogo,
  title,
  subtitle,
  seeAllUrl,
  rightSideContent,
  isLandingButtons,
}) => {
  const {isMaxWidth900} = useIsMobile();
  const {DISCOVER, SIGN_UP_ROUTE} = useRoutePaths();

  const buttonsNode = useMemo(
    () =>
      isLandingButtons ? (
        <>
          <div className={css.landingButtons}>
            <Link href={SIGN_UP_ROUTE.getLink()}>
              <a>
                <Button height={Button.HEIGHT.SMALL}>
                  <Text
                    fontSize={Text.FONT_SIZE.SIZE_16}
                    fontWeight={Text.FONT_WEIGHT.BOLD}
                  >
                    <TextLocal id={'general.startFreeTrial'} />
                  </Text>
                </Button>
              </a>
            </Link>
            <Link href={DISCOVER.getLink()}>
              <a>
                <Button
                  height={Button.HEIGHT.SMALL}
                  color={Button.COLOR.SECONDARY}
                >
                  <Text
                    fontSize={Text.FONT_SIZE.SIZE_16}
                    fontWeight={Text.FONT_WEIGHT.BOLD}
                  >
                    <TextLocal id={'general.discoverContent'} />
                  </Text>
                </Button>
              </a>
            </Link>
          </div>
        </>
      ) : (
        <Link href={seeAllUrl}>
          <a className={css.seeAllLink} aria-label={`see all link`}>
            <Button height={Button.HEIGHT.SMALL}>
              <TextLocal id={'promoSection.see-all'} />
            </Button>
          </a>
        </Link>
      ),
    [isLandingButtons]
  );

  return (
    <section className={css.section}>
      <div className={css.container}>
        <div className={css.content}>
          {showLogo && (
            <div className={css.logo}>
              <LogoSvg />
            </div>
          )}
          <div className={css.title}>
            <Text
              fontSize={
                isMaxWidth900 ? Text.FONT_SIZE.SIZE_28 : Text.FONT_SIZE.SIZE_40
              }
              fontWeight={Text.FONT_WEIGHT.BOLD}
              fontFamily={Text.FONT_FAMILY.RUBIK}
              tagComponent={Text.TAG.H2}
            >
              {title}
            </Text>
          </div>
          <div className={css.subtitle}>
            <Text
              color={Text.COLOR.SECONDARY}
              fontSize={Text.FONT_SIZE.SIZE_18}
              lineHeight={Text.LINE_HEIGHT.LINE_HEIGHT_1_6}
            >
              {subtitle}
            </Text>
          </div>
          {!isMaxWidth900 && <div className={css.controls}>{buttonsNode}</div>}
        </div>
        <div className={css.slider}>{rightSideContent}</div>
        {isMaxWidth900 && <div className={css.controls}>{buttonsNode}</div>}
      </div>
    </section>
  );
};

SectionDoubleColumnsLayout.propTypes = {
  showLogo: PropTypes.bool,
  isLandingButtons: PropTypes.bool,
  title: PropTypes.element,
  subtitle: PropTypes.element,
  seeAllUrl: PropTypes.string,
  rightSideContent: PropTypes.node,
};

SectionDoubleColumnsLayout.defaultProps = {
  seeAllUrl: '#',
  showLogo: false,
};

export default memo(SectionDoubleColumnsLayout);
