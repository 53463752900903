import {sbEditable} from '@storyblok/storyblok-editable';
import PropTypes from 'prop-types';
import {useCallback, useEffect, useState} from 'react';

import {RESPONSE_STATUSES} from '@/src/app/constants/api';

import fetchPlansDataAction from '../../../signUp/paymentDetails/utils/fethcPlansDataAction';
import PricingSectionLayout from '../../components/PricingSectionLayout/PricingSectionLayout.storyblok';

const PricingSection = ({blok}) => {
  const {
    doctors = [],
    students = [],
    contentCreators = [],
    id,
    studentsDiscount,
    studentsTitle,
    studentsDescription,
    studentsButton,
    contentCreatorsDescription,
    contentCreatorsButton,
    contentCreatorsTitle,
    doctorsButton,
    doctorsDescription,
    doctorsTitle,
    clinicTitle,
    clinicButton,
    clinicDescription,
    clinicList = [],
    studentsIcon,
    doctorsIcon,
    contentCreatorsIcon,
    clinicsIcon,
    contentCreatorMonthlyDescription,
    doctorMonthlyDescription,
    studentMonthlyDescription,
    clinicMonthlyDescription,
  } = blok;

  const [plans, setPlans] = useState([]);
  const [isYearly, setIsYearly] = useState(false);

  const getPlans = useCallback(async () => {
    const {status, responseBody} = await fetchPlansDataAction();

    if (status === RESPONSE_STATUSES.SUCCESS) {
      setPlans(responseBody.plans);
    }
  }, [plans]);

  useEffect(() => {
    getPlans();
  }, []);

  const getSectionAdvantages = useCallback((list) => {
    return list.map((item) => item.text);
  }, []);

  const handleBillTypeClick = useCallback(() => {
    setIsYearly(!isYearly);
  }, [isYearly]);

  if (!plans.length) {
    return null;
  }

  return (
    <div id={id} {...sbEditable(blok)}>
      <PricingSectionLayout
        doctorsTitle={doctorsTitle}
        doctorsDescription={doctorsDescription}
        doctorsButton={doctorsButton}
        contentCreatorsTitle={contentCreatorsTitle}
        contentCreatorsButton={contentCreatorsButton}
        contentCreatorsDescription={contentCreatorsDescription}
        studentsButton={studentsButton}
        studentsDescription={studentsDescription}
        studentsTitle={studentsTitle}
        studentsDiscount={studentsDiscount}
        plans={plans}
        doctorsList={getSectionAdvantages(doctors)}
        contentCreatorList={getSectionAdvantages(contentCreators)}
        studentList={getSectionAdvantages(students)}
        topContent={blok.topContent}
        clinicTitle={clinicTitle}
        clinicButton={clinicButton}
        clinicDescription={clinicDescription}
        clinicList={getSectionAdvantages(clinicList)}
        studentsIcon={studentsIcon?.filename}
        doctorsIcon={doctorsIcon?.filename}
        contentCreatorsIcon={contentCreatorsIcon?.filename}
        clinicsIcon={clinicsIcon?.filename}
        isYearly={isYearly}
        onBillTypeClick={handleBillTypeClick}
        contentCreatorMonthlyDescription={contentCreatorMonthlyDescription}
        doctorMonthlyDescription={doctorMonthlyDescription}
        studentMonthlyDescription={studentMonthlyDescription}
        clinicMonthlyDescription={clinicMonthlyDescription}
      />
    </div>
  );
};

PricingSection.propTypes = {
  blok: PropTypes.object.isRequired,
};

export default PricingSection;
