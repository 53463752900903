import {useTranslation} from 'next-i18next';
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from 'react-share';

import EmailIcon from '@/public/icons/email-gradient.svg';
import FacebookIcon from '@/public/icons/facebook-gradient.svg';
import LinkInIcon from '@/public/icons/linkIn-gradient.svg';
import TwitteIcon from '@/public/icons/twitter-gradient.svg';

import css from '../styles/SocialMediaShareLinks.module.css';

// Coded symbol & for email body
const SYMBOL = '%20%26';

interface SocialMediaShareLinksProps {
  jobTitle: string;
  clinicName: string;
  link: string;
}

// Change & symbol to code to have ability pass it to link query body
const getPreparedText = (string: string) => {
  return string.replaceAll('&', SYMBOL);
};

const SocialMediaShareLinks = ({
  link,
  jobTitle,
  clinicName,
}: SocialMediaShareLinksProps): JSX.Element => {
  const {t} = useTranslation('common');

  const preparedClinicName = getPreparedText(clinicName);
  const preparedJobTitle = getPreparedText(jobTitle);

  return (
    <>
      <div className={css.share}>
        <FacebookShareButton url={link}>
          <div className={css.iconCard}>
            <FacebookIcon />
          </div>
        </FacebookShareButton>
      </div>
      <div className={css.share}>
        <TwitterShareButton url={link}>
          <div className={css.iconCard}>
            <TwitteIcon />
          </div>
        </TwitterShareButton>
      </div>
      <div className={css.share}>
        <LinkedinShareButton url={link}>
          <div className={css.iconCard}>
            <LinkInIcon />
          </div>
        </LinkedinShareButton>
      </div>

      <div className={css.share}>
        <a
          href={t('job-portal.share-email', {
            subjectTitle: preparedJobTitle,
            jobTitle: preparedJobTitle,
            clinicName: preparedClinicName,
            link: link,
          })}
        >
          <div className={css.iconCard}>
            <EmailIcon />
          </div>
        </a>
      </div>
    </>
  );
};
export default SocialMediaShareLinks;
