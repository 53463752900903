import cn from 'clsx';
import PropsTypes from 'prop-types';

import LoadingIcon from '../../../../public/icons/loaing-dark.svg';
import css from '../styles/LoadingWrapper.module.css';

const INDICATOR_POSITION = {
  ABSOLUTE: 'indicatorAbsolute',
  FIXED: 'indicatorFixed',
};

const LoadingWrapper = ({
  isLoading,
  children,
  hideContentBeforeLoad,
  indicatorPosition,
}) => {
  const hideBeforeLoadNode = isLoading ? (
    <div className={css.loadingIndicatorCentered}>
      <LoadingIcon />
    </div>
  ) : (
    children
  );

  const showWhileLoadingNode = (
    <div className={css.loadingWrapper}>
      <div
        className={cn({
          [css.loadingContent]: isLoading,
        })}
      >
        {children}
      </div>
      {isLoading && (
        <div className={cn(css.loadingIndicator, css[indicatorPosition])}>
          <LoadingIcon />
        </div>
      )}
    </div>
  );

  return hideContentBeforeLoad ? hideBeforeLoadNode : showWhileLoadingNode;
};

LoadingWrapper.INDICATOR_POSITION = INDICATOR_POSITION;

LoadingWrapper.defaultProps = {
  indicatorPosition: INDICATOR_POSITION.FIXED,
};

LoadingWrapper.propTypes = {
  isLoading: PropsTypes.bool.isRequired,
  hideContentBeforeLoad: PropsTypes.bool,
  children: PropsTypes.node.isRequired,
  indicatorPosition: PropsTypes.oneOf(Object.values(INDICATOR_POSITION)),
};

export default LoadingWrapper;
