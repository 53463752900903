const removeTagsFromString = (stringWithTags: string): boolean | string => {
  if (stringWithTags === null || stringWithTags === '') {
    return false;
  } else {
    stringWithTags = stringWithTags.toString();
  }

  return stringWithTags.replace(/(<([^>]+)>)/gi, '');
};

export default removeTagsFromString;
