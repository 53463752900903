import {REST_METHODS} from '@/src/app/constants/api';
import {
  ORDER_BY_FILTER,
  ORDER_FILTER,
  SEARCH_FILTERS,
} from '@/src/app/constants/search';
import unauthorizedFetch from '@/src/app/utils/unauthorizedFetch';

const getPopularCourses = (locale) => {
  const param = [
    {
      name: SEARCH_FILTERS.PER_PAGE,
      value: '10',
    },
    {
      name: SEARCH_FILTERS.OFFSET,
      value: 0,
    },
    {
      name: SEARCH_FILTERS.ORDER,
      value: ORDER_FILTER.DESC,
    },
    {
      name: SEARCH_FILTERS.ORDER_BY,
      value: ORDER_BY_FILTER.AVERAGE_SCORE,
    },
    {
      name: SEARCH_FILTERS.LANGUAGE,
      value: locale,
    },
  ];

  // @ts-ignore
  return unauthorizedFetch({
    method: REST_METHODS.GET,
    path: `/api/v1/courses/search`,
    queryParams: param,
  });
};

export default getPopularCourses;
