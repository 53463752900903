import moment from 'moment';
import {useTranslation} from 'next-i18next';
import {useMemo} from 'react';

import {DATE_FORMAT, SERVER_FORMAT} from '@/src/app/constants/date';
import getDaysBeforeExpire from '@/src/app/utils/getDaysBeforeExpire';
import {CLINIC_POSITION_STATUSES} from '@/src/jobPortal/clinicPositionPreview/constants/clinicPositionStatuses';
import {
  MAX_DAYS_TO_EXPIRE,
  MIN_DAYS_TO_EXPIRE,
} from '@/src/jobPortal/clinicPositions/components/ActivePositionsRowLayout';
import {formatMinSalary} from '@/src/profile/clinicTarget/components/ClinicPositionDesktopWidget';
import {getCurrencySign} from '@/src/signUp/paymentDetails/utils/getCurrencySign';
import TextLocal from '@/src/textLocal/containers/TextLocal';
import {Text} from '@/src/ui';

import css from '../styles/ClinicPositionHeading.module.css';

export const getDaysLeftColor = (daysLeft) => {
  if (Number(daysLeft) <= MIN_DAYS_TO_EXPIRE) {
    return Text.COLOR.RED;
  } else if (Number(daysLeft) <= MAX_DAYS_TO_EXPIRE) {
    return Text.COLOR.ORANGE;
  } else {
    return Text.COLOR.PRIMARY;
  }
};

const mapWorkTypes = (employmentTypes: Array<any>, workType: Array<any>) => {
  return workType.map((item) => {
    const itemTranslation = employmentTypes.find((employmentType) => {
      return employmentType.value === item;
    })?.label;

    return {
      label: itemTranslation,
      value: item,
    };
  });
};

interface ClinicPositionHeadingProps {
  postedAt?: string;
  expireDate: string;
  title: string;
  workType: Array<string>;
  minSalary: number;
  currency: string;

  positionStatus: string;
  employmentTypes: Array<any>;
}

const ClinicPositionHeading = ({
  postedAt,
  expireDate,
  title,
  workType,
  minSalary,
  currency,
  positionStatus,
  employmentTypes,
}: ClinicPositionHeadingProps) => {
  const {t} = useTranslation(TextLocal.LOCALE_SOURCE.COMMON);
  const postedText = t('job-portal.posted');
  const isPositionActive = positionStatus === CLINIC_POSITION_STATUSES.ACTIVE;
  const mappedWorkTypes = useMemo(() => {
    return mapWorkTypes(employmentTypes, workType);
  }, []);

  const daysLeft = useMemo(
    () => getDaysBeforeExpire(new Date(), expireDate, true, true),
    [expireDate]
  );

  return (
    <div className={css.widget}>
      {Boolean(postedAt) && (
        <div className={css.postedAtBlock}>
          <Text
            color={Text.COLOR.GREY}
            fontSize={Text.FONT_SIZE.SIZE_12}
            fontWeight={Text.FONT_WEIGHT.NORMAL}
          >
            {`${postedText} ${moment(postedAt, SERVER_FORMAT).format(
              DATE_FORMAT
            )} ${isPositionActive ? '-' : ''}`}
          </Text>
          {isPositionActive ? (
            <Text
              color={getDaysLeftColor(daysLeft)}
              fontSize={Text.FONT_SIZE.SIZE_12}
              fontWeight={Text.FONT_WEIGHT.BOLD}
            >
              <TextLocal
                id={'job-portal.days-left'}
                params={{days: daysLeft}}
              />
            </Text>
          ) : (
            <></>
          )}
        </div>
      )}
      <div className={css.positionDataRow}>
        <div className={css.workTypesBlock}>
          {mappedWorkTypes.map((item) => {
            return (
              <div className={css.workTypeBlock} key={item.value}>
                <Text
                  fontSize={Text.FONT_SIZE.SIZE_14}
                  fontWeight={Text.FONT_WEIGHT.BOLD}
                >
                  {item.label}
                </Text>
              </div>
            );
          })}
        </div>
        <div className={css.salaryWrapper}>
          {Boolean(minSalary) && (
            <>
              <Text
                color={Text.COLOR.GREY}
                fontSize={Text.FONT_SIZE.SIZE_12}
                fontWeight={Text.FONT_WEIGHT.NORMAL}
              >
                <TextLocal id={'job-portal.min'} />
              </Text>
              <div className={css.minSalary}>
                <Text
                  fontSize={Text.FONT_SIZE.SIZE_16}
                  fontWeight={Text.FONT_WEIGHT.BOLD}
                  isOneLine
                >
                  {`${getCurrencySign(currency)} ${formatMinSalary(minSalary)}`}
                </Text>
              </div>
              <Text
                color={Text.COLOR.GREY}
                fontSize={Text.FONT_SIZE.SIZE_12}
                fontWeight={Text.FONT_WEIGHT.NORMAL}
              >
                <TextLocal id={'job-portal.per-year'} />
              </Text>
            </>
          )}
        </div>
      </div>

      <div className={css.heading}>
        <Text
          fontSize={Text.FONT_SIZE.SIZE_32}
          fontWeight={Text.FONT_WEIGHT.BOLD}
          tagComponent={Text.TAG.H1}
        >
          {title}
        </Text>
      </div>
    </div>
  );
};

export default ClinicPositionHeading;
