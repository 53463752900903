import {REST_METHODS} from '@/src/app/constants/api';
import authorizedFetch from '@/src/app/utils/authorizedFetch';

const changeLocaleAction = (newLocale) => {
  return authorizedFetch({
    method: REST_METHODS.POST,
    path: `/api/v1/profiles/profiles`,
    body: {
      profile: {
        language: newLocale,
      },
    },
  });
};

export default changeLocaleAction;
