import NavLink from 'next/link';

import css from '@/src/promoSections/styles/PricingSection.module.css';
import {Button, Text} from '@/src/ui';

interface PriceCardProps {
  icon: string;
  title: string;
  price: string | number;
  yearlyPrice: string | number;
  discount: string | number;
  description: string;
  slug: string;
  buttonText: string;
  oldPrice: string | number;
  oldPriceYearly: string | number;
  currentSymbol: string;
  isYearly: boolean;
  descriptionMonthly: string;
}

const PriceCard = ({
  icon,
  title,
  price,
  yearlyPrice,
  discount,
  description,
  slug,
  buttonText,
  oldPrice,
  oldPriceYearly,
  currentSymbol,
  isYearly,
  descriptionMonthly,
}: PriceCardProps) => {
  return (
    <div>
      <div className={css.priceCard}>
        <div className={css.priceIcon}>
          <img src={icon} loading="lazy" alt={title} />
        </div>

        <div className={css.priceInfoText}>
          <Text
            fontSize={Text.FONT_SIZE.SIZE_26}
            fontFamily={Text.FONT_FAMILY.RUBIK}
            fontWeight={Text.FONT_WEIGHT.MEDIUM}
          >
            {title}
          </Text>
        </div>

        <div className={css.priceInfo}>
          {discount && (
            <div className={css.oldPrice}>
              {currentSymbol}
              {isYearly ? oldPriceYearly : oldPrice}
            </div>
          )}

          <div className={css.priceWrapper}>
            <div className={css.priceCurrent}>
              <Text
                fontSize={Text.FONT_SIZE.SIZE_32}
                fontWeight={Text.FONT_WEIGHT.MEDIUM}
                fontFamily={Text.FONT_FAMILY.RUBIK}
                lineHeight={Text.LINE_HEIGHT.LINE_HEIGHT_1}
              >
                {currentSymbol}
                {isYearly ? yearlyPrice : price}
              </Text>
            </div>{' '}
            {discount && <div className={css.discount}>-{discount}%</div>}
          </div>

          <div className={css.priceDescription}>
            <Text fontSize={Text.FONT_SIZE.SIZE_12} color={Text.COLOR.GREY}>
              {isYearly ? description : descriptionMonthly}
            </Text>
          </div>
        </div>

        <div className={css.priceButton}>
          <NavLink href={slug}>
            <Button height={Button.HEIGHT.SMALL}>{buttonText}</Button>
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default PriceCard;
