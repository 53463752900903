import cn from 'clsx';
import Image from 'next/image';

import css from '../styles/MultiSelect.module.css';

interface MultiSelectLayoutProps {
  selectedOptions: Array<OptionProps>;
  onRemoveOption: Function;
  onAddOption: Function;
  placeholder?: string;
  isRequired?: boolean;
  errorMessage?: string;
  selectRef: any;
  search: string;
  handleOnChange: any;
  setIsSelectOpened: Function;
  isDropDownOpen: boolean;
  preparedOptions: Array<OptionProps>;
  handleSelect: Function;
}

export interface OptionProps {
  value: string;
  label: string;
}

const MultiSelectLayout = ({
  selectedOptions,
  onRemoveOption,
  placeholder,
  isRequired,
  errorMessage,
  selectRef,
  search,
  handleOnChange,
  setIsSelectOpened,
  isDropDownOpen,
  preparedOptions,
  handleSelect,
}: MultiSelectLayoutProps) => {
  return (
    <div className={css.widget}>
      <div ref={selectRef}>
        <div
          className={cn(css.inputBlock, {
            [css.errorInput]: Boolean(errorMessage),
          })}
        >
          {isRequired && <div className={css.requiredDot}>*</div>}
          <input
            className={css.inputField}
            value={search}
            onChange={handleOnChange}
            onFocus={() => setIsSelectOpened(true)}
            placeholder={placeholder}
          />
          <div
            className={cn(css.dropDownIcon, {
              [css.dropDownIconReversed]: isDropDownOpen,
            })}
            onClick={() => setIsSelectOpened(true)}
          >
            <Image
              src={`/icons/dropDownIndicator.svg`}
              loading="lazy"
              alt="true"
              width="10"
              height="10"
            />
          </div>
        </div>
        {Boolean(errorMessage) && (
          <div className={css.errorField}>{errorMessage}</div>
        )}

        {isDropDownOpen && (
          <div className={css.selectBlockWrapper}>
            <div className={css.selectBlock}>
              {preparedOptions.map((option) => {
                const isSelected = selectedOptions?.find(
                  (selectedOption) => selectedOption.value === option.value
                );

                return (
                  <div
                    className={cn(css.selectItem)}
                    key={option.value}
                    onClick={() => {
                      handleSelect(option, Boolean(isSelected));
                    }}
                  >
                    <div className={cn(css.selectItemContent)}>
                      <div className={css.selectIcon}>
                        <Image
                          src={`/icons/${
                            isSelected ? 'selected' : 'unselected'
                          }Checkbox.svg`}
                          loading="lazy"
                          alt="true"
                          width="14"
                          height="14"
                          className={css.selectIcon}
                        />
                      </div>
                      <span className={css.skillName}>{option.label}</span>
                    </div>
                  </div>
                );
              })}
              {!preparedOptions.length && (
                <div className={cn(css.noResult)}>no result</div>
              )}
            </div>
          </div>
        )}
      </div>
      <div className={css.categoriesLabels}>
        {selectedOptions?.map((option) => (
          <div key={option.value} className={css.categoryContainer}>
            <div className={css.categoryContent}>
              <span>{option.label}</span>
              <div className={css.closeIconBlock}>
                <Image
                  src={`/icons/categoryClose.svg`}
                  loading="lazy"
                  alt="true"
                  width="11"
                  height="11"
                  onClick={() => {
                    onRemoveOption(option);
                  }}
                />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MultiSelectLayout;
