import OpenForNewOpportunities from '@/src/profile/common/containers/OpenForNewOpportunities';
interface MobileOpenForNewOpportunitiesProps {
  userId: number;
  isOpenForNewJobs: boolean;
}
const MobileOpenForNewOpportunities = ({
  userId,
  isOpenForNewJobs,
}: MobileOpenForNewOpportunitiesProps): JSX.Element => {
  return (
    <div>
      <OpenForNewOpportunities
        userId={userId}
        isOpenForNewOpportunities={isOpenForNewJobs}
        isHeader
      />
    </div>
  );
};

export default MobileOpenForNewOpportunities;
