import {REST_METHODS} from '@/src/app/constants/api';
import authorizedFetch from '@/src/app/utils/authorizedFetch';

const requestOpportunitiesTurnOff = () => {
  // @ts-ignore
  return authorizedFetch({
    path: `/api/v1/profiles/open_for_new_job_opportunities/disable`,
    method: REST_METHODS.PUT,
  });
};

export default requestOpportunitiesTurnOff;
