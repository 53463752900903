import {useRouter} from 'next/router';
import {useTranslation} from 'next-i18next';
import {useCallback, useEffect, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {RESPONSE_STATUSES} from '@/src/app/constants/api';
import {ROLES_NAMES} from '@/src/app/constants/roles';
import {
  ORDER_BY_FILTER,
  ORDER_FILTER,
  SEARCH_FILTERS,
} from '@/src/app/constants/search';
import {
  selectRandomCourses,
  setRandomCourses,
} from '@/src/app/store/promoSections/promoSectionsSlice';
import useUserActions from '@/src/app/store/user/useUserActions';
import {
  selectWatchedCourses,
  setWatchedCourses,
} from '@/src/app/store/watchedCourses/watchedCoursesSlice';
import transforCoursesData from '@/src/app/utils/transforCoursesData';
import useRoutePaths from '@/src/hooks/useRoutePaths';
import TextLocal from '@/src/textLocal/containers/TextLocal';

import {Carousel, CourseCard} from '../../ui';
import CommonSectionLayout from '../components/CommonSectionLayout';
import getContinueWatching from '../utils/getContinueWatching';
import getRandomCourses from '../utils/getRandomCourses';

const ContinueWatchingContainer = () => {
  const {
    user: {user, clinic},
  } = useUserActions();
  const {locale} = useRouter();
  const {SEARCH_COURSES, WATCHLIST_CONTINUE_ROUTE} = useRoutePaths();
  const {t} = useTranslation('common');

  const isAthorized = Boolean(user?.id);

  const watchedCourses = useSelector(selectWatchedCourses);
  const randomCourses = useSelector(selectRandomCourses);
  const dispatch = useDispatch();

  const requestGetContinueWathcingData = useCallback(async () => {
    const {status, responseBody} = await getContinueWatching(locale);

    if (status === RESPONSE_STATUSES.SUCCESS) {
      responseBody.data.length === 0
        ? requestGetRandomCourses()
        : dispatch(
            setWatchedCourses(transforCoursesData(responseBody?.data, locale))
          );
    }
  }, [locale]);

  const requestGetRandomCourses = useCallback(async () => {
    const {status, responseBody} = await getRandomCourses(locale);

    if (status === RESPONSE_STATUSES.SUCCESS) {
      dispatch(
        setRandomCourses(transforCoursesData(responseBody?.data, locale))
      );
    }
  }, [locale]);

  useEffect(() => {
    isAthorized ? requestGetContinueWathcingData() : requestGetRandomCourses();
  }, [isAthorized]);

  const areWatchedCoursesExist = watchedCourses?.length > 0;

  const coursesData = areWatchedCoursesExist ? watchedCourses : randomCourses;

  const sectionTitle = useMemo(
    () =>
      areWatchedCoursesExist ? (
        <>
          <span
            dangerouslySetInnerHTML={{
              __html: t('promoSection.continue-watching', {
                name:
                  user.type === ROLES_NAMES.CLINIC
                    ? clinic.organization_name
                    : user?.first_name,
              }),
            }}
          />
        </>
      ) : (
        <TextLocal id={'promoSection.courses'} />
      ),
    [areWatchedCoursesExist, user, isAthorized]
  );

  const seeAllUrl = useMemo(
    () =>
      `${
        areWatchedCoursesExist
          ? WATCHLIST_CONTINUE_ROUTE.getLink()
          : SEARCH_COURSES.getLink()
      }?${SEARCH_FILTERS.OFFSET}=0&${SEARCH_FILTERS.ORDER}=${
        ORDER_FILTER.DESC
      }&${SEARCH_FILTERS.ORDER_BY}=${ORDER_BY_FILTER.APPROVED_AT}&${
        SEARCH_FILTERS.LANGUAGE
      }=${locale}`,
    [areWatchedCoursesExist]
  );

  return coursesData.length > 0 ? (
    <CommonSectionLayout
      seeAllUrl={seeAllUrl}
      title={sectionTitle}
      content={
        <Carousel
          slideGap={Carousel.SLIDE_GAP.SLIDE_GAP_10}
          arrowsOffset={Carousel.ARROWS_TOP_OFFSET.OFFSET_3}
          paginationMobileToRight
          settings={{
            loop: false,
          }}
        >
          {coursesData.map((item) => (
            <CourseCard {...item} key={item.id} />
          ))}
        </Carousel>
      }
    />
  ) : null;
};

export default ContinueWatchingContainer;
