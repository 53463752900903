import {useTranslation} from 'next-i18next';
import {useState} from 'react';

import SubscribeEmail from '@/src/footer/components/SubscribeEmail';
import requestSubscribeUser from '@/src/footer/utils/requestSubscribeUser';
import TextLocal from '@/src/textLocal/containers/TextLocal';

import css from '../../styles/SubscribeToNewsletter.module.css';

const SubscribeToNewsletter = ({title, buttonTitle, placeholder}) => {
  const {t} = useTranslation(TextLocal.LOCALE_SOURCE.COMMON);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const todoList: string[] = t('callToActon.todo.list', {returnObjects: true});

  const subscribeToNewsLetters = async ({email}, {resetForm}) => {
    await requestSubscribeUser(email);
    resetForm();
    setIsSubscribed(true);
  };

  return (
    <div className={css.main}>
      {!isSubscribed && <h2 className={css.title}>{title}</h2>}
      {isSubscribed && (
        <>
          <h2 className={css.titleThank}>
            <TextLocal id={'callToActon.thankyou'} />
          </h2>
          <div className={css.subTitle}>
            <TextLocal id={'callToActon.bepart'} />
          </div>
          <div className={css.text}>
            <TextLocal id={'callToActon.todo.title'} />
          </div>
          <ul className={css.list}>
            {todoList.map((todo, index) => (
              <li className={css.listItem} key={index}>
                <span className={css.text}>{todo}</span>
              </li>
            ))}
          </ul>
        </>
      )}
      {!isSubscribed && (
        <SubscribeEmail
          subscribeToNewsLetters={subscribeToNewsLetters}
          isDark
          buttonTitle={buttonTitle}
          placeholder={placeholder}
        />
      )}
    </div>
  );
};

export default SubscribeToNewsletter;
