import {useRouter} from 'next/router';
import PropTypes from 'prop-types';
import {useContext, useEffect} from 'react';

import ThankYouPopup from '@/src/discover/components/ThankYouPopup';
import PopupContext from '@/src/popup/utils/PopupContext';

import DynamicComponent from '../../dynamicComponents/components/DynamicComponent';
import MainContainer from '../../main/container/MainContainer';

const LandingLayout = ({storyBlock}) => {
  const {query} = useRouter();
  const {openPopup} = useContext(PopupContext);

  const handleThankYouPopup = () => {
    // @ts-ignore
    openPopup({
      popup: <ThankYouPopup />,
      options: {
        withCloseButton: true,
      },
    });
  };

  useEffect(() => {
    let timer;

    if (query?.thankyou) {
      timer = setTimeout(() => handleThankYouPopup(), 400);
    }

    return () => {
      clearInterval(timer);
    };
  }, [query?.thankyou]);
  return (
    <MainContainer
      widthContainer={MainContainer.CONTAINER_WIDTH.FULL_WIDTH}
      topOffset={MainContainer.TOP_OFFSET.OFFSET_0}
    >
      <DynamicComponent blok={storyBlock} />
    </MainContainer>
  );
};

LandingLayout.propTypes = {
  storyBlock: PropTypes.object.isRequired,
};

export default LandingLayout;
