import cn from 'clsx';
import {useField} from 'formik';
import {useTranslation} from 'next-i18next';
import PropTypes from 'prop-types';
import {useCallback} from 'react';
import TextareaAutosize from 'react-textarea-autosize';

import TextLocal from '@/src/textLocal/containers/TextLocal';

import Text from '../../text/components/Text';
import css from '../styles/textarea.module.css';

const ROWS = {
  ROWS_6: 6,
};

const Textarea = ({placeholder, minRows, maxLength, withCounter, ...props}) => {
  // eslint-disable-next-line no-unused-vars
  // @ts-ignore
  const [field, meta, helper] = useField(props);
  const error = meta.touched && meta.error;
  const {t} = useTranslation(TextLocal.LOCALE_SOURCE.COMMON);

  const handleOnChange = useCallback((event) => {
    helper.setValue(event.target.value);
  }, []);

  return (
    <div className={css.wrapper}>
      <TextareaAutosize
        {...field}
        minRows={minRows}
        className={cn(css.textarea, {
          [css.inputAreaError]: error,
        })}
        placeholder={placeholder}
        onChange={handleOnChange}
        value={meta.value}
        maxLength={maxLength}
      />
      {Boolean(error) && <div className={css.errorField}>{error}</div>}
      {Boolean(maxLength) && withCounter && (
        <div className={css.maxLengthCounter}>
          <Text color={Text.COLOR.GREY} fontSize={Text.FONT_SIZE.SIZE_12}>
            {`${meta.value?.length}/${maxLength} ${t('profile.symbols')}`}
          </Text>
        </div>
      )}
    </div>
  );
};

export default Textarea;

Textarea.propTypes = {
  placeholder: PropTypes.string,
  minRows: PropTypes.oneOf(Object.values(ROWS)),
  maxLength: PropTypes.number,
  withCounter: PropTypes.bool,
};

Textarea.defaultProps = {
  withCounter: false,
};

Textarea.ROWS = ROWS;
