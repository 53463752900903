import cn from 'clsx';
import {debounce} from 'lodash';
import Link from 'next/link';
import {useRouter} from 'next/router';
import PropTypes from 'prop-types';
import {useContext, useEffect, useMemo, useRef, useState} from 'react';

import {LOCALES} from '@/src/app/constants/locales';
import {StickyContext} from '@/src/header/components/HeaderLayout';
import {ROOT_PAGE} from '@/src/header/containers/Header';
import useIsMobile from '@/src/hooks/useIsMobile';
import useRoutePaths from '@/src/hooks/useRoutePaths';
import TextLocal from '@/src/textLocal/containers/TextLocal';

import {Button, Text} from '../../ui';
import MobileMenu from '../containers/MobileMenu';
import css from '../styles/menu.module.css';

export const TIMER_HAS_HASH = 1000;

export const notAuthorizedMenuList = [
  {
    link: 'features',
    value: 'landing-menu.about',
  },
  {
    link: 'pricing',
    value: 'landing-menu.pricing',
  },
  {
    link: 'advisors',
    value: 'landing-menu.advisors',
  },
  {
    link: 'faq',
    value: 'landing-menu.faq',
  },
];

const UnauthorizedHeader = ({user, isMenuWithBg}) => {
  const {isMaxWidth1100} = useIsMobile();
  const [activeMenu, setActive] = useState(null);
  const refActiveMenu = useRef(activeMenu);
  const isSticky = useContext(StickyContext);
  const {push} = useRouter();
  const {pathname, asPath, locale} = useRouter();

  const isLandingPage = useMemo(
    () => '/landing-v2' === asPath || pathname === ROOT_PAGE,
    [asPath]
  );

  const {SIGN_IN_ROUTE, SIGN_UP_ROUTE, INDEX_ROUTE} = useRoutePaths();

  useEffect(() => {
    if (isLandingPage && !isMaxWidth1100) {
      const handleScroll = () => {
        const sections = document.querySelectorAll(
          '#features, #pricing, #advisors, #faq'
        );
        const scrollPosition =
          window.pageYOffset || document.documentElement.scrollTop;

        sections.forEach((section) => {
          if (
            notAuthorizedMenuList.find((item) => item.link === section.id) &&
            // @ts-ignore
            scrollPosition >= section.offsetTop - section.clientHeight / 1.2 &&
            section.id !== refActiveMenu.current
          ) {
            refActiveMenu.current = section.id;
            setActive(section.id);
          }
        });
      };

      const debouncedHandleScroll = debounce(handleScroll, 20);

      window.addEventListener('scroll', debouncedHandleScroll);
      return () => {
        window.removeEventListener('scroll', debouncedHandleScroll);
      };
    }
  }, [isLandingPage, isMaxWidth1100]);

  const handleMenuItemClick = async (id) => {
    if (!isLandingPage) {
      await push(INDEX_ROUTE.getLink());
      setTimeout(() => {
        scrollToSection(id);
      }, TIMER_HAS_HASH);
    } else {
      scrollToSection(id);
    }
  };

  const scrollToSection = (id) => {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({behavior: 'smooth'});
    }
  };

  return (
    <>
      <span
        className={cn(css.menuWrapperLanding, {
          [css.menuWrapperLandingDe]: locale === LOCALES.DE,
        })}
      >
        <ul className={css.menu}>
          {notAuthorizedMenuList.map((menu) => {
            const isActive = menu.link === activeMenu;
            return (
              <li
                key={menu.link}
                className={css.linkListItem}
                onClick={() => handleMenuItemClick(menu.link)}
              >
                {isActive && <div className={css.activeLinkBorder} />}
                <div>
                  <span
                    className={cn(css.link, {
                      [css.activeMenu]: isActive,
                    })}
                  >
                    <Text
                      color={
                        isMenuWithBg ? Text.COLOR.WHITE : Text.COLOR.PRIMARY
                      }
                      fontSize={Text.FONT_SIZE.SIZE_17}
                      fontWeight={
                        isActive ? Text.FONT_WEIGHT.BOLD : Text.FONT_WEIGHT.THIN
                      }
                    >
                      <TextLocal id={menu.value} />
                    </Text>
                  </span>
                </div>
              </li>
            );
          })}
        </ul>
        <div className={css.singInActions}>
          {isMenuWithBg && !isSticky ? (
            <Link href={SIGN_IN_ROUTE.getLink()}>
              <a>
                <button className={css.loginButton}>
                  <TextLocal id={'landing-menu.log-in'} />
                </button>
              </a>
            </Link>
          ) : (
            <Link href={SIGN_IN_ROUTE.getLink()}>
              <a>
                <Button
                  tagComponent={Button.TAG.SPAN}
                  height={Button.HEIGHT.SMALL}
                  color={Button.COLOR.SECONDARY}
                >
                  <Text
                    fontSize={Text.FONT_SIZE.SIZE_16}
                    fontWeight={Text.FONT_WEIGHT.BOLD}
                    lineHeight={Text.LINE_HEIGHT.LINE_HEIGHT_1_6}
                  >
                    <TextLocal id={'landing-menu.log-in'} />
                  </Text>
                </Button>
              </a>
            </Link>
          )}

          <Link href={SIGN_UP_ROUTE.getLink()}>
            <a>
              <Button
                tagComponent={Button.TAG.SPAN}
                height={Button.HEIGHT.SMALL}
              >
                <Text
                  fontSize={Text.FONT_SIZE.SIZE_16}
                  fontWeight={Text.FONT_WEIGHT.BOLD}
                >
                  <TextLocal id={'landing.start-free-trail'} />
                </Text>
              </Button>
            </a>
          </Link>
        </div>
      </span>
      {isMaxWidth1100 && <MobileMenu user={user} activeMenu={activeMenu} />}
    </>
  );
};

UnauthorizedHeader.propTypes = {
  user: PropTypes.object.isRequired,
};

export default UnauthorizedHeader;
