import {useRouter} from 'next/router';
import {useCallback} from 'react';
import {useDispatch} from 'react-redux';

import {RESPONSE_STATUSES} from '@/src/app/constants/api';
import {changeLocalAction} from '@/src/app/store/user/userReducer';
import useUserActions from '@/src/app/store/user/useUserActions';
import {getAuthorizationToken} from '@/src/app/utils/authorizationToken';
import changeLocaleAction from '@/src/authentication/footer/utils/changeLocaleAction';
import useRoutePaths from '@/src/hooks/useRoutePaths';

import {setLocaleToStorage} from '../../pages/index';

const getCurrentPath = (path, locales) => {
  let preparedPath = path;

  locales.forEach((item) => {
    preparedPath = preparedPath.replace(`/${item}`, '');
  });

  return preparedPath;
};

const getTranslatedPath = (
  newLocal,
  pathname,
  currentPath,
  query,
  paths,
  locales
) => {
  const existQueries = [];

  let preparedCurrentPath = getCurrentPath(window.location.pathname, locales);
  const pathSearch = window.location.search;

  const serverPath = pathname
    .split('/')
    .reduce((previous, current) => {
      let newPath = current;
      if (newPath.includes('[')) {
        newPath = newPath.replace('[', ':');
        newPath = newPath.replace(']', '');
        const newExistQuery = newPath.replace(':', '');
        existQueries.push(newExistQuery);
      }

      return [...previous, newPath];
    }, [])
    .join('/');

  const existPaths = Object.values(paths).reduce(
    (previousValue, currentValue) => {
      // @ts-ignore
      if (!currentValue.paths) {
        return previousValue;
      }
      // @ts-ignore
      return [...previousValue, currentValue.paths];
    },
    []
  );

  // @ts-ignore
  let newPathObjet = existPaths.find((path) => {
    return (
      path.destination === serverPath ||
      Object.values(path).includes(preparedCurrentPath)
    );
  });

  newPathObjet = newPathObjet || {
    destination: preparedCurrentPath,
    en: preparedCurrentPath,
    de: preparedCurrentPath,
  };

  if (!existQueries.length && existQueries.includes('...slug')) {
    return newPathObjet[newLocal];
  }

  const preparedPath = existQueries.reduce((previous, current) => {
    return previous.replace(`:${current}`, query[current]);
  }, newPathObjet[newLocal]);

  return `${preparedPath}${pathSearch}`;
};

const useLocale = () => {
  const paths = useRoutePaths();
  const dispatch = useDispatch();

  const {changeLocale} = useUserActions();
  const {
    push,
    locale,
    asPath: currentPath,
    pathname,
    query,
    locales,
    replace,
  } = useRouter();
  const authorizationToken = getAuthorizationToken();

  const setNewLocaleWithReload = useCallback(
    async (changeLocale, push, newLocale, currentPath) => {
      const translatedPath = getTranslatedPath(
        newLocale,
        pathname,
        currentPath,
        query,
        paths,
        locales
      );
      dispatch(changeLocalAction(newLocale));
      setLocaleToStorage(newLocale);

      replace(translatedPath, undefined, {locale: newLocale});
    },
    [locale, authorizationToken, currentPath, paths]
  );

  const handleChangeLang = useCallback(
    async (newLocale) => {
      if (authorizationToken) {
        const {status} = await changeLocaleAction(newLocale);

        if (status === RESPONSE_STATUSES.SUCCESS) {
          setNewLocaleWithReload(changeLocale, push, newLocale, currentPath);
        }
      } else {
        setNewLocaleWithReload(changeLocale, push, newLocale, currentPath);
      }
    },
    [locale, authorizationToken, currentPath, paths]
  );

  return {
    currentLocale: locale,
    handleChangeLang,
  };
};

export default useLocale;
