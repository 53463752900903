import {sbEditable} from '@storyblok/storyblok-editable';
import PropTypes from 'prop-types';

import DynamicComponent from '../../../dynamicComponents/components/DynamicComponent';
import {Text} from '../../../ui';
import css from '../../styles/InfoSection.module.css';

const InfoSection = ({blok}) => {
  const {title, description, rightSideContent} = blok;

  return (
    <div className={css.infoSection} {...sbEditable(blok)}>
      <div className={css.content}>
        <div className={css.title}>
          <Text
            fontSize={Text.FONT_SIZE.SIZE_32}
            fontWeight={Text.FONT_WEIGHT.BOLD}
            color={Text.COLOR.WHITE}
            lineHeight={Text.LINE_HEIGHT.LINE_HEIGHT_1_4}
            tagComponent={Text.TAG.H2}
          >
            {title}
          </Text>
        </div>
        <div className={css.text}>
          <Text
            fontSize={Text.FONT_SIZE.SIZE_18}
            color={Text.COLOR.WHITE}
            lineHeight={Text.LINE_HEIGHT.LINE_HEIGHT_1_6}
          >
            {description}
          </Text>
        </div>
      </div>
      <div className={css.actions}>
        {rightSideContent
          ? rightSideContent.map((blok) => (
              <DynamicComponent blok={blok} key={blok._uid} />
            ))
          : null}
      </div>
    </div>
  );
};

InfoSection.propTypes = {
  blok: PropTypes.object.isRequired,
};

export default InfoSection;
