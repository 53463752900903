import TextLocal from '@/src/textLocal/containers/TextLocal';
import {Button, Text} from '@/src/ui';

import css from '../styles/ConfirmCancelOpenForJobsPopup.module.css';

interface ConfirmCancelOpenForJobsPopupProps {
  onCancelClick: (...args: any[]) => any;
  onConfirmClick: (...args: any[]) => any;
}

const ConfirmCancelOpenForJobsPopup = ({
  onCancelClick,
  onConfirmClick,
}: ConfirmCancelOpenForJobsPopupProps) => {
  return (
    <div className={css.confirmPopup}>
      <div className={css.mainText}>
        <Text
          fontSize={Text.FONT_SIZE.SIZE_24}
          fontWeight={Text.FONT_WEIGHT.MEDIUM}
          fontFamily={Text.FONT_FAMILY.RUBIK}
        >
          <TextLocal id={'open-opportunities.not-interested-in-new-job'} />
        </Text>
      </div>
      <div className={css.descriptionText}>
        <Text fontSize={Text.FONT_SIZE.SIZE_16} color={Text.COLOR.GREY}>
          <TextLocal
            id={'open-opportunities.are-you-sure-you-want-to-switch'}
          />
        </Text>
      </div>
      <div className={css.buttons}>
        <div className={css.button}>
          <Button onClick={onCancelClick} height={Button.HEIGHT.SMALL}>
            <Text
              fontSize={Text.FONT_SIZE.SIZE_16}
              fontWeight={Text.FONT_WEIGHT.BOLD}
            >
              <TextLocal id={'open-opportunities.no'} />
            </Text>
          </Button>
        </div>
        <div className={css.button}>
          <Button
            onClick={onConfirmClick}
            height={Button.HEIGHT.SMALL}
            border={Button.BORDER.BOLD}
            color={Button.COLOR.RED}
          >
            <Text
              fontSize={Text.FONT_SIZE.SIZE_16}
              fontWeight={Text.FONT_WEIGHT.BOLD}
            >
              <TextLocal id={'open-opportunities.yes'} />
            </Text>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmCancelOpenForJobsPopup;
