import cn from 'clsx';
import PropTypes from 'prop-types';

import css from '../styles/ProgressLine.module.css';

const PROGRESS = {
  ONE_THIRD: 'oneThird',
  TWO_THIRDS: 'twoThirds',
  ONE_QUARTER: 'oneQuarter',
  TWO_QUARTERS: 'twoQuarters',
  THIRD_QUARTER: 'thirdQuarter',
  FULL: 'full',
};

const ProgressLine = ({progress}) => {
  return (
    <div className={css.main}>
      <div className={cn(css.line, css[progress])} />
    </div>
  );
};

export default ProgressLine;

ProgressLine.propTypes = {
  progress: PropTypes.oneOf(Object.values(PROGRESS)).isRequired,
};

ProgressLine.defaultProps = {
  progress: PROGRESS.ONE_THIRD,
};

ProgressLine.PROGRESS = PROGRESS;
