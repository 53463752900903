import cn from 'clsx';
import Link from 'next/link';
import {useRouter} from 'next/router';
import {useTranslation} from 'next-i18next';
import PropTypes from 'prop-types';
import {useCallback, useContext, useMemo} from 'react';

import AddCourseIcon from '@/public/images/addCourseIcon.svg';
import Search from '@/public/images/searchIcon.svg';
import SearchWhite from '@/public/images/searchIconWhite.svg';
import {NEW_COURSE_ID} from '@/src/app/constants/course';
import {ROLES_NAMES} from '@/src/app/constants/roles';
import useUserActions from '@/src/app/store/user/useUserActions';
import {StickyContext} from '@/src/header/components/HeaderLayout';
import useIsMobile from '@/src/hooks/useIsMobile';
import useRoutePaths from '@/src/hooks/useRoutePaths';
import {NEW_POSITION} from '@/src/jobPortal/common/containers/SinglePositionBootstrap';
import TextLocal from '@/src/textLocal/containers/TextLocal';
import Dropdown, {DropdownItem} from '@/src/ui/dropdown/components/Dropdown';

import {Button, Text} from '../../ui';
import MobileMenu from '../containers/MobileMenu';
import ProfileDesktopMenu from '../containers/ProfileDesktopMenu';
import css from '../styles/header.module.css';
import Menu from './Menu';

const AuthorizedHeader = ({userInitials, picture, user, isRootPage}) => {
  const {push, asPath: pathname} = useRouter();
  const isSticky = useContext(StickyContext);
  const {t} = useTranslation('common');
  const {isMaxWidth1100, isMaxWidth1400} = useIsMobile();
  const {
    user: {permissions},
  } = useUserActions();
  const {
    SEARCH_UNITED_ROUTE,
    CREATOR_STUDIO_FIRST_STEP,
    JOB_PORTAL_MY_POSITION_EDIT,
  } = useRoutePaths();

  const isClinic = useMemo(
    () => user.user.type === ROLES_NAMES.CLINIC,
    [user?.user?.type]
  );

  const ADD_NEW_CONTENT_ITEMS = useMemo(
    () => [
      {
        label: t('header.add-course'),
        value: CREATOR_STUDIO_FIRST_STEP.getLink(NEW_COURSE_ID),
      },
      {
        label: t('job-portal.add-positions'),
        value: JOB_PORTAL_MY_POSITION_EDIT.getLink(NEW_POSITION),
      },
    ],
    [CREATOR_STUDIO_FIRST_STEP, JOB_PORTAL_MY_POSITION_EDIT, t]
  );

  const handleAddClick = useCallback((link) => {
    push(link);
  }, []);

  return (
    <>
      <Menu isRootPage={isRootPage && !isSticky} />
      <div className={css.actions}>
        {!isMaxWidth1100 && (
          <Link href={SEARCH_UNITED_ROUTE.getLink()}>
            <a className={css.searchIcon} aria-label={'search icon'}>
              {isRootPage ? <SearchWhite /> : <Search />}
            </a>
          </Link>
        )}
        {user.user.type === ROLES_NAMES.COURSE_CREATOR && !isClinic && (
          <div
            className={cn({
              [css.disbledAddCourse]: !permissions?.accessCreateCourse,
            })}
          >
            <Link href={CREATOR_STUDIO_FIRST_STEP.getLink(NEW_COURSE_ID)}>
              <a className={css.addCourse} aria-label={'add course'}>
                {isMaxWidth1100 ? (
                  <div>
                    <AddCourseIcon />
                  </div>
                ) : (
                  <Button
                    tagComponent={Button.TAG.DIV}
                    color={Button.COLOR.SECONDARY}
                    height={Button.HEIGHT.SMALL}
                  >
                    <Text
                      fontWeight={Text.FONT_WEIGHT.BOLD}
                      fontSize={Text.FONT_SIZE.SIZE_14}
                    >
                      <TextLocal id={'header.plus-add-course'} />
                    </Text>
                  </Button>
                )}
              </a>
            </Link>
          </div>
        )}

        {isClinic &&
          (isMaxWidth1100 ? (
            <div
              className={cn(css.mobileSelectOptionIconWrapper, {
                [css.disableSelectOptionIconWrapper]:
                  !permissions?.accessCreateCourse,
              })}
            >
              <Dropdown
                isLeftSide={isMaxWidth1400}
                toggleNode={
                  <div className={css.mobileSelectOptionIcon}>
                    <AddCourseIcon />
                  </div>
                }
              >
                {ADD_NEW_CONTENT_ITEMS.map((content) => (
                  <DropdownItem
                    key={content.value}
                    onClink={() => {
                      handleAddClick(content.value);
                    }}
                    isActive={pathname.includes(content.value)}
                  >
                    {/*@ts-ignore*/}
                    {content.label}
                  </DropdownItem>
                ))}
              </Dropdown>
            </div>
          ) : (
            <div
              className={cn(css.addWithHover, {
                [css.addWithHoverDisable]: !permissions?.accessCreateCourse,
              })}
            >
              <Dropdown
                isLeftSide={isMaxWidth1400}
                toggleNode={
                  <Button
                    tagComponent={Button.TAG.DIV}
                    color={
                      isRootPage
                        ? Button.COLOR.WHITE_SECONDARY
                        : Button.COLOR.SECONDARY
                    }
                    height={Button.HEIGHT.SMALL}
                  >
                    <Text
                      fontWeight={Text.FONT_WEIGHT.BOLD}
                      fontSize={Text.FONT_SIZE.SIZE_14}
                    >
                      <div className={css.buttonWithHoverEffect}>
                        + <TextLocal id={'general.add'} />
                        <span
                          className={cn(css.current, {
                            [css.whiteCurret]: isRootPage,
                          })}
                        />
                      </div>
                    </Text>
                  </Button>
                }
              >
                {ADD_NEW_CONTENT_ITEMS.map((content) => (
                  <DropdownItem
                    key={content.value}
                    onClink={() => {
                      handleAddClick(content.value);
                    }}
                    isActive={pathname.includes(content.value)}
                  >
                    {/*@ts-ignore*/}
                    {content.label}
                  </DropdownItem>
                ))}
              </Dropdown>
            </div>
          ))}

        {!isMaxWidth1100 && (
          <span className={css.profileWrapper}>
            {picture ? (
              <img
                src={picture}
                loading="lazy"
                alt={'profile image'}
                className={css.profileAvatar}
              />
            ) : (
              <Text fontWeight={Text.FONT_WEIGHT.BOLD}>{userInitials}</Text>
            )}
            <div className={css.profileDesktopMenu}>
              <ProfileDesktopMenu />
            </div>
          </span>
        )}
        {isMaxWidth1100 && <MobileMenu user={user} />}
      </div>
    </>
  );
};

AuthorizedHeader.propTypes = {
  userInitials: PropTypes.string,
  picture: PropTypes.string,
  user: PropTypes.object,
};

AuthorizedHeader.defaultProps = {
  userInitials: '',
};

export default AuthorizedHeader;
