import {useRouter} from 'next/router';
import PropTypes from 'prop-types';

import LogoWhiteIcon from '@/public/images/logoWhite.svg';
import {LOCALES} from '@/src/app/constants/locales';
import SocialLinks from '@/src/footer/components/Social';
import useRoutePaths from '@/src/hooks/useRoutePaths';
import {SponsorsStandAlone} from '@/src/promoSections/components/SponsorsStandAlone';
import TextLocal from '@/src/textLocal/containers/TextLocal';

import {SectionContainer, Text} from '../../ui';
import {CONTACT_US} from '../constants';
import css from '../styles/footer.module.css';
import FooterBottom from './FooterBottom';
import FooterLinks from './FooterLinks';
import SubscribeEmail from './SubscribeEmail';

const Footer = ({
  handleChangeLang,
  locale,
  currentYear,
  subscribeToNewsLetters,
  isHideSubscribe,
}) => {
  const {
    PRIVACY_POLICY_ROUTE,
    TERMS_AND_CONDITIONS_ROUTE,
    IMPRINT_ROUTE,
    HOW_T0,
  } = useRoutePaths();
  const {asPath} = useRouter();

  const LEGAL_LINKS = [
    {
      href: IMPRINT_ROUTE.getLink(),
      name: 'footer.imprint',
    },
    {
      href: PRIVACY_POLICY_ROUTE.getLink(),
      name: 'footer.privacy-policy',
    },
    {
      href: TERMS_AND_CONDITIONS_ROUTE.getLink(),
      name: 'footer.terms-use',
    },
  ];

  const ADVISER_LINKS = [
    {
      href: HOW_T0.path,
      name: 'wiki-how.title',
    },
  ];

  return (
    <>
      {!asPath.includes('_storyblok') && <SponsorsStandAlone />}

      <footer className={css.footer}>
        <SectionContainer>
          <div className={css.infoWrapper}>
            <div className={css.firstColumn}>
              <div>
                <div className={css.footerLogo}>
                  <LogoWhiteIcon />
                </div>
                <div className={css.companyText}>
                  <Text
                    color={Text.COLOR.WHITE}
                    fontSize={Text.FONT_SIZE.SIZE_16}
                    fontFamily={Text.FONT_FAMILY.RUBIK}
                    lineHeight={Text.LINE_HEIGHT.LINE_HEIGHT_24}
                  >
                    <TextLocal id={'footer.text-about-company'} />
                  </Text>
                </div>
              </div>
              {!isHideSubscribe && (
                <SubscribeEmail
                  subscribeToNewsLetters={subscribeToNewsLetters}
                />
              )}
            </div>

            <div>
              <FooterLinks title={'footer.legal'} list={LEGAL_LINKS} />
              <FooterLinks title={'footer.advisors'} list={ADVISER_LINKS} />
            </div>

            <div className={css.contactInfo}>
              <FooterLinks title={CONTACT_US.title} list={CONTACT_US.links} />
              <SocialLinks />
            </div>
          </div>
          <FooterBottom
            locale={locale}
            handleChangeLang={handleChangeLang}
            currentYear={currentYear}
          />
        </SectionContainer>
      </footer>
    </>
  );
};

export default Footer;

Footer.propTypes = {
  locale: PropTypes.oneOf(Object.values(LOCALES)).isRequired,
  handleChangeLang: PropTypes.func.isRequired,
  subscribeToNewsLetters: PropTypes.func.isRequired,
  currentYear: PropTypes.number.isRequired,
  isHideSubscribe: PropTypes.bool.isRequired,
};
