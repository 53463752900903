import {useField} from 'formik';
import {useCallback} from 'react';

import PhoneInputLayout from '@/src/ui/phoneInput/components/PhoneInputLayout';

interface PhoneInputProps {
  name: string;
  placeholder?: string;
}

const PhoneInput = ({name, placeholder}: PhoneInputProps) => {
  const [, meta, helpers] = useField(name);
  const {setValue} = helpers;

  const handleInputChange = useCallback(
    ({withMask}) => {
      setValue(withMask || '');
    },
    [setValue]
  );

  return (
    <PhoneInputLayout
      value={meta?.value}
      onInputChange={handleInputChange}
      placeholder={placeholder}
    />
  );
};

export default PhoneInput;
