import {sbEditable} from '@storyblok/storyblok-editable';
import cn from 'clsx';
import {useRouter} from 'next/router';
import PropTypes from 'prop-types';
import {pathOr} from 'ramda';
import {useState} from 'react';

import useRoutePaths from '@/src/hooks/useRoutePaths';
import SidebarItem from '@/src/howTo/components/sideBar/SidebarItem';
import SidebarItemWithSubmenu from '@/src/howTo/components/sideBar/SidebarItemWithSubmenu';

import css from '../../styles/HowToiSideBar.module.css';

const HowToSideBar = ({blok, borderOff}) => {
  const {list} = blok;
  const {asPath} = useRouter();
  const {HOW_T0} = useRoutePaths();
  const [activeCategory, setActiveCategory] = useState('');

  return (
    <div key={blok.uuid} {...sbEditable(blok)}>
      {list.map((item, index) => {
        const link = `/${pathOr('', ['story', 'full_slug'], item.href)}`;
        return !item.menuList.length ? (
          <div
            key={item._uid}
            className={cn(css.sidebarItem, {
              [css.borderOff]: borderOff,
            })}
          >
            <SidebarItem
              order={index + 1}
              href={link}
              title={item.title}
              isActive={link.includes(asPath) && asPath !== HOW_T0.path}
            />
          </div>
        ) : (
          <div
            className={cn(css.sidebarItem, {
              [css.borderOff]: borderOff,
            })}
          >
            <SidebarItemWithSubmenu
              key={item._uid}
              {...item}
              order={index + 1}
              activeMenu={activeCategory}
              setActiveMenu={setActiveCategory}
            />
          </div>
        );
      })}
    </div>
  );
};

HowToSideBar.propTypes = {
  blok: PropTypes.object.isRequired,
  borderOff: PropTypes.bool,
};

export default HowToSideBar;
