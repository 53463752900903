import {REST_METHODS} from '@/src/app/constants/api';
import {STORAGE_KEY} from '@/src/app/utils/authorizationToken';
import authorizedFetch from '@/src/app/utils/authorizedFetch';
import nextServerFetch from '@/src/app/utils/nextServerFetch';

const requestLogout = async () => {
  // @ts-ignore
  const {status, responseBody} = await authorizedFetch({
    method: REST_METHODS.DELETE,
    path: `/api/v1/auth/sign_out`,
  });

  await nextServerFetch({
    method: REST_METHODS.DELETE,
    path: '/api/cookies',
    body: {
      name: STORAGE_KEY,
    },
  });

  return {
    status,
    responseBody,
  };
};

export default requestLogout;
