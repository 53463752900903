import {FC, memo} from 'react';

import useIsMobile from '@/src/hooks/useIsMobile';
import {Text} from '@/src/ui';

interface HeroTitleProps {
  title: string;
}
const HeroTitle: FC<HeroTitleProps> = ({title}) => {
  const {isMaxWidth600} = useIsMobile();

  return (
    <Text
      fontSize={isMaxWidth600 ? Text.FONT_SIZE.SIZE_32 : Text.FONT_SIZE.SIZE_44}
      color={Text.COLOR.WHITE}
      fontWeight={Text.FONT_WEIGHT.MEDIUM}
      lineHeight={Text.LINE_HEIGHT.LINE_HEIGHT_1_6}
      fontFamily={Text.FONT_FAMILY.RUBIK}
      tagComponent={Text.TAG.H1}
    >
      {title}
    </Text>
  );
};

export default memo(HeroTitle);
