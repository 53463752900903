import {useRouter} from 'next/router';
import {useCallback, useContext, useEffect, useMemo} from 'react';

import {ORDER_FILTER, SEARCH_FILTERS} from '@/src/app/constants/search';
import useEmploymentTypes from '@/src/hooks/useEmploymentTypes';
import OtherClinicPositionsLayout from '@/src/jobPortal/targetPosition/components/OtherClinicPositionsLayout';
import useTargetClinic from '@/src/profile/clinicTarget/hooks/useTargetClinic';
import RouteContext from '@/src/routes/utils/RouteContext';

interface OtherClinicPositionsProps {
  clinicId: number;
  excludePositionId: number;
}

const MAX_POSITIONS_LENGTH = 3;

const OtherClinicPositions = ({
  clinicId,
  excludePositionId,
}: OtherClinicPositionsProps) => {
  const {push, locale} = useRouter();
  const {clinicVacancies, requestClinicVacancies} = useTargetClinic();
  const {employmentTypes} = useEmploymentTypes();
  // @ts-ignore
  const {SEARCH_CLINIC_VACANCIES} = useContext(RouteContext);

  const clinicDataWidget = useMemo(
    () =>
      clinicVacancies.data
        .filter((vacancy) => vacancy.id !== excludePositionId)
        .slice(0, MAX_POSITIONS_LENGTH),
    [clinicVacancies, excludePositionId]
  );

  const handleSeeAllClick = useCallback(() => {
    push({
      pathname: SEARCH_CLINIC_VACANCIES.getLink(),
      query: {
        [SEARCH_FILTERS.OFFSET]: '0',
        [SEARCH_FILTERS.ORDER]: ORDER_FILTER.DESC,
        [SEARCH_FILTERS.LANGUAGE]: locale,
        [SEARCH_FILTERS.CLINIC_ID]: `${clinicId}`,
      },
    });
  }, [clinicId]);

  useEffect(() => {
    requestClinicVacancies(clinicId);
  }, [clinicId]);

  if (!clinicVacancies?.isFetched || !clinicDataWidget?.length) {
    return null;
  }

  return (
    <OtherClinicPositionsLayout
      employmentTypes={employmentTypes}
      locale={locale}
      onSeeAllClick={handleSeeAllClick}
      positions={clinicDataWidget}
      withSeeAllButton={clinicVacancies.totalNumber > MAX_POSITIONS_LENGTH}
      onSuccessfulApplicationCallback={() => requestClinicVacancies(clinicId)}
    />
  );
};

export default OtherClinicPositions;
