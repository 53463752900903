import {FC, memo} from 'react';

import {Text} from '@/src/ui';

interface HeroSubTitleProps {
  subTitle: string;
}

const HeroSubTitle: FC<HeroSubTitleProps> = ({subTitle}) => {
  return (
    <Text
      fontSize={Text.FONT_SIZE.SIZE_18}
      color={Text.COLOR.WHITE}
      fontWeight={Text.FONT_WEIGHT.THIN}
    >
      <span
        dangerouslySetInnerHTML={{
          __html: subTitle,
        }}
      ></span>
    </Text>
  );
};

export default memo(HeroSubTitle);
