import moment from 'moment';
import {pathOr} from 'ramda';
import {useCallback, useEffect, useState} from 'react';

import {SERVER_FORMAT} from '@/src/app/constants/date';
import {ROLES_NAMES} from '@/src/app/constants/roles';
import SUBSCRIPTION_STATUSES, {
  SUBSCRIPTION_STRIPE_STATUSES,
} from '@/src/app/constants/subscriptionStatuses';
import useSubscriptionActions from '@/src/app/store/subscription/useSubscriptionActions';
import useUserActions from '@/src/app/store/user/useUserActions';
import getDaysBeforeExpire from '@/src/app/utils/getDaysBeforeExpire';

const DAYS_TRIAL_KEY = 'DAYS_TRIAL_KEY';
const TODAY = moment().format(SERVER_FORMAT);

const useSubscribeBannerStatus = () => {
  const {user} = useUserActions();
  const {subscription} = useSubscriptionActions();
  const [isShow, setIsShow] = useState(false);
  const [leftDays, setLeftDays] = useState(0);
  const [isSubscribeEnded, setIsSubscribeEnded] = useState(false);

  const userID = pathOr('', ['user', 'id'], user);
  const DAYS_TRIAL_KEY_USER = `${DAYS_TRIAL_KEY}_${userID}`;

  const setTrialInitialState = useCallback(
    (endTrial) => {
      const leftDays = getDaysBeforeExpire(
        moment(TODAY, SERVER_FORMAT).toDate(),
        endTrial
      );
      setIsShow(Boolean(leftDays > 0));
      setLeftDays(leftDays);
    },
    [TODAY]
  );

  const onCloseClick = useCallback(() => {
    localStorage.setItem(DAYS_TRIAL_KEY_USER, JSON.stringify(TODAY));
    setIsShow(false);
  }, [TODAY]);

  useEffect(() => {
    const daysTrialKey = JSON.parse(localStorage.getItem(DAYS_TRIAL_KEY_USER));
    const userSubscriptionInfo = subscription?.subscription;

    if (!user.user.id && !userSubscriptionInfo) {
      setIsShow(false);
    }

    if (
      userSubscriptionInfo?.status === SUBSCRIPTION_STATUSES.EXPIRED &&
      user?.user?.type !== ROLES_NAMES.COURSE_CREATOR
    ) {
      setIsSubscribeEnded(true);
    } else {
      setIsSubscribeEnded(false);
    }

    if (
      userSubscriptionInfo?.stripe_status ===
        SUBSCRIPTION_STRIPE_STATUSES.TRIALING &&
      daysTrialKey !== TODAY &&
      !subscription?.billing_profile
    ) {
      setTrialInitialState(userSubscriptionInfo.stripe_trial_end);
    } else {
      setIsShow(false);
    }
  }, [setTrialInitialState, user, subscription]);

  return {
    isShow,
    leftDays,
    onCloseClick,
    isSubscribeEnded,
  };
};

export default useSubscribeBannerStatus;
