import {REST_METHODS} from '@/src/app/constants/api';
import authorizedFetch from '@/src/app/utils/authorizedFetch';

/**
 * @param {boolean} currentStatus - is in bookMark - true; is not in bookMark -false
 * @param {boolean, number} courseId - course ID
 */
const requestToggleCourseBookmark = (courseId, currentStatus) => {
  const method = currentStatus ? REST_METHODS.DELETE : REST_METHODS.POST;
  const apiEndpoint = currentStatus
    ? '/api/v1/courses/bookmarks/delete_bookmarked_course'
    : '/api/v1/courses/bookmarks';

  // @ts-ignore
  return authorizedFetch({
    method: method,
    path: apiEndpoint,
    body: {
      course_id: courseId,
    },
    shouldSkipUnauthorizedStatus: true,
  });
};

export default requestToggleCourseBookmark;
