import isImage from 'is-image';
import PropTypes from 'prop-types';

import ImageFile from '@/src/howTo/components/fileWithPreview/ImageFile';
import VideoFile from '@/src/howTo/components/fileWithPreview/VideoFile';
import {Text} from '@/src/ui';

import css from '../../styles/FileWithPreview.module.css';

const FileWithPreview = ({blok}) => {
  const {file, description} = blok;

  if (!file.filename) {
    return null;
  }

  return (
    <div className={css.main}>
      <div className={css.mediaWrapper}>
        {isImage(file.filename) ? (
          <ImageFile src={file.filename} />
        ) : (
          <VideoFile src={file.filename} />
        )}
      </div>
      <Text fontSize={Text.FONT_SIZE.SIZE_12} color={Text.COLOR.GREY}>
        {description}
      </Text>
    </div>
  );
};

export default FileWithPreview;

FileWithPreview.propTypes = {
  blok: PropTypes.object.isRequired,
};
