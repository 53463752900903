import {useRouter} from 'next/router';
import {useTranslation} from 'next-i18next';
import PropTypes from 'prop-types';
import {useCallback, useContext, useRef, useState} from 'react';
import {useDispatch} from 'react-redux';

import {RESPONSE_STATUSES} from '@/src/app/constants/api';
import {setClinicData} from '@/src/app/store/clinic/clinicDataSlice';
import useUserActions from '@/src/app/store/user/useUserActions';
import getRootServerUrl from '@/src/app/utils/getRootServerUrl';
import toBase64 from '@/src/app/utils/toBase64';
import useLoading from '@/src/hooks/useLoading';
import useRoutePaths from '@/src/hooks/useRoutePaths';
import PopupContext from '@/src/popup/utils/PopupContext';
import fetchClinicData from '@/src/signUp/additionalInfo/utils/fetchClinicData';
import fetchUserAction from '@/src/signUp/paymentDetails/utils/fetchUserAction';

import EditProfileImagePopupLayout from '../components/EditProfileImagePopupLayout';
import {PICTURE_POPUP_TYPES} from '../constants/picturePopupTypes';
import deleteClinicImage from '../utils/deleteClinicImage';
import deleteProfileImage from '../utils/deleteProfileImage';
import {getCroppedImage} from '../utils/getCroppedImage';
import postClinicImages from '../utils/postClinicImages';
import postProfileImages from '../utils/postProfileImages';

const MAX_FILE_SIZE = 5000000;

const EditProfileImagePopup = ({image, type, isClinic, clinicId}) => {
  const dispatch = useDispatch();
  const {t} = useTranslation('common');
  const {isLoading, setLoading} = useLoading();
  const fileInputRef = useRef(null);
  const [errorMessage, setErrorMessage] = useState('');

  const {setUserData} = useUserActions();
  const {push} = useRouter();
  const [newImage, setNewImage] = useState(
    image ? `${getRootServerUrl()}${image}` : null
  );
  const [newImageFile, setNewImageFile] = useState(null);
  const [crop, setCrop] = useState({x: 0, y: 0});
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const {closePopup} = useContext(PopupContext);
  const {SIGN_IN_ROUTE} = useRoutePaths();

  const handleCropComplete = useCallback(
    (croppedArea, croppedAreaPixels) => {
      setCroppedAreaPixels(croppedAreaPixels);
    },
    [setCroppedAreaPixels]
  );

  const resetErrorMessage = () => {
    setErrorMessage('');
  };

  const handleImageChange = useCallback(async (event) => {
    resetErrorMessage();

    if (!event.target.files || !event.target.files.length) return;

    const file = event.target.files[0];

    if (file.size > MAX_FILE_SIZE) {
      return setErrorMessage(t('create-course.max-image-size'));
    }

    resetErrorMessage();
    setNewImageFile(file);
    let imageDataUrl = await toBase64(file);

    setNewImage(imageDataUrl);
  }, []);

  const handleNewImageClick = useCallback(() => {
    resetErrorMessage();
    fileInputRef.current.click();
  }, []);

  const handleCropChange = useCallback(
    (newCrop) => {
      resetErrorMessage();
      setCrop(newCrop);
    },
    [setCrop]
  );

  const handleZoomChange = useCallback(
    (newZoom) => {
      resetErrorMessage();
      setZoom(newZoom);
    },
    [setZoom]
  );

  const fetchUserData = useCallback(async () => {
    const {status: fetchUserStatus, responseBody} = await fetchUserAction();

    if (fetchUserStatus === RESPONSE_STATUSES.SUCCESS) {
      setUserData(responseBody);
    } else {
      push(SIGN_IN_ROUTE.getLink());
    }

    if (isClinic) {
      const {status: clinicStatus, responseBody: clinicData} =
        await fetchClinicData();

      if (clinicStatus === RESPONSE_STATUSES.SUCCESS) {
        dispatch(
          setClinicData({
            clinicData: clinicData,
            isFetched: true,
          })
        );
      }
    }

    closePopup();
  }, [closePopup, push, isClinic]);

  const handleApplyClick = useCallback(async () => {
    resetErrorMessage();

    const originalPictureFieldName =
      type === PICTURE_POPUP_TYPES.PICTURE
        ? 'original_picture'
        : 'original_cover_image';
    const croppedImageFieldName =
      type === PICTURE_POPUP_TYPES.PICTURE ? 'picture' : 'cover_image';

    setLoading(true);

    const croppedImageFile = await getCroppedImage(newImage, croppedAreaPixels);

    const fetchMethod = isClinic ? postClinicImages : postProfileImages;

    const {status, responseBody} = await fetchMethod(
      {
        ...(newImageFile ? {[originalPictureFieldName]: newImageFile} : {}),
        [croppedImageFieldName]: croppedImageFile,
      },
      clinicId
    );

    if (status === RESPONSE_STATUSES.SUCCESS) {
      await fetchUserData();
    }

    if (responseBody && responseBody.error) {
      setErrorMessage(responseBody.error);
    }

    setLoading(false);
  }, [
    isClinic,
    clinicId,
    newImage,
    croppedAreaPixels,
    fetchUserData,
    newImageFile,
    setLoading,
    type,
  ]);

  const handleDeleteImageClick = useCallback(async () => {
    resetErrorMessage();

    const fetchMethod = isClinic ? deleteClinicImage : deleteProfileImage;

    const {status} = await fetchMethod(type, clinicId);

    if (status === RESPONSE_STATUSES.SUCCESS) {
      await fetchUserData();
    }
  }, [type, fetchUserData, isClinic, clinicId]);

  return (
    <EditProfileImagePopupLayout
      newImage={newImage}
      onImageChange={handleImageChange}
      fileInputRef={fileInputRef}
      onNewImageClick={handleNewImageClick}
      crop={crop}
      onCropChange={handleCropChange}
      zoom={zoom}
      onZoomChange={handleZoomChange}
      onCropComplete={handleCropComplete}
      onApplyClick={handleApplyClick}
      isLoading={isLoading}
      popupType={type}
      onDeleteImageClick={handleDeleteImageClick}
      isApplyButtonDisabled={!newImage}
      errorMessage={errorMessage}
    />
  );
};

EditProfileImagePopup.TYPES = PICTURE_POPUP_TYPES;

EditProfileImagePopup.propTypes = {
  image: PropTypes.string,
  type: PropTypes.oneOf(Object.values(PICTURE_POPUP_TYPES)),
  isClinic: PropTypes.bool,
  clinicId: PropTypes.number,
};

EditProfileImagePopup.defaultProps = {
  isClinic: false,
};

export default EditProfileImagePopup;
