import {useEffect, useState} from 'react';

import requestStoryBlok from '@/src/app/utils/requestStoryBlok';
import {Sponsors} from '@/src/promoSections/components/Sponsors';

export const SponsorsStandAlone = () => {
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [blok, setBlok] = useState(null);

  const handleGetSponsors = async () => {
    setIsLoading(true);
    try {
      setIsError(false);
      const {data} = await requestStoryBlok({
        slug: 'shared/sponsores-logos',
        query: {},
      });

      setBlok(data.story.content);
    } catch (error) {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    handleGetSponsors();
  }, []);

  if (isLoading || blok === null || isError) {
    return null;
  }

  return <Sponsors blok={blok} />;
};
