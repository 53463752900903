import PropTypes from 'prop-types';

import css from '../styles/TagsList.module.css';
import Tag from './Tag';

interface TagListProps {
  tags: string[];
  color: string;
}

const TagList = ({tags, color}: TagListProps) => {
  const tagsList = tags.map((tag) => (
    <li key={tag}>
      <Tag color={color}>{tag}</Tag>
    </li>
  ));

  return <ul className={css.list}>{tagsList}</ul>;
};

TagList.COLOR = Tag.COlOR;

TagList.propTypes = {
  tags: PropTypes.array,
  color: PropTypes.oneOf(Object.values(TagList.COLOR)),
};

export default TagList;
