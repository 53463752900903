import {useRouter} from 'next/router';

import useUserActions from '@/src/app/store/user/useUserActions';
import useLocale from '@/src/hooks/useLocale';

import Footer from '../components/Footer';
import requestSubscribeUser from '../utils/requestSubscribeUser';

const FooterContainer = () => {
  const {currentLocale, handleChangeLang} = useLocale();
  const currentYear = new Date().getFullYear();
  const {user} = useUserActions();
  const {pathname} = useRouter();

  const subscribeToNewsLetters = async ({email}, {resetForm}) => {
    await requestSubscribeUser(email);
    resetForm();
  };

  return (
    <Footer
      locale={currentLocale}
      handleChangeLang={handleChangeLang}
      currentYear={currentYear}
      subscribeToNewsLetters={subscribeToNewsLetters}
      isHideSubscribe={Boolean(user?.user?.id) || pathname === '/'}
    />
  );
};

export default FooterContainer;
