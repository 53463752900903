import {useRouter} from 'next/router';
import {useTranslation} from 'next-i18next';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import * as Yup from 'yup';

import {RESPONSE_STATUSES} from '@/src/app/constants/api';
import useUserActions from '@/src/app/store/user/useUserActions';
import {prepareTranslatedEmploymentTypes} from '@/src/hooks/useEmploymentTypes';
import PopupContext from '@/src/popup/utils/PopupContext';
import OpenForNewOpportunitiesAddPopupLayout from '@/src/profile/common/components/OpenForNewOpportunitiesAddPopupLayout';
import useOpenForNewOpportunities from '@/src/profile/common/hooks/useOpenForNewOpportunities';
import fetchAvailablePositions from '@/src/profile/common/utils/fetchAvaliablePositions';
import requestGetEmploymentTypes from '@/src/profile/common/utils/requestGetEmploymentTypes';
import requestUpdateOpenForNewOpportunities from '@/src/profile/common/utils/requestUpdateOpenForNewOpportunities';
import fetchCountriesDictionary from '@/src/signUp/emailStep/utils/fetchCountriesDictionary';

interface OpenForNewOpportunitiesAddPopupProps {
  userId: number;
}

const getValidation = (t) =>
  Yup.object().shape({
    positions: Yup.mixed().required(t('validation.field-req')),
    participation: Yup.mixed().required(t('validation.field-req')),
    country: Yup.mixed().required(t('validation.field-req')),
  });

const prepareTranslatedPositions = (
  positions: Array<any>,
  locale: string
): Array<any> => {
  return positions.map((position) => ({
    value: position.id,
    label: position[`name_${locale}`],
  }));
};

const OpenForNewOpportunitiesAddPopup = ({
  userId,
}: OpenForNewOpportunitiesAddPopupProps): JSX.Element => {
  const {locale} = useRouter();
  const {t} = useTranslation('common');
  const {
    isLoading,
    getUserOpenForNewOpportunities,
    positions,
    participation,
    location,
  } = useOpenForNewOpportunities();
  const {requestUserData} = useUserActions();
  const {closePopup} = useContext(PopupContext);

  const [countriesList, setCountries] = useState([]);
  const [participationList, setParticipationList] = useState([]);
  const [positionsList, setPositionsList] = useState([]);
  const [isSubmitLoading, setSubmitLoading] = useState(false);

  useEffect(() => {
    getUserOpenForNewOpportunities(userId);
    getCountries();
    getAvailablePositions();
    getParticipation();
  }, []);

  const getCountries = useCallback(async () => {
    const {responseBody, status} = await fetchCountriesDictionary();

    if (status === RESPONSE_STATUSES.SUCCESS) {
      setCountries(responseBody.data);
    }
  }, [countriesList]);

  const getAvailablePositions = useCallback(async () => {
    const {responseBody, status} = await fetchAvailablePositions();

    if (status === RESPONSE_STATUSES.SUCCESS) {
      setPositionsList(responseBody.data);
    }
  }, []);

  const getParticipation = useCallback(async () => {
    const {status, responseBody} = await requestGetEmploymentTypes();
    if (status === RESPONSE_STATUSES.SUCCESS) {
      setParticipationList(responseBody);
    }
  }, []);

  const handleUpdateOpenForNewOpportunities = useCallback(
    async ({positions, participation, country}) => {
      setSubmitLoading(true);

      const {status} = await requestUpdateOpenForNewOpportunities({
        positions: positions.map((position) => position.value),
        workType: participation.value,
        countryId: country,
      });

      if (status === RESPONSE_STATUSES.SUCCESS) {
        await getUserOpenForNewOpportunities(userId);
        await requestUserData();
        closePopup();
      }

      setSubmitLoading(false);
    },
    [userId]
  );

  const initialData = useMemo(
    () => ({
      positions: positions?.length
        ? positions.map((position) => ({
            value: position.id,
            label: position.name,
          }))
        : null,
      participation: participation
        ? {
            value: participation,
            label: t(`employment.${participation}`),
          }
        : null,
      country: location?.id || null,
    }),
    [positions, participation, location]
  );

  return (
    <OpenForNewOpportunitiesAddPopupLayout
      validationSchema={getValidation(t)}
      isLoading={isLoading}
      positionsList={prepareTranslatedPositions(positionsList, locale)}
      participationList={prepareTranslatedEmploymentTypes(participationList, t)}
      countriesList={countriesList}
      location={location}
      closePopup={closePopup}
      isSubmitLoading={isSubmitLoading}
      onSubmitForm={handleUpdateOpenForNewOpportunities}
      data={initialData}
    />
  );
};

export default OpenForNewOpportunitiesAddPopup;
