import cn from 'clsx';

import TextLocal from '@/src/textLocal/containers/TextLocal';
import {Text} from '@/src/ui';
import Toggle from '@/src/ui/toggle/components/Toggle';

import css from '../../styles/PricingSection.module.css';

interface BillTypeType {
  isYearly: boolean;
  onChange: (...args: any[]) => any;
  isSticky: boolean;
}

const BillType = ({isYearly, onChange, isSticky}: BillTypeType) => {
  return (
    <div className={cn(css.billTypeWrapper)}>
      <div
        className={cn(css.billTypeContent, {
          [css.billTypeContentSticky]: isSticky,
        })}
      >
        <div
          className={cn(css.billTypeContentText, css.billTypeContentTextLeft)}
        >
          <Text
            fontSize={Text.FONT_SIZE.SIZE_18}
            fontWeight={Text.FONT_WEIGHT.BOLD}
            color={!isYearly ? Text.COLOR.GRADIENT : Text.COLOR.PRIMARY}
          >
            <TextLocal id={'landing.billed-monthly'} />
          </Text>
        </div>
        <Toggle value={isYearly} onChange={onChange} />
        <div className={css.billTypeContentText}>
          <Text
            fontSize={Text.FONT_SIZE.SIZE_18}
            fontWeight={Text.FONT_WEIGHT.BOLD}
            color={isYearly ? Text.COLOR.GRADIENT : Text.COLOR.PRIMARY}
          >
            <TextLocal id={'landing.billed-annually'} />
          </Text>
        </div>
      </div>
    </div>
  );
};

export default BillType;
