import cn from 'clsx';
import Link from 'next/link';
import {useRouter} from 'next/router';
import PropTypes from 'prop-types';
import {useMemo} from 'react';

import {ROLES_NAMES} from '@/src/app/constants/roles';
import useRoutePaths from '@/src/hooks/useRoutePaths';
import TextLocal from '@/src/textLocal/containers/TextLocal';

import {Button, Text} from '../../../ui';
import css from '../../styles/InfoSection.module.css';

const CATALOG_FILES = {
  de: '/files/OP-Katalog.pdf',
  en: '/files/Surgery Catalog.pdf',
};

const CONTENT_TYPE = {
  SURGEON: 'surgeon',
  TRIAL: 'trial',
  CONTENT_CREATOR: 'content_creator',
};

const InfoSection = ({contentType}) => {
  const {SIGN_UP_ROUTE} = useRoutePaths();
  const {locale} = useRouter();

  const infoSectionContent = useMemo(
    () => ({
      [CONTENT_TYPE.SURGEON]: {
        title: 'infoSection.surgeonTitle',
        description: 'infoSection.surgeonDescription',
        buttonTitle: 'landing.download-catalog',
        supportLinkInfoSection: true,
        slug: CATALOG_FILES[locale],
        targetBlank: true,
      },
      [CONTENT_TYPE.TRIAL]: {
        title: 'infoSection.trialTitle',
        description: 'infoSection.trialDescription',
        buttonTitle: 'general.startFreeTrial',
        slug: `${SIGN_UP_ROUTE.getLink()}`,
        targetBlank: false,
        isWhiteButton: true,
      },
      [CONTENT_TYPE.CONTENT_CREATOR]: {
        title: 'infoSection.contentCreatorTitle',
        description: 'infoSection.contentCreatorDescription',
        buttonTitle: 'general.applyNow',
        slug: `${SIGN_UP_ROUTE.getLink()}/${ROLES_NAMES.COURSE_CREATOR}`,
        targetBlank: false,
      },
    }),
    []
  );

  return (
    <div className={css.infoSection}>
      <div className={css.content}>
        <div className={css.title}>
          <Text
            fontSize={Text.FONT_SIZE.SIZE_32}
            fontWeight={Text.FONT_WEIGHT.BOLD}
            color={Text.COLOR.WHITE}
            lineHeight={Text.LINE_HEIGHT.LINE_HEIGHT_1_4}
          >
            <TextLocal id={infoSectionContent[contentType].title} />
          </Text>
        </div>
        <div className={css.text}>
          <Text
            fontSize={Text.FONT_SIZE.SIZE_18}
            color={Text.COLOR.WHITE}
            lineHeight={Text.LINE_HEIGHT.LINE_HEIGHT_1_6}
          >
            <TextLocal id={infoSectionContent[contentType].description} />
          </Text>
        </div>
      </div>
      <div className={css.actions}>
        {/*@ts-ignore*/}
        {infoSectionContent[contentType].supportLink && (
          <div className={css.linkWrapper}>
            <a
              href="mailto:office@medyoucate.com"
              aria-label={`email to medyoucate office`}
            >
              <Button
                color={Button.COLOR.WHITE}
                tagComponent={Button.TAG.SPAN}
                height={Button.HEIGHT.SMALL}
              >
                <Text
                  fontSize={Text.FONT_SIZE.SIZE_16}
                  fontWeight={Text.FONT_WEIGHT.BOLD}
                >
                  <TextLocal id={'footer.contact-us'} />
                </Text>
              </Button>
            </a>
          </div>
        )}
        <Link href={infoSectionContent[contentType].slug}>
          <a
            target={infoSectionContent[contentType].targetBlank && '_blank'}
            className={cn(css.mainButton, {
              [css.mainButtonWhite]:
                infoSectionContent[contentType].isWhiteButton,
            })}
          >
            <Text
              fontSize={Text.FONT_SIZE.SIZE_16}
              fontWeight={Text.FONT_WEIGHT.BOLD}
              color={
                infoSectionContent[contentType].isWhiteButton
                  ? Text.COLOR.PRIMARY
                  : Text.COLOR.WHITE
              }
            >
              <TextLocal id={infoSectionContent[contentType].buttonTitle} />
            </Text>
          </a>
        </Link>
      </div>
    </div>
  );
};

InfoSection.CONTENT_TYPE = CONTENT_TYPE;

InfoSection.propTypes = {
  contentType: PropTypes.oneOf(Object.values(CONTENT_TYPE)),
};

InfoSection.defaultProps = {
  contentType: CONTENT_TYPE.TRIAL,
};

export default InfoSection;
