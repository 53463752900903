import getRootServerUrl from '@/src/app/utils/getRootServerUrl';
import css from '@/src/profile/clinicTarget/styles/ClinicImagesAndVideo.module.css';
import ImageWithAction from '@/src/ui/imageWithAction/components/ImageWithAction';

import Card from '../../../ui/card/components/Card';

interface ClinicImageProps {
  url: string;
  onImageClink: Function;
}

const ClinicImage = ({url, onImageClink}: ClinicImageProps): JSX.Element => {
  const link = `${getRootServerUrl()}/${url}`;

  return (
    <div
      onClick={onImageClink(link)}
      data-sortorder={url}
      className={css.image}
    >
      <Card size={Card.SIZE.SMALL}>
        <ImageWithAction src={link} type={'zoomIcon'} />
      </Card>
    </div>
  );
};

export default ClinicImage;
