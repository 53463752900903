import {sbEditable} from '@storyblok/storyblok-editable';
import PropTypes from 'prop-types';

import {Collapse, Text} from '../../../ui';
import css from '../../styles/FaqSection.module.css';
import CommonSectionLayout from '../CommonSectionLayout';

const FaqSection = ({blok}) => {
  const {title, subTitle, faqs, id} = blok;

  return (
    <div className={css.section} {...sbEditable(blok)} id={id}>
      <CommonSectionLayout
        title={title}
        content={
          <div className={css.content}>
            <Text
              color={Text.COLOR.GREY}
              lineHeight={Text.LINE_HEIGHT.LINE_HEIGHT_1_6}
            >
              {subTitle}
            </Text>
            <div className={css.faqList}>
              {faqs.map(({_uid, question, answer}) => (
                <div className={css.faqListItem} key={_uid}>
                  <Collapse
                    isRoundedHeader
                    headTitle={
                      <Text
                        lineHeight={Text.LINE_HEIGHT.LINE_HEIGHT_1_4}
                        fontSize={Text.FONT_SIZE.SIZE_16}
                        fontWeight={Text.FONT_WEIGHT.BOLD}
                      >
                        {question}
                      </Text>
                    }
                  >
                    <div className={css.faqListItemContent}>
                      <Text
                        color={Text.COLOR.GREY}
                        lineHeight={Text.LINE_HEIGHT.LINE_HEIGHT_1_6}
                      >
                        {answer}
                      </Text>
                    </div>
                  </Collapse>
                </div>
              ))}
            </div>
          </div>
        }
      />
    </div>
  );
};

FaqSection.propTypes = {
  blok: PropTypes.object.isRequired,
};

export default FaqSection;
