import {useTranslation} from 'next-i18next';
import PropTypes from 'prop-types';
import React from 'react';

const LOCALE_SOURCE = {
  COMMON: 'common',
  SERVICE: 'service',
  DOCTORS: 'doctors',
  IMPRINT: 'imprint',
};

const TextLocal = ({id, params, localeSource}) => {
  const {t} = useTranslation(localeSource);

  // @ts-ignore
  return <>{t(id, params)}</>;
};

TextLocal.LOCALE_SOURCE = LOCALE_SOURCE;

export default TextLocal;

TextLocal.propTypes = {
  id: PropTypes.string.isRequired,
  params: PropTypes.object,
  localeSource: PropTypes.oneOf(Object.values(LOCALE_SOURCE)),
};

TextLocal.defaultProps = {
  id: '',
  localeSource: 'common',
};
