import css from '../styles/DragIcon.module.css';

const DragIcon = () => {
  return (
    <div className={css.main}>
      <div className={css.dots} />
      <div className={css.dots} />
      <div className={css.dots} />
    </div>
  );
};

export default DragIcon;
