import {useTranslation} from 'next-i18next';
import {useCallback, useEffect, useState} from 'react';

import {RESPONSE_STATUSES} from '@/src/app/constants/api';
import requestGetEmploymentTypes from '@/src/profile/common/utils/requestGetEmploymentTypes';

export const prepareTranslatedEmploymentTypes = (
  types: Array<any>,
  t: Function
): Array<any> => {
  if (!types?.length) {
    return [];
  }

  return types.map((type) => ({
    value: type.value,
    label: t(`employment.${type.value}`),
  }));
};

const useEmploymentTypes = () => {
  const {t} = useTranslation();
  const [employmentTypes, setEmploymentTypes] = useState<Array<any>>([]);

  useEffect(() => {
    getJobTypes();
  }, []);

  const getJobTypes = useCallback(async () => {
    const {status, responseBody} = await requestGetEmploymentTypes();

    if (status === RESPONSE_STATUSES.SUCCESS) {
      const data = prepareTranslatedEmploymentTypes(responseBody, t);
      setEmploymentTypes(data);
    }
  }, []);

  return {
    employmentTypes,
  };
};

export default useEmploymentTypes;
