import cn from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

import Text from '../../text/components/Text';
import css from '../styles/Tooltip.module.css';

const POSITION = {
  RIGHT: 'right',
  TOP: 'top',
};

const MAX_WIDTH = {
  WIDTH_330: 'maxWidth330',
  WIDTH_600: 'maxWidth600',
};

const Tooltip = ({text, position, maxWidth}) => {
  if (!text) {
    return null;
  }
  return (
    <div className={cn(css.tooltip, css[position], css[maxWidth])}>
      <Text
        color={Text.COLOR.GREY}
        fontSize={Text.FONT_SIZE.SIZE_14}
        lineHeight={Text.LINE_HEIGHT.LINE_HEIGHT_1_6}
      >
        {text}
      </Text>
    </div>
  );
};

Tooltip.propTypes = {
  text: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.string,
  ]).isRequired,
  position: PropTypes.string,
  maxWidth: PropTypes.oneOf(Object.values(MAX_WIDTH)),
};

Tooltip.defaultProps = {
  position: POSITION.RIGHT,
  text: '',
  maxWidth: MAX_WIDTH.WIDTH_330,
};

Tooltip.POSITION = POSITION;
Tooltip.MAX_WIDTH = MAX_WIDTH;

export default Tooltip;
