import {sbEditable, SbEditableBlok} from '@storyblok/storyblok-editable';
import cn from 'clsx';

import DynamicComponent from '../../../dynamicComponents/components/DynamicComponent';
import css from '../styles/Container.module.css';

interface SectionContainerProps {
  blok: SbEditableBlok;
}

const SectionContainer = ({blok}: SectionContainerProps) => {
  const {widePaddingOnDesktop, body, id} = blok;

  return (
    <div
      id={id}
      {...sbEditable(blok)}
      key={blok._uid}
      className={cn(css.container, {
        [css.widePaddingOnDesktop]: widePaddingOnDesktop,
      })}
    >
      {body
        ? body.map((blok) => <DynamicComponent blok={blok} key={blok._uid} />)
        : null}
    </div>
  );
};

SectionContainer.defaultProps = {
  children: <></>,
};

export default SectionContainer;
