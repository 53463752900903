import {sbEditable} from '@storyblok/storyblok-editable';
import PropTypes from 'prop-types';

import ForWhomLayout from '@/src/landing/components/ForWhomLayout';

const ForWhomStoryblok = ({blok}) => {
  const {title, subTitle, description, image, isReverse} = blok;

  return (
    <ForWhomLayout
      title={title}
      subTitle={subTitle}
      description={description}
      image={image}
      isReverse={isReverse === 'true'}
      {...sbEditable(blok)}
      id={blok.id}
    />
  );
};

ForWhomStoryblok.propTypes = {
  blok: PropTypes.object.isRequired,
};

ForWhomStoryblok.defaultProps = {
  isReverse: false,
};

export default ForWhomStoryblok;
