import {REST_METHODS} from '@/src/app/constants/api';
import {getAuthorizationToken} from '@/src/app/utils/authorizationToken';
import authorizedFetch from '@/src/app/utils/authorizedFetch';
import unauthorizedFetch from '@/src/app/utils/unauthorizedFetch';

const requestGetEmploymentTypes = () => {
  const requestMethod = getAuthorizationToken()
    ? authorizedFetch
    : unauthorizedFetch;

  // @ts-ignore
  return requestMethod({
    path: `/api/v1/dict/employment_types`,
    method: REST_METHODS.GET,
  });
};

export default requestGetEmploymentTypes;
