import cn from 'clsx';

import {ImageWithActionProps} from '@/src/ui/imageWithAction/components/types/ImageWithActionTypes';

import LoadingIcon from '../../../../public/icons/loaing.svg';
import css from '../styles/ImageWithAction.module.css';

export const TEST_ID = {
  MAIN: 'ImageWithAction-main-test-id',
  LOADING: 'ImageWithAction-loading-test-id',
  BUTTON: 'ImageWithAction-button-test-id',
};

const ImageWithAction = ({
  src,
  alt,
  isLoading,
  type,
  onClick,
}: ImageWithActionProps) => {
  return (
    <div
      data-testid={TEST_ID.MAIN}
      className={cn(css.main, {
        [css.loadingImage]: isLoading,
      })}
    >
      {isLoading ? (
        <LoadingIcon data-testid={TEST_ID.LOADING} />
      ) : (
        <>
          <div
            onClick={onClick}
            className={cn(css.action, css[type])}
            data-testid={TEST_ID.BUTTON}
          />
          <img src={src} loading="lazy" alt={alt || ' '} />
        </>
      )}
    </div>
  );
};

export default ImageWithAction;
