import cn from 'clsx';
import {useField} from 'formik';
import React, {useCallback} from 'react';

import ResetIcon from '@/public/images/resetInput.svg';
import getVideoNameFromUrl from '@/src/app/utils/getVideoNameFromUrl';
import TextLocal from '@/src/textLocal/containers/TextLocal';

import Text from '../../text/components/Text';
import css from './../styles/fileInpute.module.css';

interface FileInputProps {
  placeholder: string;
  name: string;
  handleResetClick?: () => void;
  allowedFileTypes?: string;
  isRequired?: boolean;
  size?: 'small' | 'medium';
}

const FileInput = ({
  placeholder,
  handleResetClick,
  allowedFileTypes,
  isRequired,
  size,
  ...props
}: FileInputProps) => {
  // @ts-ignore
  const [, meta, helpers] = useField(props);
  const {setValue, setTouched} = helpers;
  const {value} = meta;
  const error = meta.touched && meta.error;

  const handleDeleteClick = useCallback((event) => {
    event.stopPropagation();
    setValue('');
  }, []);

  const handleChange = (event) => {
    setTouched(true);
    const file = event.target.files[0];

    if (!file) {
      return setValue('');
    }

    event.target.value = '';
    setValue(file);
  };

  return (
    <div className={cn(css.inputWrapper, css[size])}>
      {isRequired && <div className={css.requiredIcon}>*</div>}
      <label
        className={cn(css.label, {
          [css.errorLabel]: error,
        })}
      >
        <input
          type="file"
          onChange={handleChange}
          className={css.input}
          accept={allowedFileTypes}
        />
        <span
          className={cn(css.fileName, {
            [css.hasFile]: value || handleResetClick,
          })}
        >
          {value ? (
            <Text
              lineHeight={Text.LINE_HEIGHT.LINE_HEIGHT_24}
              isOneLine
              fontSize={Text.FONT_SIZE.SIZE_16}
            >
              {value.name || getVideoNameFromUrl(value)}
            </Text>
          ) : (
            <Text
              isOneLine
              lineHeight={Text.LINE_HEIGHT.LINE_HEIGHT_24}
              color={Text.COLOR.GREY}
              fontSize={Text.FONT_SIZE.SIZE_16}
            >
              {placeholder}
            </Text>
          )}
        </span>
      </label>
      {(Boolean(value) || handleResetClick) && (
        <span
          className={css.closeIcon}
          onClick={handleResetClick ? handleResetClick : handleDeleteClick}
        >
          <ResetIcon />
        </span>
      )}
      {Boolean(error) && <div className={css.errorField}>{error}</div>}
    </div>
  );
};

FileInput.defaultProps = {
  placeholder: <TextLocal id="general.upload-file" />,
};

export default FileInput;
