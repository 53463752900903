import {useState} from 'react';

const useLoading = () => {
  const [isLoading, setLoading] = useState(false);
  const toggleLoading = () => {
    setLoading(!isLoading);
  };

  return {
    isLoading,
    toggleLoading,
    setLoading,
  };
};

export default useLoading;
