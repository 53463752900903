import {REST_METHODS} from '@/src/app/constants/api';
import authorizedFetch from '@/src/app/utils/authorizedFetch';

interface requestUpdateOpenForNewOpportunitiesProps {
  countryId: number;
  workType: 'full_time' | 'part_time' | 'seasonal' | 'temporary';
  positions: Array<number>;
}
const requestUpdateOpenForNewOpportunities = ({
  countryId,
  workType,
  positions,
}: requestUpdateOpenForNewOpportunitiesProps): Promise<any> => {
  // @ts-ignore
  return authorizedFetch({
    method: REST_METHODS.POST,
    path: `/api/v1/profiles/open_for_new_job_opportunities`,
    body: {
      open_for_new_job_opportunity: {
        country_id: countryId,
        work_type: workType,
        preferable_position_ids: positions,
      },
    },
  });
};

export default requestUpdateOpenForNewOpportunities;
