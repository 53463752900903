import cn from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

import DynamicComponent from '../../../dynamicComponents/components/DynamicComponent';
import css from '../styles/GradientHeadLine.module.css';

const GradientHeadLine = ({blok}) => {
  const {offsetBottom} = blok;
  return (
    <div className={cn(css.wrapper, css[offsetBottom])}>
      {blok.body
        ? blok.body.map((blok) => (
            <DynamicComponent blok={blok} key={blok._uid} />
          ))
        : null}
    </div>
  );
};

GradientHeadLine.propTypes = {
  blok: PropTypes.object.isRequired,
};

export default GradientHeadLine;
