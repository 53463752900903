import cn from 'clsx';
import PropTypes from 'prop-types';
import {Collapse as CollapseFromLibrary} from 'react-collapse';

import ArrowIcon from '@/public/icons/arrowDark.svg';

import css from '../styles/collapse.module.css';
import DragIcon from './DragIcon';

/**
 * Accordion to show and Hide content
 *
 * @param {JSX} children
 * @param {boolean} headTitle
 * @param {JSX | string} headDescription
 * @param {boolean} defaultIsOpen
 * @param {JSX | string} actions
 * @return {JSX}
 */

const INNER_PADDING = {
  DEFAULT: 'default',
  NO_PADDING: 'noPadding',
};

const ControlledCollapse = ({
  children,
  headTitle,
  headDescription,
  isOpen,
  toggleIsOpen,
  actions,
  innerPadding,
  isDraggable,
  dragHandleProps,
  collapseRef,
  hideArrow,
  isCollapseOnArrow,
  isGrayOnOpen,
}) => {
  return (
    <div>
      <div
        ref={collapseRef}
        {...dragHandleProps}
        className={cn(css.header, {
          [css.open]: !isGrayOnOpen && isOpen,
          [css.hideArrow]: hideArrow,
          [css.headerWithoutHeader]: isCollapseOnArrow,
        })}
        onClick={!isCollapseOnArrow ? toggleIsOpen : undefined}
      >
        <>
          {isDraggable && (
            <div className={css.dragIcon}>
              <DragIcon />
            </div>
          )}
          {!hideArrow && (
            <div
              onClick={isCollapseOnArrow ? toggleIsOpen : undefined}
              className={cn(css.arrowIndicator, {
                [css.arrowIndicatorOpen]: isOpen,
              })}
            >
              <ArrowIcon />
            </div>
          )}

          {headTitle}
          {Boolean() && (
            <span className={css.description}>{headDescription}</span>
          )}
        </>
        <div className={css.actionsWrapper}>{actions}</div>
      </div>
      <CollapseFromLibrary isOpened={isOpen} theme={{collapse: css.content}}>
        <div className={css[innerPadding]}>{children}</div>
      </CollapseFromLibrary>
    </div>
  );
};

ControlledCollapse.INNER_PADDING = INNER_PADDING;

ControlledCollapse.propTypes = {
  children: PropTypes.node.isRequired,
  headTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  headDescription: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  innerPadding: PropTypes.oneOf(Object.values(INNER_PADDING)).isRequired,
  isOpen: PropTypes.bool,
  isDraggable: PropTypes.bool,
  actions: PropTypes.node,
  toggleIsOpen: PropTypes.func,
  dragHandleProps: PropTypes.object,
  collapseRef: PropTypes.object,
  isCollapseOnArrow: PropTypes.bool,
  isGrayOnOpen: PropTypes.bool,
};

ControlledCollapse.defaultProps = {
  innerPadding: INNER_PADDING.DEFAULT,
  isDraggable: false,
  dragHandleProps: {},
  collapseRef: null,
  hideArrow: false,
};

export default ControlledCollapse;
