import {sbEditable} from '@storyblok/storyblok-editable';
import PropTypes from 'prop-types';

import css from '../styles/WhyBestItem.module.css';
import WhyBestItem from './WhyBestItem';

const WhyBestList = ({blok}) => {
  const {list} = blok;

  return (
    <div className={css.main} {...sbEditable(blok)}>
      {list.map(({title, list, icon, _uid}) => (
        <WhyBestItem key={_uid} list={list} title={title} icon={icon} />
      ))}
    </div>
  );
};

WhyBestList.propTypes = {
  blok: PropTypes.object.isRequired,
};

export default WhyBestList;
