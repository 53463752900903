import PropTypes from 'prop-types';

import OpenForNewOpportunities from '@/src/profile/common/containers/OpenForNewOpportunities';

import FooterContainer from '../../../footer/containers/FooterContainer';
import Header from '../../../header/containers/Header';
import {ApplicationContainer, Text} from '../../../ui';
import MyProfileNavbar from '../containers/MyProfileNavbar';
import css from '../styles/MyProfileWrapperLayout.module.css';
import MyProfileVisitBlock from './MyProfileVisitBlock';
import ProfileHeaderImages from './ProfileHeaderImages';

const MyProfileWrapperLayout = ({
  children,
  userInitials,
  userFullName,
  onVisitMyProfileClick,
  currentPath,
  onNavbarLinkClick,
  onEditProfileImageClick,
  onEditCoverImageClick,
  coverImage,
  picture,
  userSubheader,
  userId,
  isOpenForNewOpportunities,
  isClinic,
  isContentCreator,
  isCustomer,
}) => {
  return (
    <ApplicationContainer>
      <div className={css.wrapper}>
        <div className={css.contentWrapper}>
          <Header />
          <ProfileHeaderImages
            userInitials={userInitials}
            onEditProfileImageClick={onEditProfileImageClick}
            onEditCoverImageClick={onEditCoverImageClick}
            picture={picture}
            coverImage={coverImage}
          />
          <div className={css.headings}>
            <Text
              fontSize={Text.FONT_SIZE.SIZE_32}
              fontFamily={Text.FONT_FAMILY.RUBIK}
              fontWeight={Text.FONT_WEIGHT.MEDIUM}
              lineHeight={Text.LINE_HEIGHT.LINE_HEIGHT_24}
            >
              {userFullName}
            </Text>
            <div className={css.userType}>
              <Text fontSize={Text.FONT_SIZE.SIZE_18} color={Text.COLOR.GREY}>
                {userSubheader}
              </Text>
            </div>
          </div>
          <div className={css.content}>
            <div className={css.contentBlock}>
              <MyProfileNavbar
                currentPath={currentPath}
                onNavbarLinkClick={onNavbarLinkClick}
              />
              {children}
            </div>
            <div>
              <div className={css.rightSidebar}>
                <MyProfileVisitBlock
                  onVisitMyProfileClick={onVisitMyProfileClick}
                  isClinic={isClinic}
                  isContentCreator={isContentCreator}
                />
                {!isClinic && !isCustomer && (
                  <OpenForNewOpportunities
                    userId={userId}
                    isOpenForNewOpportunities={isOpenForNewOpportunities}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <FooterContainer />
      </div>
    </ApplicationContainer>
  );
};

MyProfileWrapperLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  userInitials: PropTypes.string.isRequired,
  userFullName: PropTypes.string.isRequired,
  userSubheader: PropTypes.string,
  onVisitMyProfileClick: PropTypes.func.isRequired,
  currentPath: PropTypes.string,
  onNavbarLinkClick: PropTypes.func.isRequired,
  onEditProfileImageClick: PropTypes.func.isRequired,
  onEditCoverImageClick: PropTypes.func.isRequired,
  coverImage: PropTypes.string,
  picture: PropTypes.string,
  userId: PropTypes.number.isRequired,
  isOpenForNewOpportunities: PropTypes.bool.isRequired,
  isClinic: PropTypes.bool.isRequired,
  isContentCreator: PropTypes.bool.isRequired,
  isCustomer: PropTypes.bool,
};

export default MyProfileWrapperLayout;
