import WarningIcon from '@/public/icons/fullfiledWarningIcon.svg';
import css from '@/src/header/styles/TrialDaysLeft.module.css';

const CommonWarningBanner = ({children}: {children: JSX.Element}) => {
  return (
    <div className={css.main}>
      <div className={css.content}>
        <span className={css.warningIcon}>
          <WarningIcon />{' '}
        </span>
        {children}
      </div>
    </div>
  );
};

export default CommonWarningBanner;
