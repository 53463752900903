import cn from 'clsx';

import useIsMobile from '@/src/hooks/useIsMobile';
import {SectionContainer} from '@/src/ui';

import css from '../../styles/MedicalLearning.module.css';

const Tags = ({
  studentTitle,
  doctorTitle,
  contentCreatorTitle,
  clinicTitle,
}) => {
  return (
    <div>
      <div className={cn(css.tag, css.studentTag)}>
        <span>{studentTitle}</span>
      </div>
      <div className={cn(css.tag, css.contentCreatorTag)}>
        <span>{contentCreatorTitle}</span>
      </div>
      <div className={cn(css.tag, css.doctorTag)}>
        <span>{doctorTitle}</span>
      </div>
      <div className={cn(css.tag, css.clinicTag)}>
        <span>{clinicTitle}</span>
      </div>
    </div>
  );
};

const MedicalLearning = ({blok}) => {
  const {
    studentTitle,
    doctorTitle,
    contentCreatorTitle,
    clinicTitle,
    sideTitle,
    id,
  } = blok;
  const {isMaxWidth1200} = useIsMobile();

  return (
    <SectionContainer>
      <div
        className={cn(css.main, {
          [css.mobileMain]: isMaxWidth1200,
        })}
        id={id}
      >
        <div className={css.leftContent}>
          <h2 className={css.title}>{sideTitle}</h2>
          {isMaxWidth1200 && (
            <Tags
              studentTitle={studentTitle}
              doctorTitle={doctorTitle}
              contentCreatorTitle={contentCreatorTitle}
              clinicTitle={clinicTitle}
            />
          )}
        </div>

        {!isMaxWidth1200 && (
          <div className={css.rightContent}>
            <Tags
              studentTitle={studentTitle}
              doctorTitle={doctorTitle}
              contentCreatorTitle={contentCreatorTitle}
              clinicTitle={clinicTitle}
            />
          </div>
        )}
      </div>
    </SectionContainer>
  );
};

export default MedicalLearning;
