import {Form, Formik} from 'formik';
import {useTranslation} from 'next-i18next';
import React from 'react';

import {PLACE_SELECTS_WIDTH} from '@/src/signUp/emailStep/constants/selectsWidthConstants';
import CountriesSelect from '@/src/signUp/emailStep/containers/CountriesSelect';
import TextLocal from '@/src/textLocal/containers/TextLocal';
import {LoadingWrapper, Select, Text} from '@/src/ui';
import {Button} from '@/src/ui';
import FormikMultiSelect from '@/src/ui/select/containers/FormikMultiSelect';

import css from '../styles/OpenForNewOpportunitiesAddPopup.module.css';

interface OpenForNewOpportunitiesAddPopupLayoutProps {
  validationSchema: object;
  isLoading: boolean;
  positionsList: Array<any>;
  participationList: Array<any>;
  countriesList: Array<any>;
  location: {
    id: number;
  };
  closePopup: (...args: any[]) => any;
  isSubmitLoading: boolean;
  onSubmitForm: (...args: any[]) => any;
  data: object;
}

const OpenForNewOpportunitiesAddPopupLayout = ({
  validationSchema,
  isLoading,
  positionsList,
  participationList,
  countriesList,
  location,
  closePopup,
  isSubmitLoading,
  onSubmitForm,
  data,
}: OpenForNewOpportunitiesAddPopupLayoutProps): JSX.Element => {
  const {t} = useTranslation('common');

  return (
    <div className={css.main}>
      <div className={css.title}>
        <Text
          fontSize={Text.FONT_SIZE.SIZE_32}
          fontWeight={Text.FONT_WEIGHT.MEDIUM}
          fontFamily={Text.FONT_FAMILY.RUBIK}
        >
          <TextLocal id={'open-opportunities.open-for-new-job-opportunities'} />
        </Text>
      </div>
      <div className={css.formWrapper}>
        <LoadingWrapper
          isLoading={
            isLoading || !positionsList.length || !countriesList.length
          }
          hideContentBeforeLoad
        >
          <Formik
            // @ts-ignore
            initialValues={{
              positions: null,
              participation: null,
              country: null,
              ...data,
            }}
            validationSchema={validationSchema}
            enableReinitialize
            onSubmit={onSubmitForm}
          >
            <Form>
              <div className={css.content}>
                <div className={css.inputWrapper}>
                  <FormikMultiSelect
                    isRequired
                    options={positionsList}
                    name={'positions'}
                    placeholder={t('open-opportunities.preferable-positions')}
                  />
                </div>

                <div className={css.inputWrapper}>
                  {/*@ts-ignore*/}
                  <Select
                    isRequired
                    placeholder={t('open-opportunities.participation')}
                    name={'participation'}
                    options={participationList}
                  />
                </div>

                <div className={css.inputWrapper}>
                  {/*@ts-ignore*/}
                  <CountriesSelect
                    isRequired={true}
                    defaultCountryId={location.id ? location.id : null}
                    name={'country'}
                    countriesDictionary={countriesList}
                    width={PLACE_SELECTS_WIDTH.WIDTH_100PR}
                  />
                </div>

                <div className={css.description}>
                  <Text
                    fontSize={Text.FONT_SIZE.SIZE_12}
                    color={Text.COLOR.GREY}
                  >
                    <TextLocal
                      id={'open-opportunities.your-profile-will-be-available'}
                    />
                  </Text>
                </div>
              </div>
              <div className={css.buttons}>
                <button
                  className={css.cancelButton}
                  type={'button'}
                  onClick={closePopup}
                >
                  <TextLocal id={'general.cancel'} />
                </button>
                <Button
                  type={Button.TYPES.SUBMIT}
                  height={Button.HEIGHT.SMALL}
                  isLoading={isSubmitLoading}
                >
                  <TextLocal id={'open-opportunities.add-to-my-profile'} />
                </Button>
              </div>
            </Form>
          </Formik>
        </LoadingWrapper>
      </div>
    </div>
  );
};

export default OpenForNewOpportunitiesAddPopupLayout;
