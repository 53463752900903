import cn from 'clsx';
import NavLink from 'next/link';
import {useState} from 'react';
import {Collapse} from 'react-collapse';

import PriceFeaturesTable from '@/src/promoSections/components/PricingSectionLayout/PriceFeaturesTable';
import css from '@/src/promoSections/styles/PricingSection.module.css';
import TextLocal from '@/src/textLocal/containers/TextLocal';
import {Button, Text} from '@/src/ui';

interface PriceCardMobileProps {
  title: string;
  price: string | number;
  yearlyPrice: string | number;
  discount: string | number;
  description: string;
  slug: string;
  buttonText: string;
  oldPrice: string | number;
  oldPriceYearly: string | number;
  features: Array<string>;
  currentSymbol: string;
  isYearly: boolean;
}

const PriceCardMobile = ({
  title,
  price,
  yearlyPrice,
  discount,
  description,
  slug,
  buttonText,
  oldPrice,
  oldPriceYearly,
  features,
  currentSymbol,
  isYearly,
}: PriceCardMobileProps) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={css.priceCard}>
      <div className={css.priceInfoText}>
        <Text
          fontSize={Text.FONT_SIZE.SIZE_24}
          fontFamily={Text.FONT_FAMILY.RUBIK}
          fontWeight={Text.FONT_WEIGHT.MEDIUM}
        >
          {title}
        </Text>
      </div>

      <div className={css.priceInfoMobile}>
        {discount && (
          <div className={css.oldPriceMobile}>
            {currentSymbol}
            {isYearly ? oldPriceYearly : oldPrice}
          </div>
        )}

        <div className={css.priceWrapper}>
          <div className={css.priceCurrent}>
            <Text
              fontSize={Text.FONT_SIZE.SIZE_32}
              fontWeight={Text.FONT_WEIGHT.MEDIUM}
              fontFamily={Text.FONT_FAMILY.RUBIK}
              lineHeight={Text.LINE_HEIGHT.LINE_HEIGHT_1_6}
            >
              {currentSymbol}
              {isYearly ? yearlyPrice : price}
            </Text>
          </div>{' '}
          {discount && <div className={css.discount}>-{discount}%</div>}
        </div>

        <div className={css.priceDescription}>
          <Text fontSize={Text.FONT_SIZE.SIZE_12} color={Text.COLOR.GREY}>
            {description}
          </Text>
        </div>
      </div>

      <div className={css.priceButtonMobile}>
        <NavLink href={slug}>
          <Button height={Button.HEIGHT.SMALL}>{buttonText}</Button>
        </NavLink>
      </div>

      <div className={css.mobileFeatureToggle}>
        <div onClick={() => setIsOpen(!isOpen)}>
          <Text
            color={Text.COLOR.GRADIENT}
            fontSize={Text.FONT_SIZE.SIZE_14}
            fontWeight={Text.FONT_WEIGHT.BOLD}
          >
            <TextLocal
              id={isOpen ? 'general.less-details' : 'general.more-details'}
            />
            <span
              className={cn(css.mobileFeatureToggleArrow, {
                [css.mobileFeatureToggleArrowOpen]: isOpen,
              })}
            />
          </Text>
        </div>
      </div>

      <Collapse isOpened={isOpen} theme={{collapse: css.collapseContent}}>
        <div className={css.priceFeaturesMobile}>
          <PriceFeaturesTable features={features} />
        </div>
      </Collapse>
    </div>
  );
};

export default PriceCardMobile;
