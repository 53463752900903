import {sbEditable, SbEditableBlok} from '@storyblok/storyblok-editable';
import cn from 'clsx';
import PropTypes from 'prop-types';

import css from '../styles/Text.module.css';

interface TextProps {
  blok: SbEditableBlok;
}

const Text = ({blok}: TextProps) => {
  const {
    fontSize,
    fontWeight,
    color,
    fontFamily,
    lineHeight,
    transform,
    className,
    isOneLine,
    tag: TagComponent,
    display,
    text,
  } = blok;
  return (
    <TagComponent
      {...sbEditable(blok)}
      className={cn(
        css.text,
        css[fontSize],
        css[fontWeight],
        css[color],
        css[fontFamily],
        css[lineHeight],
        css[transform],
        css[display],
        className,
        {
          [css.oneLine]: isOneLine,
        }
      )}
    >
      {text}
    </TagComponent>
  );
};

Text.propTypes = {
  blok: PropTypes.object.isRequired,
};

export default Text;
