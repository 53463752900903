import cn from 'clsx';
import PropTypes from 'prop-types';

import useIsMobile from '@/src/hooks/useIsMobile';

import {Carousel, Text} from '../../ui';
import css from '../styles/TrustedSection.module.css';

const TrustedSection = ({blok}) => {
  const {images, title, background, speed, sliderOff} = blok;

  const {isMaxWidth900} = useIsMobile();

  const imagesJSX =
    images &&
    images.map(({image, link}) => {
      const Tag = link ? 'a' : 'div';
      return (
        <Tag key={image.id} href={link?.url} target={'_blank'}>
          <div className={css.trustedBox} key={image.id}>
            <div>
              <img src={image.filename} loading="lazy" alt="" />
            </div>
          </div>
        </Tag>
      );
    });

  if (sliderOff) {
    return (
      <div
        className={cn(css.trustedSection, {
          [css.backgroundOff]: background,
        })}
      >
        <div className={css.trustedTitle}>
          <Text
            fontSize={Text.FONT_SIZE.SIZE_18}
            fontWeight={Text.FONT_WEIGHT.NORMAL}
            color={Text.COLOR.GREY}
          >
            {title}
          </Text>
        </div>

        <Carousel
          //@ts-ignore
          observer={true}
          observeParents={true}
          withAutoplay
          offset={isMaxWidth900 ? Carousel.OFFSET.TINY : Carousel.OFFSET.SMALL}
          settings={{
            observer: true,
            observeParents: true,
            loop: true,
            pagination: false,
            navigation: false,
            slidesPerView: 'auto',
            allowTouchMove: false,
            spaceBetween: 0,
            centeredSlides: true,
            speed: speed || 4000,
            autoplay: {
              delay: 2,
            },
          }}
        >
          {imagesJSX}
        </Carousel>
      </div>
    );
  }

  return (
    <div
      className={cn(css.trustedSection, {
        [css.backgroundOff]: background,
      })}
    >
      <div className={css.trustedTitle}>
        <Text
          fontSize={Text.FONT_SIZE.SIZE_18}
          fontWeight={Text.FONT_WEIGHT.NORMAL}
          color={Text.COLOR.GREY}
        >
          {title}
        </Text>
      </div>

      <div className={css.imagesInline}>{imagesJSX}</div>
    </div>
  );
};

TrustedSection.propTypes = {
  blok: PropTypes.object.isRequired,
};

export default TrustedSection;
