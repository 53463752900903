import 'react-popper-tooltip/dist/styles.css';

import cn from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import {usePopperTooltip} from 'react-popper-tooltip';

import useIsMobile from '@/src/hooks/useIsMobile';

import Text from '../../text/components/Text';
import css from '../styles/Tooltip.module.css';
import Tooltip from './Tooltip';

const TooltipWrapper = ({trigger, tooltip, placement}) => {
  const {isMaxWidth1200} = useIsMobile();
  const {
    getArrowProps,
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
    visible,
  } = usePopperTooltip(
    {offset: [0, 10]},
    {placement: isMaxWidth1200 ? 'auto' : placement}
  );
  return (
    <div className={css.tooltipWrapper}>
      <div ref={setTriggerRef}>{trigger}</div>
      {visible && (
        <div
          ref={setTooltipRef}
          {...getTooltipProps({
            className: cn('tooltip-container', css.tooltip),
            'data-placement': 'right',
          })}
        >
          <Text
            color={Text.COLOR.GREY}
            fontSize={Text.FONT_SIZE.SIZE_14}
            lineHeight={Text.LINE_HEIGHT.LINE_HEIGHT_1_6}
          >
            {tooltip}
          </Text>
          <div
            {...getArrowProps({
              className: cn('tooltip-arrow'),
            })}
          />
        </div>
      )}
    </div>
  );
};

TooltipWrapper.defaultProps = {
  placement: Tooltip.POSITION.RIGHT,
};

TooltipWrapper.propTypes = {
  placement: PropTypes.oneOf(Object.values(Tooltip.POSITION)),
  trigger: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.string,
  ]).isRequired,
  tooltip: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.string,
  ]).isRequired,
};

TooltipWrapper.POSITION = Tooltip.POSITION;
TooltipWrapper.MAX_WIDTH = Tooltip.MAX_WIDTH;

export default TooltipWrapper;
