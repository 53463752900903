import {useMemo} from 'react';

import PriceCard from '@/src/promoSections/components/PricingSectionLayout/PriceCard';
import PriceFeaturesTable from '@/src/promoSections/components/PricingSectionLayout/PriceFeaturesTable';
import css from '@/src/promoSections/styles/PricingSection.module.css';

const getArrayOfMaxElements = (first, second) => {
  const maxArray = Math.max(first.length, second.length);

  return new Array(maxArray).fill(undefined).map((value, index) => index);
};

interface PricingTabletProps {
  pricingList: Array<any>;
  doctorsList: Array<any>;
  studentList: Array<any>;
  contentCreatorList: Array<any>;
  clinicList: Array<any>;
  currencySymbol: string;
  isYearly: boolean;
}

const PricingTablet = ({
  pricingList,
  doctorsList,
  studentList,
  contentCreatorList,
  clinicList,
  currencySymbol,
  isYearly,
}: PricingTabletProps) => {
  const maxArrayOfFeaturesFirstRow = useMemo(
    () => getArrayOfMaxElements(doctorsList, studentList),
    [doctorsList, studentList]
  );

  const maxArrayOfFeaturesSecondRow = useMemo(
    () => getArrayOfMaxElements(contentCreatorList, clinicList),
    [contentCreatorList, clinicList]
  );

  const preparedArrayFirstRow = useMemo(
    () =>
      maxArrayOfFeaturesFirstRow.reduce(
        (previousValue, currentValue) => [
          ...previousValue,
          [doctorsList[currentValue], studentList[currentValue]],
        ],
        []
      ),
    [doctorsList, studentList]
  );

  const preparedArraySecondRow = useMemo(
    () =>
      maxArrayOfFeaturesSecondRow.reduce(
        (previousValue, currentValue) => [
          ...previousValue,
          [contentCreatorList[currentValue], clinicList[currentValue]],
        ],
        []
      ),
    [contentCreatorList, clinicList]
  );

  const pricingCardNodesFistRow = useMemo(
    () =>
      pricingList[0].map((props) => {
        return (
          <PriceCard
            {...props} // @ts-ignore
            key={props}
            currentSymbol={currencySymbol}
            isYearly={isYearly}
          />
        );
      }),
    [pricingList]
  );

  const pricingCardNodesSecondRow = useMemo(
    () =>
      pricingList[1].map((props) => {
        return (
          <PriceCard
            {...props}
            key={props}
            currentSymbol={currencySymbol}
            isYearly={isYearly}
          />
        );
      }),
    [pricingList]
  );

  return (
    <div className={css.tabletPriceWrapper}>
      <div>
        <div className={css.pricingWrapperTablet}>
          {pricingCardNodesFistRow}
        </div>
        <div className={css.priceFeaturesWrapper}>
          {preparedArrayFirstRow.map((arrayOfFeatures) => (
            <div className={css.priceFeaturesTablet} key={arrayOfFeatures}>
              <PriceFeaturesTable features={arrayOfFeatures} />
            </div>
          ))}
        </div>
      </div>

      <div>
        <div className={css.pricingWrapperTablet}>
          {pricingCardNodesSecondRow}
        </div>
        <div className={css.priceFeaturesWrapper}>
          {preparedArraySecondRow.map((arrayOfFeatures) => (
            <div className={css.priceFeaturesTablet} key={arrayOfFeatures}>
              <PriceFeaturesTable features={arrayOfFeatures} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PricingTablet;
