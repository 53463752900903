import Image from 'next/image';
import PropTypes from 'prop-types';

import CoverUserImage from '@/src/profile/common/components/CoverUserImage';
import UserAvatar from '@/src/profile/common/components/UserAvatar';

import css from '../styles/ProfileHeaderImages.module.css';

const ProfileHeaderImages = ({
  userInitials,
  onEditProfileImageClick,
  onEditCoverImageClick,
  coverImage,
  picture,
  withEditButtons,
}) => {
  const editImageNode = (
    <Image
      src={`/icons/editIcon.svg`}
      loading="lazy"
      alt="true"
      width={14}
      height={14}
    />
  );

  return (
    <div className={css.container}>
      <CoverUserImage
        coverImage={coverImage}
        withEditButtons={withEditButtons}
        onEditCoverImageClick={onEditCoverImageClick}
        editImageNode={editImageNode}
      />
      <div className={css.profileImageBlock}>
        <UserAvatar
          picture={picture}
          withEditButtons={withEditButtons}
          onEditProfileImageClick={onEditProfileImageClick}
          editImageNode={editImageNode}
          userInitials={userInitials}
        />
      </div>
    </div>
  );
};

ProfileHeaderImages.propTypes = {
  userInitials: PropTypes.string.isRequired,
  coverImage: PropTypes.string,
  picture: PropTypes.string,
  onEditProfileImageClick: PropTypes.func,
  onEditCoverImageClick: PropTypes.func,
  withEditButtons: PropTypes.bool,
};

ProfileHeaderImages.defaultProps = {
  withEditButtons: true,
};

export default ProfileHeaderImages;
