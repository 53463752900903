import isImage from 'is-image';

const checkIsFileMedia = (src) => {
  return isImage(src) || isPdf(src);
};

const isPdf = (src) => {
  const srcArr = src.split('.');
  return srcArr[srcArr.length - 1].includes('pdf');
};

const isVideo = (src) => {
  const srcArr = src.split('.');
  return srcArr[srcArr.length - 1].includes('mp4');
};

export {isImage, isPdf, isVideo, checkIsFileMedia as default};
