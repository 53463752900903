import {REST_METHODS} from '@/src/app/constants/api';
import unauthorizedFetch from '@/src/app/utils/unauthorizedFetch';

const requestSubscribeUser = (email) => {
  // @ts-ignore
  return unauthorizedFetch({
    method: REST_METHODS.POST,
    path: '/api/v1/marketing_subscribers',
    body: {
      email,
    },
  });
};

export default requestSubscribeUser;
