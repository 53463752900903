import {REST_METHODS} from '../constants/api';

const nextServerFetch = async ({method = REST_METHODS.POST, path, body}) => {
  const response = await fetch(path, {
    method: method,
    body: JSON.stringify(body),
  });

  let parsedResponse;

  try {
    parsedResponse = await response.json();
  } catch (error) {
    parsedResponse = null;
  }

  return {
    responseBody: parsedResponse,
    status: response.status,
  };
};
export default nextServerFetch;
